import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IPartnerInfo } from '../../../interfaces/partner';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setLoading } from '../../../store/modules/loading';
import { useTranslation } from 'react-i18next';
import { QION_ID } from '../../../constants/qion-id.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { ROLES } from '../../../constants/user-roles.constants';
import { IOptions } from '../../../components/select-input.component';

export function useGetPartners() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { role } = useSelector((state: RootState) => state.user);

	const [partnerOptions, setPartnerOptions] = useState<IOptions[]>([]);

	const {
		execute: getPartners,
		value: getPartnersVal,
		status: getPartnersStatus,
	} = useBackendRequest<any, IPartnerInfo[]>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getPartnersStatus === 'success') {
			if (getPartnersVal?.data) {
				const partners = getPartnersVal?.data?.filter((p) => p.id !== QION_ID);
				getSelectValues(partners);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getPartnersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnersStatus]);

	const getSelectValues = (partners: IPartnerInfo[]): void => {
		const arrOptions: IOptions[] = partners.map((p) => {
			return {
				label: p.name,
				value: p.id,
			};
		});
		setPartnerOptions(arrOptions);
		dispatch(setLoading({ loading: false }));
	};

	useEffect(() => {
		if (role.roleName === ROLES.SUPER_ADMIN) {
			dispatch(setLoading({ loading: true }));
			getPartners();
		}
	}, []);

	return { partnerOptions };
}
