import styles from './styles.component.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { hexToRGBA } from '../../../../utils/hex-to-rgba';

interface ICard {
	title: string;
	total: string | number;
}

const Card = ({ title, total }: ICard) => {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<div
			className={styles.card}
			style={{
				backgroundColor: hexToRGBA(colors.secondary, 0.2),
				border: `1px solid ${colors.secondary}`,
			}}
		>
			<span className={styles.name}>{title}:</span>
			<span className={styles.total}>{total}</span>
		</div>
	);
};

export default Card;
