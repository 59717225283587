import React, { useState } from 'react';
import { RadioButton } from '../../../components/radio-button.component';

import styles from './styles/settings-language.module.css';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
	availableLanguages,
	useLanguagesCatalog,
} from '../../../utils/languages-catalog';
import { ROLES } from '../../../constants/user-roles.constants';

const SettingsLanguage = () => {
	const [t, i18n] = useTranslation('global');
	const { languages } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const { getLanguageLabel } = useLanguagesCatalog();

	const [selectedValue, setSelectedValue] = useState<string>(
		localStorage.getItem('language') ? localStorage.getItem('language')! : 'en'
	);

	const handleLanguageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		i18n.changeLanguage(event.target.value);
		setSelectedValue(event.target.value);
		localStorage.setItem('language', event.target.value);
	};

	return (
		<div className={styles.langCard}>
			<span className={styles.title}>{t('languages.languagesTittle')}</span>
			{role.roleName === ROLES.SUPER_ADMIN && !simulating
				? availableLanguages.map((l) => (
						<RadioButton
							name="radio-language"
							id={l}
							value={l}
							checked={selectedValue === l}
							label={getLanguageLabel(l)}
							onClick={(e) => handleLanguageChange(e)}
						/>
				  ))
				: languages.map((l) => (
						<RadioButton
							name="radio-language"
							id={l}
							value={l}
							checked={selectedValue === l}
							label={getLanguageLabel(l)}
							onClick={(e) => handleLanguageChange(e)}
						/>
				  ))}
		</div>
	);
};

export default SettingsLanguage;
