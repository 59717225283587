export const generateCSV = (base64: string, extension: string, nombre: string) => {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new File([byteArray], 'file.csv', {
		type: 'text/csv',
	});
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', nombre + '.' + extension);
	link.click();
	/* window.open(url, '_blank');
    window.URL.revokeObjectURL(url); */
};
