import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { IGunsStatsPag } from '../../../../../interfaces/guns-stats.interface';
import { setLoading } from '../../../../../store/modules/loading';
import { RootState } from '../../../../../store';
import { useTransactionsTabAdapter } from '../adapters/transaction-tab.adapter';
import { useMonthSelector } from '../../../../../hooks/useMonthSelector';
import {
	IReportDownloadParams,
	IReportDownloadResponse,
} from '../../../../../interfaces/reports.interface';
import { generatePDF } from '../../../../../utils/generatePDF';
import { generateCSV } from '../../../../../utils/generateCSV';
import { setAlert } from '../../../../../store/modules/snackbar';
import dayjs from 'dayjs';

export function useTransactionsTab() {
	const dispatch = useDispatch();
	const { partnerGuns, partnerId } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating, simulatedPartnerGuns } = useSelector(
		(state: RootState) => state.simulation
	);

	const { actualMonth, getDateStartFormated, getDateEndFormated } = useMonthSelector();

	const { transactionsTabAdapter } = useTransactionsTabAdapter();
	const { data, setData, pagination, rowsPerPage } = useTableCustom<any>();

	const [dateFilter, setDateFilter] = useState<{
		datetime_charging_start: string;
		datetime_charging_end: string;
	}>({ datetime_charging_start: '', datetime_charging_end: '' });

	const [station, setStation] = useState<string>('');

	const {
		execute: getChargeFills,
		value: chargeFillsVal,
		status: chargeFillsStatus,
	} = useBackendRequest<
		{
			withPagination: boolean;
			gun_ids: string[];
			datetime_charging_start: string;
			datetime_charging_end: string;
			station: string;
		},
		IGunsStatsPag
	>({
		path: 'charge-fills/find',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: downloadReport,
		value: downloadReportVal,
		status: downloadReportStatus,
	} = useBackendRequest<IReportDownloadParams, IReportDownloadResponse>({
		path: 'charge-fills/getTransactionsReports',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (chargeFillsStatus === 'success') {
			if (chargeFillsVal?.data) {
				setData({
					...data,
					countRows: chargeFillsVal.data.totalElements,
					rows: transactionsTabAdapter(chargeFillsVal.data.chargeFills),
				});
				dispatch(setLoading({ loading: false }));
			}
		} else if (chargeFillsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [chargeFillsStatus]);

	useEffect(() => {
		searchInRange();
	}, [rowsPerPage, pagination, actualMonth]);

	const getQueryGuns = (): string[] => {
		const guns = simulating ? simulatedPartnerGuns : partnerGuns;
		return guns.length > 0 ? guns : ['NONE'];
	};

	const handleDownloadPdf = (): void => {
		dispatch(setLoading({ loading: true }));
		downloadReport({
			document: 'pdf',
			gun_ids: getQueryGuns(),
			datetime_charging_start:
				dateFilter.datetime_charging_start !== ''
					? dateFilter.datetime_charging_start
					: getDateStartFormated(),
			datetime_charging_end:
				dateFilter.datetime_charging_end !== ''
					? dateFilter.datetime_charging_end
					: getDateEndFormated(),
			partnerInfoId: simulating ? id : partnerId.toString(),
		});
	};

	const handleDownloadCsv = (): void => {
		dispatch(setLoading({ loading: true }));
		downloadReport({
			document: 'csv',
			gun_ids: getQueryGuns(),
			datetime_charging_start:
				dateFilter.datetime_charging_start !== ''
					? dateFilter.datetime_charging_start
					: getDateStartFormated(),
			datetime_charging_end:
				dateFilter.datetime_charging_end !== ''
					? dateFilter.datetime_charging_end
					: getDateEndFormated(),
			partnerInfoId: simulating ? id : partnerId.toString(),
		});
	};

	useEffect(() => {
		if (downloadReportStatus === 'success') {
			if (downloadReportVal?.data) {
				if (downloadReportVal.data.message.includes('pdf')) {
					generatePDF(downloadReportVal.data.base64, 'pdf', 'Report', false);
				} else {
					generateCSV(downloadReportVal.data.base64, 'csv', 'Report');
				}
				dispatch(setLoading({ loading: false }));
			}
		} else if (downloadReportStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [downloadReportStatus]);

	const handleDateInputs = (event: any): void => {
		const { name, value } = event.target;
		const date = dayjs(value.$d).toISOString().split('T')[0];
		if (name === 'startDate') {
			if (dateFilter.datetime_charging_end === date) {
				setDateFilter({
					datetime_charging_start: date,
					datetime_charging_end: dateFilter.datetime_charging_end,
				});
				return;
			}
			setDateFilter({ ...dateFilter, datetime_charging_start: date });
		} else {
			if (dateFilter.datetime_charging_start === date) {
				setDateFilter({
					datetime_charging_start: dateFilter.datetime_charging_start,
					datetime_charging_end: date,
				});
				return;
			}
			setDateFilter({ ...dateFilter, datetime_charging_end: date });
		}
	};

	const searchInRange = (): void => {
		dispatch(setLoading({ loading: true }));
		const gunsIds = getQueryGuns();
		getChargeFills(
			{
				withPagination: true,
				gun_ids: gunsIds,
				datetime_charging_start:
					dateFilter.datetime_charging_start !== ''
						? dateFilter.datetime_charging_start
						: getDateStartFormated(),
				datetime_charging_end:
					dateFilter.datetime_charging_end !== ''
						? dateFilter.datetime_charging_end
						: getDateEndFormated(),
				station: station === 'all' ? '' : station,
			},
			`?page=${pagination}&pageSize=${rowsPerPage}`
		);
	};

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		setStation(value);
	};

	return {
		data,
		dateFilter,
		station,
		setData,
		handleDownloadCsv,
		handleDownloadPdf,
		handleDateInputs,
		searchInRange,
		handleSelectChange,
	};
}
