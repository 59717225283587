export interface IGun {
	id?: string;
	partnerInfoId: string;
	name: string;
	plugType: string;
	address: string;
	state: string;
	country: string;
	zip: string;
	chargerId: string;
	longitude: string;
	latitude: string;
	qionGunUniqueId: string;
	create_time?: string;
	update_time?: string;
}

export const defaultGun: IGun = {
	partnerInfoId: '',
	name: '',
	plugType: '',
	address: '',
	state: '',
	country: '',
	zip: '',
	chargerId: '',
	longitude: '',
	latitude: '',
	qionGunUniqueId: '',
};
