import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardActionArea, Grid } from '@mui/material';
import {
	ArrowBack,
	Edit,
	OfflineBoltTwoTone,
	SettingsInputSvideo,
} from '@mui/icons-material';
import CustomButton from '../../../../../components/button.component';
import SimpleCard from '../../../../../components/simple-card.component';
import GunsTab from './tabs/guns-tab';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useChargerDetail } from './hooks/useChargerDetail';
import { ADMIN_TAB_VALUES } from '../../../../../constants/tab-values.constants';
import { useTranslation } from 'react-i18next';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';
import { ROLES } from '../../../../../constants/user-roles.constants';

const DetailCharger = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const nav = useNavigate();
	const [tab, setTab] = useState(1);

	const { chargerInfo, chargerGuns } = useChargerDetail();

	const { getChargersPermission } = useUserPermisions();

	return (
		<>
			<CustomButton
				variant="solid"
				label={t('common.back')}
				icon={ArrowBack}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: 10,
					backgroundColor: 'black',
				}}
				onClick={
					role.roleName === ROLES.SUPER_ADMIN && !simulating
						? () => nav('/partners/all-chargers')
						: () =>
								nav('/partners/details', {
									state: { tab: ADMIN_TAB_VALUES.CHARGERS },
								})
				}
			/>
			<Grid container spacing={3}>
				<Grid
					container
					item
					xs={12}
					md={4}
					spacing={2}
					style={{ alignContent: 'start' }}
				>
					<Grid item xs={12}>
						<SimpleCard
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<>
								<OfflineBoltTwoTone
									sx={{ color: colors.secondary }}
									fontSize="large"
								/>
								<span style={{ marginBottom: 25, marginTop: 25 }}>
									<strong>{t('common.name')}: </strong>
									{chargerInfo.name}
								</span>
								{getChargersPermission() && (
									<CustomButton
										variant="solid"
										label={t('common.edit')}
										icon={Edit}
										styles={{
											width: 'fit-content',
											padding: '0 15px',
											gap: 10,
											backgroundColor: 'black',
										}}
										onClick={() =>
											nav(
												`/partners/edit-charger/${chargerInfo.id}`
											)
										}
									/>
								)}
							</>
						</SimpleCard>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CardActionArea onClick={() => setTab(1)}>
							<SimpleCard
								style={{
									fontWeight: 600,
									background: colors.secondary,
									color: 'white',
								}}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '10px',
									}}
								>
									<SettingsInputSvideo
										color={tab === 1 ? 'inherit' : 'primary'}
									/>
									<span style={{ fontSize: '16px' }}>
										{t('common.guns')}
									</span>
								</div>
							</SimpleCard>
						</CardActionArea>
					</Grid>
				</Grid>
				<GunsTab data={chargerGuns} />
			</Grid>
		</>
	);
};

export default DetailCharger;
