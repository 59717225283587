import dayjs from 'dayjs';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useMonthSelector = () => {
	const [t] = useTranslation('global');
	const [actualMonth, setActualMonth] = useState<number>(dayjs().month());
	const [actualYear, setActualYear] = useState<number>(dayjs().year());

	const handleGetPastMonth = (): void => {
		if (actualMonth - 1 < 0) {
			setActualMonth(11);
			setActualYear(actualYear - 1);
		} else {
			setActualMonth(actualMonth - 1);
		}
	};

	const handleGetFutureMonth = (): void => {
		if (actualMonth + 1 > 11) {
			setActualMonth(0);
			setActualYear(actualYear + 1);
		} else {
			setActualMonth(actualMonth + 1);
		}
	};

	const getMonthName = (): string => {
		let monthStr = '';
		switch (actualMonth) {
			case 0:
				monthStr = t('months.january');
				break;
			case 1:
				monthStr = t('months.february');
				break;
			case 2:
				monthStr = t('months.march');
				break;
			case 3:
				monthStr = t('months.april');
				break;
			case 4:
				monthStr = t('months.may');
				break;
			case 5:
				monthStr = t('months.june');
				break;
			case 6:
				monthStr = t('months.july');
				break;
			case 7:
				monthStr = t('months.august');
				break;
			case 8:
				monthStr = t('months.september');
				break;
			case 9:
				monthStr = t('months.october');
				break;
			case 10:
				monthStr = t('months.november');
				break;
			case 11:
				monthStr = t('months.december');
				break;
			default:
				monthStr = t('months.january');
				break;
		}
		return `${monthStr} ${actualYear}`;
	};

	const getDateStartFormated = (): string => {
		return dayjs()
			.month(actualMonth)
			.year(actualYear)
			.startOf('month')
			.format('YYYY-MM-DD');
	};

	const getDateEndFormated = (): string => {
		return dayjs()
			.month(actualMonth)
			.year(actualYear)
			.endOf('month')
			.format('YYYY-MM-DD');
	};

	return {
		actualMonth,
		actualYear,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
		getDateStartFormated,
		getDateEndFormated,
	};
};
