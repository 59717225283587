import { Grid } from '@mui/material';
import SimpleCard from '../../../../../../components/simple-card.component';
import styles from '../../../../styles/admin-charger-tab.module.css';

import plugIcon from '../../../../../../assets/svgs/plug.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { hexToRGBA } from '../../../../../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';
import { IGun } from '../../../../../../interfaces/gun.interface';

interface IGunsTab {
	data: IGun[];
}

const GunsTab = ({ data }: IGunsTab) => {
	const [t] = useTranslation('global');
	const { secondary, accent } = useSelector((state: RootState) => state.colors);
	return (
		<Grid container item xs={12} md={8} spacing={2}>
			<Grid item xs={12}>
				<SimpleCard
					title={t('common.guns').toString()}
					style={{
						display: 'flex',
						alignItems: 'center',
						flexFlow: 'wrap',
					}}
					footerStyles={{ justifyContent: 'end' }}
				>
					<div className={styles.gunsContainer}>
						{data.map((gun) => (
							<div
								key={gun.id!}
								className={styles.removeGunBox}
								style={{
									border: `1px solid ${secondary}`,
									backgroundColor: hexToRGBA(secondary, 0.2),
								}}
							>
								<img
									src={plugIcon}
									width={20}
									height={20}
									alt="plug-icon"
								/>
								{gun.name}
							</div>
						))}
					</div>
				</SimpleCard>
			</Grid>
		</Grid>
	);
};

export default GunsTab;
