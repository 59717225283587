import { Grid, Paper } from '@mui/material';
import styles from '../../../../styles/admin-associates-tab.module.css';
import TableCustom from '../../../../../../components/table-custom.component';

import { useTabDetails } from './hooks/useTabDetails';
import { useDetailsGroups } from './utils/details.utils';

const DetailsTab = () => {
	const { data, setData } = useTabDetails();

	const { detailGroupsColumns } = useDetailsGroups();

	return (
		<Grid item container spacing={2} xs={12}>
			<Grid item xs={12}>
				<Paper className={styles['table-container']}>
					<h4>Groups</h4>
					<TableCustom
						columns={detailGroupsColumns}
						data={data}
						showPagination={false}
						setData={setData}
						titleHeader="Groups"
						showTotalCount={false}
						hideHeader
						hideChips
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};

export default DetailsTab;
