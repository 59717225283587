import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { IChargersResponse } from '../../../../interfaces/charger.interface';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { ICharger } from '../../../../../../interfaces/charger.interface';
import { IStation } from '../../../../../../interfaces/stations.interface';

export function useChargerTab() {
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const [chargersData, setChargersData] = useState<ICharger[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedCharger, setSelectedCharger] = useState<{
		id: string;
		name: string;
		qionId: string;
	}>({
		name: '',
		id: '',
		qionId: '',
	});

	const [stations, setStations] = useState<IStation[]>([]);

	const dispatch = useDispatch();

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<unknown, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<unknown, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				const data = filterData(getChargersVal.data);
				setChargersData(data);
			} else {
				setChargersData([]);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				setStations(getStationsVal?.data);
				getChargers();
			} else {
				setStations([]);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getStationsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const getStationName = (id: string): string => {
		const station = stations.filter((s) => s.id === id)[0].name;
		return station;
	};

	const filterData = (data: ICharger[]): ICharger[] => {
		const filteredData = data.filter((c) => c.partnerInfoId === id);
		return filteredData;
	};

	const handleOpenModal = (charger: string): void => {
		const { id, name, qionChargerUniqueId } = chargersData.filter(
			(c) => c.id === charger
		)[0];
		setSelectedCharger({
			name: name,
			id: id!,
			qionId: qionChargerUniqueId,
		});
		setOpenModal(true);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return {
		chargersData,
		openModal,
		selectedCharger,
		setOpenModal,
		handleOpenModal,
		getStationName,
	};
}
