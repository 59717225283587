import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/button.component';

import styles from './styles/styles.module.css';
import { ArrowBack, HelpOutline } from '@mui/icons-material';
import Input from '../../components/input.component';
import AddAssociateButton from './components/add-associate-button.component';
import CustomModal from '../../components/modal.component';
import AssociateCard from './components/associate-card.component';

import { useSelector } from 'react-redux';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import { CustomTooltip } from '../../components/button-icon-small.component';
import { RootState } from '../../store';
import { useTranslation } from 'react-i18next';
import SelectedAsociateRow from './components/selected-associate-row.component';
import { Grid } from '@mui/material';
import { useAddGroup } from './hooks/useAddGroup';
import { useDeleteGroup } from './hooks/useDeleteGroup';

const AddAssociateGroup = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);

	const {
		groupId,
		associateGroupData,
		selectedAssociates,
		openModal,
		modalAssociates,
		disabledButton,
		tempSelectedAssociates,
		handleEditGroup,
		setOpenModal,
		handleSetData,
		handleSelectAssociate,
		handleAcceptModal,
		handleRemoveCard,
		handleFirstInvoiceChange,
		handleRemnantChange,
		handleFactorUnitChange,
		handleQuantityChange,
		saveAssociateGroup,
		handleOpenModal,
	} = useAddGroup();

	const { openDeleteModal, setOpenDeleteModal, handleDeleteGroup } = useDeleteGroup();

	return (
		<div className={styles.mainContainer}>
			<CustomButton
				label={t('common.back')}
				variant={'solid'}
				onClick={() =>
					navigate('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
					})
				}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: '10px',
					backgroundColor: 'black',
				}}
				icon={ArrowBack}
			/>
			<div className={styles.formContainer}>
				<div className={styles.titleContainer}>
					<span className={styles.title}>
						{groupId ? t('groups.edit') : t('groups.new')}
					</span>
				</div>
				<hr className={styles.customHr} />
				<div className={styles.associatesContainer}>
					<div className={styles.inputsContainer}>
						<div className={styles.column}>
							<Input
								id="group"
								name="group"
								placeholder={t('common.required').toString()}
								value={associateGroupData.group}
								type="text"
								label={t('groups.name').toString()}
								required
								onChange={handleSetData}
							/>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<span className={styles.title}>{t('groups.prices')}</span>
								<CustomTooltip
									title={t('groups.msg-prices')}
									placement="top"
								>
									<HelpOutline style={{ color: colors.secondary }} />
								</CustomTooltip>
							</div>
							<Input
								id="iva"
								name="iva"
								placeholder={t('common.required').toString()}
								value={associateGroupData.iva}
								type="text"
								label={t('common.vat').toString()}
								required
								onChange={handleSetData}
							/>
							<Input
								id="chargeCost"
								name="chargeCost"
								placeholder={t('common.required').toString()}
								value={associateGroupData.chargeCost}
								type="text"
								label={t('common.charge-cost').toString()}
								required
								onChange={handleSetData}
							/>
							<Input
								id="chargeFactor"
								name="chargeFactor"
								placeholder={t('common.required').toString()}
								value={associateGroupData.chargeFactor}
								type="text"
								label={t('common.charge-factor').toString()}
								required
								onChange={handleSetData}
							/>
						</div>
						<div
							className={styles.column}
							style={{
								justifyContent: 'flex-end',
								alignItems: 'flex-end',
							}}
						>
							<Input
								id="parkingCost"
								name="parkingCost"
								placeholder={t('common.required').toString()}
								value={associateGroupData.parkingCost}
								type="text"
								label={t('common.parking-cost').toString()}
								required
								onChange={handleSetData}
							/>
							<Input
								id="parkingFactor"
								name="parkingFactor"
								placeholder={t('common.required').toString()}
								value={associateGroupData.parkingFactor}
								type="text"
								label={t('common.parking-factor').toString()}
								required
								onChange={handleSetData}
							/>
						</div>
					</div>
					<span className={styles.title}>{t('common.payment-processor')}</span>
					<Grid container display={'flex'} spacing={2}>
						<Grid item xl={6} md={12} sm={12} xs={12}>
							<Input
								id="paymentProcessorPercentaje"
								name="paymentProcessorPercentaje"
								placeholder={t('common.required').toString()}
								value={associateGroupData.paymentProcessorPercentaje}
								type="text"
								label={t('common.payment-processor-per').toString()}
								required
								onChange={handleSetData}
							/>
						</Grid>
						<Grid item xl={6} md={12} sm={12} xs={12}>
							<Input
								id="paymentProcessorQty"
								name="paymentProcessorQty"
								placeholder={t('common.required').toString()}
								value={associateGroupData.paymentProcessorQty}
								type="text"
								label={t('common.payment-processor-qty').toString()}
								required
								onChange={handleSetData}
							/>
						</Grid>
					</Grid>
					<span className={styles.title}>{t('common.associates')}</span>
					{/* Seleccionados */}
					{selectedAssociates.length < 5 && (
						<AddAssociateButton
							onClick={handleOpenModal}
							text={t('groups.add-associate').toString()}
						/>
					)}
					<div className={styles.associatesCardsContainer}>
						{selectedAssociates.length! > 0 &&
							selectedAssociates.map((associate, index) => (
								<SelectedAsociateRow
									key={`${index}-${associate.id}`}
									index={index}
									id={associate.id!}
									name={associate.name}
									rfc={associate.rfc}
									address={associate.address}
									costOwner={
										associateGroupData.firstInvoiceAssociateId ===
										`${index}-${associate.id}`
									}
									remanentOwner={
										associateGroupData.remainingOwnerId ===
										`${index}-${associate.id}`
									}
									handleRemoveCard={handleRemoveCard}
									handleFirstInvoiceChange={handleFirstInvoiceChange}
									hanldeRemaingOwnerChange={handleRemnantChange}
									handleFactorUnitChange={handleFactorUnitChange}
									handleQuantityChange={handleQuantityChange}
									inputPlaceholder={
										associateGroupData.associates[index].unit! ===
										true
											? t('groups.unit-desc').toString()
											: t('groups.fraction-desc').toString()
									}
									inputLabel={
										associateGroupData.associates[index].unit === true
											? `${t('groups.unit-desc')} ($)`
											: `${t('groups.fraction-desc')} (%)`
									}
									valueInput={
										associateGroupData.associates[index].fraction
									}
									radioFractionChecked={
										associateGroupData.associates[index].unit
									}
								/>
							))}
					</div>
				</div>
				<hr className={styles.customHr} />
				<div className={styles.buttonsContainer}>
					{groupId && (
						<CustomButton
							variant="solid"
							label={t('common.delete')}
							styles={{
								backgroundColor: '#DD0528',
								width: 'fit-content',
								padding: '0 15px',
								gap: '10px',
								marginRight: 'auto',
							}}
							onClick={() => setOpenDeleteModal(true)}
						/>
					)}
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={() =>
							navigate('/partners/details', {
								state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
							})
						}
						styles={{
							width: 'fit-content',
							padding: '0 15px',
							gap: '10px',
							backgroundColor: 'black',
						}}
					/>
					<CustomButton
						label={t('common.save')}
						variant={'solid'}
						onClick={groupId ? handleEditGroup : saveAssociateGroup}
						styles={{
							width: 'fit-content',
							padding: '0 15px',
							gap: '11px',
							color: 'black',
							backgroundColor: colors.accent,
						}}
						disabled={disabledButton}
					/>
				</div>
			</div>
			<CustomModal
				open={openModal}
				maxWidth="xl"
				fullWidth
				title={t('groups.add-associate')}
			>
				<>
					<hr className={styles.customHr} />
					<div className={styles.associatesCardsModalContainer}>
						{modalAssociates.map((associate, index) => (
							<AssociateCard
								key={index}
								id={associate.id!}
								name={associate.name}
								rfc={associate.rfc}
								address={associate.address}
								onClick={handleSelectAssociate}
							/>
						))}
					</div>
					<hr className={styles.customHr} />
					<div className={styles.buttonsContainer}>
						<CustomButton
							label={t('common.cancel')}
							variant={'solid'}
							onClick={() => setOpenModal(false)}
							styles={{
								width: '158px',
								gap: '11px',
								backgroundColor: 'black',
							}}
						/>
						<CustomButton
							label={t('common.save')}
							variant={'solid'}
							onClick={handleAcceptModal}
							styles={{
								width: '158px',
								gap: '11px',
								color: 'black',
								backgroundColor: colors.accent,
							}}
							disabled={
								tempSelectedAssociates.length < 1 ||
								selectedAssociates.length +
									tempSelectedAssociates.length >
									5
									? true
									: false
							}
						/>
					</div>
				</>
			</CustomModal>
			<CustomModal
				open={openDeleteModal}
				maxWidth="xl"
				fullWidth
				title={t('groups.delete-txt')}
			>
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={() => setOpenDeleteModal(false)}
						styles={{
							width: '158px',
							gap: '11px',
							backgroundColor: 'black',
						}}
					/>
					<CustomButton
						label={t('common.delete')}
						variant={'solid'}
						onClick={handleDeleteGroup}
						styles={{
							width: '158px',
							gap: '11px',
							color: 'white',
							backgroundColor: '#DD0528',
						}}
					/>
				</div>
			</CustomModal>
		</div>
	);
};

export default AddAssociateGroup;
