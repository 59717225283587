import { IColumns } from '../../../hooks/useTableCustom';

export const chargersColumns: IColumns[] = [
	{
		text: 'Gun',
		nameID: 'gun',
	},
	{
		text: 'Model',
		nameID: 'model',
	},
	{
		text: 'Status',
		nameID: 'status',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
