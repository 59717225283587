import styles from './styles/associates-tab.module.css';
import { useTranslation } from 'react-i18next';
import { IAssociate } from '../../../interfaces/associate.interface';
import { IAGAssociate } from '../../partners-group-details/interfaces/associates-group.interface';
import TableCustom from '../../../components/table-custom.component';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useAssociatesTabColumns } from './utils/associates.columns';
import { useEffect } from 'react';
import { useAssociatesTabAdapter } from './adapters/associates-tab.adapter';

interface IAssociatesTab {
	data: IAssociate[];
	shares: IAGAssociate[];
	firstInvoiceId: string;
	remmantOwnerId: string;
}

const AssociatesTab = ({
	data: associatesData,
	shares,
	firstInvoiceId,
	remmantOwnerId,
}: IAssociatesTab) => {
	const [t] = useTranslation('global');

	const { associatesTabAdapter } = useAssociatesTabAdapter();
	const { associatesTabColumns } = useAssociatesTabColumns();
	const { data, setData } = useTableCustom<any>();

	const getFirstInvoiceName = (): string => {
		let name = '';
		const associate = associatesData.filter((a) => a.id === firstInvoiceId)[0];
		if (associate) {
			name = associate.name;
		}
		return name;
	};

	const getRemmantOwnerName = (): string => {
		let name = '';
		const associate = associatesData.filter((a) => a.id === remmantOwnerId)[0];
		if (associate) {
			name = associate.name;
		}
		return name;
	};

	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: associatesTabAdapter(associatesData, shares),
		});
	}, [associatesData, shares]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.paperContainer}>
				<h3>{t('common.associates')}</h3>
				<hr className={styles.hr} />
				<div className={styles.ownersContainer}>
					<div>
						<span>
							<b>{t('groups.invoice')}:</b> {getFirstInvoiceName()}
						</span>
					</div>
					<div>
						<b>{t('shared-report.owner')}:</b> {getRemmantOwnerName()}
					</div>
				</div>
				<TableCustom
					columns={associatesTabColumns}
					data={data}
					setData={setData}
					showTotalCount={false}
					hideChips
					hideHeader
				/>
				{/* <Grid
					container
					columnSpacing={10}
					rowSpacing={5}
					spacing={3}
					paddingLeft={'100px'}
					paddingTop={'10px'}
					display={'flex'}
					flexDirection={'column'}
				>
					{typeof data === 'object' &&
						data.map((associate, index) => (
							<Grid
								item
								style={{
									padding: '20px 20px 0px 0px',
									backgroundColor: 'red',
								}}
							>
								<AssociateCard
									id={associate.id!}
									key={index + 'AssociateCard'}
									name={associate.name}
									rfc={associate.rfc}
									address={associate.address}
									clickable={false}
								/>
								<span>aa</span>
							</Grid>
						))}
				</Grid> */}
			</div>
		</div>
	);
};

export default AssociatesTab;
