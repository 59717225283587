import { useEffect, useState } from 'react';
import { Label } from '../label.component';

import styles from './style.component.module.css';

interface ITimeInput {
	label: string;
	value: string;
	name: string;
	disabled: boolean;
	required: boolean;
	onChange: (name: string, val: string) => void;
}

export default function TimeInput({
	label,
	value,
	onChange,
	disabled,
	name,
	required,
}: ITimeInput) {
	const [val, setValue] = useState<string | null>(value);

	useEffect(() => {
		if (value !== null) {
			let newVals: string = '';
			if (value.split(':').length > 2) {
				const vals = value.split(':');
				newVals = vals[0] + ':' + vals[1];
			} else {
				newVals = value;
			}

			setValue(subHours(newVals, 6));
		}
	}, [value]);

	const addHours = (hora: any, hoursToAdd: any): string => {
		const horaActual = new Date();
		const partesHora = hora.split(':');
		horaActual.setHours(parseInt(partesHora[0], 10) + hoursToAdd);
		horaActual.setMinutes(partesHora[1]);
		return horaActual.toLocaleTimeString('es-ES', {
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	const subHours = (hora: any, hoursToSub: any): string => {
		const horaActual = new Date();
		const partesHora = hora.split(':');
		horaActual.setHours(parseInt(partesHora[0], 10) - hoursToSub);
		horaActual.setMinutes(partesHora[1]);
		return horaActual.toLocaleTimeString('es-ES', {
			hour: '2-digit',
			minute: '2-digit',
		});
	};

	const handleChange = (e: any): void => {
		setValue(e.target.value);
		onChange(name, addHours(e.target.value, 6));
	};

	return (
		<div className={styles.mainContainer}>
			<Label label={label} name={name} required={required} disabled={disabled} />
			<input value={val!} type="time" onChange={handleChange} name={name} />
		</div>
	);
}
