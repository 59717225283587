import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles/password.component.module.css';

import acceptedIcon from '../assets/svgs/checkbox-circle-fill.svg';
import unacceptedIcon from '../assets/svgs/minus-circle-fill.svg';

interface IPasswordComponent {
	password: string;
}

const PasswordComponent = ({ password }: IPasswordComponent) => {
	const [t] = useTranslation('global');
	const [passwordStructure, setPasswordStructure] = useState({
		upperCase: false,
		lowerCase: false,
		number: false,
		length8: false,
		specialCharacter: false,
	});

	useEffect(() => {
		verifyPasswordStructure(password);
	}, [password]);

	const verifyPasswordStructure = (password: string) => {
		const upperCaseLetters = /[A-Z]/g;
		const numbers = /[0-9]/g;
		const lowerCaseLetters = /[a-z]/g;
		const specialCharacter = /[*@!#~{}"?¿¡$,._-]+/;

		if (password.match(upperCaseLetters)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				upperCase: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				upperCase: false,
			}));
		}
		if (password.match(lowerCaseLetters)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				lowerCase: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				lowerCase: false,
			}));
		}

		if (password.match(numbers)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				number: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				number: false,
			}));
		}
		if (password.match(specialCharacter)) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				specialCharacter: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				specialCharacter: false,
			}));
		}
		if (password.length >= 8) {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				length8: true,
			}));
		} else {
			setPasswordStructure((passwordStructure) => ({
				...passwordStructure,
				length8: false,
			}));
		}
	};

	return (
		<div className={styles.container}>
			<span className={styles.item}>
				{passwordStructure.upperCase ? (
					<img src={acceptedIcon} alt="icono-aceptado" />
				) : (
					<img src={unacceptedIcon} alt="icono-no-aceptado" />
				)}
				{t('users.password-params.uppercase')}
			</span>
			<span className={styles.item}>
				{passwordStructure.lowerCase ? (
					<img src={acceptedIcon} alt="icono-aceptado" />
				) : (
					<img src={unacceptedIcon} alt="icono-no-aceptado" />
				)}
				{t('users.password-params.lowercase')}
			</span>
			<span className={styles.item}>
				{passwordStructure.number ? (
					<img src={acceptedIcon} alt="icono-aceptado" />
				) : (
					<img src={unacceptedIcon} alt="icono-no-aceptado" />
				)}
				{t('users.password-params.number')}
			</span>
			<span className={styles.item}>
				{passwordStructure.specialCharacter ? (
					<img src={acceptedIcon} alt="icono-aceptado" />
				) : (
					<img src={unacceptedIcon} alt="icono-no-aceptado" />
				)}
				{t('users.password-params.special')}
			</span>

			<span className={styles.item}>
				{passwordStructure.length8 ? (
					<img src={acceptedIcon} alt="icono-aceptado" />
				) : (
					<img src={unacceptedIcon} alt="icono-no-aceptado" />
				)}
				{t('users.password-params.lenght')}
			</span>
		</div>
	);
};

export default PasswordComponent;
