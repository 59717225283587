import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { CustomTooltip } from '../../../../components/button-icon-small.component';

import pdf from '../../../../assets/imgs/pdf.png';
import csv from '../../../../assets/imgs/csv.png';

interface IDownloadButtons {
	onDownloadPdf: () => void;
	onDownloadCsv: () => void;
}

const DownloadButtons = ({ onDownloadPdf, onDownloadCsv }: IDownloadButtons) => {
	const [t] = useTranslation('global');
	return (
		<div className={styles.mainContainer}>
			<span>
				<b>{t('financial.download')}:</b>
			</span>
			<CustomTooltip title="PDF" placement="top" onClick={onDownloadPdf}>
				<img src={pdf} className={styles.img} />
			</CustomTooltip>

			<span>
				<b> | </b>
			</span>
			<CustomTooltip title="CSV" placement="top" onClick={onDownloadCsv}>
				<img src={csv} className={styles.img} />
			</CustomTooltip>
		</div>
	);
};

export default DownloadButtons;
