import { useSelector } from 'react-redux';
import { IUsersFindInfoResponse } from '../pages/login/interfaces/login.interface';
import { IUsers } from '../pages/users/interfaces/users.interface';
import { getUserPartner } from '../utils/verifyUserPartner';
import { RootState } from '../store';
import { ROLES } from '../constants/user-roles.constants';

export function useGetCmsUsers() {
	const { id } = useSelector((state: RootState) => state.partner);
	const { partnerId, role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const realId = role.roleName === ROLES.SUPER_ADMIN && !simulating ? partnerId : id;

	const filterCmsUsers = (data: IUsersFindInfoResponse[]): IUsers[] => {
		const cmsData: any[] = data.filter((user: IUsersFindInfoResponse) => {
			const userPartnerInfo = getUserPartner(
				user.userDetailsPartner,
				realId.toString()
			);
			return userPartnerInfo && userPartnerInfo.user_system_type === 2;
		});
		const tableData: IUsers[] = cmsData.map((user: IUsersFindInfoResponse) => {
			return {
				id: user.id,
				name: user.name,
				first_last_name: user.first_last_name,
				email: user.email,
				mobile: user.mobile,
				user_system_type: 2,
				is_disabled: user.is_disabled,
				userPermissions: user.userPermissions,
				partnerId: realId.toString(),
			};
		});
		return tableData;
	};

	return { filterCmsUsers };
}
