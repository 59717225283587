import { useState, useEffect } from 'react';

import { Grid } from '@mui/material';

import CardStation from '../components/cardStation';

import styles from '../styles.module.css';
import { useChargingDashboard } from './hooks/useChargingDashboard';

import { ReactComponent as PlugImg } from '../../../assets/svgs/plug.svg';
import { useTranslation } from 'react-i18next';
import { IStationGuns } from './interfaces/charging-dashboard.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import SelectInput from '../../../components/select-input.component';

const ChargingDashboard = () => {
	const [t] = useTranslation('global');
	const [tabActive, setTabActive] = useState('all');
	const colors = useSelector((state: RootState) => state.colors);

	const {
		gunsInfo,
		gunsInfoFiltered,
		stationsOptions,
		selectedStation,
		handleSelectChange,
		setGunsInfoFiltered,
	} = useChargingDashboard();

	useEffect(() => {
		if (tabActive === 'charging') {
			const newData = gunsInfo.filter((item) => item.status === true);
			setGunsInfoFiltered(newData);
		} else if (tabActive === 'not') {
			const newData = gunsInfo.filter((item) => item.status === false);
			setGunsInfoFiltered(newData);
		} else if (tabActive === 'out') {
			const newData = gunsInfo.filter(
				(item) => item.status !== true && item.status !== false
			);
			setGunsInfoFiltered(newData);
		} else {
			setGunsInfoFiltered(gunsInfo);
		}
	}, [tabActive]);

	return (
		<Grid container display={'flex'}>
			<Grid item sm={12}>
				<h1 className={styles.title}>{t('dashboard-4.title')}</h1>
			</Grid>
			<Grid item sm={12}>
				<Grid container display={'flex'} sx={{ gap: '30px' }}>
					<Grid item sm={12}>
						<div className={styles.cardContainer}>
							<div className={styles.stationHeader}>
								<div className={styles.stationHeaderIcon}>
									<PlugImg />
								</div>
								<div className={styles.stationHeaderInfo}>
									<p>
										<b>{t('dashboard-4.guns')}</b>
									</p>
									<div className={styles.infoRow}>
										<div className={styles.infoElement}>
											<span className={styles.infoNumber}>
												<PlugImg
													width={'20px'}
													height={'20px'}
													style={{ color: '#18C19F' }}
												/>
												{
													gunsInfoFiltered.filter(
														(g) => g.status === true
													).length
												}
											</span>
											<span className={styles.infoName}>
												{t('charging-status.charging')}
											</span>
										</div>
										<div className={styles.infoElement}>
											<span className={styles.infoNumber}>
												<PlugImg
													width={'20px'}
													height={'20px'}
													style={{ color: '#FAB803' }}
												/>
												{
													gunsInfoFiltered.filter(
														(g) => g.status === false
													).length
												}
											</span>
											<span className={styles.infoName}>
												{t('charging-status.not-charging')}
											</span>
										</div>
										<div className={styles.infoElement}>
											<span className={styles.infoNumber}>
												<PlugImg
													width={'20px'}
													height={'20px'}
													style={{ color: 'gray' }}
												/>
												0
											</span>
											<span className={styles.infoName}>
												{t('charging-status.out-order')}
											</span>
										</div>
									</div>
								</div>
							</div>
							<div className={styles.stationTabs}>
								<div
									onClick={() => setTabActive('all')}
									className={
										tabActive === 'all'
											? styles.stationTabsItemActive
											: styles.stationTabsItem
									}
									style={{ display: 'flex', gap: '10px' }}
								>
									<PlugImg width={'22px'} height={'22px'} />
									<span>{t('charging-status.all')}</span>
								</div>
								<div
									onClick={() => setTabActive('charging')}
									className={
										tabActive === 'charging'
											? styles.stationTabsItemActive
											: styles.stationTabsItem
									}
									style={{ display: 'flex', gap: '10px' }}
								>
									<PlugImg width={'22px'} height={'22px'} />
									<span>{t('charging-status.charging')}</span>
								</div>
								<div
									onClick={() => setTabActive('not')}
									className={
										tabActive === 'not'
											? styles.stationTabsItemActive
											: styles.stationTabsItem
									}
									style={{ display: 'flex', gap: '10px' }}
								>
									<PlugImg width={'22px'} height={'22px'} />
									<span>{t('charging-status.not-charging')}</span>
								</div>
								<div
									onClick={() => setTabActive('out')}
									className={
										tabActive === 'out'
											? styles.stationTabsItemActive
											: styles.stationTabsItem
									}
									style={{ display: 'flex', gap: '10px' }}
								>
									<PlugImg width={'22px'} height={'22px'} />
									<span>{t('charging-status.out-order')}</span>
								</div>
							</div>
						</div>
					</Grid>
					<Grid item sm={12}>
						<div
							style={{
								backgroundColor: 'white',
								padding: '15px',
								borderRadius: '4px',
							}}
						>
							<SelectInput
								name="station"
								id="station"
								label={t('financial.select-station').toString()}
								placeholder={t('common.required').toString()}
								style={{ width: '100%' }}
								required
								options={stationsOptions}
								onChange={handleSelectChange}
								value={selectedStation}
							/>
						</div>
					</Grid>
					<Grid
						container
						item
						sm={12}
						display={'flex'}
						justifyContent={'start'}
						spacing={2}
					>
						{gunsInfo.length > 0 ? (
							<>
								{gunsInfoFiltered.map((item: IStationGuns, index) => (
									<Grid
										item
										xs={12}
										sm={6}
										md={6}
										lg={4}
										xl={3}
										key={`${item.id}-${index}`}
									>
										<CardStation data={item} />
									</Grid>
								))}
							</>
						) : (
							<div style={{ display: 'flex', justifyContent: 'center' }}>
								<span
									style={{
										color: colors.secondary,
										fontSize: 30,
										fontWeight: 'bold',
									}}
								>
									{t('errors.not-guns')}
								</span>
							</div>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default ChargingDashboard;
