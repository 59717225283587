import React, { useEffect, useState } from 'react';
import { useDebounce } from '../../../hooks/useDebounce';

import styles from './styles/input-color.component.module.css';

interface IInputColor {
	label: string;
	value: string;
	name: string;
	onChange: (name: string, val: string) => void;
}

const InputColor = ({ label, value, name, onChange }: IInputColor) => {
	const [val, setVal] = useState(value);

	const debouncedVal = useDebounce(val);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value } = event.target;
		setVal(value);
	};

	useEffect(() => {
		setVal(debouncedVal);
		onChange(name, debouncedVal);
	}, [debouncedVal]);

	return (
		<>
			<span>{label}</span>
			<input
				className={styles.inputColor}
				type="color"
				name={name}
				onChange={handleChange}
				value={val}
			/>
		</>
	);
};

export default InputColor;
