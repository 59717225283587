import styles from './styles/styles.module.css';
import Input from '../../components/input.component';
import { useMobileAppSettings } from './hooks/useMobileAppSettings';
import CustomButton from '../../components/button.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ImageInput from '../../components/image-input.component';
import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import SelectInput from '../../components/select-input.component';
import { useSelectOptions } from './utils/select-option.utils';
import MenuOption from './components/menu-option.component';
import InputColor from './components/input-color.component';
import SvgUploader from './components/svg-uploader.component';
import { iconsForm } from './utils/icons-form.utils';
import { ImagesKeys } from './interfaces/images-keys.interface';
import { useGetPartners } from './hooks/useGetPartners';

const MobileAppSettings = () => {
	const [t] = useTranslation('global');
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const { accent } = useSelector((state: RootState) => state.colors);

	const { partners } = useGetPartners();

	const {
		data,
		disabledButton,
		gif,
		images,
		hanldeImages,
		handleHomeIdChange,
		handlePartnerChange,
		handleMenuConfigCheckboxChange,
		handleMenuConfigInputChange,
		handleSetData,
		handleGenerateZip,
		handleGifChange,
		handleColorsByName,
		setGif,
		isSvgInputDisabled,
	} = useMobileAppSettings();

	const { selectOptions } = useSelectOptions();

	return (
		<Grid container className={styles.mainContainer}>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12}>
					<h3>{t('mobile-settings.title')}</h3>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<SelectInput
						name="partner"
						id="partner"
						label={'Partner'}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={partners}
						onChange={handlePartnerChange}
						value={data.partnerId}
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}></Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="name"
						name="name"
						value={data.name}
						label={t('mobile-settings.name').toString()}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<SelectInput
						name="homeId"
						id="homeId"
						label={'Home ID'}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={selectOptions}
						onChange={handleHomeIdChange}
						value={data.homeId}
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12}>
					<h3>Stripe</h3>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="stripeAccountId"
						name="stripeAccountId"
						value={data.stripeAccountId}
						label={'Stripe Account ID'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="publishable_key_prod"
						name="publishable_key_prod"
						value={data.publishable_key_prod}
						label={'Publishable key Prod'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="publishable_key_qa"
						name="publishable_key_qa"
						value={data.publishable_key_qa}
						label={'Publishable key QA'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12}>
					<h3>Libraries config</h3>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="sentryKeyAndroid"
						name="sentryKeyAndroid"
						value={data.librariesConfig.sentryKeyAndroid}
						label={'Sentry Key Android'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="sentryKeyiOS"
						name="sentryKeyiOS"
						value={data.librariesConfig.sentryKeyiOS}
						label={'Sentry Key iOS'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="stripeKeyProd"
						name="stripeKeyProd"
						value={data.librariesConfig.stripeKeyProd}
						label={'Stripe Key Prod'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
					<Input
						id="stripeKeyQa"
						name="stripeKeyQa"
						value={data.librariesConfig.stripeKeyQa}
						label={'Stripe Key QA'}
						placeholder={t('common.required').toString()}
						style={{
							marginBottom: '27px',
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						type="text"
						required
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12}>
					<h3>Menu config</h3>
				</Grid>
				<Grid item xs={12}>
					<MenuOption
						label="Show privacy item"
						inputName="privacyURL"
						checkboxName="showPrivacyItem"
						inputLabel="Privacy URL"
						inputValue={data.menuConfig.privacyURL || ''}
						checked={data.menuConfig.showPrivacyItem}
						onCheckChange={handleMenuConfigCheckboxChange}
						onInputChange={handleMenuConfigInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<MenuOption
						label="Show support item"
						inputName="supportURL"
						checkboxName="showSupportItem"
						inputLabel="Support URL"
						inputValue={data.menuConfig.supportURL || ''}
						checked={data.menuConfig.showSupportItem}
						onCheckChange={handleMenuConfigCheckboxChange}
						onInputChange={handleMenuConfigInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<MenuOption
						label="Show about item"
						inputName="aboutURL"
						checkboxName="showAboutURLItem"
						inputLabel="About URL"
						inputValue={data.menuConfig.aboutURL || ''}
						checked={data.menuConfig.showAboutURLItem}
						onCheckChange={handleMenuConfigCheckboxChange}
						onInputChange={handleMenuConfigInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<MenuOption
						label="Show reviews item"
						inputName="reviewsURL"
						checkboxName="showReviewsURLItem"
						inputLabel="Reviews URL"
						inputValue={data.menuConfig.reviewsURL || ''}
						checked={data.menuConfig.showReviewsURLItem}
						onCheckChange={handleMenuConfigCheckboxChange}
						onInputChange={handleMenuConfigInputChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<MenuOption
						label="Show XC World item"
						inputName="xcWorldURL"
						checkboxName="showXcWorldURLItem"
						inputLabel="XC World URL"
						inputValue={data.menuConfig.xcWorldURL || ''}
						checked={data.menuConfig.showXcWorldURLItem}
						onCheckChange={handleMenuConfigCheckboxChange}
						onInputChange={handleMenuConfigInputChange}
					/>
				</Grid>
			</Grid>
			<Grid item container xs={12} spacing={2}>
				<Grid item xs={12}>
					<h3>{t('mobile-settings.colors')}</h3>
				</Grid>
				<Grid item xs={12} container spacing={1}>
					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<InputColor
							label={t('mobile-settings.primary')}
							value={data.colors.primary}
							onChange={handleColorsByName}
							name="primary"
						/>
					</Grid>
					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<InputColor
							label={t('mobile-settings.secondary')}
							value={data.colors.secondary}
							onChange={handleColorsByName}
							name="secondary"
						/>
					</Grid>
					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<InputColor
							label={t('mobile-settings.accent')}
							value={data.colors.accent}
							onChange={handleColorsByName}
							name="accent"
						/>
					</Grid>
					<Grid
						item
						xl={3}
						lg={3}
						md={3}
						sm={12}
						xs={12}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
					>
						<InputColor
							label={t('mobile-settings.link')}
							value={data.colors.links}
							onChange={handleColorsByName}
							name="links"
						/>
					</Grid>
				</Grid>
			</Grid>
			{data.homeId !== '' && (
				<Grid item container xs={12} spacing={2}>
					<Grid item xs={12}>
						<h3>{'Images'}</h3>
					</Grid>
					{iconsForm.map(
						(input) =>
							input.requiredIn.includes(data.homeId as number) && (
								<Grid
									key={input.name}
									item
									xs={6}
									md={2}
									display={'flex'}
									justifyContent={'center'}
									alignItems={'center'}
								>
									<SvgUploader
										name={input.name}
										label={input.label}
										width={input.width}
										height={input.height}
										value={images[input.name as ImagesKeys]}
										onChange={hanldeImages}
										disabled={isSvgInputDisabled}
									/>
								</Grid>
							)
					)}
					<Grid
						item
						xs={6}
						md={2}
						display={'flex'}
						flexDirection={'column'}
						justifyContent={'center'}
						alignItems={'center'}
						gap={'10px'}
					>
						<span style={{ fontWeight: '600' }}>Loading animation (GIF)</span>
						<ImageInput
							onChange={handleGifChange}
							onDelete={() => setGif({ base64: '', name: '' })}
							type="gif"
							image={gif.base64}
							padding={false}
						/>
					</Grid>
				</Grid>
			)}
			<Grid
				item
				container
				xs={12}
				display={'flex'}
				justifyContent={matches ? 'center' : 'flex-end'}
			>
				<CustomButton
					label={t('mobile-settings.generate')}
					variant={'solid'}
					onClick={handleGenerateZip}
					styles={{
						width: 'auto',
						paddingLeft: 25,
						paddingRight: 25,
						backgroundColor: accent,
						color: 'black',
						alignSelf: 'flex-end',
						justifySelf: 'flex-end',
						marginTop: '30px',
					}}
					disabled={disabledButton}
				/>
			</Grid>
		</Grid>
	);
};

export default MobileAppSettings;
