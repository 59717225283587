/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useMemo, useState } from 'react';
import { isPasswordValid } from '../../../utils/validators';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import CipherUtils from '../../../utils/CipherUtils';

export function useChangePassword() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { id, partnerId } = useSelector((state: RootState) => state.user);

	const [password, setPassword] = useState<string>('');
	const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');

	const disabledButton = useMemo((): boolean => {
		return !isPasswordValid(password) || passwordConfirmation !== password;
	}, [password, passwordConfirmation]);

	const { execute: changePassword, status: changePasswordStatus } = useBackendRequest<
		{ login_pwd: string; user_system_type: number; partnerId: string },
		any
	>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (changePasswordStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			setPassword('');
			setPasswordConfirmation('');
			dispatch(
				setAlert({
					show: true,
					message: t('change-password.success'),
					severity: 'success',
				})
			);
		} else if (changePasswordStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('change-password.error'),
					severity: 'error',
				})
			);
		}
	}, [changePasswordStatus]);

	const handleUpdatePassword = (): void => {
		dispatch(setLoading({ loading: true }));
		changePassword(
			{ login_pwd: password, user_system_type: 2, partnerId: partnerId.toString() },
			id
		);
	};

	return {
		disabledButton,
		password,
		passwordConfirmation,
		setPassword,
		setPasswordConfirmation,
		handleUpdatePassword,
	};
}
