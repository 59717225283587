import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../../store/modules/snackbar';
import axios from 'axios';
import { setLoading } from '../../../../../../store/modules/loading';
/* import { useApiRequest } from '../../../../../../hooks/useApiRequest';
import { IChargerDetailResponse } from '../interfaces/charger-detail.interface'; */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { IGun } from '../../../../../../interfaces/gun.interface';
import { ILogs } from '../../../../../../interfaces/logs.interface';
import { useGetQionToken } from '../../../../../../hooks/useGetQionToken';

interface IStopSCharger {
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export function useStopCharger({ setOpenModal }: IStopSCharger) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const { getToken } = useGetQionToken();

	const [guns, setGuns] = useState<IGun[]>([]);

	const { execute: saveLog } = useBackendRequest<ILogs, any>({
		path: 'apps-logs/save',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<unknown, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				if (getGunsVal.data.length > 0) {
					setGuns(getGunsVal.data);
				} else {
					dispatch(setLoading({ loading: false }));
					dispatch(
						setAlert({
							show: true,
							message: t('chargers.no-guns'),
							severity: 'error',
						})
					);
				}
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-guns'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const handleGetChargerGuns = (chargerId: string, qionId: string): void => {
		dispatch(setLoading({ loading: true }));
		const filteredGuns = guns.filter((g) => g.chargerId === chargerId);
		const gunsIds: string[] = filteredGuns.map((g) => {
			return g.qionGunUniqueId;
		});
		handleStopGuns(gunsIds, qionId);
		setOpenModal(false);
		dispatch(setLoading({ loading: false }));
	};

	const handleStopGuns = (guns: string[], qionId: string): void => {
		//const qionToken = getToken()

		const request = guns.map((gun) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`${process.env.REACT_APP_BASE_URL}/cms-gun/stopCharging/${gun}`)
					.then(() => {
						resolve(gun);
					})
					.catch((error) => {
						reject(error);
						dispatch(
							setAlert({
								show: true,
								message: t('guns.error-stoped'),
								severity: 'error',
							})
						);
					});
			});
		});

		Promise.all(request)
			.then(() => {
				setOpenModal(false);
				saveLog({
					message: `Charger with ID ${qionId} stopped.`,
					className: 'CMS',
				});
				dispatch(
					setAlert({
						show: true,
						message: t('chargers.stoped'),
						severity: 'success',
					})
				);
			})
			.catch(() => {
				saveLog({
					message: `Charger with ID ${qionId} can not be stopped.`,
					exception: 'The process to stop charger guns could not be executed.',
					url: 'cms-gun/stopCharging',
					className: 'CMS',
				});
				dispatch(
					setAlert({
						show: true,
						message: t('chargers.error-stoped'),
						severity: 'error',
					})
				);
			});
	};

	useEffect(() => {
		getGuns();
	}, []);

	return { handleGetChargerGuns };
}
