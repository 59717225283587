import { IOptions } from '../../../components/select-input.component';

export function useSelectOptions() {
	const selectOptions: IOptions[] = [
		{
			value: '1',
			label: 'Diamond',
		},
		{
			value: '2',
			label: 'Square',
		},
		{
			value: '3',
			label: 'Dial',
		},
	];

	return { selectOptions };
}
