import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../hooks/useTableCustom';

export function useFinancialColums() {
	const [t] = useTranslation('global');

	const financialColumns: IColumns[] = [
		{
			text: t('dashboard-2.month'),
			nameID: 'month',
		},
		{
			text: t('dashboard-2.usage'),
			nameID: 'totalKw',
		},
		{
			text: t('dashboard-2.revenue'),
			nameID: 'totalRevenue',
		},
	];

	return { financialColumns };
}
