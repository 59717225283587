export const iconNames = Object.freeze({
	ICON_APP: 'icon_app_1',
	ICON_MENU: 'icon_menu',
	ICON_PROFILE: 'icon_perfil',
	ICON_STATIONS: 'icon_estaciones',
	ICON_SCAN: 'icon_escanear',
	ICON_GIF: 'icon_gif_carga',
	ICON_LOGIN: 'icon_login',
	ICON_POINT: 'icon_point',
	ICON_APP_2: 'icon_app_2',
	ICON_PROFILE_2: 'icon_profile',
	ICON_SUPP: 'icon_support',
	ICON_ABOUT: 'icon_about_us',
	ICON_XC: 'icon_xcworld',
	LOGOUT: 'icon_logout',
	GHOST_SCAN: 'icon_ghost_scan',
	TYPE_1: 'icon_type_1',
	TYPE_2: 'icon_type_2',
	GBT: 'icon_gbt',
	TESLA: 'icon_tesla',
	CCS1: 'icon_ccs1',
	CCS2: 'icon_ccs2',
	GBT_DC: 'icon_gbt_dc',
	RATIO_SEARCH_ZI: 'icon_ratio_search_zi',
	RATIO_SEARCH_ZO: 'icon_ratio_search_zo',
});
