import { createSlice } from '@reduxjs/toolkit';

interface IColors {
	id: string;
	name: string;
	primary: string;
	secondary: string;
	accent: string;
}

export const defaultColors: IColors = {
	id: '1',
	name: 'Color 1',
	primary: '#000000',
	secondary: '#474747',
	accent: '#2ba8e0',
};

export const colorsSlice = createSlice({
	name: 'colors',
	initialState: defaultColors,
	reducers: {
		createColors: (state, action) => action.payload,
		editColors: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { createColors, editColors } = colorsSlice.actions;

export default colorsSlice.reducer;
