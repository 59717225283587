export interface IImagesKeys {
	icon_app_1: string;
	icon_menu: string;
	icon_perfil: string;
	icon_estaciones: string;
	icon_escanear: string;
	icon_login: string;
	icon_point: string;
	icon_app_2: string;
	icon_profile: string;
	icon_support: string;
	icon_about_us: string;
	icon_xcworld: string;
	icon_logout: string;
	icon_ghost_scan: string;
	icon_type_1: string;
	icon_type_2: string;
	icon_gbt: string;
	icon_tesla: string;
	icon_ccs1: string;
	icon_ccs2: string;
	icon_gbt_dc: string;
	icon_ratio_search_zi: string;
	icon_ratio_search_zo: string;
}

export type ImagesKeys = keyof IImagesKeys;

export const defaultImagesKeys: IImagesKeys = {
	icon_app_1: '',
	icon_menu: '',
	icon_perfil: '',
	icon_estaciones: '',
	icon_escanear: '',
	icon_login: '',
	icon_point: '',
	icon_app_2: '',
	icon_profile: '',
	icon_support: '',
	icon_about_us: '',
	icon_xcworld: '',
	icon_logout: '',
	icon_ghost_scan: '',
	icon_type_1: '',
	icon_type_2: '',
	icon_gbt: '',
	icon_tesla: '',
	icon_ccs1: '',
	icon_ccs2: '',
	icon_gbt_dc: '',
	icon_ratio_search_zi: '',
	icon_ratio_search_zo: '',
};
