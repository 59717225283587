import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useSchedulesTabColums() {
	const [t] = useTranslation('global');
	const schedulesTabColumns: IColumns[] = [
		{
			text: t('schedules.period-name'),
			nameID: 'name',
		},
		{
			text: t('schedules.group'),
			nameID: 'associateGroup',
		},
		{
			text: t('schedules.start'),
			nameID: 'startDate',
		},
		{
			text: t('schedules.end'),
			nameID: 'endDate',
		},
		{
			text: t('schedules.days'),
			nameID: 'days',
		},
		{
			text: t('schedules.schedule'),
			nameID: 'time',
		},
		{
			text: t('common.status'),
			nameID: 'status',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { schedulesTabColumns };
}
