import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IStation } from '../../../interfaces/stations.interface';
import { useTranslation } from 'react-i18next';
import { IPartnerInfo } from '../../../interfaces/partner';

export function useSuperAdminStationsAdapter() {
	const [t] = useTranslation('global');

	const stationsAdapter = (
		data: IStation[],
		partners: IPartnerInfo[],
		seeDetails: (id: string) => void
	): any[] => {
		const getPartnerName = (id: string): string => {
			const partner = partners.filter((p) => p.id === id)[0];
			if (partner) {
				return partner.name;
			} else {
				return '-';
			}
		};

		const newData: any[] = data.map((item: IStation) => {
			return {
				...item,
				address: (
					<>{`${item.address}, ${item.state}, ${item.country}. ${item.zip}`}</>
				),
				partnerInfoId: <>{getPartnerName(item.partnerInfoId)}</>,
				actions: (
					<ButtonIconSmall
						onPress={() => seeDetails(item.id!)}
						type="primary"
						icon={<VisibilityOutlinedIcon />}
						hoverLabel={t('common.view')}
					/>
				),
			};
		});
		return newData;
	};

	return { stationsAdapter };
}
