export const weekDays = [
	{
		label: 'Monday',
		value: 'mon',
	},
	{
		label: 'Tuesday',
		value: 'tue',
	},
	{
		label: 'Wednesday',
		value: 'wed',
	},
	{
		label: 'Thursday',
		value: 'thu',
	},
	{
		label: 'Friday',
		value: 'fri',
	},
	{
		label: 'Saturday',
		value: 'sat',
	},
	{
		label: 'Sunday',
		value: 'sun',
	},
];
