import ButtonIconSmall from '../../../../../../components/button-icon-small.component';
import { BorderColor, VisibilityOutlined } from '@mui/icons-material';
import { IAssociateResponse } from '../../../../interfaces/associates.interface';
import { useTranslation } from 'react-i18next';
import { IAssociate } from '../../../../../../interfaces/associate.interface';

export function useAdminAssociateAdapter() {
	const [t] = useTranslation('global');

	const adminAssociateAdapter = (
		data: IAssociate[],
		handleSeeDetails: (id: string) => void,
		handleEditPartner: (id: string) => void,
		permission: () => boolean
	): IAssociate[] => {
		const newData: any[] = data.map((item: IAssociate) => {
			return {
				...item,
				alias: item.alias ? item.alias : 'Without alias',
				address: (
					<>{`${item.address}, ${item.state}, ${item.country}. ${item.zip} `}</>
				),
				status: item.status ? (
					<ButtonIconSmall type="success" icon={<>{t('common.active')}</>} />
				) : (
					<ButtonIconSmall type="warning" icon={<>{t('common.inactive')}</>} />
				),
				actions: permission() ? (
					<div style={{ display: 'flex', gap: '10px' }}>
						<ButtonIconSmall
							onPress={() => handleSeeDetails(item.id!)}
							type="primary"
							icon={<VisibilityOutlined />}
							hoverLabel={t('common.see-details')}
						/>
						<ButtonIconSmall
							onPress={() => handleEditPartner(item.id!)}
							type="success"
							icon={<BorderColor />}
							hoverLabel={t('common.edit')}
						/>
					</div>
				) : (
					<span>{t('common.no-actions')}</span>
				),
			};
		});
		return newData;
	};
	return { adminAssociateAdapter };
}
