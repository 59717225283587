import { useTranslation } from 'react-i18next';

export function useCreateStationForm() {
	const [t] = useTranslation('global');

	const createStationForm = [
		{
			id: 'name',
			label: t('common.name'),
		},
		{
			id: 'address',
			label: t('common.address'),
		},
		{
			id: 'state',
			label: t('common.state'),
		},
		{
			id: 'country',
			label: t('common.country'),
		},
		{
			id: 'zip',
			label: t('common.zip'),
		},
		{
			id: 'latitude',
			label: t('common.latitude'),
			tooltip: t('common.lat-msg'),
		},
		{
			id: 'longitude',
			label: t('common.longitude'),
			tooltip: t('common.lng-msg'),
		},
	];

	const imagesForm = [
		{
			id: 'image1',
			name: 'image1',
			label: `${t('stations.image')} 1`,
		},
		{
			id: 'image2',
			name: 'image2',
			label: `${t('stations.image')} 2`,
		},
		{
			id: 'image3',
			name: 'image3',
			label: `${t('stations.image')} 3`,
		},
	];

	return { createStationForm, imagesForm };
}
