import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useNavigate } from 'react-router-dom';
import { IAddStationParams } from '../interfaces/add-station.interface';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';

export function useAddStation() {
	const [t] = useTranslation('global');
	const { externalPartnerId } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [stationsData, setStationsData] = useState<any>([]);
	const [selectedStation, setSelectedStation] = useState<any>({
		name: '',
		address: '',
		state: '',
		country: '',
		zip: '',
	});

	const disabledButton = useMemo((): boolean => {
		return !(selectedStation.name !== '');
	}, [selectedStation]);

	const {
		execute: getStations,
		value: partnerStationsVal,
		status: partnerStationsStatus,
	} = useApiRequest<any, any>({
		path: 'cms-station/get-unassotied-stations',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (partnerStationsStatus === 'success') {
			if (partnerStationsVal?.objData) {
				setStationsData(partnerStationsVal?.objData);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-data'),
						severity: 'error',
					})
				);
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.STATIONS },
				});
			}
		} else if (partnerStationsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-data'),
					severity: 'error',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.STATIONS },
			});
		}
	}, [partnerStationsStatus]);

	const {
		execute: addStation,
		value: addStationVal,
		status: addtnerStationStatus,
	} = useApiRequest<IAddStationParams, any>({
		path: `cms-station/add-station/${externalPartnerId}`,
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'POST',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (addtnerStationStatus === 'success') {
			if (addStationVal?.objData) {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.added'),
						severity: 'success',
					})
				);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-added'),
						severity: 'error',
					})
				);
			}
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.STATIONS },
			});
		} else if (addtnerStationStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-added'),
					severity: 'error',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.STATIONS },
			});
		}
	}, [addtnerStationStatus]);

	const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		const selectedStation = stationsData.filter(
			(e: any) => e.stationUniqueID === parseInt(value)
		)[0];
		setSelectedStation(selectedStation);
	};

	const handleAddStation = () => {
		dispatch(setLoading({ loading: true }));
		const station = {
			stationUniqueCode: selectedStation.stationUniqueID,
			name: selectedStation.name,
			address: selectedStation.address,
			state: selectedStation.state,
			country: selectedStation.country,
			zip: selectedStation.zip,
			latitude: selectedStation.latitude,
			longitude: selectedStation.longitude,
		};
		addStation(station);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return {
		stationsData,
		selectedStation,
		disabledButton,
		handleSelect,
		handleAddStation,
	};
}
