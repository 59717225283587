import styles from './styles/revenue-tab.module.css';
import PieChart from '../../../components/pie-chart.component';
import { useTranslation } from 'react-i18next';
import { useRevenueTab } from './hooks/useRevenueTab';
import PeriodSelector from '../../../components/perdiod-selector.component';
import { IAssociate } from '../../../interfaces/associate.interface';

const RevenueTab = () => {
	const [t] = useTranslation('global');

	const {
		associatesNames,
		associatesValues,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
	} = useRevenueTab();

	return (
		<div className={styles.mainContainer}>
			<div className={styles.paperContainer}>
				<h3>{t('common.revenue')}</h3>
				<hr />
				<div className={styles.periodSelectorContainer}>
					<PeriodSelector
						title={getMonthName()}
						prevText={t('groups.prev-month')}
						onPrev={handleGetPastMonth}
						nextText={t('groups.next-month')}
						onNext={handleGetFutureMonth}
					/>
				</div>
				<div className={styles.graphContainer}>
					{associatesValues.every((a) => a !== 0) ? (
						<div className={styles.graph}>
							<PieChart
								mainLabel={t('common.revenue')}
								labels={associatesNames}
								chartData={associatesValues}
							/>
						</div>
					) : (
						<span className={styles.noRevenue}>{t('groups.no-revenue')}</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default RevenueTab;
