export interface ICharger {
	gun: string;
	model: string;
	status: string;
	actions?: JSX.Element;
}
export const mockDataCharger: ICharger[] = [
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
	{
		gun: 'Group 01',
		model: '5',
		status: '10',
	},
];
