import styles from './styles/associate-card.component.module.css';
import { ReactComponent as IconUser } from '../../../assets/svgs/single-user.svg';
import { useState } from 'react';
import { HighlightOff } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { hexToRGBA } from '../../../utils/hex-to-rgba';

interface IAssociateCard {
	id: string;
	name: string;
	rfc: string;
	address: string;
	onClick?: (id: string, selected: boolean) => void;
	clickable?: boolean;
	onRemove?: (id: string) => void;
	showRemoveIcon?: boolean;
}

const AssociateCard = ({
	name,
	id,
	rfc,
	address,
	onClick,
	clickable = true,
	onRemove,
	showRemoveIcon = false,
}: IAssociateCard) => {
	const colors = useSelector((state: RootState) => state.colors);
	const [selected, setSelected] = useState<boolean>(false);

	const handleClick = () => {
		if (onClick !== undefined) onClick(id, !selected);
		setSelected(!selected);
	};

	const handleRemove = () => {
		if (onRemove !== undefined) onRemove(id);
	};

	return (
		<div
			className={styles.cardContainer}
			style={{
				borderColor: selected
					? colors.secondary
					: hexToRGBA(colors.secondary, 0.5),
				borderWidth: selected ? '2px' : '1px',
				cursor: clickable ? 'pointer' : 'default',
				backgroundColor: selected
					? hexToRGBA(colors.secondary, 0.3)
					: hexToRGBA(colors.secondary, 0.1),
			}}
			onClick={clickable ? handleClick : () => {}}
		>
			<div className={styles.rowName}>
				<IconUser
					color={colors.secondary}
					style={{ width: '16px', height: '16px' }}
				/>
				<span>{name}</span>
				{showRemoveIcon ? (
					<div
						style={{ marginLeft: 'auto', cursor: 'pointer' }}
						onClick={handleRemove}
					>
						<HighlightOff style={{ width: '16px', height: '16px' }} />
					</div>
				) : null}
			</div>
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<span className={styles.rfc}>{rfc}</span>
				<span className={styles.address}>{address}</span>
			</div>
		</div>
	);
};

export default AssociateCard;
