import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../../../../../constants/tab-values.constants';

export function useEditAssociateUser() {
	//const [userId, setuserId] = useState<string>('');
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [newEmail, setNewEmail] = useState<string>('');
	const [newStatus, setNewStatus] = useState<boolean>(false);

	const {
		execute: getUser,
		value: getUserVal,
		status: getUserStatus,
	} = useBackendRequest<any, { id: string; email: string }>({
		path: 'users/find-by-email/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getUserStatus === 'success') {
			if (getUserVal?.data) {
				editUser(
					{ email: newEmail, is_disabled: newStatus },
					getUserVal?.data.id
				);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: 'Error.',
						severity: 'error',
					})
				);
			}
		} else if (getUserStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [getUserStatus]);

	const {
		execute: editUser,
		value: editUserVal,
		status: editUserStatus,
	} = useBackendRequest<any, any>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (editUserStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('associates.updated'),
					severity: 'success',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
			});
		} else if (editUserStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [editUserStatus]);

	const handleEditUser = (prevEmail: string, email: string, status: boolean): void => {
		setNewEmail(email);
		setNewStatus(status);
		getUser({}, prevEmail);
	};

	return {
		handleEditUser,
	};
}
