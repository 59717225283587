import { useDispatch, useSelector } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';
import { IPartnerInfo } from '../../../interfaces/partner';
import { editPartner } from '../../../store/modules/partner';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';

export function useEditPartner() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { id } = useSelector((state: RootState) => state.partner);

	const {
		execute: editPartnerR,
		value: editPartnerVal,
		status: editPartnerStatus,
	} = useBackendRequest<any, IPartnerInfo>({
		path: 'partners-information/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (editPartnerStatus === 'success') {
			dispatch(editPartner(editPartnerVal?.data));
			state.prevUrl === '/users'
				? navigate(-1)
				: navigate('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.USERS },
				  });
		} else if (editPartnerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [editPartnerStatus]);

	const editPartnerInfo = (newEmail: string): void => {
		editPartnerR({ email: newEmail }, id);
	};

	return { editPartnerInfo };
}
