import { useTranslation } from 'react-i18next';
import { IOptions } from '../components/select-input.component';

export const availableLanguages: string[] = ['en', 'es'];

const languajesValues = Object.freeze({
	ES: 'es',
	EN: 'en',
});

export function useLanguagesCatalog() {
	const [t] = useTranslation('global');

	const languages: IOptions[] = [
		{
			label: t('languages.languages.english'),
			value: languajesValues.EN,
		},
		{
			label: t('languages.languages.spanish'),
			value: languajesValues.ES,
		},
	];

	const getLanguageLabel = (language: string): string => {
		switch (language) {
			case 'en':
				return t('languages.languages.english');
			case 'es':
				return t('languages.languages.spanish');
			default:
				return 'Languaje';
		}
	};

	return { languages, getLanguageLabel };
}
