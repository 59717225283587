import axios from 'axios';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { editUser } from '../../../store/modules/user';
import { useTranslation } from 'react-i18next';
import { IGunsStats } from '../../../interfaces/guns-stats.interface';
import { IGun } from '../../../interfaces/gun.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';

export function useGraph() {
	const dispatch = useDispatch();
	const [t] = useTranslation('global');
	const user = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating, simulatedPartnerGuns } = useSelector(
		(state: RootState) => state.simulation
	);

	const [days, setDays] = useState<string[]>([]);
	const [gunsStats, setGunsStats] = useState<IGunsStats[]>([]);
	const [isGraphLoading, setIsGraphLoading] = useState<boolean>(true);
	const [dailyCount, setDailyCount] = useState([]);
	const [week, setWeek] = useState(['Mon', 'Tues', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']);

	const [startMonth, setStartMonth] = useState(dayjs().startOf('month').toISOString());
	const [endMonth, setEndMonth] = useState(dayjs().endOf('month').toISOString());
	const [month, setMonth] = useState<number>(dayjs().month());

	const [qionGunsResponse, setQionGunsResponse] = useState<IGun[]>([]);

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const getMonthDays = (year: number, month: number): string[] => {
		const lastDay = new Date(year, month + 1, 0);
		const days = lastDay.getDate();

		const daysArr: string[] = [];

		for (let i = 1; i <= days; i++) {
			daysArr.push(i.toString());
		}
		return daysArr;
	};

	const getStats = async () => {
		await axios
			.post(`${process.env.REACT_APP_API}/charge-fills/find`, {
				datetime_charging_start: startMonth,
				datetime_charging_end: endMonth,
			})
			.then((response) => {
				setGunsStats(response.data.chargeFills);
			})
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	useEffect(() => {
		getStats();
	}, [startMonth, endMonth]);

	useEffect(() => {
		setDailyData();
	}, [gunsStats]);

	const setDailyData = () => {
		const year = dayjs().year();
		const dataToGraph: IGunsStats[] =
			user.role.roleName === ROLES.ADMIN || simulating
				? filterData(qionGunsResponse, gunsStats)
				: gunsStats;
		const arr: any = [];
		for (let i = 0; i <= days.length; i++) {
			let total = 0.0;
			const monthFormatted = (month + 1).toString().padStart(2, '0');
			const dayFormatted = i.toString().padStart(2, '0');
			const fecha = `${year}-${monthFormatted}-${dayFormatted}`;
			const arrDay = dataToGraph?.filter(
				(element) => element.datetime_charging.toString().split('T')[0] === fecha
			);
			if (!arrDay) return;
			if (arrDay.length > 0) {
				for (let j = 0; j < arrDay?.length!; j++) {
					if (parseFloat(arrDay[j].total) > 0) {
						total += parseFloat(arrDay[j].total);
					}
				}
				arr[i - 1] = total.toFixed(2); // Ver -1
			} else {
				arr[i - 1] = 0;
			}
		}
		setDailyCount(arr);
	};

	const handleGetPastMonth = (): void => {
		setStartMonth(dayjs(startMonth).subtract(1, 'month').toISOString());
		setEndMonth(dayjs(endMonth).subtract(1, 'month').toISOString());
		setMonth(month - 1);
	};

	const handleGetFutureMonth = (): void => {
		setStartMonth(dayjs(startMonth).add(1, 'month').toISOString());
		setEndMonth(dayjs(endMonth).add(1, 'month').toISOString());
		setMonth(month + 1);
	};

	const getMonthName = (): string => {
		const month = dayjs(endMonth).month();
		const year = dayjs(endMonth).year();
		let monthStr = '';
		switch (month) {
			case 0:
				monthStr = t('months.january');
				break;
			case 1:
				monthStr = t('months.february');
				break;
			case 2:
				monthStr = t('months.march');
				break;
			case 3:
				monthStr = t('months.april');
				break;
			case 4:
				monthStr = t('months.may');
				break;
			case 5:
				monthStr = t('months.june');
				break;
			case 6:
				monthStr = t('months.july');
				break;
			case 7:
				monthStr = t('months.august');
				break;
			case 8:
				monthStr = t('months.september');
				break;
			case 9:
				monthStr = t('months.october');
				break;
			case 10:
				monthStr = t('months.november');
				break;
			case 11:
				monthStr = t('months.december');
				break;
			default:
				monthStr = dayjs(endMonth).format('MMMM');
				break;
		}
		return `${monthStr} ${year}`;
	};

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				if (getGunsVal.data.length > 0) {
					setQionGunsResponse(getGunsVal.data);
					getStats();
				} else {
					dispatch(
						setAlert({
							show: true,
							message: t('dashboard-1.no-guns'),
							severity: 'error',
						})
					);
				}
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('dashboard-1.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-1.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	useEffect(() => {
		if (user.role.roleName === ROLES.SUPER_ADMIN) {
			const superAdminGunsIds: string[] = qionGunsResponse.map((gun) => {
				return gun.qionGunUniqueId;
			});
			dispatch(editUser({ ...user, partnerGuns: superAdminGunsIds }));
		}
	}, [qionGunsResponse]);

	const filterData = (guns: IGun[], data: IGunsStats[]): IGunsStats[] => {
		const _guns = getPartnerGuns(guns);
		const gunsId: string[] = _guns.map((gun) => {
			return gun.qionGunUniqueId;
		});
		const filteredData = data.filter((d) => {
			if (simulating) {
				return simulatedPartnerGuns.includes(d.gun_id);
			} else {
				return gunsId.includes(d.gun_id as string);
			}
		});
		return filteredData;
	};

	const getPartnerGuns = (guns: IGun[]): IGun[] => {
		if (user.role.roleName === ROLES.SUPER_ADMIN) {
			return guns;
		} else {
			const filteredGuns = guns.filter((g) => g.partnerInfoId === id);
			return filteredGuns;
		}
	};

	useEffect(() => {
		const month = new Date().getMonth();
		const year = new Date().getFullYear();
		setDays(getMonthDays(year, month));
		getGuns();

		setIsGraphLoading(false);
	}, []);

	return {
		days,
		dailyCount,
		isGraphLoading,
		week,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
	};
}
