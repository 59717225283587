/* eslint-disable react-hooks/exhaustive-deps */

import { Grid, Paper } from '@mui/material';
import LinesChart from '../../../components/line-chart.component';
import TableCustom from '../../../components/table-custom.component';

import { useGraph } from '../hooks/useGraph';
import styles from '../styles.module.css';

import { CustomTooltip } from '../../../components/button-icon-small.component';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { useRevenueDashboard } from './hooks/useRevenueDashboard';
import { useTranslation } from 'react-i18next';
import { useFinancialColums } from './utils/financial.columns';

const chartOptions = {
	scales: {
		y: {
			min: 0,
		},
		x: {
			ticks: { color: '#000' },
		},
	},
};

const RevenueDashboard = () => {
	const [t] = useTranslation('global');
	const { isGraphLoading } = useGraph();

	const { financialColumns } = useFinancialColums();

	const {
		data,
		year,
		slicedMonths,
		revenueData,
		usageData,
		setData,
		getPreviousYear,
		getNextYear,
	} = useRevenueDashboard();

	return (
		<Grid container display={'flex'}>
			<Grid item sm={12}>
				<h1 className={styles.title}>
					{t('dashboard-2.revenue-usage-breakdown')}
				</h1>
			</Grid>
			<Grid item sm={12} display={'flex'} justifyContent={'center'} margin={2}>
				<div
					style={{
						display: 'flex',
						gap: '15px',
					}}
				>
					<div
						style={{
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
						}}
						onClick={getPreviousYear}
					>
						<CustomTooltip title={t('dashboard-2.prev-year')} placement="top">
							<ChevronLeft />
						</CustomTooltip>
					</div>
					<span style={{ fontSize: '20px' }}>{year}</span>
					<div
						style={{
							cursor: 'pointer',
							display: 'flex',
							alignItems: 'center',
						}}
						onClick={getNextYear}
					>
						<CustomTooltip title={t('dashboard-2.next-year')} placement="top">
							<ChevronRight />
						</CustomTooltip>
					</div>
				</div>
			</Grid>
			<Grid item md={5} sm={12}>
				<Grid container>
					<Grid item sm={12} style={{ marginBottom: '20px' }}>
						<div className={styles.graphContainer} style={{ width: '100%' }}>
							{isGraphLoading ? (
								<>Charging...</>
							) : (
								<LinesChart
									mainData={revenueData}
									labels={slicedMonths}
									options={chartOptions}
									labelDataSet={t('dashboard-2.revenue-breakdown')}
									fillDataSet={true}
									colorDataSet={'#DBF227'}
									backgroundDataSet={'#D5EC4377'}
								/>
							)}
						</div>
					</Grid>
					<Grid item sm={12}>
						<div className={styles.graphContainer} style={{ width: '100%' }}>
							{isGraphLoading ? (
								<>Charging...</>
							) : (
								<LinesChart
									mainData={usageData}
									labels={slicedMonths}
									options={chartOptions}
									labelDataSet={t('dashboard-2.usage-breakdown')}
									fillDataSet={true}
									colorDataSet={'#165470'}
									backgroundDataSet={'#1654704D'}
								/>
							)}
						</div>
					</Grid>
				</Grid>
			</Grid>
			<Grid item md={7} sm={12}>
				<div style={{ paddingLeft: '20px' }}>
					<Paper elevation={1} sx={{ padding: 1 }}>
						<TableCustom
							columns={financialColumns}
							data={data}
							setData={setData}
							showTotalCount={false}
							hideChips
							titleHeader={t('dashboard-2.finance')}
						/>
					</Paper>
				</div>
			</Grid>
		</Grid>
	);
};

export default RevenueDashboard;
