import { Grid } from '@mui/material';
import CustomButton from '../../components/button.component';
import { useNavigate } from 'react-router-dom';

import styles from './styles/station-details.component.module.css';
import { ArrowBack, Bolt, Image, SettingsInputSvideo } from '@mui/icons-material';

import { useState } from 'react';
import StationCardDetails from './components/station-card.component';
import SectionChargers from './components/section-chargers.component';
import SectionConnectors from './components/section-connectors.component';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useStationDetails } from './hooks/useStationDetails';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import { useTranslation } from 'react-i18next';
import SectionDS from './components/section-ds.component';
import { ROLES } from '../../constants/user-roles.constants';

type selectedSection = 'chargers' | 'connectors' | 'schedules' | 'branding' | 'ds';

const StationDetails = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const navigate = useNavigate();

	const [selectedSection, setSelectedSection] = useState<selectedSection>('ds');

	const { station, chargers, guns } = useStationDetails();

	const onHandleTab = (tab: selectedSection) => {
		switch (tab) {
			case 'chargers':
				return <SectionChargers data={chargers} />;
			case 'connectors':
				return <SectionConnectors gunsData={guns} />;
			case 'ds':
				return (
					<SectionDS
						image1={station.image1}
						image2={station.image2}
						image3={station.image3}
					/>
				);
			default:
				return <SectionChargers data={chargers} />;
		}
	};

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant={'solid'}
				onClick={
					role.roleName === ROLES.SUPER_ADMIN && !simulating
						? () => navigate('/partners/all-stations')
						: () =>
								navigate('/partners/details', {
									state: { tab: ADMIN_TAB_VALUES.STATIONS },
								})
				}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					height: '33px',
					gap: '11px',
					backgroundColor: 'var(--color-black-main)',
				}}
				icon={ArrowBack}
			/>
			<Grid container spacing={5} style={{ height: '100%' }}>
				<Grid item xl={4}>
					<StationCardDetails
						id={station.id!}
						name={station.name}
						location={`${station.address}, ${station.state}, ${station.country}. ${station.zip}.`}
					/>
					<div className={styles.sectionsContainer}>
						<div
							className={styles.section}
							style={
								selectedSection === 'ds'
									? { color: 'white', backgroundColor: colors.primary }
									: {}
							}
							onClick={() => setSelectedSection('ds')}
						>
							<Image
								style={{
									width: '30px',
									height: '30px ',
									color:
										selectedSection === 'ds'
											? 'white'
											: colors.primary,
								}}
							/>
							<span>{t('stations.branding')}</span>
						</div>
						<div
							className={styles.section}
							style={
								selectedSection === 'chargers'
									? { color: 'white', backgroundColor: colors.primary }
									: {}
							}
							onClick={() => setSelectedSection('chargers')}
						>
							<Bolt
								style={{
									width: '30px',
									height: '30px ',
									color:
										selectedSection === 'chargers'
											? 'white'
											: colors.primary,
								}}
							/>
							<span>{t('common.chargers')}</span>
						</div>
						<div
							className={styles.section}
							style={
								selectedSection === 'connectors'
									? { color: 'white', backgroundColor: colors.primary }
									: {}
							}
							onClick={() => setSelectedSection('connectors')}
						>
							<SettingsInputSvideo
								style={{
									width: '30px',
									height: '30px ',
									color:
										selectedSection === 'connectors'
											? 'white'
											: colors.primary,
								}}
							/>
							<span>{t('common.guns')}</span>
						</div>
					</div>
				</Grid>
				<Grid item xl={8}>
					{onHandleTab(selectedSection)}
				</Grid>
			</Grid>
		</>
	);
};

export default StationDetails;
