import { Grid } from '@mui/material';

import styles from './styles/create-charger.module.css';
import Input from '../../components/input.component';
import { useCreateChargerForm } from './utils/create-charger-form';
import { useCreateCharger } from './hooks/useCreateCharger';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/button.component';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import DeleteModal from './components/delete-modal.component';
import { ICharger } from '../../interfaces/charger.interface';
import SelectInput from '../../components/select-input.component';
import { ROLES } from '../../constants/user-roles.constants';
import { useGetPartners } from '../create-station/hooks/useGetPartners';

const CreateCharger = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { role } = useSelector((state: RootState) => state.user);
	const colors = useSelector((state: RootState) => state.colors);
	const { createChargerForm } = useCreateChargerForm();

	const { partnerOptions } = useGetPartners();

	const {
		view,
		chargerData,
		disabledButton,
		openModal,
		stationsSelect,
		qionChargersSelect,
		handleSetData,
		setOpenModal,
		handleCreateCharger,
		editCharger,
		deleteCharger,
		handleSelectChange,
		handlePartnerSelectChange,
	} = useCreateCharger();

	return (
		<>
			<Grid container spacing={3} className={styles.mainContainer}>
				<Grid item xs={12}>
					<h3 className={styles.customH3}>
						{view === 'add-charger'
							? t('chargers.create')
							: t('chargers.edit')}
					</h3>
				</Grid>
				{role.roleName === ROLES.SUPER_ADMIN && !simulating && (
					<>
						<Grid item xl={4} lg={4} md={4} sm={12}>
							<SelectInput
								name="partnerId"
								id="partnerId"
								label={t('common.partner').toString()}
								placeholder={t('common.required').toString()}
								style={{ width: '100%' }}
								required
								options={partnerOptions}
								onChange={handlePartnerSelectChange}
								value={chargerData.partnerInfoId}
							/>
						</Grid>
						<Grid item xs={12}></Grid>
					</>
				)}
				<Grid item md={4} sm={12} xs={12}>
					<SelectInput
						name="stationId"
						id="stationId"
						label={t('common.station').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={stationsSelect}
						onChange={handleSelectChange}
						value={chargerData.stationId}
						disabled={chargerData.partnerInfoId === ''}
					/>
				</Grid>
				<Grid item xs={12}></Grid>
				{createChargerForm.map((element) => (
					<Grid key={element.id} item md={4} sm={12} xs={12}>
						<Input
							id={element.id}
							name={element.id}
							label={element.label}
							value={chargerData[element.id as keyof ICharger]}
							placeholder={t('common.required').toString()}
							style={{ height: '43px', paddingLeft: '14px' }}
							onChange={handleSetData}
							type="text"
							required
							tooltip={element.tooltip}
						/>
					</Grid>
				))}
				<Grid item xs={12}>
					<h3 className={styles.customH3}>{t('chargers.associate-to')}</h3>
				</Grid>
				{view !== 'add-charger' && (
					<Grid item xs={12}>
						<span className={styles.customH3}>
							<span>
								<span
									style={{
										color: colors.secondary,
										fontWeight: 'bold',
										fontSize: '18px',
									}}
								>
									*
								</span>{' '}
								{t('chargers.actually-msg')}{' '}
								<b>{chargerData.qionChargerUniqueId}</b>
							</span>
						</span>
					</Grid>
				)}
				<Grid item md={4} sm={12} xs={12}>
					<SelectInput
						name="chargerId"
						id="chargerId"
						label={t('common.charger').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={qionChargersSelect}
						onChange={handleSelectChange}
						value={chargerData.qionChargerUniqueId}
					/>
				</Grid>
				<Grid
					item
					container
					xs={12}
					display={'flex'}
					justifyContent={view !== 'add-charger' ? 'space-between' : 'end'}
					gap={5}
					marginTop={3}
				>
					{view !== 'add-charger' && (
						<CustomButton
							label={t('common.delete')}
							variant={'solid'}
							onClick={() => setOpenModal(true)}
							styles={{
								width: '120px',
								backgroundColor: 'var(--color-error-main)',
								padding: '0 15px',
								gap: '10px',
							}}
						/>
					)}
					<div style={{ display: 'flex', gap: '20px' }}>
						<CustomButton
							label={t('common.back')}
							variant={'solid'}
							onClick={
								role.roleName === ROLES.SUPER_ADMIN && !simulating
									? () => navigate('/partners/all-chargers')
									: view !== 'add-charger'
									? () => navigate(-1)
									: () =>
											navigate('/partners/details', {
												state: { tab: ADMIN_TAB_VALUES.CHARGERS },
											})
							}
							styles={{
								width: '120px',
								backgroundColor: 'black',
								padding: '0 15px',
								gap: '10px',
							}}
						/>
						<CustomButton
							label={t('common.save')}
							variant={'solid'}
							onClick={
								view !== 'add-charger' ? editCharger : handleCreateCharger
							}
							styles={{
								width: '120px',
								backgroundColor: colors.accent,
								color: 'black',
								padding: '0 15px',
								gap: '10px',
							}}
							disabled={disabledButton}
						/>
					</div>
				</Grid>
			</Grid>
			<DeleteModal
				open={openModal}
				setOpen={setOpenModal}
				name={chargerData.name}
				acceptModal={deleteCharger}
			/>
		</>
	);
};

export default CreateCharger;
