import { useSelector } from 'react-redux';
import styles from './styles/add-associate-button.component.module.css';
import { Add } from '@mui/icons-material';
import { RootState } from '../../../store';
import { hexToRGBA } from '../../../utils/hex-to-rgba';

interface IAddAssociateButton {
	onClick?: () => void;
	text?: string;
}

const AddAssociateButton = ({ onClick, text = 'Add Associate' }: IAddAssociateButton) => {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<div
			className={styles.container}
			style={{
				color: colors.secondary,
				backgroundColor: hexToRGBA(colors.secondary, 0.1),
			}}
			onClick={onClick}
		>
			<Add style={{ color: colors.secondary }} />
			<span className={styles.buttonText}>{text}</span>
		</div>
	);
};

export default AddAssociateButton;
