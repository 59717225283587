/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IGun } from '../../../interfaces/gun.interface';
import { ICharger } from '../../../interfaces/charger.interface';
import { IAssociatesGroup } from '../../../interfaces/partner';

export function useGetData() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { id } = useSelector((state: RootState) => state.partner);
	const [gunsData, setGunsData] = useState<IGun[]>([]);
	const [chargers, setChargers] = useState<ICharger[]>([]);
	const [associateGroupData, setAssociateGroupData] = useState<IAssociatesGroup[]>([]);

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				if (getGunsVal.data.length > 0) {
					const filteredGuns = getGunsVal.data.filter(
						(g) => g.partnerInfoId === id
					);
					setGunsData(filteredGuns);
				} else {
					dispatch(
						setAlert({
							show: true,
							message: t('errors.not-guns'),
							severity: 'error',
						})
					);
				}
			} else {
				setGunsData([]);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<unknown, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				const filtereChargers = getChargersVal.data.filter(
					(c) => c.partnerInfoId === id
				);
				setChargers(filtereChargers);
			} else {
				setChargers([]);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const getChargerName = (id: string): string => {
		let name = 'No charger provided';
		const charger = chargers.filter((c) => c.id === id)[0];
		if (charger) name = charger.name;
		return name;
	};

	const {
		execute: getAssociatesGroups,
		value: associatesGVal,
		status: associatesGStatus,
	} = useBackendRequest<unknown, IAssociatesGroup[]>({
		path: `partners-group/partner/${id}`,
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesGStatus === 'success') {
			if (associatesGVal?.data) {
				setAssociateGroupData(associatesGVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('add-schedule.error-groups'),
						severity: 'error',
					})
				);
			}
		} else if (associatesGStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.error-groups'),
					severity: 'error',
				})
			);
		}
	}, [associatesGStatus]);

	const getValues = async (): Promise<void> => {
		await getAssociatesGroups();
		await getGuns();
		await getChargers();
	};

	useEffect(() => {
		getValues();
	}, []);

	return { gunsData, associateGroupData, getChargerName };
}
