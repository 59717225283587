import { useEffect } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IGun } from '../../../interfaces/gun.interface';
import { IAssociatesGroup, ISchedule } from '../../../interfaces/partner';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { associateGroupDefaultVaule } from '../../../constants/interfaces-default-values';

export function useEditSchedule(
	gunsData: IGun[],
	associateGroupData: IAssociatesGroup[],
	setnewScheduleData: React.Dispatch<React.SetStateAction<ISchedule>>,
	setSelectedGroup: React.Dispatch<React.SetStateAction<IAssociatesGroup>>,
	setSelectedGuns: React.Dispatch<React.SetStateAction<IGun[]>>
) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		execute: getSchedule,
		value: getScheduleValue,
		status: getScheduleStatus,
	} = useBackendRequest<any, ISchedule>({
		path: 'schedules/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getScheduleStatus === 'success') {
			if (!getScheduleValue?.data) return;
			const schedule: any = getScheduleValue.data;
			delete schedule.id;
			delete schedule.create_time;
			delete schedule.update_time;
			delete schedule.applied_date;
			setnewScheduleData({
				...getScheduleValue.data!,
			});

			const selectedGroup = associateGroupData.filter(
				(e) => e.id === getScheduleValue?.data?.associateGroupId
			)[0];
			setSelectedGroup(selectedGroup ? selectedGroup : associateGroupDefaultVaule);
			const scheduleGuns = getScheduleValue.data.guns;

			const selectedGuns = [];
			for (let i = 0; i < gunsData.length; i++) {
				for (let j = 0; j < scheduleGuns.length; j++) {
					if (gunsData[i].qionGunUniqueId === scheduleGuns[j]) {
						selectedGuns.push(gunsData[i]);
					}
				}
			}
			setSelectedGuns(selectedGuns);
			dispatch(setLoading({ loading: false }));
		} else if (getScheduleStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.error-schedule-info'),
					severity: 'error',
				})
			);
		}
	}, [getScheduleStatus]);

	useEffect(() => {
		if (associateGroupData.length > 0) {
			const selectedGroup = associateGroupData.filter(
				(e) => e.id === getScheduleValue?.data?.associateGroupId
			)[0];
			setSelectedGroup(selectedGroup ? selectedGroup : associateGroupDefaultVaule);
		}
	}, [associateGroupData]);

	useEffect(() => {
		if (gunsData.length > 0) {
			const selectedGuns = [];
			for (let i = 0; i < gunsData.length; i++) {
				for (let j = 0; j < getScheduleValue?.data?.guns.length!; j++) {
					if (gunsData[i].qionGunUniqueId === getScheduleValue?.data?.guns[j]) {
						selectedGuns.push(gunsData[i]);
					}
				}
			}
			setSelectedGuns(selectedGuns);
		}
	}, [gunsData]);

	const handleGetScheduleInformation = (scheduleId: number | string): void => {
		dispatch(setLoading({ loading: true }));

		getSchedule({}, scheduleId.toString());
	};

	const { execute: editSchedule, status: editScheduleStatus } = useBackendRequest<
		ISchedule,
		any
	>({
		path: 'schedules/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (editScheduleStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.edited'),
					severity: 'success',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.SCHEDULES },
			});
		} else if (editScheduleStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.error-edited'),
					severity: 'error',
				})
			);
		}
	}, [editScheduleStatus]);

	const handleEditSchedule = (newScheduleData: ISchedule, id: string) => {
		dispatch(setLoading({ loading: true }));
		editSchedule(newScheduleData, id);
	};

	return { handleGetScheduleInformation, handleEditSchedule };
}
