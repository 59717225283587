import React from 'react';
import TableCustom from '../../../../components/table-custom.component';
import { useGAssociatesTab } from './hooks/useAssociatesTab';
import { useAssociatesTabColums } from './utils/associates-tab-colums.colums';
import PeriodSelector from '../../../../components/perdiod-selector.component';
import { useTranslation } from 'react-i18next';
import DownloadButtons from '../download-buttons';

import styles from '../../styles/styles.module.css';
import DateInput from '../../../../components/DateInput';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { Search } from '@mui/icons-material';

const AssociatesTab = () => {
	const [t] = useTranslation('global');
	const {
		data,
		dateFilter,
		setData,
		handleDownloadPdf,
		handleDownloadCsv,
		handleDateInputs,
		searchInRange,
	} = useGAssociatesTab();
	const { associatesTabColumns } = useAssociatesTabColums();

	return (
		<div style={{ width: '100%' }}>
			<div className={styles.headerContainer}>
				<div className={styles.inputsContainer}>
					<DateInput
						label={t('dashboard-1.start')}
						name="startDate"
						value={dateFilter.startDate}
						onChange={handleDateInputs}
					/>
					<DateInput
						label={t('dashboard-1.end')}
						name="endDate"
						value={dateFilter.endDate}
						onChange={handleDateInputs}
					/>
					<ButtonIconSmall
						onPress={searchInRange}
						type="primary"
						icon={<Search />}
					/>
				</div>
				<DownloadButtons
					onDownloadCsv={handleDownloadCsv}
					onDownloadPdf={handleDownloadPdf}
				/>
			</div>
			<TableCustom
				columns={associatesTabColumns}
				data={data}
				setData={setData}
				hideHeader
				showTotalCount={false}
				hideChips
				showLimitPerPage
				showPagination
			/>
		</div>
	);
};

export default AssociatesTab;
