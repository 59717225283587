/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IStation } from '../../../interfaces/stations.interface';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { useSuperAdminStationsAdapter } from '../adapters/stations.adapter';
import { setLoading } from '../../../store/modules/loading';
import { IPartnerInfo } from '../../../interfaces/partner';
import { useNavigate } from 'react-router-dom';

export function useSuperAdminStations() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data, setData } = useTableCustom<any>();
	const { stationsAdapter } = useSuperAdminStationsAdapter();

	const [partnerInfo, setPartnerInfo] = useState<IPartnerInfo[]>([]);

	const {
		execute: getPartners,
		value: getPartnersVal,
		status: getPartnersStatus,
	} = useBackendRequest<any, IPartnerInfo[]>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getPartnersStatus === 'success') {
			if (getPartnersVal?.data) {
				getStations();
				setPartnerInfo(getPartnersVal?.data);
			}
		} else if (getPartnersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnersStatus]);

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				setData({
					...data,
					countRows: 0,
					rows: stationsAdapter(
						getStationsVal.data,
						partnerInfo,
						seeStationDetails
					),
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const seeStationDetails = (id: string): void => {
		navigate(`/stations/details/${id}`);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getPartners();
	}, []);

	return { data, setData };
}
