export interface ICharger {
	id?: string;
	partnerInfoId: string;
	qionChargerUniqueId: string;
	stationId: string;
	name: string;
	address: string;
	state: string;
	country: string;
	zip: string;
	longitude: string;
	latitude: string;
	create_time?: string;
	update_time?: string;
}

export const defaultCharger: ICharger = {
	partnerInfoId: '',
	qionChargerUniqueId: '',
	stationId: '',
	name: '',
	address: '',
	state: '',
	country: '',
	zip: '',
	longitude: '',
	latitude: '',
};
