import { Close } from '@mui/icons-material';

import style from './styles/alert-input-styles.component.module.css';

interface AlertInputProps {
	valid: boolean;
	message: string;
	name: string;
}

export function AlertInput({ name, valid, message }: AlertInputProps) {
	return (
		<>
			{!valid && message && (
				<div className={style.messageError}>
					<Close fontSize="inherit" style={{ color: '#D1001F' }} />
					<span data-testid={`error-${name}`}>{message}</span>
				</div>
			)}
		</>
	);
}
