/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { isLatitude, isLongitude, zipRegex } from '../../../utils/validators';
import { ICharger, defaultCharger } from '../../../interfaces/charger.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useEditCharger } from './useEditCharger';
import { useGetData } from './useGetData';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../constants/user-roles.constants';
import { IOptions } from '../../../components/select-input.component';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { ICreateQionChargerParams } from '../interfaces/create-qion-charger.interface';
import { IStation } from '../../../interfaces/stations.interface';
//import { ROLES } from '../../../constants/user-roles.constants';

export function useCreateCharger() {
	const [t] = useTranslation('global');
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const { id: chargerId } = useParams();
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [chargerData, setChargerData] = useState<ICharger>({
		...defaultCharger,
		partnerInfoId: role.roleName === ROLES.SUPER_ADMIN && !simulating ? '' : id,
	});

	const { stationsSelect, qionChargersSelect, stationsData, setStationsSelect } =
		useGetData();
	const {
		handleGetCharger,
		handleEditCharger,
		handleDeleteCharger,
		handleDeleteQionCharger,
	} = useEditCharger({
		setChargerData,
	});

	const [selectedStation, setSelectedStation] = useState<IStation>();
	const [openModal, setOpenModal] = useState<boolean>(false);

	const path = window.location.href;
	const view = path
		.split('/')
		.filter((part) => part !== '')
		.pop();

	const disabledButton = useMemo((): boolean => {
		return (
			chargerData.partnerInfoId === '' ||
			chargerData.name === '' ||
			chargerData.address === '' ||
			chargerData.state === '' ||
			chargerData.country === '' ||
			chargerData.zip.toString().length < 1 ||
			!isLatitude(chargerData.latitude.toString()) ||
			!isLongitude(chargerData.longitude.toString()) ||
			chargerData.stationId === '' ||
			chargerData.qionChargerUniqueId === ''
		);
	}, [chargerData]);

	const { execute: createCharger, status: createChargerStatus } = useBackendRequest<
		ICharger,
		any
	>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (createChargerStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.created'),
					severity: 'success',
				})
			);
			if (state) {
				navigate(-1);
			} else {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.CHARGERS },
				});
			}
		} else if (createChargerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			console.log('Nextia charger');
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createChargerStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		}
		if (name === 'latitude' || name === 'longitude') {
			const noWhiteSpace = value.replace(/\s/g, '');
			setChargerData({ ...chargerData, [name]: noWhiteSpace });
			return;
		}
		setChargerData({ ...chargerData, [name]: value });
	};

	const editCharger = (): void => {
		handleEditCharger(chargerData);
	};

	const deleteCharger = (): void => {
		const chargersStation: IStation = stationsData.filter(
			(s) => s.id === chargerData.stationId
		)[0];
		if (chargersStation.stationUniqueCode) {
			handleDeleteQionCharger(
				chargersStation.stationUniqueCode,
				chargerData.qionChargerUniqueId
			);
		} else {
			handleDeleteCharger(chargerId!);
		}
	};

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { name, value } = event.target;
		if (name === 'chargerId') {
			setChargerData({ ...chargerData, qionChargerUniqueId: value });
		} else {
			const stationVal = stationsData.filter((s) => s.id === value)[0];
			setSelectedStation(stationVal);
			const { address, state, country, zip } = stationVal;
			setChargerData({
				...chargerData,
				stationId: value,
				address: address,
				state: state,
				country: country,
				zip: zip,
			});
		}
	};

	/* const handlePartnerSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setChargerData({ ...chargerData, partnerInfoId: value });
	}; */

	useEffect(() => {
		if (view !== 'add-charger') {
			handleGetCharger(chargerId!);
		}
	}, []);

	const handlePartnerSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setLoading({ loading: true }));
		const { value } = event.target;
		setChargerData({ ...chargerData, partnerInfoId: value });
		filterSelectByPartner(value);
	};

	const filterSelectByPartner = (partnerId: string): void => {
		const dataFiltered = stationsData.filter((s) => s.partnerInfoId === partnerId);
		const newSelect: IOptions[] = dataFiltered.map((s) => {
			return {
				label: `${s.name}`,
				value: s.id!,
			};
		});
		if (newSelect.length < 1) {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.no-data'),
					severity: 'error',
				})
			);
		}
		setStationsSelect(newSelect);
		dispatch(setLoading({ loading: false }));
	};

	//To create a Charger on Qion DB
	const { execute: createQionCharger, status: createQionChargerStatus } = useApiRequest<
		ICreateQionChargerParams,
		any
	>({
		path: 'cms-station/assing-charger/',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'PATCH',
	});

	const handleCreateCharger = (): void => {
		dispatch(setLoading({ loading: true }));
		if (selectedStation?.stationUniqueCode) {
			createQionCharger(
				{
					name: chargerData.name,
					chargerUniqueCode: chargerData.qionChargerUniqueId,
					address: chargerData.address,
					state: chargerData.address,
					country: chargerData.country,
					zip: chargerData.zip,
					longitude: chargerData.longitude,
					latitude: chargerData.latitude,
				},
				selectedStation.stationUniqueCode
			);
		} else {
			console.log('no station');
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-created'),
					severity: 'error',
				})
			);
		}
	};

	useEffect(() => {
		if (createQionChargerStatus === 'success') {
			createCharger(chargerData);
		} else if (createQionChargerStatus === 'error') {
			console.log('Qion charger');
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createQionChargerStatus]);

	return {
		view,
		chargerData,
		disabledButton,
		openModal,
		stationsSelect,
		qionChargersSelect,
		handleSetData,
		setOpenModal,
		handleCreateCharger,
		editCharger,
		deleteCharger,
		handleSelectChange,
		handlePartnerSelectChange,
	};
}
