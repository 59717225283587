import React, { InputHTMLAttributes } from 'react';
import { Label } from './label.component';
import style from './styles/select-input-styles.component.module.css';
import { AlertInput } from './alert-input.component';

export interface IOptions {
	value: string | number;
	label: string;
}

interface ISelect extends InputHTMLAttributes<HTMLSelectElement> {
	error?: { valid: boolean; msg: string };
	label?: string;
	name: string;
	options?: IOptions[];
	required?: boolean;
	disabled?: boolean;
}

const SelectInput = ({
	error,
	label,
	name,
	options,
	required = false,
	disabled = false,
	placeholder,
	style: customStyles,
	onChange,
	value,
}: ISelect) => {
	return (
		<div className={style.content} style={customStyles}>
			<Label label={label} name={name} required={required} disabled={disabled} />
			<select
				className={`${style.input} `}
				name={name}
				disabled={disabled}
				onChange={onChange}
				style={{ height: '43px' }}
				value={value}
			>
				<option disabled value={''}>
					{placeholder}
				</option>
				{options?.map(({ value, label }) => (
					<option key={value} value={value}>
						{label}
					</option>
				))}
			</select>
			{error && <AlertInput name={name} valid={error.valid} message={error.msg} />}
		</div>
	);
};

export default SelectInput;
