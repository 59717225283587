import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../../hooks/useTableCustom';

export function useAssociateColumns() {
	const [t] = useTranslation('global');
	const associateColumns: IColumns[] = [
		{
			text: t('common.name'),
			nameID: 'name',
		},
		{
			text: t('common.alias'),
			nameID: 'alias',
		},
		{
			text: t('common.address'),
			nameID: 'address',
		},
		{
			text: t('common.tax-id'),
			nameID: 'rfc',
		},
		{
			text: t('common.email'),
			nameID: 'email',
		},
		{
			text: t('common.status'),
			nameID: 'status',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { associateColumns };
}
