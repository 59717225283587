/* eslint-disable prettier/prettier */
import { useNavigate } from 'react-router';
import CustomButton from '../../components/button.component';
import { ArrowBack } from '@mui/icons-material';

import styles from './styles/add-station.module.css';
import Input from '../../components/input.component';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useAddStation } from './hooks/useAddStation';
import SelectInput from '../../components/select-input.component';
import { useTranslation } from 'react-i18next';

const AddStation = () => {
	const [t] = useTranslation('global');
	//const partner = useSelector((state: RootState) => state.partner);
	const config = useSelector((state: RootState) => state.config);
	//const chargers = partner.charges;
	const navigate = useNavigate();

	const {
		stationsData,
		selectedStation,
		disabledButton,
		handleSelect,
		handleAddStation,
	} = useAddStation();

	//console.log(stationsData);

	/* const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedChargers, setSelectedChargers] = useState<ICharge[]>([]);
	const [tempChargers, setTempChargers] = useState<ICharge[]>([]); */

	/* const [newStationData, setNewStationData] = useState<IAssociatesStation>({
		id: Math.floor(Math.random() * 10000),
		name: '',
		location: '',
		charges: [],
	});

	const disabledButton = useMemo((): boolean => {
		return !(
			newStationData.name !== '' &&
			newStationData.location !== '' &&
			newStationData.charges.length > 0
		);
	}, [newStationData]); */

	/* const handleSelectCharger = (id: number, select: boolean) => {
		let newArr;
		select
			? (newArr = tempChargers.concat(
					chargers.find((element) => element.id === id)!
			  ))
			: (newArr = tempChargers.filter((element) => element.id !== id));
		setTempChargers(newArr);
	};

	const handleAcceptModal = () => {
		setSelectedChargers(tempChargers);
		setNewStationData({
			...newStationData,
			charges: tempChargers,
		});
		setOpenModal(false);
	};

	const handleRemoveCard = (id: number) => {
		let newArr;
		newArr = selectedChargers.filter((element) => element.id !== id);
		setSelectedChargers(newArr);

		newArr = newStationData.charges.filter((element) => element.id !== id);
		setNewStationData({ ...newStationData, charges: newArr });
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;

		setNewStationData({ ...newStationData, [name]: value });
	}; */

	return (
		<div className={styles.mainContainer}>
			<div>
				<CustomButton
					label={t('common.back')}
					variant={'solid'}
					onClick={() =>
						navigate('/partners/details', {
							state: { tab: ADMIN_TAB_VALUES.STATIONS },
						})
					}
					styles={{
						width: 'fit-content',
						padding: '0 15px',
						gap: '11px',
						backgroundColor: 'var(--color-black-main)',
					}}
					icon={ArrowBack}
				/>
			</div>
			<div className={styles.paperContainer}>
				<h2>{t('stations.new')}</h2>
				<hr />
				<div className={styles.formContainer}>
					<h3>{t('stations.select')}</h3>
					<div
						className={
							config.menu
								? styles.inputsContainer
								: styles.inputsContainerFull
						}
					>
						<div className={styles.inputsItem}>
							<SelectInput
								name="gun"
								id="gun"
								label={t('common.station').toString()}
								placeholder={t('common.required').toString()}
								style={{ width: '100%' }}
								required
								options={stationsData.map((station: any) => {
									return {
										label: `${station.stationUniqueID} - ${station.name}`,
										value: station.stationUniqueID,
									};
								})}
								onChange={handleSelect}
							/>
						</div>
					</div>
					<h3>Station information</h3>
					<div
						className={
							config.menu
								? styles.inputsContainer
								: styles.inputsContainerFull
						}
					>
						<div className={styles.inputsItem}>
							<Input
								label={t('stations.name').toString()}
								name={'name'}
								defaultValue={selectedStation.name}
								disabled
							/>
						</div>
						<div className={styles.inputsItem}>
							<Input
								label={t('common.address').toString()}
								name={'location'}
								defaultValue={selectedStation.address}
								disabled
							/>
						</div>
						<div className={styles.inputsItem}>
							<Input
								label={t('common.state').toString()}
								name={'name'}
								defaultValue={selectedStation.state}
								disabled
							/>
						</div>
						<div className={styles.inputsItem}>
							<Input
								label={t('common.country').toString()}
								name={'location'}
								defaultValue={selectedStation.country}
								disabled
							/>
						</div>
						<div className={styles.inputsItem}>
							<Input
								label={t('common.zip').toString()}
								name={'name'}
								defaultValue={selectedStation.zip}
								disabled
							/>
						</div>
					</div>
				</div>
				<hr />
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={'Cancel'}
						variant={'solid'}
						onClick={() =>
							navigate('/partners/details', {
								state: { tab: ADMIN_TAB_VALUES.STATIONS },
							})
						}
						styles={{
							width: '158px',
							gap: '11px',
							backgroundColor: 'var(--color-black-main)',
						}}
					/>
					<CustomButton
						label={'Save'}
						variant={'solid'}
						onClick={handleAddStation}
						styles={{
							width: '158px',
							gap: '11px',
						}}
						disabled={disabledButton}
					/>
				</div>
			</div>
			{/* <CustomModal
				open={openModal}
				maxWidth="xl"
				fullWidth
				title="Add charger to station"
			>
				<StationsModalContent
					setOpenModal={setOpenModal}
					handleClickCard={handleSelectCharger}
					acceptModal={handleAcceptModal}
					selectedChargers={tempChargers.length}
				/>
			</CustomModal> */}
		</div>
	);
};

export default AddStation;
