import { useDispatch, useSelector } from 'react-redux';
import { useApiRequest } from '../../../../hooks/useApiRequest';
import { RootState } from '../../../../store';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { IDashboardLocationGun } from '../interfaces/location-dashboard.interface';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { IGunsStats, IGunsStatsPag } from '../../../../interfaces/guns-stats.interface';
import { IQionGunsResponse } from '../../../../interfaces/gun-qion-response.interface';
import { useTranslation } from 'react-i18next';
import { IStation, defaultStation } from '../../../../interfaces/stations.interface';
import { ROLES } from '../../../../constants/user-roles.constants';
import { useGetStationGuns } from './useGetStationGuns';
import { IGun } from '../../../../interfaces/gun.interface';
import { IGunStatus } from '../interfaces/charging-dashboard.interface';

export function useLocationDashboard() {
	const [t] = useTranslation('global');
	const { role, partnerGuns } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const dispatch = useDispatch();

	const [selectData, setSelectData] = useState<
		{ value: string | number; label: string }[]
	>([]);
	const [viewStationdata, setViewStationdata] = useState<boolean>(false);
	const [stations, setStations] = useState<IStation[]>([]);
	const [selectedStation, setSelectedStation] = useState<IStation>(defaultStation);

	const [gunsInfo, setGunsInfo] = useState<IDashboardLocationGun[]>([]);

	const [guns, setGuns] = useState<IGun[]>([]);
	const [gunsStatus, setGunsStatus] = useState<IGunStatus[]>([]);

	const { handleGetChargers, chargers } = useGetStationGuns({ setGuns });

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getChargeFills,
		value: getChargeFillsVal,
		status: getChargeFillsStatus,
	} = useBackendRequest<{}, IGunsStatsPag>({
		path: 'charge-fills/find',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: getStatusGuns,
		value: getStatusGunsVal,
		status: getStatusGunsStatus,
	} = useBackendRequest<any, IGunStatus[]>({
		path: 'charge-fills/getGunStatus',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (getStatusGunsStatus === 'success') {
			if (getStatusGunsVal?.data) {
				setGunsStatus(getStatusGunsVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-gun-information'),
						severity: 'error',
					})
				);
				dispatch(setLoading({ loading: false }));
			}
		} else if (getStatusGunsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
			dispatch(setLoading({ loading: false }));
		}
	}, [getStatusGunsStatus]);

	useEffect(() => {
		if (gunsStatus.length > 1) {
			const arr = [];
			for (let i = 0; i < gunsInfo.length; i++) {
				const gun = gunsInfo[i];
				for (let j = 0; j < gunsStatus.length; j++) {
					const element = gunsStatus[j];
					if (element.gun_id === gun.id) {
						gun.status = gunsStatus[j].status;
					}
				}

				arr.push(gun);
			}
			setGunsInfo(arr);
		}
	}, [gunsStatus]);

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				if (getStationsVal.data.length > 0) {
					const data = filterData(getStationsVal.data);
					setStations(data);
					getSelectValues(data);
				} else {
					dispatch(setLoading({ loading: false }));
					dispatch(
						setAlert({
							show: true,
							message: t('dashboard-3.no-stations'),
							severity: 'error',
						})
					);
				}
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('dashboard-3.error-stations'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-3.error-stations'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const filterData = (data: IStation[]): IStation[] => {
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			return data;
		} else {
			const filteredData = data.filter((d) => d.partnerInfoId === id);
			return filteredData;
		}
	};

	const getSelectValues = (data: IStation[]): void => {
		const arr = [];
		for (let i = 0; i < data.length; i++) {
			const label = data[i].name;
			const value = data[i].id!;
			const option = { value: value, label: label };
			arr.push(option);
		}
		setSelectData(arr);
		dispatch(setLoading({ loading: false }));
	};

	const getGunsInfo = (guns: IGun[]): void => {
		const gunsArr = [];
		for (let i = 0; i < guns.length; i++) {
			const element: IDashboardLocationGun = {
				id: guns[i].qionGunUniqueId.toString(),
				name: guns[i].name,
				kw: '0.00',
				status: false,
			};
			gunsArr.push(element);
		}
		setGunsInfo(gunsArr);
		getChargeFills();
	};

	useEffect(() => {
		if (getChargeFillsStatus === 'success') {
			if (getChargeFillsVal?.data) {
				filterTodayData(getChargeFillsVal.data.chargeFills);
				getStatusGuns({ gunsId: partnerGuns });
			}
			//dispatch(setLoading({ loading: false }));
		} else if (getChargeFillsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-3.error-guns'),
					severity: 'error',
				})
			);
		}
	}, [getChargeFillsStatus]);

	const filterTodayData = (data: IGunsStats[]): void => {
		const today = new Date().toISOString().split('T')[0];
		const filteredData = data.filter(
			(d) => d.datetime_charging.split('T')[0] === today
		);
		getDataPerGun(filteredData);
	};

	const getDataPerGun = (data: IGunsStats[]): void => {
		const arr = [];
		for (let i = 0; i < gunsInfo.length; i++) {
			const gun = gunsInfo[i];
			let total = 0.0;
			for (let j = 0; j < data.length; j++) {
				const element = data[j];
				if (element.gun_id === gun.id) {
					total += parseFloat(data[j].consumed_kw);
				}
			}
			const dataWithTotal: IDashboardLocationGun = {
				...gun,
				kw: total.toString() === 'NaN' ? '0.00' : total.toFixed(2),
			};
			arr.push(dataWithTotal);
		}
		setGunsInfo(arr);
		dispatch(setLoading({ loading: false }));
	};

	const handleSetData = (event: any) => {
		const { value } = event.target;
		const station = stations.filter((s) => s.id === value)[0];
		setSelectedStation(station);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	useEffect(() => {
		if (viewStationdata) {
			dispatch(setLoading({ loading: true }));
			handleGetChargers(selectedStation.id!);
		}
	}, [viewStationdata]);

	useEffect(() => {
		if (viewStationdata) {
			if (guns.length > 0) {
				getGunsInfo(guns);
			} else {
				dispatch(setLoading({ loading: false }));
			}
		}
	}, [guns]);

	const handleBack = (): void => {
		setViewStationdata(false);
		setSelectedStation(defaultStation);
		setGunsInfo([]);
	};

	return {
		selectData,
		selectedStation,
		viewStationdata,
		gunsInfo,
		chargers,
		setViewStationdata,
		handleSetData,
		handleBack,
	};
}
