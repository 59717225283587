/* eslint-disable prettier/prettier */
import { BorderColor } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { ROLES } from '../../../constants/user-roles.constants';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';
import { IUsers } from '../../users/interfaces/users.interface';

export function useUserPermissionAdapter() {
	const [t] = useTranslation('global');
	const { getPermissionColor, getPermissionName } = useUserPermisions();

	const userPermissionAdapter = (
		data: IUsers[],
		handleEditPermissions: (id: string) => void,
		permission: () => boolean,
		role: string
	): any[] => {
		const newData: any[] = data
			? data.map((item: IUsers) => {
					return {
						...item,
						name: item.name || t('users.no-name'),
						permissions: (
							<>
								{item.userPermissions &&
								item.userPermissions.length > 0 ? (
									<div style={{ display: 'flex', gap: '10px' }}>
										{item.userPermissions.map((p) => (
											<div
												key={p.permissionId}
												style={{
													backgroundColor: hexToRGBA(
														getPermissionColor(
															p.permissionId
														),
														0.2
													),
													borderRadius: '4px',
													padding: '10px',
													color: getPermissionColor(
														p.permissionId
													),
													fontWeight: 'bold',
												}}
											>
												{getPermissionName(p.permissionId)}
											</div>
										))}
									</div>
								) : (
									<>{t('users.no-permissions')}</>
								)}
							</>
						),
						actions:
							permission() || role === ROLES.SUPER_ADMIN ? (
								<div style={{ display: 'flex', gap: '10px' }}>
									<ButtonIconSmall
										onPress={() => handleEditPermissions(item.id)}
										type="success"
										icon={<BorderColor />}
										hoverLabel={t('common.edit')}
									/>
								</div>
							) : (
								<span>{t('common.no-actions')}</span>
							),
					};
			  })
			: [];
		return newData;
	};

	return { userPermissionAdapter };
}
