import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';
import { ROLES } from '../../../constants/user-roles.constants';

export function useMobileUserColumns() {
	const [t] = useTranslation('global');
	const { role } = useSelector((state: RootState) => state.user);
	const mobileUserColumns: IColumns[] =
		role.roleName === ROLES.SUPER_ADMIN
			? [
					{
						text: t('users.user'),
						nameID: 'name',
					},
					{
						text: t('common.email'),
						nameID: 'email',
					},
					{
						text: t('mobile-users.total-kw'),
						nameID: 'kwExpended',
					},
					{
						text: t('mobile-users.total-amount'),
						nameID: 'moneyExpended',
					},
					{
						text: t('mobile-users.remaining-kw'),
						nameID: 'gifted_kw',
					},
					{
						text: t('mobile-users.remaining-money'),
						nameID: 'gifted_money',
					},
					{
						text: t('common.status'),
						nameID: 'online',
						align: 'center',
					},
			  ]
			: [
					{
						text: t('users.user'),
						nameID: 'name',
					},
					{
						text: t('common.email'),
						nameID: 'email',
					},
					{
						text: t('mobile-users.total-kw'),
						nameID: 'kwExpended',
					},
					{
						text: t('mobile-users.total-amount'),
						nameID: 'moneyExpended',
					},
					{
						text: t('mobile-users.remaining-kw'),
						nameID: 'gifted_kw',
					},
					{
						text: t('mobile-users.remaining-money'),
						nameID: 'gifted_money',
					},
					{
						text: t('common.status'),
						nameID: 'online',
						align: 'center',
					},
					{
						text: t('common.actions'),
						nameID: 'actions',
					},
			  ];

	return { mobileUserColumns };
}
