import { useEffect, useState } from 'react';
import { useTableCustom } from '../../../../../hooks/useTableCustom';
import { useGroupsTabAdapter } from '../adapters/groups-tab.adapter';
import { useMonthSelector } from '../../../../../hooks/useMonthSelector';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { IGroupsRevenueResponse } from '../interfaces/shared-report.interface';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { RootState } from '../../../../../store';
import { setAlert } from '../../../../../store/modules/snackbar';
import { ROLES } from '../../../../../constants/user-roles.constants';
import {
	IReportDownloadParamsShort,
	IReportDownloadResponse,
} from '../../../../../interfaces/reports.interface';
import { generatePDF } from '../../../../../utils/generatePDF';
import { generateCSV } from '../../../../../utils/generateCSV';
import dayjs from 'dayjs';

export function useGroupsTab() {
	const dispatch = useDispatch();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { role } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);

	const [dateFilter, setDateFilter] = useState<{
		startDate: string;
		endDate: string;
	}>({ startDate: '', endDate: '' });

	const { groupsTabAdapter } = useGroupsTabAdapter();
	const { data, setData, pagination, rowsPerPage } = useTableCustom<any>();

	const { actualMonth, getDateStartFormated, getDateEndFormated } = useMonthSelector();

	const {
		execute: getGroupsRevenue,
		value: groupsRevenueVal,
		status: groupsRevenueStatus,
	} = useBackendRequest<
		{
			partnerId?: string;
			startDate: string;
			endDate: string;
		},
		IGroupsRevenueResponse
	>({
		path: 'partners-group/groupsByPartner/revenue',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: downloadReport,
		value: downloadReportVal,
		status: downloadReportStatus,
	} = useBackendRequest<IReportDownloadParamsShort, IReportDownloadResponse>({
		path: 'partners-group/getGroupsReports',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (groupsRevenueStatus === 'success') {
			if (groupsRevenueVal?.data) {
				setData({
					...data,
					countRows: groupsRevenueVal.data.totalElements,
					rows: groupsTabAdapter(groupsRevenueVal.data.revenues),
				});
				dispatch(setLoading({ loading: false }));
			}
		} else if (groupsRevenueStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [groupsRevenueStatus]);

	const handleDownloadPdf = (): void => {
		dispatch(setLoading({ loading: true }));
		downloadReport({
			document: 'pdf',
			startDate:
				dateFilter.startDate !== ''
					? dateFilter.startDate
					: getDateStartFormated(),
			endDate:
				dateFilter.endDate !== '' ? dateFilter.endDate : getDateEndFormated(),
			partnerId:
				role.roleName === ROLES.SUPER_ADMIN && !simulating ? undefined : id,
		});
	};

	const handleDownloadCsv = (): void => {
		dispatch(setLoading({ loading: true }));
		downloadReport({
			document: 'csv',
			startDate:
				dateFilter.startDate !== ''
					? dateFilter.startDate
					: getDateStartFormated(),
			endDate:
				dateFilter.endDate !== '' ? dateFilter.endDate : getDateEndFormated(),
			partnerId:
				role.roleName === ROLES.SUPER_ADMIN && !simulating ? undefined : id,
		});
	};

	useEffect(() => {
		if (downloadReportStatus === 'success') {
			if (downloadReportVal?.data) {
				if (downloadReportVal.data.message.includes('pdf')) {
					generatePDF(downloadReportVal.data.base64, 'pdf', 'Report', false);
				} else {
					generateCSV(downloadReportVal.data.base64, 'csv', 'Report');
				}
				dispatch(setLoading({ loading: false }));
			}
		} else if (downloadReportStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error.',
					severity: 'error',
				})
			);
		}
	}, [downloadReportStatus]);

	useEffect(() => {
		searchInRange();
	}, [rowsPerPage, pagination, actualMonth]);

	const handleDateInputs = (event: any): void => {
		const { name, value } = event.target;
		const date = dayjs(value.$d).toISOString().split('T')[0];
		if (name === 'startDate') {
			if (dateFilter.endDate === date) {
				setDateFilter({
					startDate: date,
					endDate: dateFilter.endDate,
				});
				return;
			}
			setDateFilter({ ...dateFilter, startDate: date });
		} else {
			if (dateFilter.startDate === date) {
				setDateFilter({
					startDate: dateFilter.startDate,
					endDate: date,
				});
				return;
			}
			setDateFilter({ ...dateFilter, endDate: date });
		}
	};

	const searchInRange = (): void => {
		dispatch(setLoading({ loading: true }));
		getGroupsRevenue(
			{
				startDate:
					dateFilter.startDate !== ''
						? dateFilter.startDate
						: getDateStartFormated(),
				endDate:
					dateFilter.endDate !== '' ? dateFilter.endDate : getDateEndFormated(),
				partnerId:
					role.roleName === ROLES.SUPER_ADMIN && !simulating ? undefined : id,
			},
			`?page=${pagination}&pageSize=${rowsPerPage}`
		);
	};

	return {
		data,
		dateFilter,
		setData,
		handleDownloadPdf,
		handleDownloadCsv,
		handleDateInputs,
		searchInRange,
	};
}
