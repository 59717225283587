import styles from './styles/radio-button-styles.component.module.css';

interface IRadioButton {
	name: string;
	id: string;
	value?: string;
	label: string;
	checked: boolean;
	defaultCheked?: boolean;
	onClick?: (e: any) => void;
	disabled?: boolean;
	showLabel?: boolean;
}

export function RadioButton({
	name,
	id,
	value,
	label,
	checked,
	onClick,
	disabled = false,
	showLabel = true,
}: IRadioButton) {
	return (
		<label
			className={styles.container}
			style={{ cursor: disabled ? 'default' : 'pointer' }}
		>
			{showLabel === true ? (
				<span style={{ color: disabled ? 'lightgrey' : '' }}>{label}</span>
			) : null}
			<input
				type="radio"
				name={name}
				id={id}
				value={value}
				onChange={onClick}
				checked={checked}
				data-testid={`radio-${id}`}
				disabled={disabled}
			/>
			<span className={styles.checkmark}></span>
		</label>
	);
}
