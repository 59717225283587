import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import styles from './styles/edit-permissions.module.css';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/modules/snackbar';
import CustomButton from '../../components/button.component';
import { Add, ArrowBack, Delete } from '@mui/icons-material';
import { setLoading } from '../../store/modules/loading';
import { RootState } from '../../store';
import { hexToRGBA } from '../../utils/hex-to-rgba';
import { IEditPermissions } from './interfaces/edit-permission.interface';
import ButtonIconSmall from '../../components/button-icon-small.component';
import { useUserPermisions } from '../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';

const EditUserPermissions = () => {
	const [t] = useTranslation('global');
	const permissions = useSelector((state: RootState) => state.permissions);
	const colors = useSelector((state: RootState) => state.colors);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { getPermissionColor, getPermissionName } = useUserPermisions();

	//La que se muestra
	const [userInfo, setUserInfo] = useState<any>({
		name: '',
		email: '',
		userPermissions: [
			{
				permissionId: '',
			},
		],
	});

	//La que se envia al backend
	const [userInformation, setuserInformation] = useState<IEditPermissions>({
		userId: '',
		permissions: [],
	});

	const {
		execute: getUsers,
		value: getUserVal,
		status: getUserStatus,
	} = useBackendRequest<any, any>({
		path: 'users/findInfo/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const { execute: updatePermissions, status: updatePermissionstatus } =
		useBackendRequest<IEditPermissions, any>({
			path: 'users/saveOrUpdatePermissions',
			baseURL: process.env.REACT_APP_API,
			method: 'PUT',
		});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getUserStatus === 'success') {
			if (getUserVal?.data) {
				setUserInfo(getUserVal.data);
				const id = getUserVal.data.id;
				const permissions = getUserVal.data.userPermissions.map((p: any) => {
					return p.permissionId;
				});
				const user: IEditPermissions = { userId: id, permissions: permissions };
				setuserInformation(user);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('users.permissions-error'),
						severity: 'error',
					})
				);
			}
		} else if (getUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.permissions-error'),
					severity: 'error',
				})
			);
		}
	}, [getUserStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (updatePermissionstatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.permissions-update'),
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (updatePermissionstatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.permissions-update-error'),
					severity: 'error',
				})
			);
		}
	}, [updatePermissionstatus]);

	const handleAddPermission = (id: string) => {
		if (userInformation.permissions.includes(id)) {
			dispatch(
				setAlert({
					show: true,
					message: t('users.same-permission'),
					severity: 'error',
				})
			);
			return;
		}
		const newPermissions: string[] = userInformation.permissions.concat(id);
		setuserInformation({ ...userInformation, permissions: newPermissions });
	};

	const handleRemovePermission = (id: string) => {
		const newPermissions = userInformation.permissions.filter((p) => p !== id);
		setuserInformation({ ...userInformation, permissions: newPermissions });
	};

	const handleUpdatePermissions = () => {
		dispatch(setLoading({ loading: true }));
		updatePermissions(userInformation);
	};

	useEffect(() => {
		//console.log(id);
		dispatch(setLoading({ loading: true }));
		getUsers({}, id);
	}, []);

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant={'solid'}
				onClick={() => navigate(-1)}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: '10px',
					backgroundColor: 'var(--color-black-main)',
				}}
				icon={ArrowBack}
			/>
			<div className={styles.container}>
				<h3>{t('users.edit-user-permissions')}</h3>
				<div>
					<h4>
						{t('users.user')}: {userInfo.name}
					</h4>
					<h4>
						{t('users.user-email')}: {userInfo.email}
					</h4>
				</div>
				<div className={styles.permissionsContainer}>
					<div className={styles.columnPermission}>
						<h4>{t('users.current')}</h4>
						{userInformation.permissions.map((p: any) => (
							<div
								key={p}
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'center',
								}}
							>
								<div
									style={{
										backgroundColor: hexToRGBA(
											getPermissionColor(p),
											0.2
										),
										borderRadius: '4px',
										padding: '10px',
										color: getPermissionColor(p),
										fontWeight: 'bold',
										width: '200px',
									}}
								>
									{getPermissionName(p)}
								</div>
								<ButtonIconSmall
									onPress={() => handleRemovePermission(p)}
									type="error"
									icon={<Delete />}
									hoverLabel={t('users.remove')}
								/>
							</div>
						))}
					</div>
					<div className={styles.columnPermission}>
						<h4>{t('users.all')}</h4>
						{permissions.map((p: any) => (
							<div
								key={p}
								style={{
									display: 'flex',
									gap: '10px',
									alignItems: 'center',
								}}
							>
								<div
									key={p.id}
									style={{
										backgroundColor: hexToRGBA(
											getPermissionColor(p.id),
											0.2
										),
										borderRadius: '4px',
										padding: '10px',
										color: getPermissionColor(p.id),
										fontWeight: 'bold',
										width: '200px',
									}}
								>
									{getPermissionName(p.id)}
								</div>
								{!userInformation.permissions.includes(p.id) && (
									<ButtonIconSmall
										onPress={() => handleAddPermission(p.id)}
										type="success"
										icon={<Add />}
										hoverLabel={t('users.add')}
									/>
								)}
							</div>
						))}
					</div>
				</div>
				<hr />
				<CustomButton
					label={t('common.save')}
					variant={'solid'}
					onClick={handleUpdatePermissions}
					styles={{
						width: '150px',
						height: '33px',
						gap: '11px',
						backgroundColor: colors.accent,
						color: 'black',
						justifySelf: 'flex-end',
						alignSelf: 'flex-end',
					}}
				/>
			</div>
		</>
	);
};

export default EditUserPermissions;
