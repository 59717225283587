import { useEffect, useState } from 'react';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useSchedulesTabAdapter } from '../adapters/schedules.adapter';
import { Card, Paper } from '@mui/material';
import TableCustom from '../../../components/table-custom.component';
import { Add } from '@mui/icons-material';
import CustomButton from '../../../components/button.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useLocation, useNavigate } from 'react-router-dom';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { IAssociatesGroup, ISchedule } from '../../../interfaces/partner';
import CustomModal from '../../../components/modal.component';
import { useSchedulesTabColums } from '../utils/schedules.colums';
import { useTranslation } from 'react-i18next';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import { ROLES } from '../../../constants/user-roles.constants';
import { hexToRGBA } from '../../../utils/hex-to-rgba';

const SchedulesTab = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { data, setData } = useTableCustom<any>();

	const { getSchedulesPermission } = useUserPermisions();

	const location = useLocation();

	const [associateGroupData, setAssociateGroupData] = useState<IAssociatesGroup[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedSchedule, setSelectedSchedule] = useState<ISchedule>({
		id: '',
		partnerId: '',
		name: '',
		weight: 0,
		status: true,
		limitedDays: true,
		startDate: '',
		endDate: '',
		days: [''],
		allDay: false,
		startTime: '',
		endTime: '',
		associateGroupId: '',
		guns: [],
	});

	const { schedulesTabColumns } = useSchedulesTabColums();
	const { schedulesTabAdapter } = useSchedulesTabAdapter();

	const {
		execute: getAssociatesGroups,
		value: associatesGVal,
		status: associatesGStatus,
	} = useBackendRequest<unknown, IAssociatesGroup[]>({
		path: `partners-group/partner/${id}`,
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesGStatus === 'success') {
			if (associatesGVal?.data) {
				setAssociateGroupData(associatesGVal.data);
				getSchedules();
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('add-schedule.error-groups'),
						severity: 'error',
					})
				);
			}
		} else if (associatesGStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.error-groups'),
					severity: 'error',
				})
			);
		}
	}, [associatesGStatus]);

	const {
		execute: getSchedules,
		value: schedulesVal,
		status: schedulesStatus,
	} = useBackendRequest<any, ISchedule[]>({
		path: `schedules/partner/${id}`,
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const { execute: deleteSchedule, status: deleteScheduleStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'schedules/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	const handleDeleteSchedule = () => {
		dispatch(setLoading({ loading: true }));
		deleteSchedule({}, selectedSchedule.id);
	};

	const handleEditSchedule = (id: string) => {
		navigate(`/schedules/edit-schedule/${id}`, {
			state: { prevUrl: location.pathname },
		});
	};

	useEffect(() => {
		if (deleteScheduleStatus === 'success') {
			setOpenModal(false);
			dispatch(
				setAlert({
					show: true,
					message: t('schedules.deleted'),
					severity: 'success',
				})
			);
			getSchedules();
		} else if (deleteScheduleStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			setOpenModal(false);
			dispatch(
				setAlert({
					show: true,
					message: t('schedules.deleted-error'),
					severity: 'error',
				})
			);
		}
	}, [deleteScheduleStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (schedulesStatus === 'success') {
			if (schedulesVal?.data) {
				//getActualSchedule(schedulesVal.data);
				setData({
					...data,
					countRows: 0,
					rows: schedulesTabAdapter(
						schedulesVal.data,
						handleOpenModal,
						handleEditSchedule,
						associateGroupData
					),
				});
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('schedules.error'),
						severity: 'error',
					})
				);
			}
		} else if (schedulesStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('schedules.error'),
					severity: 'error',
				})
			);
		}
	}, [schedulesStatus]);

	/* const days = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

	const hasDay = (day: string, arr: string[]): boolean => {
		return arr.includes(day);
	};

	const getActualSchedule = (schedules: ISchedule[]): void => {
		const hour = new Date().toISOString().split('T')[1].split('Z')[0];
		const date = new Date().toISOString().split('T')[0];
		const day = new Date().getDay();

		let selectedSchedule: ISchedule = {
			partnerId: '',
			name: '',
			weight: '',
			status: false,
			limitedDays: false,
			days: [],
			allDay: false,
			associateGroupId: '',
			guns: [],
		};

		for (let i = 0; i < schedules.length; i++) {
			//Tomar todos los horarios que apliquen el dia de hoy
			if (hasDay(days[day], schedules[i].days)) {
				//Verificar si el horario tiene dias limitados
				if (schedules[i].limitedDays) {
					const startDate = schedules[i].startDate;
					const endDate = schedules[i].endDate;
					//Si sí tiene dias limitados, se verifica que las fechas de inicio y de fin
					//entren en la fecha actual
					if (
						startDate !== null &&
						startDate !== undefined &&
						startDate < date &&
						endDate !== null &&
						endDate !== undefined &&
						endDate > date
					) {
						//Sí entran en la fecha actual, se verifica si el horario tiene horas limitadas
						if (schedules[i].allDay) {
							//Sí el horario es de todo el día, se verifica el peso
							if (schedules[i].weight > selectedSchedule.weight) {
								selectedSchedule = schedules[i];
							}
						} else {
							//Sí el horario no es de todo el día, se verifica que la hora de inicio y hora de fin
							//entren en la hora actual
							const startTime = schedules[i].startTime;
							const endTime = schedules[i].endTime;
							if (
								startTime !== null &&
								startTime !== undefined &&
								startTime < hour &&
								endTime !== null &&
								endTime !== undefined &&
								endTime > hour
							) {
								//Si sí entra, se verifica el peso
								if (schedules[i].weight > selectedSchedule.weight) {
									selectedSchedule = schedules[i];
								}
							}
						}
					}
				} else {
					//Misma logica que arriba
					if (schedules[i].allDay) {
						if (schedules[i].weight > selectedSchedule.weight) {
							selectedSchedule = schedules[i];
						}
					} else {
						const startTime = schedules[i].startTime;
						const endTime = schedules[i].endTime;
						if (
							startTime !== null &&
							startTime !== undefined &&
							startTime < hour &&
							endTime !== null &&
							endTime !== undefined &&
							endTime > hour
						) {
							if (schedules[i].weight > selectedSchedule.weight) {
								selectedSchedule = schedules[i];
							}
						}
					}
				}
			}
		}
		console.log(selectedSchedule);
	}; */

	const handleOpenModal = (schedule: ISchedule) => {
		setSelectedSchedule(schedule);
		setOpenModal(true);
	};

	useEffect(() => {
		if (role.roleName === ROLES.ADMIN || simulating) {
			dispatch(setLoading({ loading: true }));
			getAssociatesGroups();
		}
	}, []);

	return (
		<>
			{role.roleName === ROLES.ADMIN || simulating ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
					{getSchedulesPermission() && (
						<CustomButton
							icon={Add}
							styles={{
								display: 'flex',
								justifyContent: 'space-around',
								width: '140px',
								justifySelf: 'flex-end',
								alignSelf: 'flex-end',
								color: 'black',
								backgroundColor: colors.accent,
								marginBottom: '30px',
							}}
							variant="solid"
							label={t('schedules.new-period')}
							onClick={() =>
								navigate('/partners/add-schedules', {
									state: { prevUrl: location.pathname },
								})
							}
						/>
					)}
					<Paper elevation={1} sx={{ padding: 1 }}>
						<TableCustom
							columns={schedulesTabColumns}
							data={data}
							setData={setData}
							showTotalCount={false}
							hideChips
							hideHeader
						/>
					</Paper>
				</div>
			) : (
				<Card
					sx={{
						padding: 5,
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						backgroundColor: hexToRGBA(colors.secondary, 0.2),
						border: `1px solid ${colors.secondary}`,
					}}
				>
					<h1 style={{ justifySelf: 'center', color: colors.secondary }}>
						{t('themes.superadmin-msg')}
					</h1>
				</Card>
			)}
			<CustomModal
				open={openModal}
				maxWidth="md"
				fullWidth
				title={t('schedules.delete')}
			>
				<div>
					<span>
						{t('common.delete-txt')} <b>{selectedSchedule.name}</b>{' '}
						{t('common.permanently')}?
					</span>
					<div
						style={{
							display: 'flex',
							gap: 20,
							justifyContent: 'center',
							marginTop: 25,
						}}
					>
						<CustomButton
							variant="solid"
							label={t('common.cancel')}
							styles={{
								backgroundColor: 'black',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={() => setOpenModal(false)}
						/>
						<CustomButton
							variant="solid"
							label={t('common.delete')}
							styles={{
								backgroundColor: '#DD0528',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={handleDeleteSchedule}
						/>
					</div>
				</div>
			</CustomModal>
		</>
	);
};

export default SchedulesTab;
