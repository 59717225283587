import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import styles from './styles/switch.component.module.css';
import { OverridableStringUnion } from '@mui/types';
import { CustomTooltip } from './button-icon-small.component';

const CustomSwitch = styled((props: SwitchProps) => (
	<Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, ...props }) => ({
	width: 53,
	height: 24,
	padding: 0,
	'& .MuiSwitch-switchBase': {
		padding: 0,
		margin: 2,
		transitionDuration: '300ms',
		'&.Mui-checked': {
			transform: 'translateX(28px)',
			color: '#FFFFFF',
			'& + .MuiSwitch-track': {
				backgroundColor: props.color ? props.color : '#2BA8E0',
				opacity: 1,
			},
			'&.Mui-disabled + .MuiSwitch-track': {
				opacity: 0.5,
			},
		},
		'&.Mui-focusVisible .MuiSwitch-thumb': {
			color: '#155BFC',
			border: '6px solid #fff',
		},
		'&.Mui-disabled .MuiSwitch-thumb': {
			color:
				theme.palette.mode === 'light'
					? theme.palette.grey[100]
					: theme.palette.grey[600],
		},
		'&.Mui-disabled + .MuiSwitch-track': {
			opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
		},
	},
	'& .MuiSwitch-thumb': {
		boxSizing: 'border-box',
		width: 20,
		height: 20,
	},
	'& .MuiSwitch-track': {
		borderRadius: 24 / 2,
		backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
		opacity: 1,
		transition: theme.transitions.create(['background-color'], {
			duration: 500,
		}),
	},
}));

interface IProps {
	label?: string;
	name?: string;
	value?: boolean | any;
	onChange?: (arg: React.ChangeEvent<HTMLInputElement>) => void;
	fullWidth?: boolean;
	disabled?: boolean;
	color?: OverridableStringUnion<
		'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning' | 'default'
	>;
	tooltipLabel?: string;
}

const SwitchCustom = ({
	name,
	value,
	onChange,
	label,
	fullWidth,
	disabled = false,
	color = 'secondary',
	tooltipLabel,
}: IProps) => {
	return (
		<CustomTooltip placement="top" title={tooltipLabel}>
			<Box
				display={fullWidth ? 'flex' : 'inline-flex'}
				justifyContent={fullWidth ? 'space-between' : 'normal'}
				alignItems={'center'}
			>
				{label && (
					<span
						className={styles.labelCustom}
						data-active={value ? 'true' : 'false'}
					>
						{label}
					</span>
				)}
				<CustomSwitch
					name={name}
					checked={value}
					onChange={onChange}
					disabled={disabled}
					color={color}
				/>
			</Box>
		</CustomTooltip>
	);
};

export default SwitchCustom;
