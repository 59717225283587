import { createSlice } from '@reduxjs/toolkit';

export interface Permission {
	id: string;
	codeName: string;
	displayName: string;
}

const defaultPermissions: Permission[] = [];

export const permissionsSlice = createSlice({
	name: 'utils',
	initialState: defaultPermissions,
	reducers: {
		setPermissions: (state, action) => action.payload,
	},
});

export const { setPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
