import { useTranslation } from 'react-i18next';
import CustomModal from '../../../components/modal.component';
import CustomButton from '../../../components/button.component';
import { IUsers } from '../interfaces/users.interface';

import styles from './modal.module.css';

interface IDeleteModal {
	openModal: boolean;
	selectedUser: IUsers;
	handleCloseModal: () => void;
	handleDeleteUser: () => void;
}

const DeleteModal = ({
	openModal,
	selectedUser,
	handleCloseModal,
	handleDeleteUser,
}: IDeleteModal) => {
	const [t] = useTranslation('global');
	return (
		<CustomModal
			open={openModal}
			maxWidth="md"
			fullWidth
			title={t('users.delete-user').toString()}
		>
			<div className={styles.modalContainer}>
				<span>
					{t('users.delete-message-1')}{' '}
					<strong>{t('users.delete-message-2')}</strong>{' '}
					{t('users.delete-message-3')}
				</span>
				<span>
					<b>{t('common.name')}:</b> {selectedUser.name}
				</span>
				<span>
					<b>{t('common.email')}:</b> {selectedUser.email}
				</span>
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={handleCloseModal}
						styles={{
							backgroundColor: 'var(--color-black-main)',
							width: '124px',
							gap: '8px',
							justifySelf: 'flex-end',
							alignSelf: 'flex-end',
						}}
					/>
					<CustomButton
						label={t('common.delete')}
						variant={'solid'}
						onClick={handleDeleteUser}
						styles={{
							backgroundColor: '#DD0528',
							width: '124px',
							gap: '8px',
							justifySelf: 'flex-end',
							alignSelf: 'flex-end',
						}}
					/>
				</div>
			</div>
		</CustomModal>
	);
};

export default DeleteModal;
