import React from 'react';
import CustomModal from '../../modal.component';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../button.component';

interface ILogoutModalProps {
	openLogoutModal: boolean;
	setOpenLogoutModal: React.Dispatch<React.SetStateAction<boolean>>;
	handleLogout: () => void;
}

const LogoutModal = ({
	openLogoutModal,
	setOpenLogoutModal,
	handleLogout,
}: ILogoutModalProps) => {
	const [t] = useTranslation('global');
	return (
		<CustomModal
			open={openLogoutModal}
			maxWidth="md"
			fullWidth
			title={t('logout.title')}
		>
			<div>
				<span>{t('logout.desc')}</span>
				<div
					style={{
						display: 'flex',
						gap: 20,
						justifyContent: 'center',
						marginTop: 25,
					}}
				>
					<CustomButton
						variant="solid"
						label={t('common.cancel')}
						styles={{
							backgroundColor: 'black',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={() => setOpenLogoutModal(false)}
					/>
					<CustomButton
						variant="solid"
						label={t('logout.title')}
						styles={{
							backgroundColor: '#DD0528',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={handleLogout}
					/>
				</div>
			</div>
		</CustomModal>
	);
};

export default LogoutModal;
