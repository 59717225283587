// React dependencies
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Third-party libraries
import { PhonelinkLockOutlined } from '@mui/icons-material';
import GridViewIcon from '@mui/icons-material/GridView';
import {
	Avatar,
	CardContent,
	Grid,
	Paper,
	Card,
	Tab,
	Tabs,
	Typography,
	CardActions,
	CardHeader,
} from '@mui/material';

import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';

// Style dependencies
import styles from './styles/chargers.module.css';

// Resources
import TableCustom from '../../components/table-custom.component';
import { chargersColumns } from './utils/chargers.column';
import { useTableCustom } from '../../hooks/useTableCustom';
import CustomButton from '../../components/button.component';
import { chargersAdapter } from './adapters/chargers.adapter';
import { mockDataCharger } from './interfaces/chargers.interface';

export const Chargers = () => {
	const navigate = useNavigate();
	const [tabValue, setValueTab] = useState<number>(0);
	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();

	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: chargersAdapter(mockDataCharger),
		});
	}, []);

	return (
		<Grid container gap={1}>
			<Grid container item xs={12} md={11} display="flex">
				<Grid item xs={5} sm={4} md={3} lg={2} xl={1}>
					<CustomButton
						label="Back"
						onClick={() => navigate(-1)}
						variant="solid"
						styles={{ backgroundColor: 'black' }}
						icon={ArrowBackIcon}
					/>
				</Grid>
				{/* <Grid
					item
					xs={5}
					sm={4}
					md={3}
					lg={2}
					xl={1}
					flex={1}
					style={{ marginLeft: 'auto' }}
				>
					<CustomButton
						icon={SimCardDownloadIcon}
						label="Report"
						onClick={() => {}}
						variant="solid"
						styles={{ backgroundColor: '#2AA8E0' }}
					/>
				</Grid> */}
			</Grid>
			<Grid item xs={12} md={4} lg={3} xl={2} className={styles.cardCharger}>
				<Card>
					<Grid item xs={12} className={styles.boxAvatar}>
						<Avatar className={styles.avatarBackground} variant="square">
							<BoltOutlinedIcon />
						</Avatar>
					</Grid>
					<CardContent>
						<Typography
							variant="h4"
							fontWeight={'bold'}
							letterSpacing={2}
							textAlign="center"
						>
							Charger 01
						</Typography>
						<Grid container className={styles.marginContent}>
							<Grid
								item
								xs={4}
								display="flex"
								flexDirection="column"
								gap={0}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									textAlign="center"
								>
									5
								</Typography>
								<Typography
									variant="subtitle2"
									fontWeight="bold"
									textAlign="center"
									color="#9C9FB5"
								>
									Guns
								</Typography>
							</Grid>
						</Grid>
						<Typography variant="h6" color={'#B6B6C3'}></Typography>
					</CardContent>
					<CardActions disableSpacing>
						<Tabs
							orientation="vertical"
							value={tabValue}
							onChange={() => {}}
							style={{ width: '100%' }}
							variant={'fullWidth'}
						>
							<Tab label="Guns" style={{ textAlign: 'left' }} />
						</Tabs>
					</CardActions>
				</Card>
				<Card className={styles.infoCard}>
					<CardHeader
						avatar={<LocalGasStationOutlinedIcon />}
						className={styles.headerCard}
					/>
					<CardContent>
						<Typography
							variant="subtitle1"
							fontWeight={'bold'}
							color={'#B6B6C3'}
							fontSize={18}
						>
							assigned to
						</Typography>
						<Typography
							fontSize={18}
							variant="subtitle2"
							color="var(--color-blue-main)"
							fontWeight={'bold'}
						>
							Station 02
						</Typography>
					</CardContent>
					<CardActions disableSpacing sx={{ marginTop: '25px' }}>
						<Grid item xs={3}>
							<CustomButton
								label="View"
								variant="solid"
								onClick={() => {}}
								styles={{ backgroundColor: '#2AA8E0' }}
							/>
						</Grid>
					</CardActions>
				</Card>
			</Grid>
			<Grid item xs={12} md={7} lg={8} xl={9}>
				<Paper elevation={2} className={styles.boxPaper}>
					<TableCustom
						columns={chargersColumns}
						data={data}
						setData={setData}
						showPagination
						showLimitPerPage
						titleHeader={'Guns'}
						showTotalCount={false}
						hideChips
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
