export const isNumberValidate = (value: string): boolean => {
	const reg = /^[0-9]+$|^$/;
	if (!value.match(reg)) {
		return false;
	}
	return true;
};

export const isEmpty = (prop: any) => {
	return (
		prop === false ||
		prop === null ||
		prop === undefined ||
		(Object.prototype.hasOwnProperty.call(prop, 'length') && prop.length === 0) ||
		(prop.constructor === Object && Object.keys(prop).length === 0)
	);
};

export const emailValid = (value: string): { valid: boolean; msg: string } => {
	const reg =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (value === '') return { valid: false, msg: 'Escribe tu correo electrónico.' };
	if (!value.match(reg)) {
		return {
			valid: false,
			msg: 'Correo no válido.',
		};
	}
	return { valid: true, msg: 'Correo electrónico correcto' };
};

export const isPasswordValid = (value: string): boolean => {
	const reg = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).{8,}$/;
	const regSpecial = /[*@!#~{}"?¿¡$,._-]+/;
	if (value.match(reg) && value.match(regSpecial)) return true;
	else return false;
};

export const isLatitude = (value: string): boolean => {
	const regex = /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?)$/;
	return regex.test(value);
};

export const isLongitude = (value: string): boolean => {
	const regex = /^[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;
	return regex.test(value);
};

export const zipRegex = (value: string): boolean => {
	const regex = /^[a-zA-Z0-9\s-]{1,20}$/;
	return regex.test(value);
};
