import PeriodSelector from '../../../../components/perdiod-selector.component';
import TableCustom from '../../../../components/table-custom.component';
import { useTransactionsTabColums } from './utils/transactions-tab-colums.colums';
import { useTranslation } from 'react-i18next';
import { useTransactionsTab } from './hooks/useTransactionsTab';
import DownloadButtons from '../download-buttons';

import styles from '../../styles/styles.module.css';
import DateInput from '../../../../components/DateInput';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { Search } from '@mui/icons-material';
import { useGetStations } from './hooks/useGetStations';
import SelectInput from '../../../../components/select-input.component';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

const TransactionsTab = () => {
	const [t] = useTranslation('global');
	const { transactionsTabColumns } = useTransactionsTabColums();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const { stationsOptions } = useGetStations();

	const {
		data,
		dateFilter,
		station,
		setData,
		handleDownloadCsv,
		handleDownloadPdf,
		handleDateInputs,
		searchInRange,
		handleSelectChange,
	} = useTransactionsTab();

	return (
		<div style={{ width: '100%' }}>
			<div className={styles.headerContainer}>
				<Grid
					container
					display={'flex'}
					spacing={2}
					alignItems={'flex-end'}
					width={matches ? '100%' : '75%'}
				>
					<Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
						<SelectInput
							name="station"
							id="station"
							label={t('financial.select-station').toString()}
							placeholder={t('common.required').toString()}
							required
							options={stationsOptions}
							onChange={handleSelectChange}
							value={station}
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
						<DateInput
							label={t('dashboard-1.start')}
							name="startDate"
							value={dateFilter.datetime_charging_start}
							onChange={handleDateInputs}
						/>
					</Grid>
					<Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
						<DateInput
							label={t('dashboard-1.end')}
							name="endDate"
							value={dateFilter.datetime_charging_end}
							onChange={handleDateInputs}
						/>
					</Grid>
					<Grid item xl={1} lg={1} md={1} sm={12} xs={12}>
						<ButtonIconSmall
							onPress={searchInRange}
							type="primary"
							icon={<Search />}
						/>
					</Grid>
				</Grid>

				<DownloadButtons
					onDownloadCsv={handleDownloadCsv}
					onDownloadPdf={handleDownloadPdf}
				/>
			</div>
			<TableCustom
				columns={transactionsTabColumns}
				data={data}
				setData={setData}
				hideHeader
				showTotalCount={false}
				hideChips
				showLimitPerPage
				showPagination
			/>
		</div>
	);
};

export default TransactionsTab;
