import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { IStation } from '../../../../../interfaces/stations.interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useEffect, useState } from 'react';
import { IOptions } from '../../../../../components/select-input.component';
import { setLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { ROLES } from '../../../../../constants/user-roles.constants';

export function useGetStations() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { role } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const [stationsOptions, setStationsOptions] = useState<IOptions[]>([]);

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				const data = filterData(getStationsVal.data);
				getStationsSelect(data);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const filterData = (stations: IStation[]): IStation[] => {
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			return stations;
		} else {
			return stations.filter((s) => s.partnerInfoId === id);
		}
	};

	const getStationsSelect = (stations: IStation[]): void => {
		const optionsSelect: IOptions[] = stations.map((s) => {
			return {
				label: s.name,
				value: s.name,
			};
		});
		const stationsOptions: IOptions[] = [
			{ label: t('common.all'), value: 'all' },
			...optionsSelect,
		];
		setStationsOptions(stationsOptions);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return { stationsOptions };
}
