export interface IGuns {
	gun: string | JSX.Element;
	type: string;
	charger: string | number;
	station: string | number;
	location: string | number;
	actions?: JSX.Element;
}
export const mockDataGuns: IGuns[] = [
	{
		gun: 'Gun 01',
		type: 'A',
		charger: '20',
		station: 'Station 01',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
	},
	{
		gun: 'Gun 02',
		type: 'A',
		charger: '20',
		station: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
	},
	{
		gun: 'Gun 02',
		type: 'A',
		charger: '20',
		station: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
	},
	{
		gun: 'Gun 04',
		type: 'A',
		charger: '20',
		station: 'Station 04',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
	},
	{
		gun: 'Gun 05',
		type: 'A',
		charger: '20',
		station: 'Station 05',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
	},
];
