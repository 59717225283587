import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useApiRequest } from '../../../../../../hooks/useApiRequest';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { IGun, defaultGun } from '../../../../../../interfaces/gun.interface';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { ICharger } from '../../../../../../interfaces/charger.interface';
import { RootState } from '../../../../../../store';
import { ILogs } from '../../../../../../interfaces/logs.interface';

export function useGunsTab() {
	const urlStopGun = 'cms-gun/stopCharging';
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const [gunsData, setGunsData] = useState<IGun[]>([]);
	const dispatch = useDispatch();

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedGun, setSelectedGun] = useState<IGun>(defaultGun);

	const [chargers, setChargers] = useState<ICharger[]>([]);

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<{}, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const { execute: stopGun, status: stopGunStatus } = useApiRequest<
		{ gunUniqueCode: string },
		unknown
	>({
		path: urlStopGun,
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'GET',
	});

	const { execute: saveLog } = useBackendRequest<ILogs, any>({
		path: 'apps-logs/save',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const filteredGuns: IGun[] = getGunsVal.data.filter(
					(g) => g.partnerInfoId === id
				);
				setGunsData(filteredGuns);
			} else {
				setGunsData([]);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				setChargers(getChargersVal?.data);
				getGuns();
			} else {
				setChargers([]);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const getChargerName = (id: string): string => {
		const charger = chargers.filter((c) => c.id === id)[0];
		let name = '';
		if (charger !== undefined) name = charger.name;
		return name;
	};

	useEffect(() => {
		if (stopGunStatus === 'success') {
			setOpenModal(false);
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.stoped'),
					severity: 'success',
				})
			);
			saveLog({
				message: `Gun with ID ${selectedGun.qionGunUniqueId} stopped.`,
				url: urlStopGun,
				className: 'CMS',
			});
		} else if (stopGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-stoped'),
					severity: 'error',
				})
			);
			saveLog({
				message: `Gun with ID ${selectedGun.qionGunUniqueId} can not be stopped.`,
				exception: 'The process to stop the gun could not be executed.',
				url: urlStopGun,
				className: 'CMS',
			});
		}
	}, [stopGunStatus]);

	const handleOpenModal = (gun: IGun): void => {
		setSelectedGun(gun);
		setOpenModal(true);
	};

	const handleStopGun = () => {
		dispatch(setLoading({ loading: true }));
		stopGun({ gunUniqueCode: selectedGun.qionGunUniqueId });
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getChargers();
	}, []);

	return {
		gunsData,
		openModal,
		selectedGun,
		setOpenModal,
		handleOpenModal,
		handleStopGun,
		getChargerName,
	};
}
