import { createSlice } from '@reduxjs/toolkit';
import { IUser, defaultPermissions } from '../../interfaces/user';

export const defaultUser: IUser = {
	id: '',
	name: '',
	lastName: '',
	partnerId: 0,
	partnerGuns: [],
	role: {
		roleId: 0,
		roleName: '',
	},
	permissions: defaultPermissions,
};

export const userSlice = createSlice({
	name: 'user',
	initialState: defaultUser,
	reducers: {
		createUser: (state, action) => action.payload,
		editUser: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { createUser, editUser } = userSlice.actions;

export default userSlice.reducer;
