import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IAssociate,
	defaultAssociate,
} from '../../../../../../interfaces/associate.interface';
import { setLoading } from '../../../../../../store/modules/loading';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../../../../constants/tab-values.constants';
import { emailValid } from '../../../../../../utils/validators';
import { useDeleteUser } from './useDeleteUser';
import { useEditAssociateUser } from './useEditAssociateUser';
import { IAssociateGroupResponse } from '../../../../interfaces/associates-group.interface';

export function useEditAssociate() {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [editAssociateData, setEditAssociateData] =
		useState<IAssociate>(defaultAssociate);

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [actualEmail, setActualEmail] = useState<string>('');
	const [actualStatus, setActualStatus] = useState<boolean>(false);

	//Modal de error al borrar
	const [openErrorModal, setOpenErrorModal] = useState<boolean>(false);
	const [groupsList, setGroupsList] = useState<string[]>([]);

	const { handleGetPartnerUsers } = useDeleteUser();
	const { handleEditUser } = useEditAssociateUser();

	const disabledButton = useMemo((): boolean => {
		return !(
			editAssociateData.name !== '' &&
			editAssociateData.alias !== '' &&
			editAssociateData.rfc !== '' &&
			editAssociateData.address !== '' &&
			editAssociateData.zip.length > 0 &&
			emailValid(editAssociateData.email) &&
			editAssociateData.password !== ''
		);
	}, [editAssociateData]);

	const {
		execute: getAssociate,
		value: associateVal,
		status: associateStatus,
	} = useBackendRequest<unknown, IAssociate>({
		path: 'associates/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associateStatus === 'success') {
			if (associateVal?.data) {
				setActualEmail(associateVal?.data.email);
				setActualStatus(associateVal?.data.status === 1);
				setEditAssociateData({
					...associateVal.data,
					status: associateVal.data.status === 1,
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('associates.error-info'),
						severity: 'error',
					})
				);
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
				});
			}
		} else if (associateStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.error-info'),
					severity: 'error',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
			});
		}
	}, [associateStatus]);

	const {
		execute: editAssociate,
		value: editAssociateVal,
		status: editAssociateStatus,
	} = useBackendRequest<IAssociate, IAssociate>({
		path: 'associates/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (editAssociateStatus === 'success') {
			if (editAssociateVal?.data) {
				if (
					editAssociateData.email !== actualEmail ||
					editAssociateData.status !== actualStatus
				) {
					handleEditUser(
						actualEmail,
						editAssociateVal.data.email,
						editAssociateVal.data.status !== 1
					);
				} else {
					dispatch(
						setAlert({
							show: true,
							message: t('associates.updated'),
							severity: 'success',
						})
					);
					navigate('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
					});
				}
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('associates.updated-error'),
						severity: 'error',
					})
				);
			}
		} else if (editAssociateStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('associates.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [editAssociateStatus]);

	const {
		execute: deleteAssociate,
		value: deleteAssociateVal,
		status: deleteAssociateStatus,
	} = useBackendRequest<unknown, any>({
		path: 'associates/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteAssociateStatus === 'success') {
			if (deleteAssociateVal?.data.statusCode === 400) {
				const groups: string[] = deleteAssociateVal?.data.partnersGroups.map(
					(g: IAssociateGroupResponse) => g.group
				);
				setGroupsList(groups);
				setOpenModal(false);
				setOpenErrorModal(true);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				handleGetPartnerUsers(editAssociateData.email);
				dispatch(
					setAlert({
						show: true,
						message: t('associates.deleted'),
						severity: 'success',
					})
				);
			}
		} else if (deleteAssociateStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('associates.deleted-error'),
					severity: 'error',
				})
			);
		}
	}, [deleteAssociateStatus]);

	const handleEditAssociate = () => {
		dispatch(setLoading({ loading: true }));
		delete editAssociateData.create_time;
		delete editAssociateData.update_time;
		delete editAssociateData.id;
		delete editAssociateData.is_deleted;
		editAssociate(editAssociateData, id);
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		setEditAssociateData({ ...editAssociateData, [name]: value });
	};

	const handleDelete = () => {
		dispatch(setLoading({ loading: true }));
		deleteAssociate({}, id);
	};

	const handleStatusChange = (): void => {
		setEditAssociateData({ ...editAssociateData, status: !editAssociateData.status });
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getAssociate({}, id);
	}, []);

	return {
		openModal,
		disabledButton,
		editAssociateData,
		openErrorModal,
		groupsList,
		handleSetData,
		setOpenModal,
		setOpenErrorModal,
		handleEditAssociate,
		handleDelete,
		handleStatusChange,
	};
}
