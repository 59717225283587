import { useTranslation } from 'react-i18next';

export function useCreateGunForm() {
	const [t] = useTranslation('global');

	const createGunForm = [
		{
			id: 'name',
			label: t('common.name'),
		},
		{
			id: 'latitude',
			label: t('common.latitude'),
			tooltip: t('common.lat-msg'),
		},
		{
			id: 'longitude',
			label: t('common.longitude'),
			tooltip: t('common.lng-msg'),
		},
		{
			id: 'address',
			label: t('common.address'),
		},
		{
			id: 'state',
			label: t('common.state'),
		},
		{
			id: 'country',
			label: t('common.country'),
		},
		{
			id: 'zip',
			label: t('common.zip'),
		},
	];

	return { createGunForm };
}
