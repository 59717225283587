import { IColumns } from '../../../hooks/useTableCustom';

export const associateColumns: IColumns[] = [
	{
		text: 'Group',
		nameID: 'group',
	},
	{
		text: 'Associates',
		nameID: 'associates',
	},
	{
		text: 'Stations',
		nameID: 'stations',
	},
	{
		text: 'Charger',
		nameID: 'charger',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
export const stationsColumns: IColumns[] = [
	{
		text: 'Name',
		nameID: 'name',
	},
	{
		text: 'Location',
		nameID: 'locations',
	},
	{
		text: 'Chargers',
		nameID: 'chargers',
	},
	{
		text: 'Guns',
		nameID: 'guns',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
export const chargerColumns: IColumns[] = [
	{
		text: 'Charger',
		nameID: 'charger',
	},
	{
		text: 'Station',
		nameID: 'station',
	},
	{
		text: 'Location',
		nameID: 'location',
	},
	{
		text: 'Guns',
		nameID: 'guns',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
export const financeColumns: IColumns[] = [
	{
		text: 'Month',
		nameID: 'month',
	},
	{
		text: 'Year',
		nameID: 'year',
	},
	{
		text: 'Cost',
		nameID: 'cost',
	},
	{
		text: 'revenue',
		nameID: 'guns',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
