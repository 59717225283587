import { useNavigate } from 'react-router-dom';
import { Paper } from '@mui/material';
import { Add } from '@mui/icons-material';
import CustomButton from '../../../../../components/button.component';
import TableCustom from '../../../../../components/table-custom.component';
import styles from '../../../styles/admin-associates-tab.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';
import { useAssociatesTab } from './hooks/useAssociatesTab';

const AdminAssociates = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);
	const { getAssociatesPermission } = useUserPermisions();

	const { associateColumns, data, setData } = useAssociatesTab();

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				{getAssociatesPermission() && (
					<CustomButton
						label={t('associates.new')}
						variant="solid"
						icon={Add}
						styles={{
							width: 'fit-content',
							padding: '0 15px',
							gap: '10px',
							backgroundColor: colors.accent,
							color: 'black',
						}}
						onClick={() => navigate('/partners/add-associate')}
					/>
				)}
			</div>
			<Paper className={styles['table-container']} elevation={1}>
				<TableCustom
					columns={associateColumns}
					data={data}
					setData={setData}
					showTotalCount={false}
					hideChips
					hideHeader
				/>
			</Paper>
		</>
	);
};

export default AdminAssociates;
