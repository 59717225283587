import { IColumns } from '../../../hooks/useTableCustom';

export const gunsColumns: IColumns[] = [
	{
		text: 'Gun',
		nameID: 'gun',
	},
	{
		text: 'Type',
		nameID: 'type',
	},
	{
		text: 'Charger',
		nameID: 'charger',
	},
	{
		text: 'Station',
		nameID: 'station',
	},
	{
		text: 'Location',
		nameID: 'location',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
