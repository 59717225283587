import { useContext, useEffect, useMemo } from 'react';
import { GenerateReportContext } from '../../context/GenerateReportContext';
import {
	IDownloadReportParams,
	IDownloadReportResponse,
} from '../../interfaces/generate-report.interface';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { generatePDF } from '../../../../utils/generatePDF';
import { generateCSV } from '../../../../utils/generateCSV';
import { setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

export function useGenerateReport() {
	const [t] = useTranslation('global');
	const { filterData, selectedColumns } = useContext(GenerateReportContext);
	const dispatch = useDispatch();

	const disabledButton = useMemo((): boolean => {
		if (selectedColumns.length > 0 && filterData.gun_ids.length > 0) return false;
		else return true;
	}, [filterData, selectedColumns]);

	const {
		execute: generateReport,
		value: generateReportVal,
		status: generateReportStatus,
	} = useBackendRequest<IDownloadReportParams, IDownloadReportResponse>({
		path: 'charge-fills/getDataReports',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (generateReportStatus === 'success') {
			if (generateReportVal?.data) {
				if (generateReportVal.data.message.includes('pdf')) {
					generatePDF(generateReportVal.data.base64, 'pdf', 'Report', false);
				} else {
					generateCSV(generateReportVal.data.base64, 'csv', 'Report');
				}
				dispatch(setLoading({ loading: false }));
			}
		} else if (generateReportStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('report.error-download'),
					severity: 'error',
				})
			);
		}
	}, [generateReportStatus]);

	const handleDownloadDocument = (): void => {
		dispatch(setLoading({ loading: true }));
		const docParams: IDownloadReportParams = {
			...filterData,
			station:
				filterData.station === 'all' ? '' : filterData.station?.split('-')[0],
			dataReports: selectedColumns,
		};
		generateReport(docParams);
	};

	return { disabledButton, handleDownloadDocument };
}
