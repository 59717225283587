import React from 'react';
import { Checkbox } from '../../../components/Checkbox';
import Input from '../../../components/input.component';
import { Grid, useMediaQuery, useTheme } from '@mui/material';

interface IMenuOptions {
	label: string;
	inputName: string;
	checkboxName: string;
	inputLabel: string;
	inputValue: string;
	checked: boolean;
	onCheckChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MenuOption = ({
	label,
	inputName,
	checkboxName,
	inputLabel,
	inputValue,
	checked,
	onCheckChange,
	onInputChange,
}: IMenuOptions) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	return (
		<Grid container spacing={matches ? 0 : 2}>
			<Grid
				item
				xl={3}
				lg={3}
				md={3}
				sm={12}
				xs={12}
				display={'flex'}
				alignItems={'center'}
				paddingLeft={matches ? 0 : 10}
			>
				<Checkbox
					label={label}
					value={checkboxName}
					checked={checked}
					onChange={onCheckChange}
				/>
			</Grid>
			<Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
				<Input
					id={inputName}
					name={inputName}
					value={inputValue}
					label={inputLabel}
					placeholder={'URL'}
					style={{
						marginBottom: '27px',
						height: '43px',
						paddingLeft: '14px',
					}}
					onChange={onInputChange}
					type="text"
					disabled={!checked}
				/>
			</Grid>
		</Grid>
	);
};

export default MenuOption;
