import { useDispatch, useSelector } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useNavigate, useParams } from 'react-router-dom';
import { IGun } from '../../../interfaces/gun.interface';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { useApiRequest } from '../../../hooks/useApiRequest';

interface IUseEditGun {
	setGunData: React.Dispatch<React.SetStateAction<IGun>>;
}

export function useEditGun({ setGunData }: IUseEditGun) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: gunId } = useParams();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { role } = useSelector((state: RootState) => state.user);

	const {
		execute: getGun,
		value: getGunVal,
		status: getGunStatus,
	} = useBackendRequest<any, IGun>({
		path: 'guns/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getGunStatus === 'success') {
			if (getGunVal?.data) {
				setGunData(getGunVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (getGunStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getGunStatus]);

	const { execute: editGun, status: editGunStatus } = useBackendRequest<IGun, any>({
		path: 'guns/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (editGunStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.edited'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.GUNS },
				});
			} else {
				navigate('/partners/all-guns');
			}
		} else if (editGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-edited'),
					severity: 'error',
				})
			);
		}
	}, [editGunStatus]);

	const { execute: deleteGun, status: deleteGunStatus } = useBackendRequest<any, any>({
		path: 'guns/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteGunStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.deleted'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.GUNS },
				});
			} else {
				navigate('/partners/all-guns');
			}
		} else if (deleteGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deleteGunStatus]);

	const handleGetGun = (id: string): void => {
		dispatch(setLoading({ loading: true }));
		getGun({}, id);
	};

	const handleEditGun = (gun: IGun): void => {
		dispatch(setLoading({ loading: true }));
		const id = gun.id;
		delete gun.id;
		delete gun.create_time;
		delete gun.update_time;
		editGun(gun, id);
	};

	const handleDeleteGun = (qionId: string): void => {
		dispatch(setLoading({ loading: true }));
		deleteQionGun({}, qionId);
	};

	//To delete a gun from Qion DB
	const { execute: deleteQionGun, status: deleteQionGunStatus } = useApiRequest<
		any,
		any
	>({
		path: 'cms-charger/remove-gun/',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteQionGunStatus === 'success') {
			deleteGun({}, gunId);
		} else if (deleteQionGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-created'),
					severity: 'error',
				})
			);
		}
	}, [deleteQionGunStatus]);

	return { handleGetGun, handleEditGun, handleDeleteGun };
}
