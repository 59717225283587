import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { IGunInfo, IGunRequest } from '../interfaces/gun-detail.interface';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useTableCustom } from '../../../../../../hooks/useTableCustom';
import { gunDetailAdapter } from '../adapters/gun-detail.adapter';
import dayjs from 'dayjs';
import { IGunsStats } from '../../../../../../interfaces/guns-stats.interface';
import { useTranslation } from 'react-i18next';

export function useGunDetail() {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const { state } = useLocation();
	const dispatch = useDispatch();

	const { data, setData } = useTableCustom<any>();

	const [gunInfo, setGunInfo] = useState<IGunInfo>({
		id: '',
		charger: '',
		plugtype: '',
		gunId: '',
		name: '',
	});
	const week = [
		t('days.sun'),
		t('days.mon'),
		t('days.tue'),
		t('days.wed'),
		t('days.thu'),
		t('days.fri'),
		t('days.sat'),
	];
	const slicedWeek: string[] = week.map((d) => {
		return d.slice(0, 3);
	});
	const [gunFinancials, setGunFinancials] = useState<IGunsStats[]>([]);
	const [dailyCount, setDailyCount] = useState([]);

	const [startWeek, setStartWeek] = useState(dayjs().startOf('week').toISOString());
	const [endWeek, setEndWeek] = useState(dayjs().endOf('week').toISOString());

	const {
		execute: getGunData,
		value: gunDataVal,
		status: gunDataStatus,
	} = useBackendRequest<IGunRequest, IGunsStats[]>({
		path: 'charge-fills/findByDatesAndGunId',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const setDailyData = () => {
		const arr: any = [];
		const arrTable: any = [];
		for (let i = 0; i <= week.length - 1; i++) {
			let total = 0.0;
			const fecha = dayjs(startWeek).add(i, 'day').format('YYYY-MM-DD');
			const arrDay = gunFinancials?.filter(
				(element) => element.datetime_charging.toString().split('T')[0] === fecha
			);
			if (!arrDay) return;
			if (arrDay.length > 0) {
				for (let j = 0; j < arrDay?.length!; j++) {
					if (parseFloat(arrDay[j].total) > 0) {
						total += parseFloat(arrDay[j].total);
					}
				}
				arr[i] = total.toFixed(2);
				arrTable[i] = { day: week[i], price: total.toFixed(2) };
			} else {
				arr[i] = 0;
				arrTable[i] = { day: week[i], price: 0 };
			}
		}

		setData({
			...data,
			countRows: 0,
			rows: gunDetailAdapter(arrTable),
		});
		setDailyCount(arr);
	};

	const formatDate = (dateToFormat: string): string => {
		const date = dateToFormat.split('T')[0];
		const dateArr = date.split('-');
		const dateFormated = `${dateArr[2]}-${dateArr[1]}-${dateArr[0]}`;
		return dateFormated;
	};

	useEffect(() => {
		const { charger, plugtype, gunId, gunName } = state;
		setGunInfo({
			id: id,
			charger: charger,
			plugtype: plugtype,
			gunId: gunId,
			name: gunName,
		});
	}, [state]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getGunData({
			gun_id: id!,
			datetime_charging_start: startWeek,
			datetime_charging_end: endWeek,
		});
	}, []);

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (gunDataStatus === 'success') {
			if (gunDataVal?.data) {
				setGunFinancials(gunDataVal.data);
			}
		} else if (gunDataStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [gunDataStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getGunData({
			gun_id: id!,
			datetime_charging_start: startWeek,
			datetime_charging_end: endWeek,
		});
	}, [startWeek, endWeek]);

	useEffect(() => {
		setDailyData();
	}, [gunFinancials]);

	const handleGetPastWeek = () => {
		setStartWeek(dayjs(startWeek).subtract(7, 'day').toISOString());
		setEndWeek(dayjs(endWeek).subtract(7, 'day').toISOString());
	};

	const handleGetFutureWeek = () => {
		setStartWeek(dayjs(startWeek).add(7, 'day').toISOString());
		setEndWeek(dayjs(endWeek).add(7, 'day').toISOString());
	};

	return {
		startWeek,
		endWeek,
		gunInfo,
		gunFinancials,
		data,
		slicedWeek,
		dailyCount,
		setData,
		formatDate,
		handleGetPastWeek,
		handleGetFutureWeek,
	};
}
