import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../hooks/useTableCustom';

export function useGunsColums() {
	const [t] = useTranslation('global');
	const gunsColumns: IColumns[] = [
		{
			text: t('common.name'),
			nameID: 'name',
		},
		{
			text: t('common.plugtype'),
			nameID: 'plugType',
			align: 'center',
		},
		{
			text: t('common.location'),
			nameID: 'address',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
			align: 'center',
		},
	];

	return { gunsColumns };
}
