export interface IAssociate {
	id?: string;
	status?: boolean | number;
	name: string;
	alias: string;
	phone: string;
	address: string;
	rfc: string;
	img: string;
	email: string;
	password: string;
	state: string;
	country: string;
	zip: string;
	partnerInfoId: string;
	create_time?: string;
	update_time?: string;
	is_deleted?: boolean;
}

export const defaultAssociate: IAssociate = {
	name: '',
	alias: '',
	phone: '',
	address: '',
	rfc: '',
	img: '',
	email: '',
	password: '',
	state: '',
	country: '',
	zip: '',
	partnerInfoId: '',
};
