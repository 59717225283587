import { useSelector } from 'react-redux';
import { IMenuList } from '../../../router/utils/route-list';
import { RootState } from '../../../store';

interface IDrawerLiProps {
	menu: IMenuList;
	i: number;
	selectMenu: number;
	submenuNumber: number;
	arrowToggle: boolean;
	handleSubmenuChange: (index: number, navigateUrl: string) => void;
	toogleMenu: (menu: number, redirect?: string) => void;
}

const DrawerLi = ({
	menu,
	i,
	selectMenu,
	submenuNumber,
	arrowToggle,
	handleSubmenuChange,
	toogleMenu,
}: IDrawerLiProps) => {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<li
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
			}}
			onClick={() => handleSubmenuChange(i + 1, menu.link)}
		>
			<div
				data-active={selectMenu === i ? 'true' : 'false'}
				style={
					arrowToggle
						? {
								display: 'flex',
								justifyContent: 'center',
								gap: '10px',
						  }
						: {
								display: 'flex',
								gap: '10px',
								padding: '10px',
								border: '1px solid',
								borderColor:
									submenuNumber === i + 1
										? colors.accent
										: 'transparent',
						  }
				}
				onClick={() => toogleMenu(i, !menu.submenu ? menu.link : undefined)}
			>
				<div
					style={{
						color: submenuNumber === i + 1 ? colors.accent : 'white',
					}}
				>
					{menu.icon}
				</div>
			</div>
		</li>
	);
};

export default DrawerLi;
