import { useTranslation } from 'react-i18next';
import { useAssociateColumns } from '../utils/associate-colums';
import { useAdminAssociateAdapter } from '../adapters/associates.adapter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useTableCustom } from '../../../../../../hooks/useTableCustom';
import { useNavigate } from 'react-router-dom';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { IAssociate } from '../../../../../../interfaces/associate.interface';
import { setLoading } from '../../../../../../store/modules/loading';
import { useEffect } from 'react';
import { useUserPermisions } from '../../../../../../hooks/useUserPermissions';
import { setAlert } from '../../../../../../store/modules/snackbar';

export function useAssociatesTab() {
	const [t] = useTranslation('global');

	const { id } = useSelector((state: RootState) => state.partner);
	const { data, setData } = useTableCustom<any>();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const { getAssociatesPermission } = useUserPermisions();
	const { associateColumns } = useAssociateColumns();
	const { adminAssociateAdapter } = useAdminAssociateAdapter();

	const onDetail = (id: string) => navigate('/partners/detail-associate/' + id);
	const handleEditPartner = (id: string) => {
		navigate('/partners/edit-associate/' + id);
	};

	const {
		execute: getAssociates,
		value: associatesVal,
		status: associatesStatus,
	} = useBackendRequest<{}, IAssociate[]>({
		path: 'associates',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesStatus === 'success') {
			if (associatesVal?.data) {
				const newData = filterData(associatesVal?.data);
				setData({
					...data,
					rows: adminAssociateAdapter(
						newData,
						onDetail,
						handleEditPartner,
						getAssociatesPermission
					),
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('associates.error-associates'),
						severity: 'error',
					})
				);
			}
		} else if (associatesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.error-associates'),
					severity: 'error',
				})
			);
		}
	}, [associatesStatus]);

	const filterData = (data: IAssociate[]): IAssociate[] => {
		const filteredData = data.filter((e) => e.partnerInfoId === id);
		return filteredData;
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getAssociates();
	}, []);

	return { associateColumns, data, setData };
}
