import CustomButton from '../../components/button.component';
import { ArrowBack } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import SimpleCard from '../../components/simple-card.component';
import { newAssociateForm } from './utils/utils';
import { Grid } from '@mui/material';
import Input from '../../components/input.component';
import { useAddAssociate } from './hooks/useAddAssociate';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import styles from './styles/add-associate.module.css';
import ImageInput from '../../components/image-input.component';
import { IAssociate } from '../../interfaces/associate.interface';
import PasswordComponent from '../../components/password.component';

const AddAssociate = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const navigate = useNavigate();

	const {
		newAssociateData,
		disabledButton,
		handleSetData,
		getInputLabel,
		saveAssociate,
		handleImageChange,
		handleDeleteImage,
	} = useAddAssociate();

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant="solid"
				icon={ArrowBack}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: '10px',
					backgroundColor: 'var(--color-black-main)',
				}}
				onClick={() =>
					navigate('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
					})
				}
			/>
			<SimpleCard
				title={t('associates.new').toString()}
				dividers
				footer={
					<div className={styles.associateAddFooterContainer}>
						<CustomButton
							variant="solid"
							label={t('common.cancel')}
							styles={{ backgroundColor: 'var(--color-black-main)' }}
							onClick={() =>
								navigate('/partners/details', {
									state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
								})
							}
						/>
						<CustomButton
							variant="solid"
							label={t('common.save')}
							disabled={disabledButton}
							onClick={saveAssociate}
							styles={{ color: 'black', backgroundColor: colors.accent }}
						/>
					</div>
				}
			>
				<Grid container spacing={2}>
					{newAssociateForm.map(({ name }) => (
						<Grid key={name} item xs={12} sm={6}>
							<Input
								label={getInputLabel(name)}
								name={name}
								required
								onChange={handleSetData}
								value={newAssociateData[
									name as keyof IAssociate
								]?.toString()}
								placeholder={t('common.required').toString()}
							/>
						</Grid>
					))}
					<Grid item xs={12}>
						<h3>{t('associates.credentials')}</h3>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={getInputLabel('email')}
							name={'email'}
							required
							onChange={handleSetData}
							value={newAssociateData.email}
							placeholder={t('common.required').toString()}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={getInputLabel('password')}
							name={'password'}
							required
							onChange={handleSetData}
							value={newAssociateData.password}
							placeholder={t('common.required').toString()}
							type="password"
						/>
						<PasswordComponent password={newAssociateData.password} />
					</Grid>

					<Grid item xs={12}>
						<h3>{t('partners.branding')}</h3>
					</Grid>
					<Grid item xs={12}>
						<ImageInput
							onChange={handleImageChange}
							onDelete={handleDeleteImage}
							image={newAssociateData.img}
						/>
					</Grid>
				</Grid>
			</SimpleCard>
		</>
	);
};

export default AddAssociate;
