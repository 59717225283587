import styles from './styles/associate-card.component.module.css';
import { useEffect, useState } from 'react';
import { HighlightOff, PersonOutline } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ReactComponent as UsersLogo } from '../../../assets/svgs/users-alt.svg';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';

interface ICostFactor {
	chargeCost: string | number;
	chargeFactor: string | number;
	parkingCost: string | number;
	parkingFactor: string | number;
}

interface IAssociateCard {
	id: number;
	name: string;
	address: string;
	onClick?: (id: number) => void;
	clickable?: boolean;
	onRemove?: () => void;
	showCostFactor?: boolean;
	costFactor?: ICostFactor;
	isSelected?: boolean;
}

const AssociateCard = ({
	id,
	name,
	address,
	onClick,
	clickable = true,
	onRemove,
	showCostFactor,
	costFactor,
	isSelected,
}: IAssociateCard) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const [selected, setSelected] = useState<boolean>(false);

	const handleClick = () => {
		if (onClick !== undefined) onClick(id);
		setSelected(!selected);
	};

	const handleRemove = () => {
		if (onRemove !== undefined) onRemove();
	};

	useEffect(() => {
		if (isSelected !== undefined) setSelected(isSelected);
	}, [isSelected]);

	return (
		<div
			className={styles.cardContainer}
			style={{
				borderColor: selected
					? colors.secondary
					: hexToRGBA(colors.secondary, 0.5),
				borderWidth: selected ? '2px' : '1px',
				cursor: clickable ? 'pointer' : 'default',
				backgroundColor: selected
					? hexToRGBA(colors.secondary, 0.3)
					: hexToRGBA(colors.secondary, 0.1),
			}}
			onClick={clickable ? handleClick : () => {}}
		>
			<div className={styles.rowName}>
				<UsersLogo width={20} height={20} color={colors.secondary} />
				<span>{name}</span>
				{!clickable ? (
					<div
						style={{ marginLeft: 'auto', cursor: 'pointer' }}
						onClick={handleRemove}
					>
						<HighlightOff style={{ width: '16px', height: '16px' }} />
					</div>
				) : null}
			</div>
			{showCostFactor && (
				<Grid
					container
					display={'flex'}
					flexDirection={'column'}
					padding={2}
					gap={2}
				>
					<Grid item display={'flex'} justifyContent={'space-between'}>
						<div className={styles.costFactorContainer}>
							<span className={styles.titleCost}>
								{t('common.charge-cost')}:
							</span>
							<span className={styles.valueCost}>
								{costFactor?.chargeCost
									? `$${costFactor?.chargeCost}`
									: 'No cost provided'}
							</span>
						</div>
						<div className={styles.costFactorContainer}>
							<span className={styles.titleCost}>
								{t('common.charge-factor')}:
							</span>
							<span className={styles.valueCost}>
								{costFactor?.chargeFactor
									? `${costFactor?.chargeFactor}`
									: 'No factor provided'}
							</span>
						</div>
					</Grid>
					<Grid item display={'flex'} justifyContent={'space-between'}>
						<div className={styles.costFactorContainer}>
							<span className={styles.titleCost}>
								{t('common.parking-cost')}:
							</span>
							<span className={styles.valueCost}>
								{costFactor?.parkingCost
									? `$${costFactor?.parkingCost}`
									: 'No cost provided'}
							</span>
						</div>
						<div className={styles.costFactorContainer}>
							<span className={styles.titleCost}>
								{t('common.parking-factor')}:
							</span>
							<span className={styles.valueCost}>
								{costFactor?.parkingFactor
									? `${costFactor?.parkingFactor}`
									: 'No factor provided'}
							</span>
						</div>
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default AssociateCard;
