import {
	ArrowBack,
	Sell,
	SettingsInputSvideo,
	ChevronLeft,
	ChevronRight,
	Edit,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { Avatar, CardActionArea, Grid } from '@mui/material';
import CustomButton from '../../../../../components/button.component';
import SimpleCard from '../../../../../components/simple-card.component';
import { ReactComponent as Plug } from '../../../../../assets/svgs/plug.svg';
import { ADMIN_TAB_VALUES } from '../../../../../constants/tab-values.constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { useGunDetail } from './hooks/useGunDetail';
import LinesChart from '../../../../../components/line-chart.component';
import TableCustom from '../../../../../components/table-custom.component';
import { CustomTooltip } from '../../../../../components/button-icon-small.component';
import { useTranslation } from 'react-i18next';
import { useGunDetailColums } from './utils/table.colums';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';
import { ROLES } from '../../../../../constants/user-roles.constants';
import { PLUGTYPES_IMAGES, PLUGTYPES_NAMES } from './utils/plugtypes.utils';

const chartOptions = {
	scales: {
		y: {
			min: 0,
		},
		x: {
			ticks: { color: '#000' },
		},
	},
	responsive: true,
};

const AdminDetailGun = () => {
	const [t] = useTranslation('global');
	const nav = useNavigate();

	const colors = useSelector((state: RootState) => state.colors);

	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const { gunDetailColumns } = useGunDetailColums();

	const {
		startWeek,
		endWeek,
		gunInfo,
		data,
		slicedWeek,
		dailyCount,
		setData,
		formatDate,
		handleGetPastWeek,
		handleGetFutureWeek,
	} = useGunDetail();

	const { getGunsPermission } = useUserPermisions();

	return (
		<>
			<CustomButton
				variant="solid"
				label={t('common.back')}
				icon={ArrowBack}
				onClick={
					role.roleName === ROLES.SUPER_ADMIN && !simulating
						? () => nav('/partners/all-guns')
						: () =>
								nav('/partners/details', {
									state: { tab: ADMIN_TAB_VALUES.GUNS },
								})
				}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: 10,
					backgroundColor: 'black',
				}}
			/>
			<Grid container spacing={2}>
				<Grid
					container
					item
					spacing={2}
					xs={12}
					sm={3}
					style={{ placeSelf: 'start' }}
				>
					<Grid item xs={12}>
						<SimpleCard
							style={{
								display: 'flex',
								alignItems: 'center',
								flexDirection: 'column',
								textAlign: 'center',
							}}
						>
							<>
								<Avatar
									style={{
										background: colors.secondary,
										width: '50px',
										height: '50px',
									}}
								>
									<Plug style={{ width: 25 }} />
								</Avatar>
								<h3 style={{ marginBottom: 10 }}>{gunInfo.name}</h3>
								<h4 style={{ marginBottom: 10, marginTop: 0 }}>
									{gunInfo.id}
								</h4>
								<span
									style={{
										marginBottom: 25,
										fontSize: '14px',
									}}
								>
									{t('dashboard-4.charger-asigned')}
									{': '}
									<b>{gunInfo?.charger}</b>
								</span>
								{getGunsPermission() && (
									<CustomButton
										variant="solid"
										label={t('common.edit')}
										icon={Edit}
										onClick={() =>
											nav(`/partners/edit-gun/${gunInfo.gunId}`)
										}
										styles={{
											width: 'fit-content',
											padding: '0 15px',
											gap: 10,
											backgroundColor: 'black',
										}}
									/>
								)}
							</>
						</SimpleCard>
					</Grid>
					<Grid item xs={12}>
						<SimpleCard>
							<>
								<div>{t('common.plugtype')}</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '10px',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<img
										src={
											PLUGTYPES_IMAGES[parseInt(gunInfo?.plugtype)]
										}
										alt="plugtype-img"
									/>
									<span style={{ fontSize: '18px' }}>
										<strong>
											{PLUGTYPES_NAMES[parseInt(gunInfo?.plugtype)]}
										</strong>
									</span>
								</div>
							</>
						</SimpleCard>
					</Grid>
					<Grid item xs={12} sm={6}>
						<CardActionArea>
							<SimpleCard
								style={{ background: colors.secondary, color: 'white' }}
							>
								<div
									style={{
										display: 'flex',
										flexDirection: 'column',
										gap: '15px',
									}}
								>
									<Sell />
									<span>{t('guns.prices')}</span>
								</div>
							</SimpleCard>
						</CardActionArea>
					</Grid>
				</Grid>
				<Grid container item spacing={2} xs={12} sm={9}>
					<Grid item xs={7}>
						<SimpleCard>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									gap: '15px',
								}}
							>
								<span style={{ fontWeight: 'bold' }}>
									{t('guns.revenue-week')}
								</span>
								<div
									style={{
										display: 'flex',
										gap: '15px',
									}}
								>
									<div
										style={{ cursor: 'pointer' }}
										onClick={handleGetPastWeek}
									>
										<CustomTooltip
											title={t('guns.prev')}
											placement="top"
										>
											<ChevronLeft />
										</CustomTooltip>
									</div>
									<span>{`${t('guns.week')} ${formatDate(
										startWeek
									)} ${t('guns.to')} ${formatDate(endWeek)}`}</span>
									<div
										style={{ cursor: 'pointer' }}
										onClick={handleGetFutureWeek}
									>
										<CustomTooltip
											title={t('guns.next')}
											placement="top"
										>
											<ChevronRight />
										</CustomTooltip>
									</div>
								</div>
								<LinesChart
									mainData={dailyCount}
									labels={slicedWeek}
									options={chartOptions}
									colorDataSet={'#165470'}
									backgroundDataSet={'#1654704D'}
									fillDataSet={true}
									labelDataSet={t('guns.revenue')}
								/>
							</div>
						</SimpleCard>
					</Grid>
					<Grid item xs={5}>
						<SimpleCard>
							<TableCustom
								columns={gunDetailColumns}
								data={data}
								setData={setData}
								showTotalCount={false}
								hideChips
								hideHeader
							/>
						</SimpleCard>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export default AdminDetailGun;
