export interface IAssociateGroup {
	group: string | JSX.Element;
	associates: string;
	stations: string | number;
	charger: string | number;
	actions?: JSX.Element;
}
export const mockDataAssociateGroup: IAssociateGroup[] = [
	{
		group: 'Group 01',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 02',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 03',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 04',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 05',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 06',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 07',
		associates: '4',
		stations: '10',
		charger: '10',
	},

	{
		group: 'Group 08',
		associates: '4',
		stations: '10',
		charger: '10',
	},
];

export interface IAGAssociate {
	associateId: number | string;
	unit: boolean;
	fraction: number;
}

export interface IAssociateGroupResponse {
	id: string;
	parterId: number;
	group: string;
	firstInvoiceAssociateId: string;
	remainingOwnerId: string;
	iva: string | number;
	chargeCost: number;
	chargeFactor: number;
	parkingCost: number;
	parkingFactor: number;
	associates: IAGAssociate[];
}
