import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { hexToRGBA } from '../utils/hex-to-rgba';
import { DefaultTFuncReturn } from 'i18next';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = {
	responsive: true,
	maintainAspectRatio: false,
};

interface IPieChart {
	mainLabel: string;
	labels?: string[];
	chartData?: any[];
}

const PieChart = ({ labels, chartData, mainLabel }: IPieChart) => {
	const data = {
		labels: labels,
		datasets: [
			{
				label: mainLabel,
				data: chartData,
				backgroundColor: [
					hexToRGBA('#DC8665', 1),
					hexToRGBA('#138086', 1),
					hexToRGBA('#564666', 1),
					hexToRGBA('#CD7672', 1),
					hexToRGBA('#EEB462', 1),
				],
				borderColor: [
					hexToRGBA('#DC8665', 1),
					hexToRGBA('#138086', 1),
					hexToRGBA('#564666', 1),
					hexToRGBA('#CD7672', 1),
					hexToRGBA('#EEB462', 1),
				],
				borderWidth: 1,
			},
		],
	};

	return <Pie data={data} options={options} />;
};

export default PieChart;
