import { useTranslation } from 'react-i18next';

export function useEditUserForm() {
	const [t] = useTranslation('global');

	const editUserForm = [
		{
			name: 'name',
			label: t('common.name'),
			required: true,
		},
		{
			name: 'first_last_name',
			label: t('common.f-last-name'),
			required: true,
		},
		{
			name: 'second_last_name',
			label: t('common.s-last-name'),
			required: false,
		},
		{
			name: 'mobile',
			label: t('common.phone'),
			required: true,
		},
		{
			name: 'email',
			label: t('common.email'),
			required: true,
		},
	];

	return { editUserForm };
}
