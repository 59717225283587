import { useDispatch } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IPartnerInfo } from '../../../interfaces/partner';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { QION_ID } from '../../../constants/qion-id.constants';
import { IOptions } from '../../../components/select-input.component';

export function useGetPartners() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const [partners, setPartners] = useState<IOptions[]>([]);

	const {
		execute: getPartners,
		value: getPartnersVal,
		status: getPartnersStatus,
	} = useBackendRequest<any, IPartnerInfo[]>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getPartnersStatus === 'success') {
			if (getPartnersVal?.data) {
				const partners = getPartnersVal?.data?.filter((p) => p.id !== QION_ID);
				const partnerOptions: IOptions[] = partners.map((p) => {
					return {
						value: p.id,
						label: p.name,
					};
				});
				setPartners(partnerOptions);
				dispatch(setLoading({ loading: false }));
			}
		} else if (getPartnersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnersStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getPartners();
	}, []);

	return { partners };
}
