import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { ICharger } from '../interfaces/charger.interface';

export const chargerAdapter = (
	data: ICharger[],
	handleSeeDetails: (data: ICharger) => void
): ICharger[] => {
	const newData: ICharger[] = data.map((item: ICharger) => {
		return {
			...item,
			actions: (
				<ButtonIconSmall
					onPress={() => handleSeeDetails(item)}
					type="primary"
					icon={<VisibilityOutlinedIcon />}
				/>
			),
		};
	});
	return newData;
};
