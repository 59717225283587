import { useNavigate, useParams } from 'react-router-dom';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';

export function useDeleteGroup() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();

	const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);

	const { execute: deleteGroup, status: deleteGroupStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'partners-group/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteGroupStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('groups.deleted'),
					severity: 'success',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
			});
		} else if (deleteGroupStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deleteGroupStatus]);

	const handleDeleteGroup = (): void => {
		dispatch(setLoading({ loading: true }));
		deleteGroup({}, id);
	};

	return { openDeleteModal, setOpenDeleteModal, handleDeleteGroup };
}
