import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function usePartnersColums() {
	const [t] = useTranslation('global');
	const partnersColumns: IColumns[] = [
		{
			text: '',
			nameID: 'img',
		},
		{
			text: t('partners.partner-name'),
			nameID: 'name',
		},
		{
			text: t('partners.responsible'),
			nameID: 'responsibleName',
		},
		{
			text: t('common.email'),
			nameID: 'email',
		},
		{
			text: t('common.phone'),
			nameID: 'phone',
		},
		{
			text: t('common.tax-id'),
			nameID: 'rfc',
		},
		{
			text: t('common.address'),
			nameID: 'address',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { partnersColumns };
}
