/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useMemo, useState } from 'react';
import {
	associateGroupDefaultVaule,
	schedulesDefaultValue,
} from '../../../constants/interfaces-default-values';
import { IAssociatesGroup, ISchedule } from '../../../interfaces/partner';
import { IGun } from '../../../interfaces/gun.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import dayjs from 'dayjs';
import { useEditSchedule } from './useEditSchedule';
import { useGetData } from './useGetData';
import { isNumberValidate } from '../../../utils/validators';

export function useCreateSchedule() {
	const [t] = useTranslation('global');
	const { id: partnerId } = useSelector((state: RootState) => state.partner);
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [newScheduleData, setnewScheduleData] = useState<ISchedule>({
		...schedulesDefaultValue,
		partnerId: partnerId,
	});

	const [openAGModal, setOpenAGModal] = useState(false);
	const [openGunsModal, setOpenGunsModal] = useState(false);

	const [selectedGroup, setSelectedGroup] = useState<IAssociatesGroup>(
		associateGroupDefaultVaule
	);
	const [tempGroup, setTempGroup] = useState<IAssociatesGroup>(
		associateGroupDefaultVaule
	);

	const [selectedGuns, setSelectedGuns] = useState<IGun[]>([]);
	const [tempGuns, setTempGuns] = useState<IGun[]>([]);
	const [modalGuns, setModalGuns] = useState<IGun[]>([]);

	const { gunsData, associateGroupData, getChargerName } = useGetData();

	const { handleGetScheduleInformation, handleEditSchedule } = useEditSchedule(
		gunsData,
		associateGroupData,
		setnewScheduleData,
		setSelectedGroup,
		setSelectedGuns
	);

	const disabledButton = useMemo((): boolean => {
		if (
			newScheduleData.name === '' ||
			newScheduleData.days.length <= 0 ||
			(newScheduleData.limitedDays === false
				? newScheduleData.limitedDays
				: newScheduleData.startDate === '' || newScheduleData.endDate === '') ||
			(newScheduleData.allDay === false
				? newScheduleData.startTime === '' || newScheduleData.endTime === ''
				: !newScheduleData.allDay) ||
			newScheduleData.associateGroupId === 0 ||
			newScheduleData.guns.length <= 0
		)
			return true;
		return false;
	}, [newScheduleData]);

	const {
		execute: addSchedule,
		value: addScheduleVal,
		status: addScheduleStatus,
	} = useBackendRequest<ISchedule, any>({
		path: 'schedules',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (addScheduleStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('add-schedule.added'),
					severity: 'success',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.SCHEDULES },
			});
		} else if (addScheduleStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			if (addScheduleVal?.data === undefined) {
				dispatch(
					setAlert({
						show: true,
						message: t('add-schedule.error-added'),
						severity: 'error',
					})
				);
				return;
			}
			if (addScheduleVal?.data.statusCode === 409) {
				dispatch(
					setAlert({
						show: true,
						message: t('add-schedule.error-group'),
						severity: 'error',
					})
				);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('add-schedule.error-added'),
						severity: 'error',
					})
				);
			}
		}
	}, [addScheduleStatus]);

	const handleRemoveCardAssociateGroup = () => {
		setTempGroup(associateGroupDefaultVaule);
		setnewScheduleData({ ...newScheduleData, associateGroupId: 0 });
	};

	const handleSelectCard = (id: number) => {
		const selectedGroup = associateGroupData.filter((e) => e.id === id)[0];
		setTempGroup(selectedGroup);
	};

	const handleCancelModal = () => {
		setTempGroup(associateGroupDefaultVaule);
		setOpenAGModal(false);
	};

	const handleAcceptModal = () => {
		setSelectedGroup(tempGroup);
		setnewScheduleData({ ...newScheduleData, associateGroupId: tempGroup.id });
		setOpenAGModal(false);
	};

	const handleSetData = (event: any) => {
		const { name, value } = event.target;
		if (name === 'startDate' || name === 'endDate') {
			const date = dayjs(value.$d);
			setnewScheduleData({ ...newScheduleData, [name]: date.format('YYYY-MM-DD') });
		} /* if (name === 'startTime' || name === 'endTime') {
			//const realVal = value.split('T')[1].split('Z')[0];
			//console.log(value);
			setnewScheduleData({ ...newScheduleData, [name]: value });
		} else */ else if (name === 'weight') {
			if (!isNumberValidate(value)) return;
			if (value !== '') {
				setnewScheduleData({ ...newScheduleData, [name]: parseInt(value) });
			} else {
				setnewScheduleData({ ...newScheduleData, [name]: '' });
			}
		} else {
			setnewScheduleData({ ...newScheduleData, [name]: value });
		}
	};

	const handleTimeChange = (name: string, time: string): void => {
		setnewScheduleData({ ...newScheduleData, [name]: time });
	};

	const handleWeekDays = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		if (checked) {
			setnewScheduleData({
				...newScheduleData,
				days: newScheduleData.days.concat(value),
			});
		} else {
			setnewScheduleData({
				...newScheduleData,
				days: newScheduleData.days.filter((e) => !e.includes(value)),
			});
		}
	};

	const handleCancelGunsModal = () => {
		setOpenGunsModal(false);
		setTempGroup(associateGroupDefaultVaule);
	};

	const handleAcceptGunsModal = () => {
		setSelectedGuns(tempGuns);
		const gunUniqueCodesArr = tempGuns.map((gun) => gun.qionGunUniqueId);
		setTempGroup(associateGroupDefaultVaule);
		setnewScheduleData({ ...newScheduleData, guns: gunUniqueCodesArr });
		setOpenGunsModal(false);
	};

	const handleSelectGun = (id: string, selected: boolean) => {
		let newArr;
		selected
			? (newArr = tempGuns.concat(
					gunsData.find((element) => element.qionGunUniqueId === id.toString())!
			  ))
			: (newArr = tempGuns.filter(
					(element) => element.qionGunUniqueId !== id.toString()
			  ));

		setTempGuns(newArr);
	};

	const handleRemoveSelectedGun = (id: string): void => {
		const newSelectedGuns = selectedGuns.filter((g) => g.qionGunUniqueId !== id);
		setSelectedGuns(newSelectedGuns);
	};

	const handleOpenGunsModal = (): void => {
		const newModalGuns = gunsData.filter((g) => !selectedGuns.includes(g));
		setModalGuns(newModalGuns);
		setOpenGunsModal(true);
	};

	const handleSaveSchedule = () => {
		dispatch(setLoading({ loading: true }));
		addSchedule(newScheduleData);
	};

	const editSchedule = (): void => {
		handleEditSchedule(newScheduleData, id!);
	};

	const handleAllDayCheck = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { checked } = event.target;
		if (checked) {
			setnewScheduleData({
				...newScheduleData,
				allDay: true,
				startTime: null,
				endTime: null,
			});
		} else {
			setnewScheduleData({
				...newScheduleData,
				allDay: false,
				startTime: '',
				endTime: '',
			});
		}
	};

	useEffect(() => {
		if (id) {
			handleGetScheduleInformation(id);
		}
	}, []);

	return {
		id,
		newScheduleData,
		openAGModal,
		openGunsModal,
		selectedGroup,
		tempGroup,
		selectedGuns,
		tempGuns,
		associateGroupData,
		gunsData,
		disabledButton,
		modalGuns,
		getChargerName,
		setnewScheduleData,
		handleSetData,
		handleWeekDays,
		handleRemoveCardAssociateGroup,
		setOpenAGModal,
		setOpenGunsModal,
		editSchedule,
		handleSaveSchedule,
		handleSelectCard,
		handleCancelModal,
		handleAcceptModal,
		handleSelectGun,
		handleAcceptGunsModal,
		handleCancelGunsModal,
		handleRemoveSelectedGun,
		handleOpenGunsModal,
		handleAllDayCheck,
		handleTimeChange,
	};
}
