/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { IUserDetailsResponse } from '../interfaces/user-details.interface';
import { useTranslation } from 'react-i18next';
import { isNumberValidate } from '../../../utils/validators';
import { RootState } from '../../../store';
import { useEditPartner } from './useEditPartner';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';

export function useEditUser() {
	const [t] = useTranslation('global');
	const { email } = useSelector((state: RootState) => state.partner);
	const { state } = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [userData, setUserData] = useState<any>({
		mobile: '',
		email: '',
		name: '',
		first_last_name: '',
		second_last_name: '',
	});

	const [prevEmail, setPrevEmail] = useState<string>('');

	const { editPartnerInfo } = useEditPartner();

	const disabledButton = useMemo((): boolean => {
		if (
			userData.mobile.length < 10 ||
			userData.email === '' ||
			userData.name === '' ||
			userData.first_last_name === '' ||
			userData.second_last_name === ''
		)
			return true;
		return false;
	}, [userData]);

	const {
		execute: editUser,
		value: editUserVal,
		status: editUserStatus,
	} = useBackendRequest<any, any>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	const {
		execute: getUser,
		value: getUserVal,
		status: getUserStatus,
	} = useBackendRequest<any, IUserDetailsResponse>({
		path: 'users/find/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (editUserStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('users.updated'),
					severity: 'success',
				})
			);
			if (prevEmail === email) {
				if (editUserVal?.data.email !== email) {
					editPartnerInfo(editUserVal?.data.email);
				} else {
					redirect();
				}
			} else {
				redirect();
			}
		} else if (editUserStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('common.error-updated'),
					severity: 'error',
				})
			);
		}
	}, [editUserStatus]);

	const redirect = (): void => {
		if (state.prevUrl === '/users') {
			navigate(-1);
		} else {
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.USERS },
			});
		}
	};

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getUserStatus === 'success') {
			if (getUserVal?.data) {
				setUserData({
					mobile: getUserVal.data.mobile,
					email: getUserVal.data.email,
					name: getUserVal.data.name,
					first_last_name: getUserVal.data.first_last_name,
					second_last_name: getUserVal.data.second_last_name,
				});
				setPrevEmail(getUserVal.data.email);
			}
		} else if (getUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('common.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getUserStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getUser({}, id);
	}, []);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		if (name === 'mobile') {
			if (!isNumberValidate(value)) return;
		}
		setUserData({ ...userData, [name]: value });
	};

	const handleAddUser = () => {
		dispatch(setLoading({ loading: true }));
		editUser(userData, id);
	};

	return { userData, disabledButton, handleSetData, handleAddUser };
}
