import styles from './styles.module.css';
import Card from './components/card';
import LinesChart from '../../components/line-chart.component';
import Map from '../../components/map';
import { useDashboard } from './hooks/useDashboard';
import DateInput from '../../components/DateInput';
import { useGraph } from './hooks/useGraph';
import { Grid, Slider, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ROLES } from '../../constants/user-roles.constants';
import ServicesStatus from './components/servicesStatus';
import { useTranslation } from 'react-i18next';
import PeriodSelector from '../../components/perdiod-selector.component';
import gifLoading from '../../assets/gifs/loading.gif';

const chartOptions = {
	scales: {
		y: {
			min: 0,
		},
		x: {
			ticks: { color: '#000' },
		},
	},
};

const Dashboard = () => {
	const [t] = useTranslation('global');
	const user = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const {
		days,
		isGraphLoading,
		dailyCount,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
	} = useGraph();

	const {
		stationsUbications,
		dateFilter,
		totalCharges,
		totalKW,
		dateRange,
		totalDays,
		qionServiceWorking,
		externalServiceWorking,
		mapCenter,
		renderMap,
		handleDateChange,
		formatDate,
		handleSetData,
		filterDataInRange,
	} = useDashboard();

	return (
		<>
			<div className={styles.mainContainer}>
				<div className={styles.dataSection}>
					<div>
						<h3 style={{ marginBottom: '50px' }}>
							{t('dashboard-1.title-filter')}
						</h3>
						<div className={styles.inputsContainer}>
							<DateInput
								label={t('dashboard-1.start')}
								name="startDate"
								value={dateFilter.datetime_charging_start}
								onChange={handleSetData}
							/>
							<DateInput
								label={t('dashboard-1.end')}
								name="endDate"
								value={dateFilter.datetime_charging_end}
								onChange={handleSetData}
							/>
						</div>
					</div>
					<div style={{ width: '90%', alignSelf: 'center' }}>
						<div>
							<Typography id="date-slider" gutterBottom>
								{t('dashboard-1.selected')}: {formatDate(dateRange[0])} -{' '}
								{formatDate(dateRange[1])}
							</Typography>
							<Slider
								value={dateRange}
								onChange={handleDateChange}
								min={1}
								max={totalDays}
								valueLabelDisplay="off"
								getAriaLabel={(value) => formatDate(value)}
								onChangeCommitted={filterDataInRange}
							/>
						</div>
					</div>
					<div className={styles.cardsContainer}>
						<Card
							title={t('dashboard-1.total-revenue')}
							total={totalCharges || 0}
						/>
						<Card
							title={t('dashboard-1.total-consumed')}
							total={totalKW || 0}
						/>
					</div>
				</div>
				<div className={styles.graphContainer}>
					{isGraphLoading ? (
						<>{t('dashboard-1.charging')}...</>
					) : (
						<>
							<PeriodSelector
								title={getMonthName()}
								prevText={t('dashboard-1.prev-month')}
								nextText={t('dashboard-1.next-month')}
								onPrev={handleGetPastMonth}
								onNext={handleGetFutureMonth}
							/>
							<LinesChart
								mainData={dailyCount}
								labels={days}
								options={chartOptions}
								labelDataSet={t('dashboard-1.total-revenue')}
							/>
						</>
					)}
				</div>
			</div>
			<Grid container spacing={2}>
				{user.role.roleName === ROLES.SUPER_ADMIN && !simulating && (
					<Grid item xl={3} md={12} sm={12} xs={12}>
						<ServicesStatus
							qionServiceWorking={qionServiceWorking}
							externalServiceWorking={externalServiceWorking}
						/>
					</Grid>
				)}

				<Grid
					item
					xl={user.role.roleName === ROLES.SUPER_ADMIN && !simulating ? 9 : 12}
					md={12}
					sm={12}
					xs={12}
				>
					{renderMap ? (
						<Map stations={stationsUbications} zoom={2} center={mapCenter} />
					) : (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: 'white',
								width: '100%',
								height: '100%',
							}}
						>
							<img
								src={gifLoading}
								alt="loading-gift"
								className={styles.imgGift}
							/>
						</div>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default Dashboard;
