import { CustomTooltip } from './button-icon-small.component';
import styles from './styles/stop-button.component.module.css';

interface IStopButton {
	onButtonClick: () => void;
	text: string;
}

const StopButton = ({ onButtonClick, text }: IStopButton) => {
	return (
		<CustomTooltip title={text} placement="top" onClick={onButtonClick}>
			<div className={styles.mainContainer}>
				<div className={styles.button}></div>
			</div>
		</CustomTooltip>
	);
};

export default StopButton;
