import { useEffect, useMemo, useState } from 'react';
import JSZip from 'jszip';
import {
	IMobileSettings,
	defaultMobileSettings,
} from '../interfaces/mobile-settings.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import {
	IImagesKeys,
	ImagesKeys,
	defaultImagesKeys,
} from '../interfaces/images-keys.interface';
import { iconNames } from '../utils/icon-names.utils';

export function useMobileAppSettings() {
	const [data, setData] = useState<IMobileSettings>({
		...defaultMobileSettings,
	});

	const [images, setImages] = useState<IImagesKeys>(defaultImagesKeys);

	const [gif, setGif] = useState<{ base64: string; name: string }>({
		base64: '',
		name: '',
	});

	const isPrivacyChecked = (): boolean => {
		if (data.menuConfig.showPrivacyItem) {
			return data.menuConfig.privacyURL !== undefined
				? data.menuConfig.privacyURL === ''
				: true;
		} else {
			return data.menuConfig.showPrivacyItem;
		}
	};

	const isSupportChecked = (): boolean => {
		if (data.menuConfig.showSupportItem) {
			return data.menuConfig.supportURL === '' || images.icon_support === '';
		} else {
			return data.menuConfig.showSupportItem;
		}
	};

	const isAboutChecked = (): boolean => {
		if (data.menuConfig.showAboutURLItem) {
			return data.menuConfig.aboutURL === '' || images.icon_about_us === '';
		} else {
			return data.menuConfig.showAboutURLItem;
		}
	};

	const isReviewsChecked = (): boolean => {
		if (data.menuConfig.showReviewsURLItem) {
			return data.menuConfig.reviewsURL !== undefined
				? data.menuConfig.reviewsURL === ''
				: true;
		} else {
			return data.menuConfig.showReviewsURLItem;
		}
	};

	const isXcWorldChecked = (): boolean => {
		if (data.menuConfig.showXcWorldURLItem) {
			return data.menuConfig.xcWorldURL === '' || images.icon_xcworld === '';
		} else {
			return data.menuConfig.showXcWorldURLItem;
		}
	};

	const disabledButton = useMemo((): boolean => {
		switch (data.homeId) {
			case 1:
			case 2:
				return (
					data.name === '' ||
					data.publishable_key_prod === '' ||
					data.publishable_key_qa === '' ||
					data.librariesConfig.sentryKeyAndroid === '' ||
					data.librariesConfig.sentryKeyiOS === '' ||
					data.librariesConfig.stripeKeyProd === '' ||
					data.librariesConfig.stripeKeyQa === '' ||
					gif.name === '' ||
					gif.base64 === '' ||
					images.icon_app_1 === '' ||
					images.icon_menu === '' ||
					images.icon_perfil === '' ||
					images.icon_estaciones === '' ||
					images.icon_escanear === '' ||
					images.icon_login === '' ||
					images.icon_point === '' ||
					images.icon_app_2 === '' ||
					images.icon_profile === '' ||
					isPrivacyChecked() ||
					isSupportChecked() ||
					isAboutChecked() ||
					isReviewsChecked() ||
					isXcWorldChecked()
				);
			case 3:
				return (
					data.name === '' ||
					data.publishable_key_prod === '' ||
					data.publishable_key_qa === '' ||
					data.librariesConfig.sentryKeyAndroid === '' ||
					data.librariesConfig.sentryKeyiOS === '' ||
					data.librariesConfig.stripeKeyProd === '' ||
					data.librariesConfig.stripeKeyQa === '' ||
					gif.name === '' ||
					gif.base64 === '' ||
					images.icon_app_1 === '' ||
					images.icon_menu === '' ||
					images.icon_login === '' ||
					images.icon_point === '' ||
					images.icon_app_2 === '' ||
					isPrivacyChecked() ||
					isSupportChecked() ||
					isAboutChecked() ||
					isReviewsChecked() ||
					isXcWorldChecked()
				);
			default:
				return true;
		}
	}, [data, images, gif]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		switch (name) {
			case 'name':
				setData({ ...data, name: value });
				break;
			case 'publishable_key_prod':
				setData({ ...data, publishable_key_prod: value });
				break;
			case 'publishable_key_qa':
				setData({ ...data, publishable_key_qa: value });
				break;
			case 'stripeAccountId':
				setData({ ...data, stripeAccountId: value });
				break;
			case 'sentryKeyAndroid':
			case 'sentryKeyiOS':
			case 'stripeKeyProd':
			case 'stripeKeyQa':
				setData({
					...data,
					librariesConfig: { ...data.librariesConfig, [name]: value },
				});
		}
	};

	const handleHomeIdChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		setData({ ...data, homeId: parseInt(value) });
	};

	const handlePartnerChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		setData({ ...data, partnerId: value });
	};

	const handleMenuConfigCheckboxChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { value, checked } = event.target;
		setData({
			...data,
			menuConfig: { ...data.menuConfig, [value]: checked },
		});
	};

	const handleMenuConfigInputChange = (
		event: React.ChangeEvent<HTMLInputElement>
	): void => {
		const { name, value } = event.target;
		setData({
			...data,
			menuConfig: { ...data.menuConfig, [name]: value },
		});
	};

	const handleGenerateZip = (): void => {
		const zip = new JSZip();
		const jsonData = JSON.stringify(data, null, 2);
		const base64Json = convertToBase64(jsonData);
		zip.file('app_settings.json', base64Json, { base64: true });
		zip.file(gif.name, gif.base64, { base64: true });

		//Iconos pistolas
		zip.file(`${iconNames.TYPE_1}.svg`, images.icon_type_1, { base64: true });
		zip.file(`${iconNames.TYPE_2}.svg`, images.icon_type_2, { base64: true });
		zip.file(`${iconNames.GBT}.svg`, images.icon_gbt, { base64: true });
		zip.file(`${iconNames.TESLA}.svg`, images.icon_tesla, { base64: true });
		zip.file(`${iconNames.CCS1}.svg`, images.icon_ccs1, { base64: true });
		zip.file(`${iconNames.CCS2}.svg`, images.icon_ccs2, { base64: true });
		zip.file(`${iconNames.GBT_DC}.svg`, images.icon_gbt_dc, { base64: true });

		zip.file(`${iconNames.ICON_APP}.svg`, images.icon_app_1, { base64: true });
		zip.file(`${iconNames.ICON_MENU}.svg`, images.icon_menu, { base64: true });
		zip.file(`${iconNames.ICON_LOGIN}.svg`, images.icon_login, { base64: true });
		zip.file(`${iconNames.ICON_POINT}.svg`, images.icon_point, { base64: true });
		zip.file(`${iconNames.ICON_APP_2}.svg`, images.icon_app_2, { base64: true });
		zip.file(`${iconNames.GHOST_SCAN}.svg`, images.icon_ghost_scan, { base64: true });
		zip.file(`${iconNames.LOGOUT}.svg`, images.icon_logout, { base64: true });

		//Zoom
		zip.file(`${iconNames.RATIO_SEARCH_ZI}.svg`, images.icon_ratio_search_zi, {
			base64: true,
		});
		zip.file(`${iconNames.RATIO_SEARCH_ZO}.svg`, images.icon_ratio_search_zo, {
			base64: true,
		});

		if (data.homeId === 1 || data.homeId === 2) {
			zip.file(`${iconNames.ICON_PROFILE}.svg`, images.icon_perfil, {
				base64: true,
			});
			zip.file(`${iconNames.ICON_STATIONS}.svg`, images.icon_estaciones, {
				base64: true,
			});
			zip.file(`${iconNames.ICON_SCAN}.svg`, images.icon_escanear, {
				base64: true,
			});
			zip.file(`${iconNames.ICON_PROFILE_2}.svg`, images.icon_profile, {
				base64: true,
			});
		}
		if (!isXcWorldChecked()) {
			zip.file(`${iconNames.ICON_XC}.svg`, images.icon_xcworld, { base64: true });
		}
		if (!isSupportChecked()) {
			zip.file(`${iconNames.ICON_SUPP}.svg`, images.icon_support, { base64: true });
		}
		if (!isAboutChecked()) {
			zip.file(`${iconNames.ICON_ABOUT}.svg`, images.icon_about_us, {
				base64: true,
			});
		}

		zip.generateAsync({ type: 'blob' }).then(function (content) {
			const url = URL.createObjectURL(content);

			const link = document.createElement('a');
			link.href = url;
			link.download = generateMobileAppName();
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			URL.revokeObjectURL(url);
		});
	};

	const generateMobileAppName = (): string => {
		switch (data.homeId) {
			case 1:
				return 'diamond-mobile-settings.zip';
			case 2:
				return 'square-mobile-settings.zip';
			case 3:
				return 'dial-mobile-settings.zip';
			default:
				return 'mobile-settings.zip';
		}
	};

	const convertToBase64 = (jsonData: string): string => {
		const base64String = btoa(jsonData);
		return base64String;
	};

	const handleGifChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setGif({ name: file.name, base64: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleColorsByName = (name: string, value: string) => {
		const newColors = data.colors;
		switch (name) {
			case 'primary':
				newColors.primary = value;
				break;
			case 'secondary':
				newColors.secondary = value;
				break;
			case 'accent':
				newColors.accent = value;
				break;
			case 'links':
				newColors.links = value;
		}
		setData({ ...data, colors: newColors });
	};

	const hanldeImages = (name: ImagesKeys, value: string): void => {
		setImages({ ...images, [name]: value });
	};

	const isSvgInputDisabled = (name: ImagesKeys): boolean => {
		switch (name) {
			case iconNames.ICON_SUPP:
				return !data.menuConfig.showSupportItem;
			case iconNames.ICON_XC:
				return !data.menuConfig.showXcWorldURLItem;
			case iconNames.ICON_ABOUT:
				return !data.menuConfig.showAboutURLItem;
			default:
				return false;
		}
	};

	return {
		data,
		disabledButton,
		gif,
		images,
		hanldeImages,
		handleHomeIdChange,
		handlePartnerChange,
		handleMenuConfigCheckboxChange,
		handleMenuConfigInputChange,
		handleSetData,
		handleGenerateZip,
		handleGifChange,
		handleColorsByName,
		setGif,
		isSvgInputDisabled,
	};
}
