export const ADMIN_TAB_VALUES = Object.freeze({
	HOME: 1,
	ASSOCIATES: 2,
	ASSOCIATES_GROUP: 3,
	STATIONS: 4,
	CHARGERS: 5,
	GUNS: 6,
	SCHEDULES: 7,
	USERS: 8,
});
