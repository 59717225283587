import CustomButton from './button.component';
import styles from './styles/associate-group-card-styles.component.module.css';
import { ReactComponent as UserLogo } from '../assets/svgs/users-alt.svg';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { hexToRGBA } from '../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';

interface IAssociateGroupCard {
	name: string;
	id?: string | number;
	associates: string | number;
	opacity?: number;
	action?: 'view' | 'edit';
}

const AssociateGroupCard = ({
	name,
	id,
	associates,
	opacity,
	action,
}: IAssociateGroupCard) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { permissions } = useSelector((state: RootState) => state.user);
	const navigate = useNavigate();

	const comparePermissions = (): boolean => {
		const addUserPermissionId = permissions.filter(
			(p) => p.permissionId === '40038c3c-6adc-4659-a6e0-4edc3a5405ec'
		)[0];

		if (addUserPermissionId !== undefined) return true;
		else return false;
	};

	return (
		<div
			className={styles.container}
			style={{
				backgroundColor: hexToRGBA(
					colors.secondary,
					opacity ? opacity * 0.2 : 0.2
				),
				border: `1px solid ${colors.secondary}`,
				width: action === 'edit' ? '100%' : '',
			}}
		>
			<div
				className={styles.imgContainer}
				style={{
					backgroundColor: hexToRGBA(colors.secondary, 0.35),
					color: colors.secondary,
				}}
			>
				<UserLogo width={20} height={20} />
			</div>
			<span className={styles.name}>{name}</span>
			<div className={styles.infoRow}>
				<div
					className={styles.infoElement}
					style={{ borderColor: colors.secondary }}
				>
					<span
						className={styles.infoNumber}
						style={{ color: colors.secondary }}
					>
						{associates}
					</span>
					<span className={styles.infoName}>{t('common.associates')}</span>
				</div>
			</div>
			{action && action === 'edit' && (
				<div>
					{comparePermissions() && (
						<CustomButton
							label={t('common.edit')}
							variant={'solid'}
							onClick={() =>
								navigate(`/partners/associate-group-edit/${id}`)
							}
							styles={{
								width: '111px',
								backgroundColor: 'black',
								fontSize: '14px',
								borderRadius: '4px',
							}}
						/>
					)}
				</div>
			)}
			{action === 'view' && (
				<div>
					<CustomButton
						label={t('common.view')}
						variant={'solid'}
						onClick={() =>
							navigate(`/partners/associate-group-details/${id}`)
						}
						styles={{
							width: '111px',
							backgroundColor: colors.secondary,
							fontSize: '14px',
							borderRadius: '4px',
						}}
					/>
				</div>
			)}
		</div>
	);
};

export default AssociateGroupCard;
