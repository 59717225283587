import { RootState } from '../../../store';
import { useDispatch, useSelector } from 'react-redux';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IGunsStatsPag } from '../../../interfaces/guns-stats.interface';
import { setLoading } from '../../../store/modules/loading';
import dayjs from 'dayjs';
import { financialAdapter } from '../adapters/financial.adapter';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { IFinancialParams } from '../interfaces/financial.interface';
import { useGetStations } from './useGetStations';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import { useNavigate } from 'react-router-dom';

export function useFinancial() {
	const [t] = useTranslation('global');
	const status: string[] = [t('financial.active'), t('financial.inactive')];
	const user = useSelector((state: RootState) => state.user);
	const { simulating, simulatedPartnerGuns } = useSelector(
		(state: RootState) => state.simulation
	);

	const {
		getAssociatesGroupPermission,
		getReportsPermission,
		getStationsPermission,
		getGunsPermission,
	} = useUserPermisions();

	//const guns = user.partnerGuns;
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const { data, setData, pagination, rowsPerPage } = useTableCustom<any>();

	const [guns, setGuns] = useState<string[]>([]);

	const { stationsOptions, gunsOptions, disabledReportButton } = useGetStations();

	const [dataQuery, setDataQuery] = useState<IFinancialParams>({
		gun_ids: [],
		station: '',
	});

	const path = window.location.href;
	const view = path
		.split('/')
		.filter((part) => part !== '')
		.pop();

	const {
		execute: getChargeFills,
		value: valueCode,
		status: statusCode,
	} = useBackendRequest<{}, IGunsStatsPag>({
		path: 'charge-fills/find',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		if (
			!getReportsPermission() &&
			!getStationsPermission() &&
			!getGunsPermission() &&
			getAssociatesGroupPermission()
		) {
			navigate('/financial/shared-order-report');
		}
		if (view === 'being') {
			getChargeFills({
				...dataQuery,
				status: true,
				gun_ids: getQueryGuns(),
				withPagination: true,
			});
		} else {
			getChargeFills({
				...dataQuery,
				gun_ids: getQueryGuns(),
				withPagination: true,
			});
		}
	}, [path]);

	const handleSetData = (event: any) => {
		const { name, value } = event.target;
		if (name === 'datetime_charging_start' || name === 'datetime_charging_end') {
			const date = dayjs(value.$d).toISOString().split('T')[0];
			setDataQuery({ ...dataQuery, [name]: date });
		} else if (name === 'status') {
			if (value === 'true') {
				setDataQuery({ ...dataQuery, status: true });
			} else {
				setDataQuery({ ...dataQuery, status: false });
			}
		} else {
			setDataQuery({ ...dataQuery, [name]: value });
		}
	};

	const handleSearch = () => {
		dispatch(setLoading({ loading: true }));
		let paramsSearch: IFinancialParams = {
			gun_ids: dataQuery.gun_ids,
		};
		if (view === 'being') {
			paramsSearch = {
				...dataQuery,
				status: true,
			};
		} else {
			paramsSearch = {
				...dataQuery,
			};
		}
		getChargeFills({
			...paramsSearch,
			gun_ids:
				paramsSearch.gun_ids.length > 0 ? paramsSearch.gun_ids : getQueryGuns(),
			order_number: paramsSearch.order_number
				? paramsSearch.order_number
				: undefined,
			station: paramsSearch.station
				? paramsSearch.station.split('-')[0]
				: undefined,
			withPagination: true,
		});
	};

	const hanldeClearFilters = () => {
		dispatch(setLoading({ loading: true }));
		setDataQuery({
			order_number: '',
			gun_ids: [],
			station: '',
			status: undefined,
		});
		getChargeFills({
			withPagination: true,
			gun_ids: getQueryGuns(),
		});
	};

	useEffect(() => {
		if (gunsOptions.length > 0) {
			const gunsNames: string[] = gunsOptions.map((g) => {
				return g.label;
			});
			setGuns(gunsNames);
		}
	}, [gunsOptions]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getChargeFills(
			{
				...dataQuery,
				datetime_charging_start: dataQuery.datetime_charging_start
					? dataQuery.datetime_charging_start
					: null,
				datetime_charging_end: dataQuery.datetime_charging_end
					? dataQuery.datetime_charging_end
					: null,
				gun_ids:
					dataQuery.gun_ids.length > 0 ? dataQuery.gun_ids : getQueryGuns(),
				status: view === 'being' ? true : dataQuery.status,
				withPagination: true,
			},
			`?page=${pagination}&pageSize=${rowsPerPage}`
		);
	}, [rowsPerPage, pagination]);

	useEffect(() => {
		if (statusCode === 'success') {
			if (valueCode?.data) {
				const financialData = valueCode?.data.chargeFills;
				setData({
					...data,
					countRows: valueCode?.data.totalElements,
					rows: financialAdapter(financialData, status),
				});
				dispatch(setLoading({ loading: false }));
			}
		} else if (statusCode === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.unavailable'),
					severity: 'error',
				})
			);
		}
	}, [statusCode]);

	const handleGunsChange = (guns: string[]): void => {
		setDataQuery({ ...dataQuery, gun_ids: guns });
	};

	const getQueryGuns = (): string[] => {
		const guns = simulating ? simulatedPartnerGuns : user.partnerGuns;
		return guns.length > 0 ? guns : ['NONE'];
	};

	useEffect(() => {
		setDataQuery({
			datetime_charging_end: null,
			datetime_charging_start: null,
			order_number: '',
			gun_ids: [],
			station: '',
			status: undefined,
		});
	}, [path]);

	return {
		view,
		dataQuery,
		stationsOptions,
		data,
		guns,
		gunsOptions,
		disabledReportButton,
		setData,
		handleSetData,
		handleSearch,
		hanldeClearFilters,
		handleGunsChange,
	};
}
