import {
	IAddAssociateGroup,
	IAssociateForGroup,
} from '../pages/add-associate-group/interfaces/associate-group.interface';

export const transformToDecimal = (data: string): string => {
	let newData: string = '';
	if (parseInt(data) >= 1.0) {
		newData = (parseInt(data) / 100).toString();
	} else {
		newData = data;
	}
	return newData;
};

const transformIds = (id: string): string => {
	return id.slice(2);
};

export const transformateDataToDecimal = (
	data: IAddAssociateGroup
): IAddAssociateGroup => {
	const newAssociatesArr = data.associates.map((associate) => {
		const newAssociate: IAssociateForGroup = {
			associateId: associate.associateId,
			unit: associate.unit,
			fraction: associate.unit
				? associate.fraction
				: parseFloat(transformToDecimal(associate.fraction.toString())),
		};
		return newAssociate;
	});
	const newGroupData: IAddAssociateGroup = {
		...data,
		iva: transformToDecimal(data.iva.toString()),
		paymentProcessorPercentaje: data.paymentProcessorPercentaje
			? transformToDecimal(data.paymentProcessorPercentaje.toString())
			: '0.0',
		paymentProcessorQty: data.paymentProcessorQty
			? data.paymentProcessorQty.toString()
			: '0.0',
		remainingOwnerId: transformIds(data.remainingOwnerId),
		firstInvoiceAssociateId: transformIds(data.firstInvoiceAssociateId),
		associates: newAssociatesArr,
	};
	return newGroupData;
};
