import { useState } from 'react';
import { Checkbox as CheckboxMui } from '@mui/material';

//style dependencies
import { StyledEngineProvider } from '@mui/material/styles';
import styles from './index.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface ICheckboxProps {
	label: string;
	value: string;
	checked?: boolean;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function Checkbox({ checked, label, value, onChange }: ICheckboxProps) {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<div>
			<StyledEngineProvider injectFirst>
				<CheckboxMui
					checked={checked}
					onChange={onChange}
					className={styles.checkbox}
					value={value}
					sx={{
						'&.Mui-checked': {
							color: colors.primary,
						},
					}}
				/>
				<label className={styles.label}>{label}</label>
			</StyledEngineProvider>
		</div>
	);
}
