import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

import CustomButton from '../../../../../components/button.component';
import StationCard from '../../../../../components/station-card.component';

import { Add } from '@mui/icons-material';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import { Card } from '@mui/material';
import { hexToRGBA } from '../../../../../utils/hex-to-rgba';
import ModalStop from '../../../../../components/modal-stop.component';
import { useTranslation } from 'react-i18next';
import { useStationsTab } from './hooks/useStationsTab';
import { useStopStation } from './hooks/useStopStation';

const AdminStationsTab = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const navigate = useNavigate();

	const {
		stationsData,
		openModal,
		selectedStation,
		setOpenModal,
		handleOpenModal,
		getStationsPermission,
	} = useStationsTab();

	const { handleGetStationGuns } = useStopStation({ setOpenModal });

	return (
		<>
			<div className={styles.container}>
				{getStationsPermission() && (
					<div
						style={{
							display: 'flex',
							justifyContent: 'end',
							width: '100%',
						}}
					>
						<CustomButton
							label={t('stations.new')}
							variant={'solid'}
							onClick={() => navigate('/stations/create-station')}
							styles={{
								width: 'fit-content',
								padding: '0 15px',
								display: 'flex',
								gap: '10px',
								backgroundColor: colors.accent,
								color: 'black',
							}}
							icon={Add}
						/>
					</div>
				)}
				<div className={styles.cardsContainer}>
					{stationsData.length > 0 ? (
						stationsData.map((station, index) => (
							<StationCard
								key={index}
								id={station.id!}
								name={station.name}
								address={station.address}
								state={station.state}
								country={station.country}
								zip={station.zip}
								onStopButtonClick={() => handleOpenModal(station)}
							/>
						))
					) : (
						<Card
							sx={{
								padding: 5,
								width: '100%',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								backgroundColor: hexToRGBA(colors.secondary, 0.2),
								border: `1px solid ${colors.secondary}`,
							}}
						>
							<h1
								style={{ justifySelf: 'center', color: colors.secondary }}
							>
								{t('stations.no-data')}
							</h1>
						</Card>
					)}
				</div>
			</div>
			<ModalStop
				open={openModal}
				setOpen={setOpenModal}
				acceptModal={() =>
					handleGetStationGuns(selectedStation.id!, selectedStation.name)
				}
				name={selectedStation.name}
				type="station"
			/>
		</>
	);
};

export default AdminStationsTab;
