export interface IStations {
	name: string | JSX.Element;
	location: string;
	chargers: string | number;
	guns: string | number;
	actions?: JSX.Element;
}
export const mockDataStations: IStations[] = [
	{
		name: 'Station 01',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 03',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 04',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 05',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 06',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
	{
		name: 'Station 07',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '20',
	},
];

export interface IStationsResponse {
	stationUniqueID: number;
	name: string;
	address: string;
	state: string;
	country: string;
	zip: number;
	longitude: number;
	latitude: number;
}
