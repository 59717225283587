import { DefaultTFuncReturn } from 'i18next';
import { ROLES } from '../../constants/user-roles.constants';
import { useAdminRoutes } from '../routes/useAdminRoutes';
import { useSuperAdminRoutes } from '../routes/useSuperAdminRoutes';

export interface ISubmenuItem {
	image?: JSX.Element | JSX.Element[];
	title: string | DefaultTFuncReturn;
	description?: string | DefaultTFuncReturn;
	link: string;
	Component?: () => JSX.Element;
	hideMenu?: boolean;
	available?: boolean;
}

export interface IMenuList {
	icon?: JSX.Element | JSX.Element[];
	title?: string | DefaultTFuncReturn;
	submenu?: ISubmenuItem[];
	link: string;
	Component?: () => JSX.Element;
	noProtected?: boolean;
	hideMenu?: boolean;
	available?: boolean;
}

export const filterAvailableMenuItems = (menuList: IMenuList[]): IMenuList[] => {
	return menuList
		.filter((menu) => menu.available)
		.map((menu) => {
			if (menu.submenu) {
				const availableSubmenu = menu.submenu.filter(
					(submenuItem) => submenuItem.available
				);
				return { ...menu, submenu: availableSubmenu };
			}
			return menu;
		});
};

export function useRouteList() {
	const { FilteredAdminRouteList } = useAdminRoutes();
	const { SuperAdminRouteList } = useSuperAdminRoutes();

	const getRouteList = (role: string): IMenuList[] => {
		switch (role) {
			default:
			case ROLES.ADMIN:
				return FilteredAdminRouteList;
			case ROLES.SUPER_ADMIN:
				return SuperAdminRouteList;
		}
	};

	return { getRouteList };
}
