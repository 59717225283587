import { useContext, useState } from 'react';
import { GenerateReportContext } from '../../context/GenerateReportContext';

export function useColumnSelector() {
	const { columns, selectedColumns, setColumns, setSelectedColumns } =
		useContext(GenerateReportContext);

	const handleItemClick = (itemId: string): void => {
		if (columns.find((c) => c.id === itemId)) {
			const newColums = columns.filter((c) => c.id !== itemId);
			setColumns(newColums);
			const extractedColumn = columns.find((c) => c.id === itemId);
			const newSelectedColums = selectedColumns;
			newSelectedColums.push(extractedColumn!);
			setSelectedColumns(newSelectedColums);
		} else {
			const newSelectedColums = selectedColumns.filter((c) => c.id !== itemId);
			setSelectedColumns(newSelectedColums);
			const extractedColumn = selectedColumns.find((c) => c.id === itemId);
			const newColumns = columns;
			newColumns.push(extractedColumn!);
			setColumns(newColumns);
		}
	};

	const handleSelectAllColumns = (): void => {
		const newSelectedColums = selectedColumns;
		const arr = newSelectedColums.concat(columns);
		setSelectedColumns(arr);
		setColumns([]);
	};

	const handleDeleteAllColumns = (): void => {
		const newColums = columns;
		const arr = newColums.concat(selectedColumns);
		setColumns(arr);
		setSelectedColumns([]);
	};

	return {
		columns,
		selectedColumns,
		handleItemClick,
		handleDeleteAllColumns,
		handleSelectAllColumns,
	};
}
