/* eslint-disable prettier/prettier */
import { Grid } from '@mui/material';
import { Checkbox } from '../../components/Checkbox';
import { useLocation, useNavigate } from 'react-router-dom';

import Input from '../../components/input.component';
import CustomButton from '../../components/button.component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AssociateCard from './components/associate-card.component';
import AddAssociateButton from './components/add-associate-button.component';
import DateInput from '../../components/DateInput';
import TimeInput from '../../components/TimeInput';

import styles from './styles/index.module.css';

import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import GunCard from './components/gun-card.component';
import { weekDays } from './utils/utils';
import { useTranslation } from 'react-i18next';
import GunsModal from './components/guns-modal.component';
import GroupModal from './components/group-modal.component';
import { useCreateSchedule } from './hooks/useCreateSchedule';

const dayjs = require('dayjs');

export default function AddSchedules() {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const navigate = useNavigate();

	const { state } = useLocation();

	const {
		id,
		newScheduleData,
		openAGModal,
		openGunsModal,
		selectedGroup,
		tempGroup,
		selectedGuns,
		tempGuns,
		associateGroupData,
		disabledButton,
		modalGuns,
		getChargerName,
		setnewScheduleData,
		handleSetData,
		handleWeekDays,
		handleRemoveCardAssociateGroup,
		setOpenAGModal,
		editSchedule,
		handleSaveSchedule,
		handleSelectCard,
		handleCancelModal,
		handleAcceptModal,
		handleSelectGun,
		handleAcceptGunsModal,
		handleCancelGunsModal,
		handleRemoveSelectedGun,
		handleOpenGunsModal,
		handleAllDayCheck,
		handleTimeChange,
	} = useCreateSchedule();

	return (
		<>
			<CustomButton
				variant="solid"
				label={t('common.back')}
				icon={ArrowBackIcon}
				onClick={
					state.prevUrl === '/schedules'
						? () => navigate(-1)
						: () =>
								navigate('/partners/details', {
									state: { tab: ADMIN_TAB_VALUES.SCHEDULES },
								})
				}
				styles={{
					backgroundColor: 'var(--color-black-main)',
					width: 'fit-content',
					gap: '10px',
					padding: '0 15px',
				}}
			/>
			<div className={styles.boxPaper}>
				<div className={styles.titleContainer}>
					<span className={styles.title}>{t('add-schedule.title')}</span>
				</div>
				<hr className={styles.customHr} />
				<div className={styles.container}>
					<Grid
						container
						item
						xs={12}
						spacing={3}
						paddingRight={2}
						paddingLeft={2}
						mb={4}
					>
						<Grid item xs={12} md={6}>
							<Input
								label={t('add-schedule.name').toString()}
								name="name"
								placeholder="Required"
								type="text"
								required
								value={newScheduleData.name}
								onChange={handleSetData}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<Input
								label={t('add-schedule.weight').toString()}
								type="text"
								name="weight"
								placeholder="Required"
								required
								value={newScheduleData.weight}
								onChange={handleSetData}
								tooltip={t('add-schedule.weight-desc').toString()}
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<Checkbox
								label={t('add-schedule.no-date')}
								value="withoutDate"
								checked={!newScheduleData.limitedDays}
								onChange={() =>
									setnewScheduleData({
										...newScheduleData,
										limitedDays: !newScheduleData.limitedDays,
										startDate: null,
										endDate: null,
									})
								}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<DateInput
								label={t('add-schedule.start')}
								name="startDate"
								value={dayjs(newScheduleData.startDate) || null}
								onChange={handleSetData}
								disabled={!newScheduleData.limitedDays}
								required
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<DateInput
								label={t('add-schedule.end')}
								name="endDate"
								value={dayjs(newScheduleData.endDate) || null}
								onChange={handleSetData}
								disabled={!newScheduleData.limitedDays}
								required
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<div className={styles.days}>
								{weekDays.map((day) => (
									<Checkbox
										key={`day-${day.value}`}
										label={t(`days.${day.value}`)}
										value={day.value}
										onChange={handleWeekDays}
										checked={newScheduleData.days.includes(day.value)}
									/>
								))}
							</div>
						</Grid>
						<Grid item xs={12} md={12}>
							<span className={styles.title}>
								{t('add-schedule.schedule')}
							</span>
						</Grid>
						<Grid item xs={12} md={12}>
							<Checkbox
								label={t('add-schedule.all-day')}
								value="rangeTime"
								checked={newScheduleData.allDay}
								onChange={handleAllDayCheck}
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TimeInput
								label={t('add-schedule.start-t')}
								name="startTime"
								value={newScheduleData.startTime!}
								onChange={handleTimeChange}
								disabled={newScheduleData.allDay}
								required
							/>
						</Grid>
						<Grid item xs={12} md={6}>
							<TimeInput
								label={t('add-schedule.end-t')}
								name="endTime"
								value={newScheduleData.endTime!}
								onChange={handleTimeChange}
								disabled={newScheduleData.allDay}
								required
							/>
						</Grid>
						<Grid item xs={12} md={12}>
							<span className={styles.title}>{t('common.groups')}</span>
							<br />
							<span>{t('add-schedule.groups-desc')}</span>
							<div className={styles.associatesCardsContainer}>
								{newScheduleData.associateGroupId !== 0 && (
									<AssociateCard
										id={selectedGroup.id}
										name={selectedGroup.group}
										address="Robert Robertson, 1234 NW Bobcat Lane, St. Robert"
										clickable={false}
										onRemove={handleRemoveCardAssociateGroup}
										showCostFactor={true}
										costFactor={{
											chargeCost:
												selectedGroup.chargeCost as string,
											chargeFactor:
												selectedGroup.chargeFactor as string,
											parkingCost:
												selectedGroup.parkingCost as string,
											parkingFactor:
												selectedGroup.parkingFactor as string,
										}}
									/>
								)}
								{newScheduleData.associateGroupId === 0 && (
									<AddAssociateButton
										onClick={() => setOpenAGModal(true)}
										text={t('add-schedule.add-group')}
									/>
								)}
							</div>
						</Grid>
						<Grid item xs={12} md={12}>
							<span className={styles.title}>{t('common.guns')}</span>
							<br />
							<span>{t('add-schedule.guns-desc')}</span>
							<div className={styles.gunsCardContainer}>
								{selectedGuns.map((gun) => (
									<GunCard
										key={gun.id!}
										id={gun.qionGunUniqueId}
										name={gun.name}
										clickable={false}
										chargerName={getChargerName(gun.chargerId)}
										location={`${gun.address}, ${gun.state}, ${gun.country}. ${gun.zip}.`}
										onRemove={handleRemoveSelectedGun}
									/>
								))}
								<AddAssociateButton
									onClick={handleOpenGunsModal}
									text={t('add-schedule.add-gun')}
								/>
							</div>
						</Grid>
					</Grid>
				</div>

				<div
					className={styles.separator}
					style={{ marginTop: '28px', marginBottom: '22px' }}
				/>
				<hr className={styles.customHr} />
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant="solid"
						styles={{ backgroundColor: 'var(--color-black-main)' }}
						onClick={
							state.prevUrl === '/schedules'
								? () => navigate(-1)
								: () =>
										navigate('/partners/details', {
											state: { tab: ADMIN_TAB_VALUES.SCHEDULES },
										})
						}
					/>
					<CustomButton
						label={t('common.save')}
						variant="solid"
						disabled={disabledButton}
						styles={{ color: 'black', backgroundColor: colors.accent }}
						onClick={id ? editSchedule : handleSaveSchedule}
					/>
				</div>
			</div>
			<GroupModal
				data={associateGroupData}
				openModal={openAGModal}
				tempGroupId={tempGroup.id}
				disabledButton={tempGroup?.id! === 0 ? true : false}
				onCardClick={handleSelectCard}
				onAccept={handleAcceptModal}
				onCancel={handleCancelModal}
			/>
			<GunsModal
				data={modalGuns}
				openModal={openGunsModal}
				buttonDisabled={tempGuns.length < 1 ? true : false}
				onCardClick={handleSelectGun}
				getChargerName={getChargerName}
				onAccept={handleAcceptGunsModal}
				onCancel={handleCancelGunsModal}
			/>
		</>
	);
}
