import { IColumns } from '../../../hooks/useTableCustom';

export const chargerColumns: IColumns[] = [
	{
		text: 'Chargers',
		nameID: 'chargers',
	},
	{
		text: 'Station',
		nameID: 'station',
	},
	{
		text: 'Location',
		nameID: 'location',
	},
	{
		text: 'Guns',
		nameID: 'guns',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
