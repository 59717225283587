import { IRevenueDashboardResponse } from '../interfaces/revenue-dashboard.interface';

export const revenueAdapter = (
	data: IRevenueDashboardResponse[],
	months: string[]
): any[] => {
	const MONTH_NAMES: { [key: number]: string } = {
		1: months[0],
		2: months[1],
		3: months[2],
		4: months[3],
		5: months[4],
		6: months[5],
		7: months[6],
		8: months[7],
		9: months[8],
		10: months[9],
		11: months[10],
		12: months[11],
	};

	const newData: any[] = data.map((item: IRevenueDashboardResponse) => {
		return {
			...item,
			month: <>{MONTH_NAMES[item.month]}</>,
			totalKw: <>{item.totalKw.toFixed(2)}</>,
			totalRevenue: <>{item.totalRevenue.toFixed(2)}</>,
		};
	});
	return newData;
};
