import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../hooks/useTableCustom';

export function useAssociatesTabColums() {
	const [t] = useTranslation('global');

	const associatesTabColumns: IColumns[] = [
		{
			text: 'Name',
			nameID: 'nameAssociate',
		},
		{
			text: 'RFC',
			nameID: 'rfc',
		},
		{
			text: 'Total revenue',
			nameID: 'total',
		},
	];

	return { associatesTabColumns };
}
