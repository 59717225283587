import { IColumns } from '../../../hooks/useTableCustom';

export const associateColumns: IColumns[] = [
	{
		text: 'Name',
		nameID: 'name',
	},
	{
		text: 'Mail',
		nameID: 'email',
	},
	{
		text: 'Stations',
		nameID: 'stations',
	},
	{
		text: 'Charger',
		nameID: 'chargers',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
