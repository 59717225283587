import { useTranslation } from 'react-i18next';
import { IRevenue } from '../../../../associate-group-details/components/interfaces/revenue-tab.interface';
import { IGroupRevenue } from '../interfaces/shared-report.interface';

export function useGroupsTabAdapter() {
	const [t] = useTranslation('global');

	const getAssociateValues = (associate: IRevenue): JSX.Element => {
		if (!associate) {
			return <>-</>;
		} else {
			return associate.name !== 'iva' ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					{associate.isRemainingOwner && (
						<span style={{ textDecoration: 'underline' }}>
							<b>{t('shared-report.owner')}</b>
						</span>
					)}
					<span>
						<b>{t('shared-report.name')}: </b>
						{associate.name ? associate.name : '-'}
					</span>
					<span>
						<b>{t('shared-report.revenue')}: </b>
						{parseFloat(associate.revenue).toFixed(2)}
					</span>
					{!associate.isFirstInvoice && (
						<span>
							<b>
								{associate.unit
									? `${t('shared-report.unit')}: `
									: `${t('shared-report.fraction')}: `}
							</b>
							{`${associate.fraction ? associate.fraction : '-'} ${
								associate.unit ? '' : '%'
							}`}
						</span>
					)}
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<span>
						<b>{t('shared-report.vat-reserve')}: </b>
						{associate.fraction}%
					</span>
					<span>
						<b>{t('shared-report.vat-total')}: </b>
						{associate.revenue}
					</span>
				</div>
			);
		}
	};

	const groupsTabAdapter = (data: IGroupRevenue[]): any[] => {
		const newData: any[] = data.map((item: IGroupRevenue) => {
			return {
				...item,
				firstInvoice: (
					<>{item.revenues ? getAssociateValues(item.revenues[0]) : '-'}</>
				),
				vat: <>{item.revenues ? getAssociateValues(item.revenues[1]) : '-'}</>,
				associate1: (
					<>{item.revenues ? getAssociateValues(item.revenues[2]) : '-'}</>
				),
				associate2: (
					<>{item.revenues ? getAssociateValues(item.revenues[3]) : '-'}</>
				),
				associate3: (
					<>{item.revenues ? getAssociateValues(item.revenues[4]) : '-'}</>
				),
				associate4: (
					<>{item.revenues ? getAssociateValues(item.revenues[5]) : '-'}</>
				),
				associate5: (
					<>{item.revenues ? getAssociateValues(item.revenues[6]) : '-'}</>
				),
			};
		});
		return newData;
	};

	return { groupsTabAdapter };
}
