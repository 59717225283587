import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../hooks/useTableCustom';

export function useAssociatesTabColumns() {
	const [t] = useTranslation('global');

	const associatesTabColumns: IColumns[] = [
		{
			text: t('common.name'),
			nameID: 'name',
		},
		{
			text: t('common.tax-id'),
			nameID: 'taxId',
		},
		{
			text: t('common.address'),
			nameID: 'address',
		},
		{
			text: t('groups.share-type'),
			nameID: 'share',
		},
		{
			text: t('groups.value'),
			nameID: 'value',
		},
	];

	return { associatesTabColumns };
}
