import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Label } from '../label.component';

import style from './style.module.css';

export default function DateInput({
	label,
	value,
	onChange,
	disabled,
	name,
	required,
}: any) {
	return (
		<div style={{ width: '100%' }}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<DemoContainer components={['DatePicker']}>
					<div style={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
						<Label
							label={label}
							name={name}
							required={required}
							disabled={disabled}
						/>
						<DatePicker
							value={value}
							onChange={(newValue: string | null) =>
								// eslint-disable-next-line no-restricted-globals
								onChange({ target: { name: name, value: newValue } })
							}
							className={style.input}
							disabled={disabled}
							format="DD-MM-YYYY"
						/>
					</div>
				</DemoContainer>
			</LocalizationProvider>
		</div>
	);
}
