import { useLocation, useNavigate } from 'react-router-dom';

import styles from './styles/add-user.styles.module.css';
import { Grid, Paper } from '@mui/material';
import CustomButton from '../../components/button.component';
import Input from '../../components/input.component';
import { useAddUser } from './hooks/useAddUser';
import { newUserForm } from './utils/utils';
import { useTranslation } from 'react-i18next';
import PasswordComponent from '../../components/password.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';

export const AddUser = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);

	const { state } = useLocation();

	const { addUserData, disabledButton, handleSetData, handleAddUser, getInputLabel } =
		useAddUser();

	return (
		<Paper elevation={1} className={styles.boxPaper}>
			<Grid container display={'flex'} flexDirection={'column'}>
				<Grid item className={styles.titleContainer}>
					<span>{t('users.new-user')}</span>
				</Grid>
				<div
					className={styles.separator}
					style={{ marginTop: '24px', marginBottom: '33px' }}
				/>
				<Grid container className={styles.inputsContainer} spacing={3}>
					{newUserForm.map((input) => (
						<Grid item xl={6} sm={12} md={12} key={input.name}>
							<Input
								label={getInputLabel(input.name)}
								id={input.name}
								name={input.name}
								placeholder="Required"
								type={'text'}
								required={input.required}
								onChange={handleSetData}
							/>
						</Grid>
					))}
					<Grid item xl={6} sm={12} md={12}>
						<Input
							label={getInputLabel('login_pwd')}
							id={'login_pwd'}
							name={'login_pwd'}
							placeholder="Required"
							type={'password'}
							required
							onChange={handleSetData}
						/>
						<PasswordComponent password={addUserData.login_pwd} />
					</Grid>
				</Grid>
				<div
					className={styles.separator}
					style={{ marginTop: '28px', marginBottom: '22px' }}
				/>
				<Grid item className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={
							state.prevUrl === '/users'
								? () => navigate(-1)
								: () =>
										navigate('/partners/details', {
											state: { tab: ADMIN_TAB_VALUES.USERS },
										})
						}
						styles={{
							background: 'black',
							width: '124px',
							gap: '8px',
						}}
					/>
					<CustomButton
						label={t('common.save')}
						variant={'solid'}
						onClick={handleAddUser}
						disabled={disabledButton}
						styles={{
							width: '124px',
							gap: '8px',
							color: 'black',
							backgroundColor: colors.accent,
						}}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};
