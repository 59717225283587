import { Delete } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { RadioButton } from '../../../components/radio-button.component';
import AssociateCard from './associate-card.component';
import { useTranslation } from 'react-i18next';
import Input from '../../../components/input.component';
import { FormControlLabel, Radio, RadioGroup, useMediaQuery } from '@mui/material';
import theme from '../../../config/theme';

import styles from './styles/selected-associate-row.component.module.css';

interface ISelectedAsociateRow {
	index: number;
	id: string;
	name: string;
	rfc: string;
	address: string;
	costOwner: boolean;
	remanentOwner: boolean;
	radioFractionChecked: boolean;
	inputPlaceholder: string;
	inputLabel: string;
	valueInput: number | string;
	handleRemoveCard: (index: number) => void;
	handleFirstInvoiceChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hanldeRemaingOwnerChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	handleFactorUnitChange: (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
	handleQuantityChange: (
		id: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => void;
}

const SelectedAsociateRow = ({
	index,
	id,
	name,
	rfc,
	address,
	costOwner,
	remanentOwner = false,
	radioFractionChecked,
	inputPlaceholder,
	inputLabel,
	valueInput,
	handleRemoveCard,
	handleFirstInvoiceChange,
	hanldeRemaingOwnerChange,
	handleFactorUnitChange,
	handleQuantityChange,
}: ISelectedAsociateRow) => {
	const [t] = useTranslation('global');
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<div key={id} className={styles.selectedAssociatesRow}>
			<AssociateCard
				id={id!}
				name={name}
				rfc={rfc}
				address={address}
				clickable={false}
				showRemoveIcon={false}
			/>
			<div className={styles.selectedAssociatesForm}>
				<RadioButton
					name="firstInvoice"
					id={`associate-${id}`}
					value={`${index}-${id}`}
					checked={costOwner}
					label={t('groups.invoice')}
					onClick={(e) => handleFirstInvoiceChange(e)}
				/>
				<RadioButton
					name="remainingOwnwe"
					id={`remaining-associate-${id}`}
					value={`${index}-${id}`}
					checked={remanentOwner}
					label={t('groups.remaining')}
					onClick={(e) => hanldeRemaingOwnerChange(e)}
				/>
				<div className={styles.optionsContainer}>
					<RadioGroup
						name="ElNameQueTuQuieras"
						value={radioFractionChecked ? 'true' : 'false'}
						onChange={(e) => handleFactorUnitChange(index, e)}
					>
						<div className={styles.optionRow}>
							<FormControlLabel
								value={'false'}
								control={
									<Radio
										style={{ color: '#2BA8E0', borderColor: 'black' }}
									/>
								}
								label={t('groups.fraction').toString()}
							/>
							<Input
								id={`fraction-id${id}`}
								name="fraction"
								label={inputLabel}
								placeholder={inputPlaceholder}
								type="text"
								required
								onChange={(e) => handleQuantityChange(index, e)}
								value={valueInput}
								containerStyles={{
									width: isMobile ? '100%' : '35%',
								}}
							/>
						</div>
						<div className={styles.optionRow}>
							<FormControlLabel
								value={'true'}
								control={<Radio style={{ color: '#2BA8E0' }} />}
								label={t('groups.unit').toString()}
							/>
						</div>
					</RadioGroup>
				</div>
				<ButtonIconSmall
					type="error"
					icon={<Delete />}
					hoverLabel={t('groups.remove')}
					onPress={() => handleRemoveCard(index)}
				/>
			</div>
		</div>
	);
};

export default SelectedAsociateRow;
