// React dependencies
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// Third-party libraries
import { Paper, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
// Style dependencies
import styles from './styles/partners.module.css';
// Resources
import TableCustom from '../../components/table-custom.component';
import { useTableCustom } from '../../hooks/useTableCustom';
import CustomButton from '../../components/button.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ROLES } from '../../constants/user-roles.constants';
import { editSimulation } from '../../store/modules/partner-simulation';
import { setLoading } from '../../store/modules/loading';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import { setAlert } from '../../store/modules/snackbar';
import CustomModal from '../../components/modal.component';
import { IPartnerInfo, defaultPartnerInfo } from '../../interfaces/partner';
import { editPartner } from '../../store/modules/partner';
import { useTranslation } from 'react-i18next';
import { usePartnersColums } from './utils/partners-table.columns';
import { usePartnersApter } from './adapters/partners.adapter';
import { setDefaultValues } from '../../store/modules/default-values';
import { editColors } from '../../store/modules/colors';
import { QION_ID } from '../../constants/qion-id.constants';
import { IGun } from '../../interfaces/gun.interface';

const Partners = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { data, setData } = useTableCustom<any>();

	const { partnersColumns } = usePartnersColums();
	const { partnersAdapter } = usePartnersApter();

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedPartner, setSelectedPartner] =
		useState<IPartnerInfo>(defaultPartnerInfo);

	const {
		execute: getPartners,
		value: getPartnersVal,
		status: getPartnersStatus,
	} = useBackendRequest<any, IPartnerInfo[]>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getPartnersStatus === 'success') {
			if (getPartnersVal?.data) {
				const partners = getPartnersVal?.data?.filter((p) => p.id !== QION_ID);
				setData({
					...data,
					countRows: 0,
					rows: partnersAdapter(
						partners,
						handleStartPartnerSimulation,
						handleOpenModal
					),
				});
			}
		} else if (getPartnersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnersStatus]);

	const {
		execute: getDefaultValues,
		value: valueDefaultValues,
		status: statusDefaultValues,
	} = useBackendRequest<any, any>({
		path: 'partners/partner/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (statusDefaultValues === 'success') {
			dispatch(setDefaultValues(valueDefaultValues?.data[0]));
			if (valueDefaultValues?.data[0].themes.length > 0) {
				setPartnerTheme(
					valueDefaultValues?.data[0].themes[0].id,
					valueDefaultValues?.data[0].themes[0].name,
					valueDefaultValues?.data[0].themes[0].colors.primary,
					valueDefaultValues?.data[0].themes[0].colors.secondary,
					valueDefaultValues?.data[0].themes[0].colors.accent
				);
			}
			getGuns();
		} else if (statusDefaultValues === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('default-values.error-default-val'),
					severity: 'error',
				})
			);
		}
	}, [statusDefaultValues]);

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const partnerGuns = getGunsVal.data.filter((g) => g.partnerInfoId === id);
				const qionIds = partnerGuns.map((g) => g.qionGunUniqueId);
				dispatch(
					editSimulation({ simulating: true, simulatedPartnerGuns: qionIds })
				);
				dispatch(setLoading({ loading: false }));
				navigate('/dashboard');
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const setPartnerTheme = (
		id: string,
		name: string,
		primary: string,
		secondary: string,
		accent: string
	): void => {
		dispatch(
			editColors({
				id: id,
				name: name,
				primary: primary,
				secondary: secondary,
				accent: accent,
			})
		);
	};

	const handleStartPartnerSimulation = (partner: IPartnerInfo) => {
		dispatch(setLoading({ loading: true }));
		dispatch(editSimulation({ simulating: true }));
		dispatch(editPartner(partner));
		getDefaultValues({}, partner.id);
	};

	const handleOpenModal = (partner: IPartnerInfo): void => {
		setSelectedPartner(partner);
		setOpenModal(true);
	};

	const { execute: deletePartner, status: deletePartnerStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'partners-information/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deletePartnerStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.deleted'),
					severity: 'success',
				})
			);
			setOpenModal(false);
			getPartners();
		} else if (deletePartnerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deletePartnerStatus]);

	const handleDeletePartner = (): void => {
		dispatch(setLoading({ loading: true }));
		deletePartner({}, selectedPartner.id);
	};

	useEffect(() => {
		if (role.roleName === ROLES.ADMIN || simulating) {
			navigate('/partners/details');
			return;
		}
		dispatch(setLoading({ loading: true }));
		getPartners();
	}, []);

	return (
		<>
			<Grid container display="flex" justifyContent="flex-end">
				<div
					style={{
						display: 'flex',
						justifyContent: 'flex-end',
						marginBottom: '20px',
					}}
				>
					<CustomButton
						label={t('partners.new')}
						icon={AddIcon}
						onClick={() => navigate('/partners/add-partner')}
						styles={{
							backgroundColor: 'var(--color-black-main)',
							width: 'fit-content',
							padding: '0 15px',
							gap: 10,
							justifySelf: 'end',
						}}
						variant="solid"
					/>
				</div>
				<Grid item xs={12}>
					<Paper className={styles.boxPaper} elevation={1}>
						<TableCustom
							columns={partnersColumns}
							data={data}
							setData={setData}
							titleHeader={t('partners.partners')}
							showTotalCount={false}
							hideChips
						/>
					</Paper>
				</Grid>
			</Grid>
			<CustomModal open={openModal} maxWidth="md" fullWidth title="Delete partner">
				<div>
					<span>
						{t('common.delete-txt')} <b>{selectedPartner.name}</b>{' '}
						{t('common.permanently')}?
					</span>
					<div
						style={{
							display: 'flex',
							gap: 20,
							justifyContent: 'center',
							marginTop: 25,
						}}
					>
						<CustomButton
							variant="solid"
							label={t('common.cancel')}
							styles={{
								backgroundColor: 'black',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={() => setOpenModal(false)}
						/>
						<CustomButton
							variant="solid"
							label={t('common.delete')}
							styles={{
								backgroundColor: '#DD0528',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={handleDeletePartner}
						/>
					</div>
				</div>
			</CustomModal>
		</>
	);
};
export default Partners;
