/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setLoading } from '../store/modules/loading';
import { setAlert } from '../store/modules/snackbar';

export interface IMainResponse<I> {
	data: I | null;
}

interface IRequestPayload {
	path: string;
	headers?: {
		[key: string]: string;
	};
	baseURL?: string;
	port?: string;
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
}

export const useBackendRequest = <ParamsType, ResponseType>(
	{
		port,
		path,
		headers,
		baseURL = process.env.REACT_APP_API_URL,
		method,
	}: IRequestPayload,
	immediate = false
) => {
	const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
	const [value, setValue] = useState<IMainResponse<ResponseType> | null>(null);
	const dispatch = useDispatch();

	const getTokenAuth = (): string => {
		const data = localStorage.getItem('accessToken')!;
		const token = data
			? data
			: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjM0NGVkMGVlLTkyNzktNDE5Zi1iOTczLWJkODA2N2Y1YzBhNyIsImVtYWlsIjoiYWRtaW54Y3BAeW9wbWFpbC5jb20iLCJpYXQiOjE2ODYwNjY3MDMsImV4cCI6MTY4NjY3MTUwM30.Q8w1yGAUIW2QLjBmcFbpTcS7jolN7a5Mx8LU_p9aAVE';
		if (token) {
			return `Bearer ${token}`;
		}
		return '';
	};

	const execute = useCallback(async (params?: ParamsType, pathPag?: string) => {
		setStatus('pending');
		setValue(null);
		const token = getTokenAuth();
		if (token) {
			headers = {
				...headers,
				Authorization: token,
			};
		}

		try {
			//const key = await getKey();
			const pathFlag = pathPag ? path + pathPag : path;
			const url =
				process.env.NODE_ENV === 'development'
					? port
						? `http://localhost:${port}/api/v1/${path}`
						: `${baseURL}/${pathFlag}`
					: `${baseURL}/${pathFlag}`;

			const response = await axios({
				url: url,
				method: method ? method : 'POST',
				headers: {
					'Content-Type': 'application/json',
					...headers,
				},
				data: {
					...params,
				},
			});
			setValue(response);
			setStatus('success');
		} catch (error: any) {
			if (!error.response.status) {
				setStatus('error');
				return;
			}
			if (401 === error.response.status) {
				setStatus('error');
				if (error?.response.data.message === 'Unauthorized') {
					dispatch(
						setAlert({
							show: true,
							message: 'Session expired.',
							severity: 'error',
						})
					);
					dispatch(setLoading({ loading: false }));
					localStorage.removeItem('accessToken');
					localStorage.removeItem('validToken');
					localStorage.removeItem('persist:root');
					window.location.reload();
					return;
				}
				setValue(error?.response);
				return;
			} else if (409 === error.response.status) {
				setValue(error?.response);
				setStatus('error');
				return;
			} else if (error.response.status !== 200 || error.response.status !== 204) {
				setValue(error?.response?.data);
			} else if (error?.response?.data) {
				setValue(error?.response?.data);
			}
			setStatus('error');
			dispatch(setLoading({ loading: false }));
		}
	}, []);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, status, value };
};
