import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../../store/modules/loading';
import { RootState } from '../../../../../store';
import { ITheme } from '../../settings-theme.component';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useUpdateUserTheme } from './useUpdateUserTheme';

export function useUpdatePartnerThemes() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const defaultValues = useSelector((state: RootState) => state.defaultValues);
	const defaultValuesId = defaultValues.id;

	const { updateDefaultTheme } = useUpdateUserTheme();

	const [selectedTheme, setSelectedTheme] = useState<ITheme>();
	const [themes, setThemes] = useState<ITheme[]>([]);

	const [newTheme, setNewTheme] = useState<ITheme>({
		partnerId: defaultValuesId!,
		name: '',
		colors: {
			primary: '#000000',
			secondary: '#000000',
			accent: '#000000',
		},
	});

	const [themeToEdit, setThemeToEdit] = useState<ITheme>({
		partnerId: '',
		name: '',
		colors: {
			primary: '#000000',
			secondary: '#000000',
			accent: '#000000',
		},
	});

	const [openModal, setOpenModal] = useState<boolean>(false);

	const {
		execute: getThemes,
		value: getThemesVal,
		status: getThemesStatus,
	} = useBackendRequest<any, ITheme[]>({
		path: 'themes/find/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: createTheme,
		value: createThemeVal,
		status: createThemeStatus,
	} = useBackendRequest<ITheme, any>({
		path: 'themes/create',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const { execute: deleteTheme, status: deleteThemeStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'themes/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (createThemeStatus === 'success') {
			if (createThemeVal?.data) {
				dispatch(
					setAlert({
						show: true,
						message: t('themes.updated'),
						severity: 'success',
					})
				);
				setNewTheme({
					partnerId: defaultValuesId!,
					name: '',
					colors: {
						primary: '#000000',
						secondary: '#000000',
						accent: '#000000',
					},
				});
				handleGetThemes();
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('themes.updated-error'),
						severity: 'error',
					})
				);
			}
		} else if (createThemeStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [createThemeStatus]);

	useEffect(() => {
		if (deleteThemeStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated'),
					severity: 'success',
				})
			);
			setOpenModal(false);
			handleGetThemes();
		} else if (deleteThemeStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [deleteThemeStatus]);

	const handleSaveColors = () => {
		dispatch(setLoading({ loading: true }));
		createTheme(newTheme);
	};

	const handleColors = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		const newColors = newTheme.colors;
		switch (name) {
			case 'primary':
				newColors.primary = value;
				break;
			case 'secondary':
				newColors.secondary = value;
				break;
			case 'accent':
				newColors.accent = value;
				break;
		}
		setNewTheme({ ...newTheme, colors: newColors });
	};

	const handleDeleteTheme = (id: string) => {
		dispatch(setLoading({ loading: true }));
		if (id === selectedTheme?.id) {
			updateDefaultTheme();
		}
		deleteTheme({}, themeToEdit?.id);
	};

	const handleOpenModal = (id: string): void => {
		const themeEdit = themes.filter((t) => t.id === id)[0];
		setThemeToEdit(themeEdit);
		setOpenModal(true);
	};

	const handleCancelModal = (): void => {
		setOpenModal(false);
	};

	useEffect(() => {
		if (getThemesStatus === 'success') {
			if (getThemesVal?.data) {
				setThemes(getThemesVal.data);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: 'Themes error',
						severity: 'error',
					})
				);
			}
		} else if (getThemesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Themes error',
					severity: 'error',
				})
			);
		}
	}, [getThemesStatus]);

	useEffect(() => {
		handleGetThemes();
	}, []);

	const handleGetThemes = () => {
		dispatch(setLoading({ loading: true }));
		getThemes({}, defaultValuesId);
	};

	return {
		themes,
		openModal,
		newTheme,
		themeToEdit,
		defaultValues,
		selectedTheme,
		setSelectedTheme,
		handleCancelModal,
		handleDeleteTheme,
		handleColors,
		handleSaveColors,
		setNewTheme,
		setOpenModal,
		handleOpenModal,
	};
}
