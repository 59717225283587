//React dependencies
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// Third-party libraries
import { Grid } from '@mui/material';
import { ArrowBack, Person, AttachMoney } from '@mui/icons-material';

//Resources
import AssociatesTab from './components/associates-tab.component';
import AssociateGroupCard from '../../components/associate-group-card';
import CustomButton from '../../components/button.component';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../store/modules/loading';
import { setAlert } from '../../store/modules/snackbar';
import { IAssociateGroupResponse } from '../partners-group-details/interfaces/associates-group.interface';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import RevenueTab from './components/revenue-tab.component';
import { useTranslation } from 'react-i18next';
import { IAssociate } from '../../interfaces/associate.interface';
import TabCard from './components/tab-card.component';

export type selectedSection = 'revenue' | 'associates' | 'stations' | 'chargers' | 'guns';

const AssociateGroupDetails = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();

	const [associates, setAssociates] = useState<IAssociate[]>([]);
	const [tempAssociates, setTempAssociates] = useState<IAssociate[]>([]);
	const [groupData, setGroupData] = useState<IAssociateGroupResponse>();

	const [selectedSection, setSelectedSection] = useState<selectedSection>('associates');

	const {
		execute: getAssociates,
		value: associatesVal,
		status: associatesStatus,
	} = useBackendRequest<{}, IAssociate[]>({
		path: 'associates',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGroup,
		value: groupVal,
		status: groupStatus,
	} = useBackendRequest<unknown, IAssociateGroupResponse>({
		path: `partners-group/${id}`,
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesStatus === 'success') {
			if (associatesVal?.data) {
				setTempAssociates(associatesVal.data);
				getGroup();
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-associates'),
						severity: 'error',
					})
				);
			}
		} else if (associatesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-associates'),
					severity: 'error',
				})
			);
		}
	}, [associatesStatus]);

	useEffect(() => {
		if (groupStatus === 'success') {
			if (groupVal?.data) {
				setGroupData(groupVal?.data);
				const associatesIds = groupVal.data.associates.map((a) => a);
				const mappedAssociates: any[] = associatesIds.map((id) =>
					tempAssociates.find((asociado) => asociado.id === id.associateId)
				);
				setAssociates(mappedAssociates);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-associates'),
						severity: 'error',
					})
				);
			}
		} else if (groupStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-associates'),
					severity: 'error',
				})
			);
		}
	}, [groupStatus]);

	useEffect(() => {
		if (!id) navigate(-1);
		dispatch(setLoading({ loading: true }));
		getAssociates();
	}, []);

	const onHandleTab = (tab: selectedSection) => {
		switch (tab) {
			case 'revenue':
				return <RevenueTab />;
			case 'associates':
				return (
					<AssociatesTab
						data={associates}
						shares={groupData?.associates!}
						firstInvoiceId={groupData?.firstInvoiceAssociateId!}
						remmantOwnerId={groupData?.remainingOwnerId!}
					/>
				);

			default:
				return (
					<AssociatesTab
						data={associates}
						shares={groupData?.associates!}
						firstInvoiceId={groupData?.firstInvoiceAssociateId!}
						remmantOwnerId={groupData?.remainingOwnerId!}
					/>
				);
		}
	};

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant={'solid'}
				onClick={() =>
					navigate('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
					})
				}
				icon={ArrowBack}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					backgroundColor: 'black',
					fontSize: '14px',
					minHeight: '40px',
					borderRadius: '8px',
				}}
				iconStyles={{
					width: '18px',
					marginRight: '10px',
				}}
			/>
			<Grid container spacing={3}>
				<Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
					<AssociateGroupCard
						name={groupVal?.data?.group || 'Associates Group'}
						associates={associates.length || 0}
						action="edit"
						id={id}
					/>
					<Grid container spacing={2}>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<TabCard
								selected={selectedSection === 'associates'}
								name={t('common.associates')}
								icon={Person}
								setSelectedTo={'associates'}
								setSelectedSection={setSelectedSection}
							/>
						</Grid>
						<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
							<TabCard
								selected={selectedSection === 'revenue'}
								name={t('common.revenue')}
								icon={AttachMoney}
								setSelectedTo={'revenue'}
								setSelectedSection={setSelectedSection}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xl={8} lg={8} md={12} sm={12} xs={12}>
					{onHandleTab(selectedSection)}
				</Grid>
			</Grid>
		</>
	);
};

export default AssociateGroupDetails;
