export const generatePDF = (
	base64: string,
	extension: string,
	nombre: string,
	open = false
) => {
	const byteCharacters = atob(base64);
	const byteNumbers = new Array(byteCharacters.length);
	for (let i = 0; i < byteCharacters.length; i++) {
		byteNumbers[i] = byteCharacters.charCodeAt(i);
	}
	const byteArray = new Uint8Array(byteNumbers);
	const blob = new File([byteArray], 'file.pdf', {
		type: 'application/pdf',
	});
	const url = window.URL.createObjectURL(blob);
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', nombre + '.' + extension);
	link.click();
	if (open) {
		window.open(url, '_blank');
		window.URL.revokeObjectURL(url);
	}
};
