import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IAssociate } from '../../../interfaces/associate.interface';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';

export function useGetAssociates() {
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();

	const [associatesData, setAssociatesData] = useState<IAssociate[]>([]);

	const {
		execute: getAssociates,
		value: associatesVal,
		status: associatesStatus,
	} = useBackendRequest<{}, IAssociate[]>({
		path: 'associates',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesStatus === 'success') {
			if (associatesVal?.data) {
				const newData = filterData(associatesVal?.data);
				setAssociatesData(newData);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('associates.error-associates'),
						severity: 'error',
					})
				);
			}
		} else if (associatesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.error-associates'),
					severity: 'error',
				})
			);
		}
	}, [associatesStatus]);

	const filterData = (data: IAssociate[]): IAssociate[] => {
		const filteredData = data.filter((e) => e.partnerInfoId === id);
		return filteredData;
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getAssociates();
	}, []);

	return { associatesData, setAssociatesData };
}
