import React, { useEffect, useState } from 'react';
import { RadioButton } from '../../../components/radio-button.component';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { editColors } from '../../../store/modules/colors';
import styles from './styles/settings-theme.module.css';
import CustomButton from '../../../components/button.component';
import ColorsContainer from './colors-container.component';
import Input from '../../../components/input.component';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import ButtonIconSmall, {
	CustomTooltip,
} from '../../../components/button-icon-small.component';
import { Delete } from '@mui/icons-material';
import CustomModal from '../../../components/modal.component';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';
import { useGetDefaultValues } from '../hooks/useGetDefaultValues';
import { useUpdatePartnerThemes } from './hooks/themes-page/useUpdatePartnerThemes';

export interface ITheme {
	id?: string;
	partnerId: string;
	name: string;
	colors: {
		primary: string;
		secondary: string;
		accent: string;
	};
}

const SettingsTheme = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);

	useGetDefaultValues();

	const { id: userId } = useSelector((state: RootState) => state.user);
	const { id: partnerId } = useSelector((state: RootState) => state.partner);

	const {
		themes,
		openModal,
		newTheme,
		themeToEdit,
		selectedTheme,
		setSelectedTheme,
		handleCancelModal,
		handleDeleteTheme,
		handleColors,
		handleSaveColors,
		setNewTheme,
		setOpenModal,
		handleOpenModal,
	} = useUpdatePartnerThemes();

	const { getAddThemePermission } = useUserPermisions();

	const dispatch = useDispatch();

	const { execute: updateUserTheme, status: statusUserTheme } = useBackendRequest<
		{ themeId: string; user_system_type: number; partnerId: string },
		any
	>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	const handleChangePalette = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;

		const selectedTheme = themes.find((theme) => theme.id === value);

		setSelectedTheme(selectedTheme);

		dispatch(setLoading({ loading: true }));
		updateUserTheme(
			{ themeId: selectedTheme?.id!, user_system_type: 2, partnerId: partnerId },
			userId
		);
	};

	useEffect(() => {
		if (statusUserTheme === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated'),
					severity: 'success',
				})
			);
			dispatch(
				editColors({
					id: selectedTheme!.id,
					name: selectedTheme!.name,
					primary: selectedTheme!.colors.primary,
					secondary: selectedTheme!.colors.secondary,
					accent: selectedTheme!.colors.accent,
				})
			);
		} else if (statusUserTheme === 'error') {
			dispatch(setLoading({ loading: false }));
			setOpenModal(false);
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [statusUserTheme]);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.themeCard}>
				<span className={styles.title}>{t('themes.choose')}</span>
				<div className={styles.themesContainer}>
					{themes.map((theme, index) => (
						<div className={styles.iconRow} key={theme.id}>
							<RadioButton
								name="radio-theme"
								id={theme.id!}
								value={theme.id}
								checked={colors.id! === theme.id!}
								label={''}
								onClick={handleChangePalette}
								showLabel={false}
							/>
							<ColorsContainer
								name={theme.name}
								primary={theme.colors.primary}
								secondary={theme.colors.secondary}
								accent={theme.colors.accent}
							/>
							<div className={styles.buttonsContainer}>
								{getAddThemePermission() && index !== 0 && (
									<ButtonIconSmall
										onPress={() => handleOpenModal(theme.id!)}
										type="error"
										icon={<Delete />}
										hoverLabel={t('themes.delete')}
									/>
								)}
							</div>
						</div>
					))}
				</div>
			</div>
			{getAddThemePermission() && (
				<div className={styles.addThemeCard}>
					<div className={styles.colorsFormContainer}>
						<span className={styles.title}>{t('themes.add-new-theme')}</span>
						<h3>{t('themes.theme-name')}</h3>
						<Input
							id={'name'}
							name={'name'}
							label={t('themes.name').toString()}
							placeholder={t('common.required').toString()}
							onChange={(e) =>
								setNewTheme({ ...newTheme, name: e.target.value })
							}
							value={newTheme.name}
							type="text"
							required
						/>
						<h3>{t('themes.choose-colors')}</h3>
						<div className={styles.colorsContainer}>
							<div className={styles.colorInputContainer}>
								<span>{t('themes.primary')}</span>
								<input
									value={newTheme.colors.primary}
									className={styles.inputColor}
									type="color"
									name="primary"
									onChange={handleColors}
								/>
							</div>
							<div className={styles.colorInputContainer}>
								<span>{t('themes.secondary')}</span>
								<input
									value={newTheme.colors.secondary}
									className={styles.inputColor}
									type="color"
									name="secondary"
									onChange={handleColors}
								/>
							</div>
							<div className={styles.colorInputContainer}>
								<span>{t('themes.accent')}</span>
								<input
									value={newTheme.colors.accent}
									className={styles.inputColor}
									type="color"
									name="accent"
									onChange={handleColors}
								/>
							</div>
						</div>
					</div>
					<div
						style={{
							alignSelf: 'flex-end',
							marginTop: 'auto',
						}}
					>
						<CustomTooltip
							title={themes.length + 1 > 20 ? t('themes.max-msg') : ''}
							placement="top"
						>
							<div>
								<CustomButton
									label={t('themes.save')}
									variant={'solid'}
									onClick={handleSaveColors}
									styles={{
										width: 'auto',
										paddingLeft: 25,
										paddingRight: 25,
										backgroundColor: colors.accent,
										color: 'black',
									}}
									disabled={
										newTheme.name === '' || themes.length + 1 > 20
									}
								/>
							</div>
						</CustomTooltip>
					</div>
				</div>
			)}
			<CustomModal
				open={openModal}
				maxWidth="md"
				fullWidth
				title={t('themes.delete')}
			>
				<div className={styles.modalContainer}>
					<span>
						{t('themes.delete-txt')} <b>{themeToEdit.name}</b>{' '}
						{t('common.permanently')}?
					</span>
					<div className={styles.modalButtonsContainer}>
						<CustomButton
							label={t('common.cancel')}
							variant={'solid'}
							onClick={handleCancelModal}
							styles={{
								width: '140px',
								paddingLeft: 25,
								paddingRight: 25,
								backgroundColor: 'var(--color-black-main)',
							}}
						/>
						<CustomButton
							label={t('common.delete')}
							variant={'solid'}
							onClick={() => handleDeleteTheme(themeToEdit.id!)}
							styles={{
								width: '140px',
								paddingLeft: 25,
								paddingRight: 25,
								backgroundColor: 'var(--color-error-main)',
							}}
						/>
					</div>
				</div>
			</CustomModal>
		</div>
	);
};

export default SettingsTheme;
