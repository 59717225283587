import { useTranslation } from 'react-i18next';
import { IUsers } from '../interfaces/users.interface';

import CustomModal from '../../../components/modal.component';
import CustomButton from '../../../components/button.component';

import styles from './modal.module.css';
import { IModalContent } from '../interfaces/modal-content.interface';

interface IDisableModal {
	openDisableModal: boolean;
	selectedUser: IUsers;
	content: IModalContent;
	handleCloseModal: () => void;
	handleDisableUser: () => void;
}

const DisableModal = ({
	openDisableModal,
	selectedUser,
	content,
	handleCloseModal,
	handleDisableUser,
}: IDisableModal) => {
	const [t] = useTranslation('global');
	return (
		<CustomModal
			open={openDisableModal}
			maxWidth="md"
			fullWidth
			title={content.title}
		>
			<div className={styles.modalContainer}>
				<span>
					{content.deleteMsg1} <strong>{content.disableMsg}</strong>{' '}
					{content.deleteMsg2}
				</span>
				<span>
					<b>{t('common.name')}:</b> {selectedUser.name}
				</span>
				<span>
					<b>{t('common.email')}:</b> {selectedUser.email}
				</span>
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={handleCloseModal}
						styles={{
							backgroundColor: 'var(--color-black-main)',
							width: '124px',
							gap: '8px',
							justifySelf: 'flex-end',
							alignSelf: 'flex-end',
						}}
					/>
					<CustomButton
						label={content.buttonTxt}
						variant={'solid'}
						onClick={handleDisableUser}
						styles={{
							backgroundColor: '#DD0528',
							width: '124px',
							gap: '8px',
							justifySelf: 'flex-end',
							alignSelf: 'flex-end',
						}}
					/>
				</div>
			</div>
		</CustomModal>
	);
};

export default DisableModal;
