import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { isLatitude, isLongitude, zipRegex } from '../../../utils/validators';
import { IStation, defaultStation } from '../../../interfaces/stations.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useEditStation } from './useEditStation';
import { VIEW_TYPES } from '../constants';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../constants/user-roles.constants';

export function useCreateStation() {
	const [t] = useTranslation('global');
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const { id: stationId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { state } = useLocation();

	const [stationData, setStationData] = useState<IStation>({
		...defaultStation,
		partnerInfoId: role.roleName === ROLES.SUPER_ADMIN && !simulating ? '' : id,
	});
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [images, setImages] = useState<{
		image1: string;
		image2: string;
		image3: string;
	}>({
		image1: '',
		image2: '',
		image3: '',
	});

	const {
		handleGetStation,
		handleEditStation,
		handleDeleteStation,
		handleDeleteQionStation,
	} = useEditStation({
		setStationData,
		setImages,
	});

	const path = window.location.href;
	const view = path
		.split('/')
		.filter((part) => part !== '')
		.pop();

	const disabledButton = useMemo((): boolean => {
		return (
			stationData.partnerInfoId === '' ||
			stationData.name === '' ||
			stationData.address === '' ||
			stationData.state === '' ||
			stationData.country === '' ||
			stationData.zip.toString().length < 1 ||
			!isLatitude(stationData.latitude.toString()) ||
			!isLongitude(stationData.longitude.toString()) ||
			stationData.image1 === '' ||
			stationData.image2 === '' ||
			stationData.image3 === ''
		);
	}, [stationData]);

	const { execute: createStation, status: createStationStatus } = useBackendRequest<
		IStation,
		any
	>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (createStationStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.created'),
					severity: 'success',
				})
			);
			if (state) {
				navigate(-1);
			} else {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.STATIONS },
				});
			}
		} else if (createStationStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createStationStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		}
		if (name === 'latitude' || name === 'longitude') {
			const noWhiteSpace = value.replace(/\s/g, '');
			setStationData({ ...stationData, [name]: noWhiteSpace });
			return;
		}
		setStationData({ ...stationData, [name]: value });
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!event.target.files) return;
		const { name } = event.target;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setStationData({ ...stationData, [name]: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteImage = (name: string): void => {
		setStationData({ ...stationData, [name]: '' });
	};

	const handleAddStation = (): void => {
		dispatch(setLoading({ loading: true }));
		delete stationData.id;
		createStation(stationData);
	};

	const editStation = (): void => {
		handleEditStation(stationData);
	};

	const deleteStation = (): void => {
		if (stationData.stationUniqueCode) {
			handleDeleteQionStation(stationData.stationUniqueCode);
		} else {
			handleDeleteStation(stationId!);
		}
	};

	const getImage = (img: string): string => {
		switch (img) {
			case 'image1':
				return images.image1;
			case 'image2':
				return images.image2;
			case 'image3':
				return images.image3;
			default:
				return '';
		}
	};

	const handlePartnerSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const { value } = event.target;
		setStationData({ ...stationData, partnerInfoId: value });
	};

	useEffect(() => {
		if (view !== VIEW_TYPES.CREATE) {
			handleGetStation(stationId!);
		}
	}, []);

	return {
		view,
		stationData,
		disabledButton,
		openModal,
		images,
		handleSetData,
		handleImageChange,
		handleDeleteImage,
		setOpenModal,
		handleAddStation,
		editStation,
		deleteStation,
		getImage,
		handlePartnerSelectChange,
	};
}
