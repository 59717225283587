import { ISubmenuItem, useRouteList } from '../../../router/utils/route-list';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { RootState } from '../../../store';
import { useSelector } from 'react-redux';

import styles from '../drawer-layout.component.module.css';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import DashboardOption from '../../DashboardOption';

interface IDrawerSubmenuProps {
	classNameDrawer: string;
	submenuNumber: number;
	isSubMenuOpen: boolean;
}

const DrawerSubmenu = ({
	classNameDrawer,
	submenuNumber,
	isSubMenuOpen,
}: IDrawerSubmenuProps) => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);
	const { roleName } = useSelector((state: RootState) => state.user.role);
	const { name, lastName } = useSelector((state: RootState) => state.user);
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const { getRouteList } = useRouteList();

	return (
		<div
			style={{
				backgroundColor: hexToRGBA(colors.primary, 0.85),
			}}
			className={`${classNameDrawer} ${
				isSubMenuOpen ? styles.slideRight : styles.slideLeft
			}`}
		>
			{getRouteList(roleName).map(
				(menu, i) =>
					!menu.hideMenu && (
						<div key={`submenu-${i}`}>
							{menu.submenu && submenuNumber === i + 1 && (
								<Box
									className={styles.drawerSubMenu}
									sx={{
										backgroundColor: matches
											? hexToRGBA(colors.primary, 0.95)
											: '',
									}}
								>
									<Box
										className={styles.subMenuHeader}
										sx={{ backgroundColor: colors.primary }}
									>
										<span className={styles.subMenuHeaderTxt}>
											<strong>{t('menu.welcome')}</strong>{' '}
											{`${name} ${lastName}`}.
										</span>
									</Box>
									<Box className={styles.subMenuContent}>
										<Box
											className={
												styles.subMenuContetnOptionsContainer
											}
										>
											<span className={styles.subMenuContentTxt}>
												{menu.title}
											</span>
											{menu.submenu.map(
												(submenu: ISubmenuItem, index) =>
													!submenu.hideMenu && (
														<DashboardOption
															key={`item-${index}`}
															onClick={() =>
																navigate(
																	menu.link +
																		submenu.link
																)
															}
															name={submenu.title}
															desc={submenu.description!}
															img={submenu.image!}
															hexColor={colors.accent}
														/>
													)
											)}
										</Box>
									</Box>
								</Box>
							)}
						</div>
					)
			)}
		</div>
	);
};

export default DrawerSubmenu;
