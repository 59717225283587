import styles from './styles/styles.module.css';
import ColumnSelector from './components/column-selector.component';
import GenerateReportProvider from './context/GenerateReportProvider';
import FiltersSelector from './components/filters-selector.component';
import CustomButton from '../../components/button.component';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { useGenerateReport } from './components/hooks/useGenerateReport';
import { useTranslation } from 'react-i18next';

const GenerateReport = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	return (
		<GenerateReportProvider>
			<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
				<CustomButton
					label={t('common.back')}
					icon={ArrowBack}
					onClick={() => navigate(-1)}
					styles={{
						backgroundColor: 'var(--color-black-main)',
						width: 'fit-content',
						padding: '0 15px',
						gap: 10,
					}}
					variant="solid"
				/>
				<div className={styles.mainContainer}>
					<h3>{t('report.title')}</h3>
					<hr />
					<span className={styles.txtSection}>
						{t('report.select-columns')}
					</span>
					<ColumnSelector />
					<hr />
					<span className={styles.txtSection}>
						{t('report.select-filters')}
					</span>
					<FiltersSelector />
				</div>
			</div>
		</GenerateReportProvider>
	);
};

export default GenerateReport;
