import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { ICharger } from '../../../interfaces/charger.interface';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';
import { IOptions } from '../../../components/select-input.component';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { IQionCharger } from '../../../interfaces/qion-data.interface';
import { setLoading } from '../../../store/modules/loading';
import { ROLES } from '../../../constants/user-roles.constants';

export function useGetChargers() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const [chargersSelect, setChargersSelect] = useState<IOptions[]>([]);
	const [qionChargersSelect, setQionChargersSelect] = useState<IOptions[]>([]);

	const [qionChargers, setqionChargers] = useState<IQionCharger[]>([]);
	const [chargersData, setChargersData] = useState<ICharger[]>([]);

	const {
		execute: getUnassotiedChargers,
		value: getUnassotiedChargersVal,
		status: getUnassotiedChargersStatus,
	} = useApiRequest<{}, IQionCharger[]>({
		path: 'cms-charger/get-unassotied-chargers',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'POST',
	});

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<{}, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				setChargersData(getChargersVal.data);
				getSelectValues(getChargersVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-chargers'),
						severity: 'error',
					})
				);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-chargers'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	useEffect(() => {
		if (getUnassotiedChargersStatus === 'success') {
			if (getUnassotiedChargersVal?.objData) {
				setqionChargers(getUnassotiedChargersVal.objData);
				getQionSelectValues(getUnassotiedChargersVal.objData);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-chargers'),
						severity: 'error',
					})
				);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getUnassotiedChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-chargers'),
					severity: 'error',
				})
			);
			dispatch(setLoading({ loading: false }));
		}
	}, [getUnassotiedChargersStatus]);

	const getSelectValues = (data: ICharger[]): void => {
		let filteredData;
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			filteredData = data;
		} else {
			filteredData = data.filter((s) => s.partnerInfoId === id);
		}
		const selectData: IOptions[] = filteredData.map((c) => {
			return {
				label: c.name,
				value: c.id!,
			};
		});
		setChargersSelect(selectData);
	};

	const getQionSelectValues = (chargers: IQionCharger[]): void => {
		const data: IOptions[] = chargers.map((charger) => {
			return {
				label: `${charger.chargerUniqueCode} - ${charger.name}`,
				value: charger.chargerUniqueCode,
			};
		});
		setQionChargersSelect(data);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getChargers();
		getUnassotiedChargers();
	}, []);

	return {
		chargersSelect,
		qionChargersSelect,
		qionChargers,
		chargersData,
		setChargersSelect,
	};
}
