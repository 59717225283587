import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { USER_PERMISSIONS } from '../constants/permissions';
import { useTranslation } from 'react-i18next';

export const useUserPermisions = () => {
	const [t] = useTranslation('global');
	const { permissions } = useSelector((state: RootState) => state.user);

	const getPermissionColor = (noPermision: string) => {
		switch (noPermision) {
			//Usuarios
			case USER_PERMISSIONS.MANAGE_USERS:
				return '#EAC435';
			//Grupos de associados
			case USER_PERMISSIONS.MANAGE_ASSOCIATES_GROUPS:
				return '#345995';
			//Estaciones
			case USER_PERMISSIONS.MANAGE_STATIONS:
				return '#03CEA4';
			//Reportes
			case USER_PERMISSIONS.MANAGE_REPORTS:
				return '#FB4D3D';
			//Associados
			case USER_PERMISSIONS.MANAGE_ASSOCIATES:
				return '#CA1551';
			case USER_PERMISSIONS.ADD_THEMES:
				return '#008080';
			case USER_PERMISSIONS.MANAGE_CHARGERS:
				return '#f50400';
			case USER_PERMISSIONS.MANAGE_GUNS:
				return '#b93af8';
			case USER_PERMISSIONS.MANAGE_SCHEDULES:
				return '#ff968a';
			case USER_PERMISSIONS.MANAGE_DEFAULT_VALUES:
				return '#89caca';
			case USER_PERMISSIONS.MANAGE_STRIPE:
				return '#8a8bd8';
			default:
				return '#999999';
		}
	};

	const getPermissionName = (permissionId: string): string => {
		switch (permissionId) {
			//Usuarios
			case USER_PERMISSIONS.MANAGE_USERS:
				return t('users.manage-users');
			//Grupos de associados
			case USER_PERMISSIONS.MANAGE_ASSOCIATES_GROUPS:
				return t('users.manage-groups');
			//Estaciones
			case USER_PERMISSIONS.MANAGE_STATIONS:
				return t('users.manage-stations');
			//Reportes
			case USER_PERMISSIONS.MANAGE_REPORTS:
				return t('users.manage-reports');
			//Associados
			case USER_PERMISSIONS.MANAGE_ASSOCIATES:
				return t('users.manage-associates');
			case USER_PERMISSIONS.ADD_THEMES:
				return t('users.manage-themes');
			case USER_PERMISSIONS.MANAGE_GUNS:
				return t('users.manage-guns');
			case USER_PERMISSIONS.MANAGE_CHARGERS:
				return t('users.manage-chargers');
			case USER_PERMISSIONS.MANAGE_SCHEDULES:
				return t('users.manage-schedules');
			case USER_PERMISSIONS.MANAGE_DEFAULT_VALUES:
				return t('users.manage-vals');
			case USER_PERMISSIONS.MANAGE_STRIPE:
				return t('users.mange-stripe');
			default:
				return 'Permission name';
		}
	};

	const getAddThemePermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.ADD_THEMES
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getAssociatesGroupPermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_ASSOCIATES_GROUPS
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getStationsPermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_STATIONS
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getReportsPermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_REPORTS
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getAssociatesPermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_ASSOCIATES
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getUsersPermission = (): boolean => {
		const addThemePermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_USERS
		)[0];

		if (addThemePermissionId !== undefined) return true;
		else return false;
	};

	const getChargersPermission = (): boolean => {
		const chargersPermissionId = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_CHARGERS
		)[0];

		if (chargersPermissionId !== undefined) return true;
		else return false;
	};

	const getGunsPermission = (): boolean => {
		const guns = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_GUNS
		)[0];

		if (guns !== undefined) return true;
		else return false;
	};

	const getDefaultValuesPermission = (): boolean => {
		const defaultvVals = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_DEFAULT_VALUES
		)[0];

		if (defaultvVals !== undefined) return true;
		else return false;
	};

	const getSchedulesPermission = (): boolean => {
		const schedules = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_SCHEDULES
		)[0];

		if (schedules !== undefined) return true;
		else return false;
	};

	const getStripePermission = (): boolean => {
		const stripe = permissions.filter(
			(p) => p.permissionId === USER_PERMISSIONS.MANAGE_STRIPE
		)[0];

		if (stripe !== undefined) return true;
		else return false;
	};

	return {
		getAddThemePermission,
		getAssociatesGroupPermission,
		getAssociatesPermission,
		getReportsPermission,
		getStationsPermission,
		getUsersPermission,
		getPermissionColor,
		getPermissionName,
		getChargersPermission,
		getGunsPermission,
		getDefaultValuesPermission,
		getSchedulesPermission,
		getStripePermission,
	};
};
