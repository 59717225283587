export interface IPartnerInfo {
	id: string;
	img: string;
	name: string;
	responsibleName: string;
	responsibleLastName: string;
	address: string;
	rfc: string;
	email: string;
	password: string;
	phone: string;
	state: string;
	country: string;
	zip: string;
	externalPartnerId: string;
	stripeCode: string;
	languages: string[];
	hold: string;
}

export const defaultPartnerInfo: IPartnerInfo = {
	id: '',
	name: '',
	responsibleName: '',
	responsibleLastName: '',
	address: '',
	state: '',
	country: '',
	zip: '',
	rfc: '',
	phone: '',
	email: '',
	password: '',
	img: '',
	externalPartnerId: '',
	stripeCode: '',
	languages: [],
	hold: '',
};

export interface IAssociatesGroup {
	id: number;
	group: string;
	firstInvoiceAssociate: number;
	chargeCost: string | number;
	chargeFactor: string | number;
	parkingCost: string | number;
	parkingFactor: string | number;
	associates: { associateId: number; fraction?: number; unit?: number }[];
}

/* export const defaultAssociateGroup: IAssociatesGroup = {
	id: 0,
	chargeCost: '',
	chargeFactor: '',
	parkingCost: '',
	parkingFactor: '',
	group: '',
	associates: [],
}; */

export interface IAssociatesStation {
	id: number;
	name: string;
	location: string;
	charges: ICharge[];
	guns?: IGun[];
}

export interface ICharge {
	id: number;
	name: string;
	pileUniqueCode: string;
	station: string | number;
	location: string;
	guns: IGun[];
}

export interface IGun {
	id: number;
	name: string;
	type: string;
	gunUniqueCode: string;
	charger: string | number;
	chargeCost: string;
	chargeFactor: string;
	parkingCost: string;
	parkingFactor: string;
}

export interface IUser {
	id: number;
	user: string;
	mail: string;
	profile: string;
}

export interface ISchedule {
	id?: string;
	partnerId: number | string;
	name: string;
	weight: number | string;
	status: boolean | JSX.Element;
	limitedDays: boolean;
	startDate?: string | null;
	endDate?: string | null;
	days: string[];
	allDay: boolean;
	startTime?: string | null;
	endTime?: string | null;
	associateGroupId: number | string;
	guns: number[] | string[];
}
