import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IAddAssociateGroup } from '../interfaces/associate-group.interface';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { IAssociate } from '../../../interfaces/associate.interface';

interface IUseGetGroupData {
	associatesData: IAssociate[];
	setAssociateGroupData: React.Dispatch<React.SetStateAction<IAddAssociateGroup>>;
	setSelectedAssociates: React.Dispatch<React.SetStateAction<IAssociate[]>>;
}

export function useGetGroupData({
	associatesData,
	setAssociateGroupData,
	setSelectedAssociates,
}: IUseGetGroupData) {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const dispatch = useDispatch();

	const [tempAssociatesIds, setTempAssociatesIds] = useState<string[]>([]);

	const {
		execute: getGroup,
		value: valueGroup,
		status: statusGroup,
	} = useBackendRequest<any, IAddAssociateGroup>({
		path: 'partners-group/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (statusGroup === 'success') {
			if (valueGroup?.data) {
				delete valueGroup.data.id;
				delete valueGroup.data.create_time;
				delete valueGroup.data.update_time;
				delete valueGroup.data.default_theme;
				delete valueGroup.data.default_iva;
				delete valueGroup.data.language;
				delete valueGroup.data.default_charge_cost;
				delete valueGroup.data.default_charge_factor;
				delete valueGroup.data.default_parking_cost;
				delete valueGroup.data.default_parking_factor;
				const associatesId = valueGroup.data.associates.map((a) => a.associateId);
				setTempAssociatesIds(associatesId);
				setAssociateGroupData(valueGroup.data);
			}
			dispatch(setLoading({ loading: false }));
		} else if (statusGroup === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-single-info'),
					severity: 'error',
				})
			);
			dispatch(setLoading({ loading: false }));
		}
	}, [statusGroup]);

	useEffect(() => {
		if (associatesData.length > 0) {
			const filteredData: IAssociate[] = tempAssociatesIds.map(
				(id) => associatesData.find((asociado) => asociado.id === id)!
			);
			setSelectedAssociates(filteredData);
		}
	}, [associatesData]);

	useEffect(() => {
		if (id) {
			dispatch(setLoading({ loading: true }));
			getGroup({}, id);
		}
	}, []);
}
