/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useEffect, useState } from 'react';
import { IUsers } from '../interfaces/users.interface';
import { useUserColumns } from '../utils/users-table.columns';
import { useUsersAdapter } from '../adapters/users.adapter';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
import {
	IModalContent,
	defaultModalContent,
} from '../interfaces/modal-content.interface';
import { IAllUsersResponse } from '../../login/interfaces/login.interface';
import { useGetCmsUsers } from '../../../hooks/useGetCmsUsers';
import { ROLES } from '../../../constants/user-roles.constants';

export function useUsers() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const user = useSelector((state: RootState) => state.user);
	const { partnerId } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const { data, setData } = useTableCustom<any>();

	const { getUsersPermission } = useUserPermisions();
	const { userColumns } = useUserColumns();
	const { usersAdapter } = useUsersAdapter();
	const { filterCmsUsers } = useGetCmsUsers();

	const [openModal, setOpenModal] = useState<boolean>(false);
	const [openDisableModal, setOpenDisableModal] = useState<boolean>(false);
	const [disabledModalContent, setDisabledModalContent] =
		useState<IModalContent>(defaultModalContent);
	const [selectedUser, setSelectedUser] = useState<IUsers>({
		id: '',
		name: '',
		email: '',
		mobile: '',
		partnerId: '',
		profile: '',
	});

	const {
		execute: getUsers,
		value: getUsersVal,
		status: getUsersStatus,
	} = useBackendRequest<any, IAllUsersResponse>({
		path: 'users/find-by-partner-id/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const { execute: editUser, status: editUserStatus } = useBackendRequest<any, any>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (getUsersStatus === 'success') {
			if (getUsersVal?.data) {
				const filteredCMSUsers = filterCmsUsers(getUsersVal?.data.userCms);
				setData({
					...data,
					countRows: 0,
					rows: usersAdapter(
						filteredCMSUsers,
						user.role.roleName,
						handleSeeDetails,
						getUsersPermission,
						handleOpenModal,
						handleDisableUser
					),
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('users.user-list-error'),
						severity: 'error',
					})
				);
			}
		} else if (getUsersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-list-error'),
					severity: 'error',
				})
			);
		}
	}, [getUsersStatus]);

	useEffect(() => {
		if (editUserStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('associates.updated'),
					severity: 'success',
				})
			);
			setOpenDisableModal(false);
			const idPartner = simulating ? id : partnerId;
			getUsers({}, idPartner.toString());
		} else if (editUserStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [editUserStatus]);

	const handleSeeDetails = (id: string) => {
		navigate(`/users/details/${id}`, { state: { prevUrl: location.pathname } });
	};

	const handleDisableUser = (user: IUsers) => {
		setSelectedUser(user);
		if (user.is_disabled) {
			setDisabledModalContent({
				title: t('common.enable'),
				deleteMsg1: t('users.delete-message-1'),
				disableMsg: t('common.enable').toLowerCase(),
				deleteMsg2: t('users.delete-message-3'),
				buttonTxt: t('common.enable'),
			});
		} else {
			setDisabledModalContent({
				title: t('users.disable-account'),
				deleteMsg1: t('users.delete-message-1'),
				disableMsg: t('users.disable').toLowerCase(),
				deleteMsg2: t('users.delete-message-3'),
				buttonTxt: t('users.disable'),
			});
		}
		setOpenDisableModal(true);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		const idPartner = simulating ? id : partnerId;
		getUsers({}, idPartner.toString());
	}, []);

	const handleOpenModal = (user: IUsers): void => {
		setOpenModal(true);
		setSelectedUser(user);
	};

	const handleCloseModal = (): void => {
		setOpenModal(false);
		setOpenDisableModal(false);
		setSelectedUser({
			id: '',
			name: '',
			email: '',
			mobile: '',
			partnerId: '',
			profile: '',
		});
	};

	const handleDeleteUser = (): void => {
		dispatch(setLoading({ loading: true }));
		deleteUser({
			partnerId: id,
			user_system_type: 2,
			id_user: selectedUser.id,
		});
	};

	const { execute: deleteUser, status: deleteUserStatus } = useBackendRequest<
		{
			partnerId: string;
			user_system_type: number;
			id_user: string;
		},
		any
	>({
		path: 'users/delete',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (deleteUserStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-deleted'),
					severity: 'success',
				})
			);
			handleCloseModal();
			const idPartner = simulating ? id : partnerId;
			getUsers({}, idPartner.toString());
		} else if (deleteUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-deleted-error'),
					severity: 'error',
				})
			);
		}
	}, [deleteUserStatus]);

	const handleAcceptModal = (): void => {
		editUser(
			{
				is_disabled: selectedUser.is_disabled === 0,
				user_system_type: 2,
				partnerId:
					user.role.roleName === ROLES.SUPER_ADMIN && !simulating
						? partnerId
						: id,
			},
			selectedUser.id
		);
	};

	return {
		user,
		userColumns,
		data,
		openModal,
		selectedUser,
		openDisableModal,
		disabledModalContent,
		setData,
		handleCloseModal,
		handleDeleteUser,
		handleAcceptModal,
	};
}
