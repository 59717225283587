/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { Box, SvgIconTypeMap, Chip, CircularProgress } from '@mui/material';
import { ArrowUpward, ArrowDownward, Search } from '@mui/icons-material';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';

import { IColumns, IDataTable } from '../hooks/useTableCustom';
// styles
import styles from './styles/table-custom-styles.component.module.css';

import { OverridableComponent } from '@mui/material/OverridableComponent';

import theme from '../config/theme';
import { useEffect, useState, createRef, cloneElement } from 'react';
import CustomButton from './button.component';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { hexToRGBA } from '../utils/hex-to-rgba';
import { DefaultTFuncReturn } from 'i18next';
import { useTranslation } from 'react-i18next';

const optionsLimit = [
	{ label: '10', value: '10' },
	{ label: '25', value: '25' },
	{ label: '50', value: '50' },
	{ label: '100', value: '100' },
];

export interface IButtonsAction {
	type?: 'info' | 'warning' | 'success' | 'error' | 'primary' | 'black' | 'grey';
	variant?: 'solid' | 'outlined';
	label: string;
	execute: (arg?: any) => void;
	icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
		muiName: string;
	};
	width?: number;
	isHidden?: boolean;
}

interface IProps {
	columns: IColumns[];
	data: IDataTable<any>;
	setData: (arg: IDataTable<any>) => void;
	showPagination?: boolean;
	labelNotRows?: string;
	hideHeader?: boolean;
	titleHeader?: JSX.Element[] | JSX.Element | string | DefaultTFuncReturn;
	iconHeader?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
	imgHeader?: string;
	rowsSelect?: { position: 'start' | 'end'; label?: string; width?: number };
	buttonsAction?: {
		width?: number;
		buttons: IButtonsAction[];
	};
	fnButtonRowsSelect?: (arg: any) => void;
	labelSelectRows?: string;
	showLimitPerPage?: boolean;
	showSearchBar?: boolean;
	buttons?: IButtonsAction[];
	filterChips?: any[];
	extraHeader?: JSX.Element;
	clickInRow?: (arg: any) => void;
	modal?: JSX.Element;
	totalCountTxt?: string;
	totalCountStyle?: React.CSSProperties;
	numberStyle?: React.CSSProperties;
	maxHeight?: string;
	hideChips?: boolean;
	showTotalCount?: boolean;
	bodyBold?: boolean;
	scrollX?: boolean;
}

const TableCustom = ({
	columns,
	data,
	setData,
	showPagination,
	labelNotRows,
	hideHeader,
	titleHeader,
	iconHeader: Icon,
	imgHeader,
	buttons,
	rowsSelect,
	buttonsAction,
	fnButtonRowsSelect,
	labelSelectRows,
	showLimitPerPage,
	showSearchBar = true,
	// filterChips,
	extraHeader,
	clickInRow,
	totalCountTxt = 'Total de registros encontrados',
	totalCountStyle,
	numberStyle,
	modal,
	maxHeight,
	hideChips,
	showTotalCount = true,
	bodyBold = false,
	scrollX = false,
}: IProps) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const config = useSelector((state: RootState) => state.config);
	const {
		countRows,
		rows,
		rowsSelect: rowSelect,
		rowsPerPage,
		pagination,
		filters,
		activeAdvanceFilter,
		advancedFilter,
	} = data;

	const getSorting = (colId: string) => {
		if (data.sort.col !== colId) return 'ASC';
		if (data.sort.order === 'ASC') return 'DESC';
		if (data.sort.order === 'DESC') return 'ASC';
		return '';
	};

	const filterRef = createRef<any>();
	const [currentFilters, setCurrentFilters] = useState<string[]>([]);
	const getOptions = () => {
		const options = [];
		const limit = Number(rowsPerPage || 10);
		let start = 1;
		let finish = 0;
		let value = 0;

		for (let index = 0; index < Math.ceil(countRows / limit); index++) {
			start = index * limit + 1;
			value = index * limit;
			finish = finish + limit;
			if (finish > countRows) {
				finish = countRows;
			}
			options.push({ start, finish, value, index: index + 1 });
		}
		return options;
	};

	const BootstrapInput = styled(InputBase)(({ theme }) => ({
		'label + &': {
			marginTop: theme.spacing(3),
		},
		'& .MuiInputBase-input': {
			borderRadius: 4,
			position: 'relative',
			backgroundColor: theme.palette.background.paper,
			border: '1px solid white',
			fontSize: 14,
			padding: '10px 26px 10px 12px',
			transition: theme.transitions.create(['border-color', 'box-shadow']),
			// Use the system font instead of the default Roboto font.
			'&:focus': {
				borderRadius: 4,
				borderColor: '#80bdff',
				boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
			},
		},
	}));

	useEffect(() => {
		let _filters: string[] = [];
		if (filterRef.current && filterRef.current.getFilters) {
			_filters = filterRef.current.getFilters();
		}
		setCurrentFilters(_filters);
	}, [filterRef.current, filters]);

	const changeCheckbox = (selected: any) => {
		const index = (rowSelect || []).indexOf(selected);

		if (index > -1) {
			return setData({
				...data,
				rowsSelect: (rowSelect || []).filter((e, j) => j !== index),
			});
		}

		setData({
			...data,
			rowsSelect: [...(data.rowsSelect || []), selected],
		});
	};

	const changeAllCheckboxs = (isAddItem: boolean) => {
		if (isAddItem) {
			return setData({
				...data,
				rowsSelect: rows,
			});
		}

		setData({
			...data,
			rowsSelect: [],
		});
	};

	const onClickInRow = (values: any) => {
		if (clickInRow === undefined) return;

		clickInRow(values);
	};

	return (
		<div>
			{!hideHeader && (
				<Box display={'flex'} alignItems={'center'}>
					<Box
						display={'flex'}
						alignItems={'center'}
						flexGrow={1}
						fontWeight={600}
						marginBottom={imgHeader ? 2 : 0}
						sx={{ fontSize: '18px', color: '#002739', fontWeight: 'bold' }}
					>
						{Icon && <Icon color="primary" style={{ marginRight: 10 }} />}
						{imgHeader && (
							<img
								src={imgHeader}
								alt="logotipoheader"
								style={{ marginRight: 10 }}
							/>
						)}
						{titleHeader}
					</Box>

					{showSearchBar && (
						<div className={styles.inputSearch}>
							<Search style={{ fontSize: 18, margin: '0px 10px' }} />
							<input
								value={advancedFilter || ''}
								placeholder={'Search...'}
								onChange={(e) =>
									setData({
										...data,
										activeAdvanceFilter: true,
										advancedFilter: e.target.value,
									})
								}
							/>
							{activeAdvanceFilter && (
								<CircularProgress size={16} style={{ marginRight: 10 }} />
							)}
						</div>
					)}
					{buttons && (
						<Box display={'flex'} alignItems={'center'} gap={1}>
							{buttons.map(
								(button, k) =>
									!button.isHidden && (
										<Box key={k} width={button?.width || 'auto'}>
											<CustomButton
												label={button?.label}
												variant={button?.variant || 'solid'}
												onClick={() =>
													button?.execute
														? button?.execute()
														: {}
												}
												icon={button?.icon}
											/>
										</Box>
									)
							)}
						</Box>
					)}
					{/*modal && modal*/}
					{modal && cloneElement(modal, { ref: filterRef })}

					{(rowSelect || []).length > 0 && (
						<Box width={210}>
							<CustomButton
								// eslint-disable-next-line prettier/prettier
								label={`${
									labelSelectRows ? labelSelectRows : 'Seleccionado:'
									// eslint-disable-next-line prettier/prettier
								} ${(rowSelect || []).length}`}
								variant="solid"
								onClick={() =>
									fnButtonRowsSelect
										? fnButtonRowsSelect(rowSelect)
										: {}
								}
							/>
						</Box>
					)}
				</Box>
			)}

			<Box fontSize={14} display={'flex'} alignItems="center">
				{showTotalCount && (
					<Box flexGrow={1}>
						<span style={totalCountStyle}>{totalCountTxt}:</span>{' '}
						<span
							style={{
								color: theme.palette.primary.main,
								fontWeight: 800,
								...numberStyle,
							}}
						>
							{countRows}
						</span>
					</Box>
				)}
				{!hideChips && (
					<>
						<span style={{ marginRight: 4 }}>Filtrado por:</span>
						<Box display={'flex'} alignItems={'center'} gap={'2px'}>
							{currentFilters.length > 0 ? (
								currentFilters.map((i, key) => (
									<Chip
										key={key}
										label={i}
										color="primary"
										variant="outlined"
										size="small"
									/>
								))
							) : (
								<Chip
									label="Sin filtro"
									color="primary"
									variant="outlined"
									size="small"
								/>
							)}
						</Box>
					</>
				)}
			</Box>

			{extraHeader !== undefined && <Box>{extraHeader}</Box>}

			<Box
				width={
					scrollX && config.menu
						? '65vw'
						: scrollX && !config.menu
						? '86vw'
						: '100%'
				}
				maxHeight={maxHeight}
				overflow={scrollX ? 'scroll' : 'auto'}
				marginTop={!hideHeader && !titleHeader ? -6 : 0}
			>
				<table
					className={styles.tableCustom}
					style={{ width: scrollX ? 'max-content' : '100%' }}
				>
					<thead>
						<tr>
							{rowsSelect?.position === 'start' && (
								<th
									data-checkbox={'true'}
									style={{
										display: 'flex',
										justifyContent: 'center',
										minWidth: rowsSelect.width
											? rowsSelect.width
											: 'auto',
									}}
								>
									{rowsSelect.label}
									<input
										type="checkbox"
										id="rowsSelect"
										name="rowsSelect"
										checked={
											(rowSelect || []).length === rows.length
												? true
												: false
										}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => changeAllCheckboxs(e.target.checked)}
									/>
								</th>
							)}

							{columns.map((col, i) => (
								<th
									style={{
										minWidth: col.width ? col.width : 'auto',
										textAlign: col.align,
										fontSize: 16,
									}}
									key={i}
									data-text={col.nameID}
									data-sort={col.sort ? 'true' : 'false'}
									onClick={() => {
										if (col.sort) {
											setData({
												...data,
												sort: {
													col: col.nameID,
													order: getSorting(col.nameID),
												},
											});
										}
									}}
								>
									{col.text}
									{data?.sort?.col === col.nameID && (
										<>
											{data?.sort?.order === 'ASC' && (
												<ArrowUpward
													style={{
														fontSize: 13,
														marginLeft: 3,
													}}
												/>
											)}
											{data?.sort?.order === 'DESC' && (
												<ArrowDownward
													style={{
														fontSize: 13,
														marginLeft: 3,
													}}
												/>
											)}
										</>
									)}
								</th>
							))}

							{rowsSelect?.position === 'end' && (
								<th
									data-checkbox={'true'}
									style={{
										minWidth: rowsSelect.width
											? rowsSelect.width
											: 'auto',
									}}
								>
									{rowsSelect.label}
									<input
										type="checkbox"
										id="rowsSelect"
										name="rowsSelect"
										checked={
											(rowSelect || []).length === rows.length
												? true
												: false
										}
										onChange={(
											e: React.ChangeEvent<HTMLInputElement>
										) => changeAllCheckboxs(e.target.checked)}
									/>
								</th>
							)}

							{buttonsAction && (
								<th
									style={{
										minWidth: buttonsAction.width
											? buttonsAction.width
											: 'auto',
									}}
								></th>
							)}
						</tr>
					</thead>
					<tbody>
						{rows.length > 0 ? (
							rows.map((row, j) => (
								<tr
									key={j}
									onClick={() => onClickInRow(row)}
									style={{
										backgroundColor:
											j % 2 === 0
												? ''
												: hexToRGBA(colors.secondary, 0.1),
									}}
								>
									{rowsSelect?.position === 'start' && (
										<td style={{ textAlign: 'center' }}>
											<input
												type="checkbox"
												id="rowsSelect"
												name="rowsSelect"
												checked={
													(rowSelect || []).indexOf(row) > -1
														? true
														: false
												}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => changeCheckbox(row)}
											/>
										</td>
									)}

									{columns.map((col, k: number) => (
										<td
											key={`${col.nameID}-${j}-${k}`}
											style={{
												textAlign:
													col.nameID === 'status'
														? 'center'
														: col.align,
												color: '#0B2536',
												fontWeight: bodyBold ? 'bold' : '500',
												width:
													col.nameID === 'status' ||
													col.nameID === 'price'
														? '100px'
														: 'auto',
											}}
											data-text={col.nameID}
										>
											{row[col.nameID] ? row[col.nameID] : '-'}
										</td>
									))}

									{rowsSelect?.position === 'end' && (
										<td style={{ textAlign: 'center' }}>
											<input
												type="checkbox"
												id="rowsSelect"
												name="rowsSelect"
												checked={
													(rowSelect || []).indexOf(row) > -1
														? true
														: false
												}
												onChange={(
													e: React.ChangeEvent<HTMLInputElement>
												) => changeCheckbox(row)}
											/>
										</td>
									)}

									{buttonsAction && (
										<td>
											{buttonsAction.buttons.map((btn, k) => (
												<CustomButton
													key={`button-${j}-${k}`}
													label={btn.label}
													variant={btn.variant || 'solid'}
													icon={btn?.icon}
													onClick={() => btn.execute(row)}
												/>
											))}
										</td>
									)}
								</tr>
							))
						) : (
							<tr>
								<td
									colSpan={
										rowsSelect ? columns.length + 1 : columns.length
									}
									style={{ textAlign: 'center' }}
								>
									{labelNotRows ? labelNotRows : t('table.no-data')}
								</td>
							</tr>
						)}
					</tbody>
				</table>
			</Box>
			<div className={styles.footer}>
				{showLimitPerPage && (
					<div className={styles.limitPerPage}>
						<span>{t('table.page')}</span>
						<FormControl sx={{ m: 1 }} variant="standard">
							<Select
								id="demo-customized-select"
								value={rowsPerPage || '1'}
								onChange={(e: any) =>
									setData({
										...data,
										rowsPerPage: e.target.value,
										pagination: '1',
									})
								}
								input={<BootstrapInput />}
							>
								{optionsLimit.map((op, i) => (
									<MenuItem key={i} value={op.value}>
										{op.label}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</div>
				)}
				{showPagination && (
					<div className={styles.pagination}>
						{t('table.showing')}{' '}
						<select
							value={pagination}
							onChange={(e) => {
								e.preventDefault();
								setData({
									...data,
									pagination: e.target.value,
								});
							}}
						>
							{getOptions().map((op, j) => (
								<option key={j} value={op.index}>
									{op.start} - {op.finish}
								</option>
							))}
						</select>
						{t('table.of')} {countRows}
					</div>
				)}
			</div>
		</div>
	);
};

export default TableCustom;
