import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Grid } from '@mui/material';
import CustomButton from '../../../../../components/button.component';
import SimpleCard from '../../../../../components/simple-card.component';
import Input from '../../../../../components/input.component';
import SwitchCustom from '../../../../../components/switch.component';
import styles from '../../../styles/admin-associates-tab.module.css';
import { ADMIN_TAB_VALUES } from '../../../../../constants/tab-values.constants';
import CustomModal from '../../../../../components/modal.component';
import { useTranslation } from 'react-i18next';
import { useEditAssociate } from './hooks/useEditAssociate';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';

const EditAssociate = () => {
	const [t] = useTranslation('global');
	const nav = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);

	const {
		openModal,
		disabledButton,
		editAssociateData,
		openErrorModal,
		groupsList,
		handleSetData,
		setOpenModal,
		handleEditAssociate,
		handleDelete,
		handleStatusChange,
		setOpenErrorModal,
	} = useEditAssociate();

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant="solid"
				icon={ArrowBack}
				styles={{
					width: 'fit-content',
					backgroundColor: 'black',
					padding: '0 15px',
				}}
				iconStyles={{
					width: '18px',
					marginRight: '10px',
				}}
				onClick={() =>
					nav('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
					})
				}
			/>
			<SimpleCard
				title={t('associates.edit').toString()}
				titleStyles={{
					fontWeight: 600,
					fontSize: 'large',
				}}
				dividers
				style={{
					padding: '5px 40px 40px',
				}}
				footerStyles={{ justifyContent: 'end' }}
				footer={
					<div
						style={{
							width: '100%',
							display: 'flex',
							justifyContent: 'space-between',
							alignItems: 'center',
							padding: '0 40px',
						}}
					>
						<div>
							<CustomButton
								variant="solid"
								label={t('common.delete')}
								styles={{
									backgroundColor: '#DD0528',
									fontSize: '14px',
									minHeight: '40px',
									borderRadius: '4px',
									width: '100px',
								}}
								onClick={() => setOpenModal(true)}
							/>
						</div>
						<div className={styles['associate-add-footer-container']}>
							<CustomButton
								variant="solid"
								label={t('common.cancel')}
								styles={{
									backgroundColor: 'black',
									fontSize: '14px',
									minHeight: '40px',
									borderRadius: '4px',
								}}
								onClick={() =>
									nav('/partners/details', {
										state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
									})
								}
							/>
							<CustomButton
								variant="solid"
								label={t('common.save')}
								styles={{
									backgroundColor: colors.accent,
									color: 'black',
									fontSize: '14px',
									minHeight: '40px',
									borderRadius: '4px',
								}}
								disabled={disabledButton}
								onClick={handleEditAssociate}
							/>
						</div>
					</div>
				}
				headerActions={
					<div>
						<SwitchCustom
							label={t('common.status').toString()}
							value={editAssociateData.status}
							onChange={handleStatusChange}
							name="status"
							color="primary"
						/>
					</div>
				}
			>
				<Grid container spacing={2}>
					{/* <Grid item xs={12}>
						<h3 style={{ margin: '10px 0' }}>Branding</h3>
					</Grid>
					<Grid item xs={12}>
						<div className={styles['box-image-container']}>
							<IconButton size="small">
								<Edit color="primary" style={{ fontSize: 'small' }} />
							</IconButton>
							<Paper elevation={6}>
								<ImageOutlined style={{ fontSize: 'xxx-large' }} />
							</Paper>
						</div>
						<div>
							<p>Partner logo</p>
						</div>
					</Grid> */}
					<Grid item xs={12} sm={6}>
						<Input
							label={t('associates.name').toString()}
							name={'name'}
							value={editAssociateData.name}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.alias').toString()}
							name={'alias'}
							value={editAssociateData.alias}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.tax-id').toString()}
							name={'rfc'}
							value={editAssociateData.rfc}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.address').toString()}
							name={'address'}
							value={editAssociateData.address}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.state').toString()}
							name={'state'}
							value={editAssociateData.state}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.country').toString()}
							name={'country'}
							value={editAssociateData.country}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.zip').toString()}
							name={'zip'}
							value={editAssociateData.zip}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.phone').toString()}
							name={'phone'}
							value={editAssociateData.phone}
							required
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12}>
						<h3 style={{ margin: '10px 0' }}>
							{t('associates.credentials')}
						</h3>
					</Grid>
					<Grid item xs={12} sm={6}>
						<Input
							label={t('common.email').toString()}
							name={'email'}
							value={editAssociateData.email}
							required
							onChange={handleSetData}
							disabled
						/>
					</Grid>
				</Grid>
			</SimpleCard>
			<CustomModal
				open={openErrorModal}
				maxWidth="md"
				fullWidth
				title={t('groups.error-deleted')}
			>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					<span>{t('groups.error-delete-txt')}</span>
					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
					>
						{groupsList.map((g) => (
							<span>• {g}</span>
						))}
					</div>
					<CustomButton
						variant="solid"
						label={t('common.cancel')}
						styles={{
							backgroundColor: 'black',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
							marginTop: 20,
							justifySelf: 'center',
							alignSelf: 'center',
						}}
						onClick={() => setOpenErrorModal(false)}
					/>
				</div>
			</CustomModal>
			<CustomModal
				open={openModal}
				maxWidth="md"
				fullWidth
				title={t('associates.remove')}
			>
				<div>
					<span>
						{t('associates.remove-txt-1')} <b>{editAssociateData.name}</b>{' '}
						{t('associates.remove-txt-2')}
					</span>
					<div
						style={{
							display: 'flex',
							gap: 20,
							justifyContent: 'center',
							marginTop: 25,
						}}
					>
						<CustomButton
							variant="solid"
							label={t('common.cancel')}
							styles={{
								backgroundColor: 'black',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={() => setOpenModal(false)}
						/>
						<CustomButton
							variant="solid"
							label={t('common.delete')}
							styles={{
								backgroundColor: '#DD0528',
								fontSize: '18px',
								minHeight: '40px',
								width: '25%',
								borderRadius: '4px',
							}}
							onClick={handleDelete}
						/>
					</div>
				</div>
			</CustomModal>
		</>
	);
};

export default EditAssociate;
