import React from 'react';
import CustomModal from '../../../components/modal.component';
import CustomButton from '../../../components/button.component';
import { useTranslation } from 'react-i18next';

interface IDeleteModal {
	open: boolean;
	name: string;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	acceptModal: () => void;
}

const DeleteModal = ({ open, name, setOpen, acceptModal }: IDeleteModal) => {
	const [t] = useTranslation('global');
	return (
		<CustomModal open={open} maxWidth="md" fullWidth title={t('chargers.delete')}>
			<div>
				<span>
					{t('chargers.delete-msg')} <strong>{name}</strong>?
				</span>
				<div
					style={{
						display: 'flex',
						gap: 20,
						justifyContent: 'center',
						marginTop: 25,
					}}
				>
					<CustomButton
						variant="solid"
						label={t('common.cancel')}
						styles={{
							backgroundColor: 'black',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={() => setOpen(false)}
					/>
					<CustomButton
						variant="solid"
						label={t('common.delete')}
						styles={{
							backgroundColor: '#DD0528',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={acceptModal}
					/>
				</div>
			</div>
		</CustomModal>
	);
};

export default DeleteModal;
