export interface ICharger {
	chargers: string | number;
	station: string | JSX.Element;
	location: string;
	guns: string | number;
	actions?: JSX.Element;
}
export const mockDataCharger: ICharger[] = [
	{
		chargers: 'Charger 01',
		station: 'Station 01',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 02',
		station: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 03',
		station: 'Station 03',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 04',
		station: 'Station 04',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 05',
		station: 'Station 05',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 06',
		station: 'Station 06',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
	{
		chargers: 'Charger 07',
		station: 'Station 07',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '20',
	},
];

export interface IChargersResponse {
	name: string;
	stationId: number;
	chargerUniqueCode: number | string;
	latitude: string;
	longitude: string;
}
