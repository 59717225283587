import { Button, Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { hexToRGBA } from '../../../../../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useNavigate } from 'react-router-dom';
import { Bolt } from '@mui/icons-material';
import StopButton from '../../../../../../components/stop-button.component';
import { useUserPermisions } from '../../../../../../hooks/useUserPermissions';

interface IChargerCard {
	id: string | number;
	name: string;
	stationId: string | number;
	handleOpenModal: (charger: string) => void;
}

const ChargerCard = ({ id, name, stationId, handleOpenModal }: IChargerCard) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const nav = useNavigate();

	const { getChargersPermission } = useUserPermisions();

	return (
		<Card
			sx={{
				backgroundColor: hexToRGBA(colors.secondary, 0.2),
				border: `1px solid ${colors.secondary}`,
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
			}}
		>
			<CardHeader
				titleTypographyProps={{
					fontWeight: 600,
					fontSize: 'large',
					color: colors.primary,
				}}
				title={name}
				avatar={
					<Bolt
						style={{
							color: colors.primary,
						}}
					/>
				}
			/>
			<CardContent style={{ marginTop: 'auto' }}>
				<div
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: '5px',
					}}
				>
					<span
						style={{
							fontWeight: 'bold',
							color: colors.primary,
						}}
					>
						{t('chargers.asigned-to')}:
					</span>
					{stationId}
					{/* <span
            style={{
                fontWeight: 'bold',
                color: colors.primary,
            }}
        >
            Assosiates group:
        </span>
        Assosiate group */}
				</div>
			</CardContent>
			<CardActions
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					marginTop: 'auto',
				}}
			>
				<Button
					onClick={() => nav(`/partners/detail-charger/${id}`)}
					style={{
						color: colors.secondary,
						fontWeight: 'bold',
						fontSize: 14,
					}}
				>
					{t('common.view').toUpperCase()}
				</Button>
				{getChargersPermission() && (
					<StopButton
						onButtonClick={() => handleOpenModal(id.toString())}
						text={`${t('chargers.stop-charger')} ${name}`}
					/>
				)}
			</CardActions>
		</Card>
	);
};

export default ChargerCard;
