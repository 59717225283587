import { useEffect, useMemo, useState } from 'react';
import {
	IAddPartnerParams,
	IAddQionPartnerParams,
	IAddQionPartnerResponse,
	defaultPartner,
} from '../interfaces/add-partner.interface';
import {
	isEmpty,
	isNumberValidate,
	isPasswordValid,
	zipRegex,
} from '../../../utils/validators';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import { useNavigate } from 'react-router-dom';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { getJWTInfo } from '../../../utils/getJWTInfo';
import { IAddUserParams } from '../../../interfaces/user';
import { useTranslation } from 'react-i18next';

export function useAddParter() {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [partnerInfo, setPartnerInfo] = useState<IAddPartnerParams>(defaultPartner);
	const [newPartnerId, setNewPartnerId] = useState<string>('');

	/**
	 * Proceso para crear un Partner por Beto Mtz
	 *
	 * 1.- Se tiene que crear un partner en la BD externa (QION).
	 * 2.- Cuando ese partner se haya creado, se debe de crear un partner con los mismos datos
	 * en la BD utilizando el ID del partner previo para enviarlo en el campo "externalPartnerId".
	 * 3.- Cuando este segundo partner haya sido creado, se deberán de crear sus valores por defecto.
	 * 4.- Se debe de crear un usuario que pertenezca al parter del paso 2.
	 * 5.- Asignar todos los permisos existentes al usuario recien creado.
	 *
	 * **/

	const disabledButton = useMemo((): boolean => {
		if (
			isEmpty(partnerInfo.name) ||
			isEmpty(partnerInfo.stripeCode) ||
			isEmpty(partnerInfo.responsibleName) ||
			isEmpty(partnerInfo.responsibleLastName) ||
			isEmpty(partnerInfo.address) ||
			isEmpty(partnerInfo.state) ||
			isEmpty(partnerInfo.country) ||
			partnerInfo.zip.length < 1 ||
			isEmpty(partnerInfo.rfc) ||
			partnerInfo.phone.length < 10 ||
			isEmpty(partnerInfo.email) ||
			!isPasswordValid(partnerInfo.password) ||
			isEmpty(partnerInfo.img) ||
			partnerInfo.languages.length < 1 ||
			isEmpty(partnerInfo.hold)
		) {
			return true;
		}

		return false;
	}, [partnerInfo]);

	//Para agregar el partner en la BD de Qion
	const {
		execute: addQionPartner,
		value: addQionPartnerVal,
		status: addQionPartnerStatus,
	} = useApiRequest<IAddQionPartnerParams, IAddQionPartnerResponse>({
		path: 'cms-partner/add-partner',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'POST',
	});

	//Para agregar el partner en la BD Nextia
	const {
		execute: addPartner,
		value: addPartnerVal,
		status: addPartnerStatus,
	} = useBackendRequest<IAddPartnerParams, any>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	//Para agregar los valores por defecto a ese partner
	const { execute: createDefaultValues, status: createDefaultValuesStatus } =
		useBackendRequest<any, any>({
			path: 'partners',
			baseURL: process.env.REACT_APP_API,
			method: 'POST',
		});

	//Para crear el usuario
	const {
		execute: createUser,
		value: createUserVal,
		status: createUserStatus,
	} = useBackendRequest<IAddUserParams, { accessToken: string; refreshToken: string }>({
		path: 'users/sign-up',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	//Para añadir los permisos al usuario
	const { execute: editUserPermissions, status: editUserPermissionsStatus } =
		useBackendRequest<any, any>({
			path: 'users/saveOrUpdatePermissions',
			baseURL: process.env.REACT_APP_API,
			method: 'PUT',
		});

	useEffect(() => {
		if (addQionPartnerStatus === 'success') {
			if (addQionPartnerVal?.objData) {
				handleAddPartner(addQionPartnerVal.objData.id);
			} else {
				handleAddPartner(0);
			}
		} else if (addQionPartnerStatus === 'error') {
			handleAddPartner(0);
		}
	}, [addQionPartnerStatus]);

	const handleAddPartner = (id: number | string): void => {
		addPartner({ ...partnerInfo, externalPartnerId: id.toString() });
	};

	useEffect(() => {
		if (addPartnerStatus === 'success') {
			setNewPartnerId(addPartnerVal?.data.id);
			handleCreateDefaultValues(addPartnerVal?.data.id);
		} else if (addPartnerStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-created'),
					severity: 'error',
				})
			);
		}
	}, [addPartnerStatus]);

	const handleCreateDefaultValues = (id: string): void => {
		createDefaultValues({
			partnerId: id,
			language: 'English',
			default_iva: '1.0',
			default_charge_cost: '1.0',
			default_charge_factor: '1.0',
			default_parking_cost: '1.0',
			default_parking_factor: '1.0',
			themes: [],
			stripe_key_prod: '',
			stripe_key_qa: '',
			publishable_key_prod: '',
			publishable_key_qa: '',
			account_id_qa: '',
			account_id_prod: '',
		});
	};

	useEffect(() => {
		if (createDefaultValuesStatus === 'success') {
			handleCreateUser();
		} else if (createDefaultValuesStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createDefaultValuesStatus]);

	const handleCreateUser = (): void => {
		createUser({
			partnerId: newPartnerId,
			user_name: 'X',
			mobile: partnerInfo.phone,
			login_pwd: partnerInfo.password,
			real_name: 'X',
			sex: 'unknown',
			email: partnerInfo.email,
			user_system_type: 2,
			name: partnerInfo.responsibleName,
			first_last_name: partnerInfo.responsibleLastName,
			second_last_name: 'X',
		});
	};

	useEffect(() => {
		if (createUserStatus === 'success') {
			const user = getJWTInfo(createUserVal?.data?.accessToken);
			handleAddPermissions(user.id);
		} else if (createUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createUserStatus]);

	const handleAddPermissions = (userId: string): void => {
		editUserPermissions({
			userId: userId,
			permissions: [
				'367cec7a-c1ac-4814-8218-ec396535f112',
				'40038c3c-6adc-4659-a6e0-4edc3a5405ec',
				'4c70ac77-d39c-42d2-950e-3473448fc86e',
				'8aa84d8e-788f-4056-b937-a8f5def07452',
				'91b0a09a-f878-4060-a853-5e18d1b79235',
				'a36d3646-1445-43c9-bf5b-f249f819cfc0',
				'45452459-fa74-47ce-9149-df02a0cccc16',
				'45f46a8b-9beb-454e-b902-1e0ba617b958',
				'3d87b0ed-4aff-46ad-9666-3db5cebfeba0',
				'58b35f0b-521b-4920-9310-96d51045c4ac',
				'78d7c431-3222-4a3e-b8e1-1ffc3143a9ee',
			],
		});
	};

	useEffect(() => {
		if (editUserPermissionsStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.created'),
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (editUserPermissionsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-created'),
					severity: 'error',
				})
			);
		}
	}, [editUserPermissionsStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		} else if (name === 'phone') {
			if (!isNumberValidate(value)) return;
		} else if (name === 'hold') {
			if (!isNumberValidate(value)) return;
		}
		setPartnerInfo({ ...partnerInfo, [name]: value });
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setPartnerInfo({ ...partnerInfo, img: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteImage = (): void => {
		setPartnerInfo({ ...partnerInfo, img: '' });
	};

	const handleLanguages = (langs: string[]): void => {
		setPartnerInfo({ ...partnerInfo, languages: langs });
	};

	const handleCreatePartner = () => {
		dispatch(setLoading({ loading: true }));
		addQionPartner({
			img: '',
			name: partnerInfo.name,
			responsibleName: partnerInfo.responsibleName,
			responsibleLastName: partnerInfo.responsibleLastName,
			address: `${partnerInfo.address}, ${partnerInfo.state}, ${partnerInfo.country}, ${partnerInfo.zip}`,
			rfc: partnerInfo.rfc,
			email: partnerInfo.email,
			password: partnerInfo.password,
			phone: partnerInfo.phone,
		});
	};

	return {
		partnerInfo,
		disabledButton,
		handleSetData,
		handleImageChange,
		handleDeleteImage,
		handleCreatePartner,
		handleLanguages,
	};
}
