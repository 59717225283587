import { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { isLatitude, isLongitude, zipRegex } from '../../../utils/validators';
import { useGetChargers } from './useGetChargers';
import { IGun, defaultGun } from '../../../interfaces/gun.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEditGun } from './useEditGun';
import { IQionCharger, IQionGun } from '../../../interfaces/qion-data.interface';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../constants/user-roles.constants';
import { IOptions } from '../../../components/select-input.component';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { ICreateQionGunParams } from '../interfaces/create-qion-gun.interface';
import { ICharger } from '../../../interfaces/charger.interface';

export function useCreateGun() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const { state } = useLocation();
	const { id: gunId } = useParams();
	const [gunData, setGunData] = useState<IGun>({
		...defaultGun,
		partnerInfoId: role.roleName === ROLES.SUPER_ADMIN && !simulating ? '' : id,
	});
	const [openModal, setOpenModal] = useState<boolean>(false);

	const {
		chargersSelect,
		qionChargersSelect,
		qionChargers,
		chargersData,
		setChargersSelect,
	} = useGetChargers();

	const { handleGetGun, handleEditGun, handleDeleteGun } = useEditGun({
		setGunData,
	});

	const [gunsSelect, setGunsSelect] = useState<{ label: string; value: string }[]>([]);
	const [selectedChargerGuns, setSelectedChargerGuns] = useState<IQionGun[]>([]);

	const [selectedCharger, setSelectedCharger] = useState<ICharger>();

	const path = window.location.href;
	const view = path
		.split('/')
		.filter((part) => part !== '')
		.pop();

	const disabledButton = useMemo((): boolean => {
		return (
			gunData.partnerInfoId === '' ||
			gunData.name === '' ||
			gunData.address === '' ||
			gunData.state === '' ||
			gunData.country === '' ||
			gunData.zip.toString().length < 1 ||
			!isLatitude(gunData.latitude.toString()) ||
			!isLongitude(gunData.longitude.toString()) ||
			gunData.chargerId === '' ||
			gunData.qionGunUniqueId === '' ||
			gunData.plugType === ''
		);
	}, [gunData]);

	const { execute: addGun, status: addGunStatus } = useBackendRequest<IGun, {}>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (addGunStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.created'),
					severity: 'success',
				})
			);
			if (state) {
				navigate(-1);
			} else {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.GUNS },
				});
			}
		} else if (addGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-created'),
					severity: 'error',
				})
			);
		}
	}, [addGunStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		}
		if (name === 'latitude' || name === 'longitude') {
			const noWhiteSpace = value.replace(/\s/g, '');
			setGunData({ ...gunData, [name]: noWhiteSpace });
			return;
		}
		setGunData({ ...gunData, [name]: value });
	};

	const handleChargerSelectChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	): void => {
		const { value } = event.target;
		dispatch(setLoading({ loading: true }));
		const charger: ICharger = chargersData.filter((c) => c.id === value)[0];
		setSelectedCharger(charger);
		const { address, state, country, zip, qionChargerUniqueId } = charger;
		setGunData({
			...gunData,
			chargerId: value,
			address,
			state,
			country,
			zip,
		});
		getQionCharger({}, qionChargerUniqueId);
	};

	const generateGunsSelectData = (guns: IQionGun[]): void => {
		const arr: { label: string; value: string }[] = [];
		guns.map((gun) => {
			const data = {
				label: `${gun.gunUniqueCode} - ${gun.name}`,
				value: gun.gunUniqueCode,
			};
			arr.push(data);
		});
		setGunsSelect(arr);
		dispatch(setLoading({ loading: false }));
	};

	const handleGunSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		const selectedGun: IQionGun = selectedChargerGuns.filter(
			(g) => g.gunUniqueCode === value
		)[0];
		setGunData({
			...gunData,
			qionGunUniqueId: value,
			plugType: selectedGun.plugtype,
		});
	};

	const handleAddGun = (): void => {
		dispatch(setLoading({ loading: true }));
		createQionGun({
			gunUniqueCode: gunData.qionGunUniqueId,
			plugType: gunData.plugType,
			name: gunData.name,
			chargerUniqueCode: selectedCharger?.qionChargerUniqueId!,
			chargeCost: 1.0,
			chargeFactor: 1.0,
			parkingCost: 1.0,
			parkingFactor: 1.0,
		});
	};

	const editGun = (): void => {
		handleEditGun(gunData);
	};

	const deleteGun = (): void => {
		handleDeleteGun(gunId!);
	};

	useEffect(() => {
		if (view !== 'add-gun') {
			handleGetGun(gunId!);
		}
	}, []);

	const handlePartnerSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
		dispatch(setLoading({ loading: true }));
		const { value } = event.target;
		console.log(value);
		setGunData({ ...gunData, partnerInfoId: value });
		filterSelectByPartner(value);
	};

	const filterSelectByPartner = (partnerId: string): void => {
		const dataFiltered = chargersData.filter((c) => c.partnerInfoId === partnerId);
		const newSelect: IOptions[] = dataFiltered.map((d) => {
			return {
				label: d.name,
				value: d.id!,
			};
		});
		setGunData({
			...gunData,
			partnerInfoId: partnerId,
			chargerId: '',
			address: '',
			state: '',
			country: '',
			zip: '',
		});
		if (newSelect.length < 1) {
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.no-data'),
					severity: 'error',
				})
			);
		}
		setChargersSelect(newSelect);
		dispatch(setLoading({ loading: false }));
	};

	//Qion Charger info request
	const {
		execute: getQionCharger,
		value: getQionChargerVal,
		status: getQionChargerStatus,
	} = useApiRequest<{}, IQionCharger>({
		path: 'cms-charger/charger/',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'GET',
	});

	useEffect(() => {
		if (getQionChargerStatus === 'success') {
			if (getQionChargerVal?.objData) {
				if (getQionChargerVal.objData.guns.length > 0) {
					setSelectedChargerGuns(getQionChargerVal.objData.guns);
					generateGunsSelectData(getQionChargerVal.objData.guns);
				} else {
					setGunsSelect([]);
					setGunData({
						...gunData,
						qionGunUniqueId: '',
						plugType: '',
					});
					dispatch(setLoading({ loading: false }));
					dispatch(
						setAlert({
							show: true,
							message: t('guns.error-chargers'),
							severity: 'error',
						})
					);
				}
			} else {
				setGunsSelect([]);
				setGunData({
					...gunData,
					qionGunUniqueId: '',
					plugType: '',
				});
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-chargers'),
						severity: 'error',
					})
				);
			}
		} else if (getQionChargerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-chargers'),
					severity: 'error',
				})
			);
		}
	}, [getQionChargerStatus]);

	//To create a Gun on Qion DB
	const { execute: createQionGun, status: createQionGunStatus } = useApiRequest<
		ICreateQionGunParams,
		any
	>({
		path: 'cms-gun/add-gun',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'POST',
	});

	useEffect(() => {
		if (createQionGunStatus === 'success') {
			addGun(gunData);
		} else if (createQionGunStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-created'),
					severity: 'error',
				})
			);
		}
	}, [createQionGunStatus]);

	return {
		view,
		gunData,
		disabledButton,
		openModal,
		chargersSelect,
		qionChargersSelect,
		gunsSelect,
		handleSetData,
		handleChargerSelectChange,
		handleGunSelectChange,
		setOpenModal,
		handleAddGun,
		editGun,
		deleteGun,
		handlePartnerSelectChange,
	};
}
