import { useMemo, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Input from '../../components/input.component';
import VerificationCode from '../../components/VerificationCode';

import styles from './styles.module.css';
import Logo from '../../assets/logos/logo.png';
import CustomButton from '../../components/button.component';
import VerificationCodeImg from '../../assets/imgs/email-verification.png';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/modules/loading';

import { emailValid } from '../../utils/validators';
import { LoginCredentialsRequest } from './interfaces/login.interface';
import { setAlert } from '../../store/modules/snackbar';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import { useGetPermissions } from './hooks/useGetPermissions';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useValidateMultipartner } from './hooks/useValidateMultipartner';
import SelectInput from '../../components/select-input.component';

const Login = () => {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [actualStep, setActualStep] = useState<0 | 1>(0);
	const [email, setEmail] = useState<string>('');
	const [password, setPassword] = useState<string>('');

	const [code, setCode] = useState<string>('');

	const {
		hasMultipartners,
		partnersOptions,
		selectedPartner,
		handleEmailBlur,
		setSelectedPartner,
		getSelectedPartner,
	} = useValidateMultipartner();
	const { handleGetPermissions } = useGetPermissions({ getSelectedPartner });

	const disabledButton = useMemo((): boolean => {
		if (actualStep === 0) {
			if (emailValid(email).valid && password !== '' && selectedPartner !== '')
				return false;
			return true;
		} else if (actualStep === 1) {
			return code.length !== 6;
		}
		return true;
	}, [email, password, code, actualStep, selectedPartner]);

	const {
		execute: validateCredentials,
		value: valueCredentials,
		status: statusCredentials,
	} = useBackendRequest<LoginCredentialsRequest, any>({
		path: 'users/log-in',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: validateCode,
		value: valueCode,
		status: statusCode,
	} = useBackendRequest<LoginCredentialsRequest, any>({
		path: 'users/log-in',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const handleLogin = () => {
		dispatch(setLoading({ loading: true }));
		handleGetPermissions();
	};

	const handleSendCode = async () => {
		dispatch(setLoading({ loading: true }));
		validateCredentials({
			email: email,
			login_pwd: password,
			id_device: '1',
			partnerId: selectedPartner,
			system_type: 2,
		});
	};

	useEffect(() => {
		if (statusCredentials === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('login.code-sent'),
					severity: 'success',
				})
			);
			if (actualStep !== 1) setActualStep(1);
		} else if (statusCredentials === 'error') {
			dispatch(setLoading({ loading: false }));
			if (valueCredentials?.data === undefined) {
				dispatch(
					setAlert({
						show: true,
						message: t('login.error-services'),
						severity: 'error',
					})
				);
				return;
			}
			if (valueCredentials?.data.statusCode === 401) {
				if (valueCredentials.data.message.includes('password')) {
					dispatch(
						setAlert({
							show: true,
							message: t('login.invalid-psw'),
							severity: 'error',
						})
					);
				} else {
					dispatch(
						setAlert({
							show: true,
							message: t('login.invalid-email'),
							severity: 'error',
						})
					);
				}
				return;
			}
			dispatch(
				setAlert({
					show: true,
					message: t('login.error-services'),
					severity: 'error',
				})
			);
		}
	}, [statusCredentials]);

	const handleValidateCode = async () => {
		dispatch(setLoading({ loading: true }));
		validateCode({
			email: email,
			login_pwd: password,
			id_device: '1',
			system_type: 2,
			partnerId: selectedPartner,
			login_code: code,
		});
	};

	useEffect(() => {
		if (statusCode === 'success') {
			dispatch(setLoading({ loading: false }));
			window.localStorage.setItem('accessToken', valueCode?.data.accessToken);
			handleLogin();
		} else if (statusCode === 'error') {
			dispatch(setLoading({ loading: false }));
			if (valueCode?.data.statusCode === 401) {
				if (valueCode.data.message.includes('The user is not active.')) {
					dispatch(
						setAlert({
							show: true,
							message: t('login.not-active'),
							severity: 'error',
						})
					);
					return;
				}
				dispatch(
					setAlert({
						show: true,
						message: t('login.code-error'),
						severity: 'error',
					})
				);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('login.error-services'),
						severity: 'error',
					})
				);
			}
		}
	}, [statusCode]);

	return (
		<Grid className={styles.subContainer} container spacing={0}>
			<Grid className={styles.mainContainerForm} item xs={12} md={6}>
				<div className={styles.subContainerForm}>
					<div className={styles.subContainerFormInfo}>
						{actualStep === 0 ? (
							<>
								<img src={Logo} alt="Logo" />
								<h2
									style={{
										marginTop: '55px',
										marginBottom: '65px',
									}}
								>
									{t('login.sign-in')}
								</h2>
								<Input
									id="email"
									name="email"
									placeholder={t('login.email').toString()}
									style={{
										marginBottom: '27px',
										height: '43px',
										paddingLeft: '14px',
									}}
									onChange={(e) => setEmail(e.target.value)}
									type="text"
									readOnly
									onFocus={(e) => e.target.removeAttribute('readonly')}
									onBlur={(e) => handleEmailBlur(e.target.value)}
								/>
								<Input
									id="password"
									name="password"
									placeholder={t('login.psw').toString()}
									style={{
										height: '43px',
										paddingLeft: '14px',
										marginBottom: 40,
									}}
									onChange={(e) => setPassword(e.target.value)}
									type="password"
								/>
								{hasMultipartners && (
									<SelectInput
										name="partner"
										id="partner"
										label={'Partner'}
										placeholder={t('common.required').toString()}
										style={{ width: '100%', marginBottom: 40 }}
										required
										options={partnersOptions}
										onChange={(e) =>
											setSelectedPartner(e.target.value)
										}
										value={selectedPartner}
									/>
								)}
								<CustomButton
									label={t('login.sign-in')}
									onClick={handleSendCode}
									variant="solid"
									disabled={disabledButton}
								/>
							</>
						) : (
							<>
								<img src={VerificationCodeImg} alt="LogoEnvelope" />
								<h2>{t('login.two-step')}</h2>
								<span className={styles.enterCodeTxt}>
									{t('login.enter-code')}
								</span>
								<span className={styles.mailTxt}>
									{email || 'Us*****1@****le.com'}
								</span>
								<div
									style={{
										width: '100%',
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'start',
										marginBottom: '13px',
									}}
								>
									<span className={styles.tapCodeTxt}>
										{t('login.type-digits')}
									</span>
								</div>
								<VerificationCode setCode={setCode} />
								<CustomButton
									onClick={handleValidateCode}
									variant="solid"
									label={t('login.submit')}
									disabled={disabledButton}
								/>
								<span
									className={styles.enterCodeTxt}
									style={{ marginTop: '44px' }}
								>
									{t('login.no-code')}
									<span
										onClick={handleSendCode}
										className={styles.txtResend}
									>
										{t('login.resend')}
									</span>
								</span>
							</>
						)}
					</div>
				</div>
			</Grid>
			<Grid className={styles.mainContainerImgs} item xs={12} md={6} />
		</Grid>
	);
};

export default Login;
