import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../../store';
import { useEffect, useState } from 'react';
import { IStationsResponse } from '../../../../interfaces/stations.interface';
import { useUserPermisions } from '../../../../../../hooks/useUserPermissions';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import {
	IStation,
	defaultStation,
} from '../../../../../../interfaces/stations.interface';

export function useStationsTab() {
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const [stationsData, setStationsData] = useState<IStation[]>([]);
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedStation, setSelectedStation] = useState<IStation>(defaultStation);

	const dispatch = useDispatch();

	const { getStationsPermission } = useUserPermisions();

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getStationStatus === 'success') {
			if (getStationsVal?.data) {
				const data = filterData(getStationsVal?.data);
				setStationsData(data);
			} else {
				setStationsData([]);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getStationStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationStatus]);

	const filterData = (data: IStation[]): IStation[] => {
		const filteredData = data.filter((s) => s.partnerInfoId === id);
		return filteredData;
	};

	const handleOpenModal = (station: IStation): void => {
		setSelectedStation(station);
		setOpenModal(true);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return {
		stationsData,
		openModal,
		selectedStation,
		setOpenModal,
		handleOpenModal,
		getStationsPermission,
	};
}
