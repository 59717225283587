import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../../../hooks/useTableCustom';

export function useDetailsGroups() {
	const [t] = useTranslation('global');

	const detailGroupsColumns: IColumns[] = [
		{
			text: t('groups.group-name'),
			nameID: 'group',
		},

		{
			text: t('common.charge-cost'),
			nameID: 'chargeCost',
		},

		{
			text: t('common.charge-factor'),
			nameID: 'chargeFactor',
		},

		{
			text: t('common.parking-cost'),
			nameID: 'parkingCost',
		},

		{
			text: t('common.parking-factor'),
			nameID: 'parkingFactor',
		},

		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { detailGroupsColumns };
}
