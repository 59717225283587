// React dependencies
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Third-party libraries
import { Paper } from '@mui/material';
// Style dependencies
import styles from '../styles/partners-details.module.css';
// Resources
import TableCustom from '../../../components/table-custom.component';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { associateAdapter } from '../adapters/associate.adapter';
import { associateColumns } from '../utils/associate-table.columns';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export const AssociatesTab = () => {
	//const { associates } = useSelector((state: RootState) => state.partner);

	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();
	const navigate = useNavigate();
	const handleSeeDetails = () => {
		navigate('/partners/associates');
	};
	useEffect(() => {
		/* setData({
			...data,
			countRows: 0,
			rows: associateAdapter(associates, handleSeeDetails),
		}); */
	}, []);

	return (
		<Paper className={styles.boxPaper} elevation={1}>
			<TableCustom
				columns={associateColumns}
				data={data}
				setData={setData}
				showPagination
				showLimitPerPage
				titleHeader="Associates"
				showTotalCount={false}
				hideChips
			/>
		</Paper>
	);
};
