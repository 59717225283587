export interface IGun {
	day: string;
	price: string;
}
export const mockDataGun: IGun[] = [
	{
		day: 'Monday',
		price: '$12.34',
	},
	{
		day: 'Tuesday',
		price: '$12.34',
	},
	{
		day: 'Wednesday',
		price: '$12.34',
	},
	{
		day: 'Thursday',
		price: '$12.34',
	},
	{
		day: 'Friday',
		price: '$12.34',
	},
	{
		day: 'Saturday',
		price: '$12.34',
	},
	{
		day: 'Saturday',
		price: '$12.34',
	},
];
