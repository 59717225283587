import React from 'react';

import styles from './styles.component.module.css';
import { useTranslation } from 'react-i18next';

interface IServicesStatus {
	qionServiceWorking: boolean;
	externalServiceWorking: boolean;
}

const ServicesStatus = ({
	qionServiceWorking,
	externalServiceWorking,
}: IServicesStatus) => {
	const [t] = useTranslation('global');
	return (
		<div className={styles.servicesContainer}>
			<h2>{t('dashboard-1.status-title')}</h2>
			<div className={styles.statusRow}>
				<h3>{t('dashboard-1.service-name')}</h3>
				<h3>{t('dashboard-1.status')}</h3>
			</div>
			<div className={styles.statusRow}>
				<span>{t('dashboard-1.qion-name')}</span>
				<span
					style={{
						color: qionServiceWorking ? 'green' : 'red',
					}}
				>
					<b>
						{qionServiceWorking
							? t('dashboard-1.working')
							: t('dashboard-1.not-working')}
					</b>
				</span>
			</div>
			<div className={styles.statusRow}>
				<span>{t('dashboard-1.externals')}</span>
				<span
					style={{
						color: externalServiceWorking ? 'green' : 'red',
					}}
				>
					<b>
						{externalServiceWorking
							? t('dashboard-1.working')
							: t('dashboard-1.not-working')}
					</b>
				</span>
			</div>
		</div>
	);
};

export default ServicesStatus;
