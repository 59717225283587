import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useNavigate } from 'react-router-dom';
import { IAddUserParams } from '../../../interfaces/user';
import { useTranslation } from 'react-i18next';
import { isPasswordValid } from '../../../utils/validators';

export function useAddMobileUser() {
	const [t] = useTranslation('global');
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { partnerId } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [addUserData, setAddUserData] = useState<IAddUserParams>({
		partnerId: simulating ? id : partnerId.toString(),
		user_name: 'X',
		mobile: '',
		login_pwd: '',
		real_name: 'X',
		sex: 'unknown',
		email: '',
		user_system_type: 1,
		name: '',
		first_last_name: '',
		second_last_name: '',
	});

	const disabledButton = useMemo((): boolean => {
		if (
			addUserData.mobile.length < 10 ||
			!isPasswordValid(addUserData.login_pwd) ||
			addUserData.email === '' ||
			addUserData.name === '' ||
			addUserData.first_last_name === '' ||
			addUserData.second_last_name === ''
		)
			return true;
		return false;
	}, [addUserData]);

	const { execute: addUser, status: addUserStatus } = useBackendRequest<
		IAddUserParams,
		any
	>({
		path: 'users/sign-up',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (addUserStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('mobile-users.created'),
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (addUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('mobile-users.created-error'),
					severity: 'error',
				})
			);
		}
	}, [addUserStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { value, name } = event.target;
		if (name === 'name') {
			setAddUserData({
				...addUserData,
				user_name: value,
				real_name: value,
				name: value,
			});
		} else {
			setAddUserData({ ...addUserData, [name]: value });
		}
	};

	const handleAddUser = () => {
		dispatch(setLoading({ loading: true }));
		addUser(addUserData);
	};

	const getInputLabel = (inputName: string): string => {
		switch (inputName) {
			case 'name':
				return t('common.name');
			case 'first_last_name':
				return t('common.f-last-name');
			case 'second_last_name':
				return t('common.s-last-name');
			case 'mobile':
				return t('common.phone');
			case 'email':
				return t('common.email');
			case 'login_pwd':
				return t('common.password');
			default:
				return 'Label';
		}
	};

	return { addUserData, disabledButton, handleSetData, handleAddUser, getInputLabel };
}
