import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../store';
import { useDispatch, useSelector } from 'react-redux';

import AssociateGroupCard from '../../../../../components/associate-group-card';
import CustomButton from '../../../../../components/button.component';

import { Add } from '@mui/icons-material';

import styles from './styles.module.css';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { Card } from '@mui/material';
import { hexToRGBA } from '../../../../../utils/hex-to-rgba';
import { IAssociateGroupResponse } from '../../../interfaces/associates-group.interface';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';

const AdminAssociateGroupTab = () => {
	const [t] = useTranslation('global');
	//const { associatesGroups } = useSelector((state: RootState) => state.partner);
	const { id } = useSelector((state: RootState) => state.partner);
	const [associatesGroups, setAssociatesGroups] = useState<IAssociateGroupResponse[]>(
		[]
	);
	const colors = useSelector((state: RootState) => state.colors);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { getAssociatesGroupPermission } = useUserPermisions();

	const {
		execute: getAssociatesGroups,
		value: associatesGVal,
		status: associatesGStatus,
	} = useBackendRequest<unknown, IAssociateGroupResponse[]>({
		path: 'partners-group/partner/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (associatesGStatus === 'success') {
			if (associatesGVal?.data) {
				setAssociatesGroups(associatesGVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('groups.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (associatesGStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-info'),
					severity: 'error',
				})
			);
		}
	}, [associatesGStatus]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getAssociatesGroups({}, id);
	}, []);

	return (
		<div className={styles.container}>
			{getAssociatesGroupPermission() && (
				<div
					style={{
						display: 'flex',
						justifyContent: 'end',
						width: '100%',
					}}
				>
					<CustomButton
						label={t('groups.new')}
						variant={'solid'}
						onClick={() => navigate('/partners/add-associate-group')}
						styles={{
							width: 'fit-content',
							padding: '0 15px',
							display: 'flex',
							gap: '5px',
							backgroundColor: colors.accent,
							color: 'black',
						}}
						icon={Add}
					/>
				</div>
			)}
			<div className={styles.cardsContainer}>
				{associatesGroups.length > 0 ? (
					associatesGroups.map((associate, index) => (
						<AssociateGroupCard
							key={associate.id + 'AssociateGroup'}
							id={associate.id!}
							name={associate.group}
							associates={associate.associates?.length || 0}
							action="view"
						/>
					))
				) : (
					<Card
						sx={{
							padding: 5,
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: hexToRGBA(colors.secondary, 0.2),
							border: `1px solid ${colors.secondary}`,
						}}
					>
						<h1 style={{ justifySelf: 'center', color: colors.secondary }}>
							{t('groups.no-data')}
						</h1>
					</Card>
				)}
			</div>
		</div>
	);
};

export default AdminAssociateGroupTab;
