/* eslint-disable jsx-a11y/anchor-is-valid */
//Dependencies
import React, { useState } from 'react';

// Material Components
import { Box, useMediaQuery, useTheme } from '@mui/material';

// Assets
import styles from './index.module.css';

// Resources
import { isNumberValidate } from '../../utils/validators';

interface IVerificationCode {
	setCode: React.Dispatch<React.SetStateAction<string>>;
}

const VerificationCode = ({ setCode }: IVerificationCode) => {
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const [code1, setCode1] = useState({ valid: false, value: '' });
	const [code2, setCode2] = useState({ valid: false, value: '' });
	const [code3, setCode3] = useState({ valid: false, value: '' });
	const [code4, setCode4] = useState({ valid: false, value: '' });
	const [code5, setCode5] = useState({ valid: false, value: '' });
	const [code6, setCode6] = useState({ valid: false, value: '' });

	const setFocusElementByName = (name: string) => {
		const element = document.getElementsByName(name)[0];
		element.focus();
	};

	const validateNumber = (value: any) => {
		const esValido = isNumberValidate(value.target.value);

		if (esValido) {
			let valString = value.target.value;
			if (valString.length > 1) {
				valString = valString.charAt(valString.length - 1);
			}

			switch (value.target.name) {
				case 'code1':
					setCode1({ valid: true, value: valString });
					setCode(
						valString +
							code2.value +
							code3.value +
							code4.value +
							code5.value +
							code6.value
					);
					if (valString === '') return;
					setFocusElementByName('code2');
					break;
				case 'code2':
					setCode2({ valid: true, value: valString });
					setCode(
						code1.value +
							valString +
							code3.value +
							code4.value +
							code5.value +
							code6.value
					);
					if (valString === '') return;
					setFocusElementByName('code3');
					break;
				case 'code3':
					setCode3({ valid: true, value: valString });
					setCode(
						code1.value +
							code2.value +
							valString +
							code4.value +
							code5.value +
							code6.value
					);
					if (valString === '') return;
					setFocusElementByName('code4');
					break;
				case 'code4':
					setCode4({ valid: true, value: valString });
					setCode(
						code1.value +
							code2.value +
							code3.value +
							valString +
							code5.value +
							code6.value
					);
					if (valString === '') return;
					setFocusElementByName('code5');
					break;
				case 'code5':
					setCode5({ valid: true, value: valString });
					setCode(
						code1.value +
							code2.value +
							code3.value +
							code4.value +
							valString +
							code6.value
					);
					if (valString === '') return;
					setFocusElementByName('code6');
					break;
				case 'code6':
					setCode6({ valid: true, value: valString });
					setCode(
						code1.value +
							code2.value +
							code3.value +
							code4.value +
							code5.value +
							valString
					);
					break;
			}
		} else {
			switch (value.target.name) {
				case 'code1':
					setCode1({ valid: false, value: '' });
					break;
				case 'code2':
					setCode2({ valid: false, value: '' });
					break;
				case 'code3':
					setCode3({ valid: false, value: '' });
					break;
				case 'code4':
					setCode4({ valid: false, value: '' });
					break;
				case 'code5':
					setCode5({ valid: true, value: '' });
					break;
				case 'code6':
					setCode6({ valid: true, value: '' });
					break;
			}
		}
	};

	return (
		<Box
			className={styles.divCodigos}
			sx={{
				display: 'flex',
			}}
		>
			<input
				id="code1"
				name="code1"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code1.value}
				onChange={validateNumber}
			/>
			<input
				id="code2"
				name="code2"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code2.value}
				onChange={validateNumber}
			/>
			<input
				id="code3"
				name="code3"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code3.value}
				onChange={validateNumber}
			/>
			<input
				id="code4"
				name="code4"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code4.value}
				onChange={validateNumber}
			/>
			<input
				id="code5"
				name="code5"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code5.value}
				onChange={validateNumber}
			/>
			<input
				id="code6"
				name="code6"
				pattern="[0-9]"
				className={
					matches
						? styles.VerificacionCodigoResponsive
						: styles.VerificacionCodigo
				}
				value={code6.value}
				onChange={validateNumber}
			/>
		</Box>
	);
};

export default VerificationCode;
