import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { DefaultTFuncReturn } from 'i18next';

interface ICustomModal {
	title?: string | DefaultTFuncReturn;
	fullWidth?: boolean;
	maxWidth?: 'sm' | 'xs' | 'lg' | 'md' | 'xl';
	open: boolean;
	onClose?: () => void;
	onConfirm?: () => void;
	children: JSX.Element;
	backgroundStyles?: React.CSSProperties;
}
const CustomModal = ({
	fullWidth = true,
	maxWidth = 'md',
	open,
	onClose,
	children,
	title,
	backgroundStyles,
	onConfirm,
}: ICustomModal) => {
	return (
		<Dialog
			sx={{ ...backgroundStyles }}
			fullWidth={fullWidth}
			maxWidth={maxWidth ? maxWidth : 'md'}
			open={open}
			onClose={onClose}
		>
			{title && <DialogTitle style={{ fontWeight: 'bold' }}>{title}</DialogTitle>}
			<DialogContent>{children}</DialogContent>
			{(onClose || onConfirm) && (
				<DialogActions>
					{onClose && (
						<Button variant="contained" color="inherit" onClick={onClose}>
							Cancel
						</Button>
					)}
					{onConfirm && (
						<Button variant="contained" onClick={onConfirm}>
							Accept
						</Button>
					)}
				</DialogActions>
			)}
		</Dialog>
	);
};

export default CustomModal;
