// React dependencies
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Third-party libraries
import { Paper } from '@mui/material';
// Style dependencies
import styles from '../styles/partners-details.module.css';
// Resources
import TableCustom from '../../../components/table-custom.component';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { ICharger, mockDataCharger } from '../interfaces/charger.interface';
import { chargerAdapter } from '../adapters/charger.adapter';
import { chargerColumns } from '../utils/charger-table.columns';

export const ChargerTab = () => {
	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();
	const navigate = useNavigate();
	const handleSeeDetails = (data: ICharger) => {
		navigate('/partners/chargers');
	};
	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: chargerAdapter(mockDataCharger, handleSeeDetails),
		});
	}, []);
	return (
		<Paper className={styles.boxPaper} elevation={1}>
			<TableCustom
				columns={chargerColumns}
				data={data}
				setData={setData}
				showPagination
				showLimitPerPage
				titleHeader="Charger"
				showTotalCount={false}
				hideChips
			/>
		</Paper>
	);
};
