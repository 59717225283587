import { Grid } from '@mui/material';
import Input from '../../components/input.component';
import { useTranslation } from 'react-i18next';

import styles from './styles/styles.module.css';
import PasswordComponent from '../../components/password.component';
import CustomButton from '../../components/button.component';
import { RootState } from '../../store';
import { useSelector } from 'react-redux';
import { useChangePassword } from './hooks/useChangePassword';

const ChangePassword = () => {
	const [t] = useTranslation('global');
	const { accent } = useSelector((state: RootState) => state.colors);

	const {
		disabledButton,
		password,
		passwordConfirmation,
		setPassword,
		setPasswordConfirmation,
		handleUpdatePassword,
	} = useChangePassword();

	return (
		<Grid container spacing={3} className={styles.mainContainer}>
			<Grid item xs={12}>
				<h3 style={{ margin: 0 }}>{t('change-password.change')}</h3>
			</Grid>
			<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
				<Input
					id="password"
					name="password"
					value={password}
					placeholder={t('change-password.new').toString()}
					label={t('change-password.new').toString()}
					required
					onChange={(e) => setPassword(e.target.value)}
					type="password"
				/>
				<PasswordComponent password={password} />
			</Grid>
			<Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
				<Input
					id="passwordConfirmation"
					name="passwordConfirmation"
					value={passwordConfirmation}
					placeholder={t('change-password.confirmation').toString()}
					label={t('change-password.confirmation').toString()}
					required
					onChange={(e) => setPasswordConfirmation(e.target.value)}
					type="password"
				/>
			</Grid>
			<Grid item xs={12} display={'flex'} justifyContent={'flex-end'}>
				<CustomButton
					label={t('change-password.change')}
					onClick={handleUpdatePassword}
					variant="solid"
					disabled={disabledButton}
					styles={{
						width: '180px',
						backgroundColor: accent,
						color: 'black',
					}}
				/>
			</Grid>
		</Grid>
	);
};

export default ChangePassword;
