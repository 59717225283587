import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

declare module '@mui/material/styles' {
	interface Palette {
		greenCard: Palette['primary'];
		redCard: Palette['primary'];
		yellowCard: Palette['primary'];
		brown: Palette['primary'];
		black: Palette['primary'];
		white: Palette['primary'];
		greenAlert: Palette['primary'];
	}

	interface PaletteOptions {
		greenCard?: PaletteOptions['primary'];
		redCard?: PaletteOptions['primary'];
		yellowCard?: PaletteOptions['primary'];
		brown?: PaletteOptions['primary'];
		black?: PaletteOptions['primary'];
		white?: PaletteOptions['primary'];
		greenAlert?: PaletteOptions['primary'];
	}
}
const theme = createTheme(
	{
		palette: {
			primary: {
				light: '#e5edff',
				main: '#155CFC',
				dark: '#104bd6',
			},
			secondary: {
				main: '#197AFF',
				dark: '#1d7ad6',
			},
			success: {
				main: '#51CD8B',
				dark: '#728472',
				light: '#D0F0C9',
				contrastText: '#DEFFF1',
			},
			warning: {
				main: '#FCF0D8',
				dark: '#D1943D',
			},
			error: {
				main: '#DD0528',
				dark: '#DD0528',
				light: '#FFE2E5',
			},
			info: {
				main: '#293990',
				light: '#CBE4FF',
			},
			brown: {
				main: '#A08A7F',
				light: '#EDE6E2',
			},
			background: {
				default: '#f8f8f8',
			},
			grey: {
				'200': '#CBCBCB',
				'300': '#5E5E71',
			},
			greenCard: {
				main: 'rgb(0, 174, 116)',
				dark: 'rgb(85, 105, 88)',
				light: '#6DD0B9',
			},
			redCard: {
				main: 'rgb(168, 7, 53)',
			},
			yellowCard: {
				main: '#F0B366',
				dark: '#90701C',
			},
			black: {
				main: 'rgb(29, 28, 40)',
			},
			white: {
				main: 'rgb(255, 255, 255)',
			},
			greenAlert: {
				main: '#08812c',
			},
		},
		components: {},
	},
	esES
);

export const themBackdrop = createTheme({
	components: {
		MuiBackdrop: {
			styleOverrides: {
				root: {
					zIndex: 99999,
					backgroundColor: 'rgba(0,0,0,0.6)',
				},
			},
		},
	},
});

export default theme;
