import {
	Paper,
	Grid,
	Card,
	CardHeader,
	CardContent,
	Typography,
	CardActions,
	Avatar,
	Tab,
	Tabs,
} from '@mui/material';
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import React, { useEffect, useState } from 'react';
import styles from './styles/associates.module.css';
import { useTableCustom } from '../../hooks/useTableCustom';
import { associatesAdapter } from './adapters/associates.adapter';
import Graph from '../../assets/imgs/img-grap.png';

import {
	associateColumns,
	chargerColumns,
	stationsColumns,
	financeColumns,
} from './utils/associates.column';
import {
	mockDataAssociate,
	mockDataCharger,
	mockDataStations,
	mockDataFinance,
} from './interfaces/associates.interface';
import TableCustom from '../../components/table-custom.component';
import CustomButton from '../../components/button.component';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { useNavigate } from 'react-router-dom';
export const Associates = () => {
	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();
	const [tabValue, setValueTab] = useState<number>(0);
	const navigate = useNavigate();
	const [manageData, setManageData] = useState<any>({
		columns: associateColumns,
		data: mockDataAssociate,
		title: 'Associates',
	});
	const onHandleTab = (tab: number) => {
		switch (tab) {
			case 0:
				setManageData({
					columns: associateColumns,
					data: mockDataAssociate,
					title: 'Associates',
				});
				break;
			case 1:
				setManageData({
					columns: stationsColumns,
					data: mockDataStations,
					title: 'Stations',
				});
				break;
			case 2:
				setManageData({
					columns: chargerColumns,
					data: mockDataCharger,
					title: 'Chargers',
				});
				break;
			case 3:
				setManageData({
					columns: financeColumns,
					data: mockDataFinance,
					title: 'Finance per month',
				});
				break;

			default:
				break;
		}
	};
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValueTab(newValue);
		onHandleTab(newValue);
	};
	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: associatesAdapter(manageData.data),
		});
	}, [manageData, tabValue]);

	return (
		<Grid container gap={1}>
			<Grid container item xs={12} md={11} display="flex">
				<Grid item xs={5} sm={4} md={3} lg={2} xl={1}>
					<CustomButton
						label="Back"
						onClick={() => navigate(-1)}
						variant="solid"
						styles={{ backgroundColor: 'black' }}
						icon={ArrowBackIcon}
					/>
				</Grid>
				<Grid
					item
					xs={5}
					sm={4}
					md={3}
					lg={2}
					xl={1}
					flex={1}
					style={{ marginLeft: 'auto' }}
				>
					<CustomButton
						icon={SimCardDownloadIcon}
						label="Report"
						onClick={() => {}}
						variant="solid"
						styles={{ backgroundColor: '#2AA8E0' }}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12} md={4} lg={3} xl={2}>
				<Card>
					<Grid item xs={12} className={styles.boxAvatar}>
						<Avatar className={styles.avatarBackground} variant="square">
							<GroupRoundedIcon />
						</Avatar>
					</Grid>
					<CardContent>
						<Typography
							variant="h4"
							fontWeight={'bold'}
							letterSpacing={2}
							textAlign="center"
						>
							Associates Group 01
						</Typography>
						<Grid container className={styles.marginContent}>
							<Grid
								item
								xs={4}
								display="flex"
								flexDirection="column"
								gap={0}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									textAlign="center"
								>
									5
								</Typography>
								<Typography
									variant="subtitle2"
									fontWeight="bold"
									textAlign="center"
									color="#9C9FB5"
								>
									Associate
								</Typography>
							</Grid>
							<Grid
								item
								xs={4}
								display="flex"
								flexDirection="column"
								gap={0}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									textAlign="center"
								>
									10
								</Typography>
								<Typography
									variant="subtitle2"
									fontWeight="bold"
									textAlign="center"
									color="#9C9FB5"
								>
									Stations
								</Typography>
							</Grid>
							<Grid
								item
								xs={4}
								display="flex"
								flexDirection="column"
								gap={0}
							>
								<Typography
									variant="h6"
									fontWeight="bold"
									textAlign="center"
								>
									20
								</Typography>
								<Typography
									variant="subtitle2"
									fontWeight="bold"
									textAlign="center"
									color="#9C9FB5"
								>
									Chargers
								</Typography>
							</Grid>
						</Grid>
						<Typography variant="h6" color={'#B6B6C3'}></Typography>
					</CardContent>
					<CardActions disableSpacing>
						<Tabs
							orientation="vertical"
							value={tabValue}
							onChange={handleChange}
							style={{ width: '100%' }}
							variant={'fullWidth'}
						>
							<Tab label="Associates" style={{ textAlign: 'left' }} />
							<Tab label="Stations" style={{ textAlign: 'left' }} />
							<Tab label="Chargers" style={{ textAlign: 'left' }} />
							<Tab label="Finance" style={{ textAlign: 'left' }} />
						</Tabs>
					</CardActions>
				</Card>
				<Card className={styles.infoCard}>
					<CardContent>
						<Grid container gap={1}>
							<Grid item xs={12} display="flex" flexDirection={'column'}>
								<Typography variant="subtitle1" color="#C8C8C8">
									RFC
								</Typography>
								<Typography variant="subtitle1" color="#002739">
									ABC 3453253 P-52
								</Typography>
							</Grid>
							<Grid item xs={12} display="flex" flexDirection={'column'}>
								<Typography variant="subtitle1" color="#C8C8C8">
									Email
								</Typography>
								<Typography variant="subtitle1" color="#002739">
									Mailuser@example.com
								</Typography>
							</Grid>
							<Grid item xs={12} display="flex" flexDirection={'column'}>
								<Typography variant="subtitle1" color="#C8C8C8">
									Phone
								</Typography>
								<Typography variant="subtitle1" color="#002739">
									222-222-2222
								</Typography>
							</Grid>
						</Grid>

						<Typography variant="h6" color={'#B6B6C3'}></Typography>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12} md={7} lg={8} xl={9}>
				{tabValue === 3 && (
					<Grid container item gap={0} xs={12}>
						<Grid item xs={12} md={6}>
							<img src={Graph} width={'99%'} />
						</Grid>
						<Grid item xs={12} md={6}>
							<img src={Graph} width={'99%'} />
						</Grid>
					</Grid>
				)}
				<Paper elevation={2} className={styles.boxPaper}>
					<TableCustom
						columns={manageData.columns}
						data={data}
						setData={setData}
						showPagination
						showLimitPerPage
						titleHeader={manageData.title}
						showTotalCount={false}
						hideChips
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
