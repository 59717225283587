import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../../components/button-icon-small.component';
import { useTranslation } from 'react-i18next';
import { IGun } from '../../../../interfaces/gun.interface';

export function useConnectorsAdapter() {
	const [t] = useTranslation('global');

	const connectorsAdapter = (
		data: IGun[],
		handleSeeDetails: (gun: IGun) => void
	): any[] => {
		const newData: any[] = data.map((item: IGun) => {
			return {
				...item,
				actions: (
					<ButtonIconSmall
						onPress={() => handleSeeDetails(item)}
						type="primary"
						icon={<VisibilityOutlinedIcon />}
						hoverLabel={t('common.see-details')}
					/>
				),
			};
		});
		return newData;
	};

	return { connectorsAdapter };
}
