import { useTranslation } from 'react-i18next';
import DateInput from '../../../components/DateInput';
import CustomMultiSelect from '../../../components/custom-multiselect.component';
import styles from './styles/filters-selector.component.module.css';
import { useFiltersSelector } from './hooks/useFiltersSelector';
import SelectInput from '../../../components/select-input.component';
import dayjs from 'dayjs';
import Input from '../../../components/input.component';
import CustomButton from '../../../components/button.component';
import { useGenerateReport } from './hooks/useGenerateReport';
import { RadioButton } from '../../../components/radio-button.component';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

const FiltersSelector = () => {
	const [t] = useTranslation('global');

	const {
		filterData,
		stationsOptions,
		setFilterData,
		handleSetData,
		handleGunsChange,
		handleSelectChange,
	} = useFiltersSelector();
	const { disabledButton, handleDownloadDocument } = useGenerateReport();

	const { state } = useLocation();
	const { guns } = state;

	return (
		<div className={styles.mainContainer}>
			<Grid container spacing={2}>
				<Grid item xl={4} lg={4} md={12}>
					<CustomMultiSelect
						name={t('common.guns')}
						selectData={guns}
						onChange={handleGunsChange}
						val={filterData.gun_ids}
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={12}>
					<DateInput
						label={t('financial.start-date')}
						name="datetime_charging_start"
						value={dayjs(filterData.datetime_charging_start)}
						onChange={handleSetData}
					/>
				</Grid>
				<Grid item xl={4} lg={4} md={12}>
					<DateInput
						label={t('financial.end-date')}
						name="datetime_charging_end"
						value={dayjs(filterData.datetime_charging_end)}
						onChange={handleSetData}
					/>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid item xl={6} lg={6} md={12}>
					<SelectInput
						name="station"
						id="station"
						label={t('financial.select-station').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={stationsOptions}
						onChange={handleSelectChange}
						value={filterData.station}
					/>
				</Grid>
				<Grid item xl={6} lg={6} md={12}>
					<Input
						id="order_number"
						name="order_number"
						label={t('report.order').toString()}
						onChange={handleSetData}
						type="text"
					/>
				</Grid>
			</Grid>
			<div className={styles.rowRadio}>
				<span>{t('report.format')}: </span>
				<RadioButton
					name="document"
					id="csv"
					value="csv"
					checked={filterData.document === 'csv'}
					label={'CSV'}
					onClick={(e) =>
						setFilterData({ ...filterData, document: e.target.value })
					}
				/>
				<RadioButton
					name="document"
					id="pdf"
					value="pdf"
					checked={filterData.document === 'pdf'}
					label={'PDF'}
					onClick={(e) =>
						setFilterData({ ...filterData, document: e.target.value })
					}
				/>
			</div>
			<CustomButton
				label={t('financial.download')}
				onClick={handleDownloadDocument}
				variant="solid"
				disabled={disabledButton}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					justifySelf: 'flex-end',
					alignSelf: 'flex-end',
				}}
			/>
		</div>
	);
};

export default FiltersSelector;
