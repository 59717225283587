import styles from './styles/station-card-styles.component.module.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import { LocalGasStationOutlined } from '@mui/icons-material';
import { hexToRGBA } from '../utils/hex-to-rgba';
import StopButton from './stop-button.component';
import { useTranslation } from 'react-i18next';
import { useUserPermisions } from '../hooks/useUserPermissions';

interface IStationCard {
	id: number | string;
	name: string;
	address: string;
	state: string;
	country: string;
	zip: string | number;
	onStopButtonClick: () => void;
}

const StationCard = ({
	id,
	name,
	address,
	state,
	country,
	zip,
	onStopButtonClick,
}: IStationCard) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { getStationsPermission } = useUserPermisions();
	const navigate = useNavigate();
	return (
		<div
			className={styles.container}
			style={{
				backgroundColor: hexToRGBA(colors.secondary, 0.2),
				border: `1px solid ${colors.secondary}`,
			}}
		>
			<div className={styles.rowName}>
				<LocalGasStationOutlined
					style={{ color: colors.primary, width: '25px', height: '25px' }}
				/>
				<span className={styles.name} style={{ color: colors.primary }}>
					{name}
				</span>
			</div>
			<div className={styles.rowInfo}>
				{/* <span className={styles.chargers}>{chargers} chargers</span> */}
				<span className={styles.address}>
					<b style={{ color: colors.primary }}>{t('common.address')}</b>
					{address}
				</span>
				<span className={styles.address}>
					<b style={{ color: colors.primary }}>
						{t('common.state')} & {t('common.country')}
					</b>
					{state}, {country}
				</span>
				<span className={styles.address}>
					<b style={{ color: colors.primary }}>{t('common.zip')}</b>
					{zip}
				</span>
			</div>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<span
					className={styles.viewLink}
					style={{ color: colors.secondary }}
					onClick={() => navigate(`/stations/details/${id}`)}
				>
					{t('common.view').toUpperCase()}
				</span>
				{getStationsPermission() && (
					<StopButton
						onButtonClick={onStopButtonClick}
						text={`${t('common.stop')} ${name}`}
					/>
				)}
			</div>
		</div>
	);
};

export default StationCard;
