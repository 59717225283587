import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useFinancialColums() {
	const [t] = useTranslation('global');
	const financialTabColumns: IColumns[] = [
		{
			text: t('financial.date'),
			nameID: 'datetime_charging',
		},
		{
			text: t('financial.order-number'),
			nameID: 'order_number',
			align: 'center',
		},
		{
			text: t('financial.charging-pile-number'),
			nameID: 'gun_id',
			align: 'center',
		},
		{
			text: t('financial.station'),
			nameID: 'station',
		},
		{
			text: t('financial.status'),
			nameID: 'status',
		},
		{
			text: t('financial.car-number'),
			nameID: 'vehicle_id',
			align: 'center',
		},
		{
			text: t('financial.voltage'),
			nameID: 'voltage',
			align: 'center',
		},
		{
			text: t('financial.current'),
			nameID: 'current',
			align: 'center',
		},
		{
			text: t('financial.acumulated'),
			nameID: 'accumulated_charge',
			align: 'center',
		},
		{
			text: t('financial.time'),
			nameID: 'load_time',
			align: 'center',
		},
		{
			text: t('financial.amount'),
			nameID: 'accumulated_charge_cost',
			align: 'center',
		},
	];

	return { financialTabColumns };
}
