import { Paper } from '@mui/material';
import TableCustom from '../../components/table-custom.component';
import { useTableCustom } from '../../hooks/useTableCustom';
import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import { setLoading } from '../../store/modules/loading';
import { setAlert } from '../../store/modules/snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import { useUserPermisions } from '../../hooks/useUserPermissions';
import { useUserPermissionAdapter } from './adapters/user-permision.adapter';
import { useTranslation } from 'react-i18next';
import { useUserPermissionsColumns } from './utils/user-permissions.colums';
import { useGetCmsUsers } from '../../hooks/useGetCmsUsers';
import { IAllUsersResponse } from '../login/interfaces/login.interface';

const UsersPermissions = () => {
	const [t] = useTranslation('global');
	const { partnerId, role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data, setData } = useTableCustom<any>();

	const { getUsersPermission } = useUserPermisions();
	const { userPermissionAdapter } = useUserPermissionAdapter();
	const { userPermissionsColumns } = useUserPermissionsColumns();
	const { filterCmsUsers } = useGetCmsUsers();

	const {
		execute: getUsers,
		value: getUsersVal,
		status: getUsersStatus,
	} = useBackendRequest<any, IAllUsersResponse>({
		path: 'users/find-by-partner-id/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getUsersStatus === 'success') {
			if (getUsersVal?.data) {
				const filteredCMSUsers = filterCmsUsers(getUsersVal?.data.userCms);
				if (filteredCMSUsers.length > 0) {
					setData({
						...data,
						countRows: 0,
						rows: userPermissionAdapter(
							filteredCMSUsers,
							handleEditPermissions,
							getUsersPermission,
							role.roleName
						),
					});
				} else {
					dispatch(
						setAlert({
							show: true,
							message: t('users.user-list-error'),
							severity: 'error',
						})
					);
				}
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('users.user-list-error'),
						severity: 'error',
					})
				);
			}
		} else if (getUsersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-list-error'),
					severity: 'error',
				})
			);
		}
	}, [getUsersStatus]);

	const handleEditPermissions = (id: string) => {
		navigate(`/users/edit-permissions/${id}`);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getUsers({}, simulating ? id : partnerId.toString());
	}, []);

	return (
		<div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
			<Paper elevation={1} sx={{ padding: 1 }}>
				<TableCustom
					titleHeader={t('users.user-permissions')}
					columns={userPermissionsColumns}
					data={data}
					setData={setData}
					showTotalCount={false}
					hideChips
				/>
			</Paper>
		</div>
	);
};

export default UsersPermissions;
