import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useSuperAdminGunsColumns() {
	const [t] = useTranslation('global');

	const superAdminGunsColumns: IColumns[] = [
		{
			text: t('common.name'),
			nameID: 'name',
		},
		{
			text: t('dashboard-4.gun-id'),
			nameID: 'qionGunUniqueId',
		},
		{
			text: t('common.address'),
			nameID: 'address',
		},
		{
			text: t('common.partner'),
			nameID: 'partnerInfoId',
		},
		{
			text: t('common.plugtype'),
			nameID: 'plugType',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { superAdminGunsColumns };
}
