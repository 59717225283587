import dayjs from 'dayjs';
import { IGunsStats } from '../../../interfaces/guns-stats.interface';
import { hexToRGBA } from '../../../utils/hex-to-rgba';

export const financialAdapter = (data: IGunsStats[], status: string[]): any[] => {
	const getChargingStatus = (itemStatus: any): any => {
		switch (itemStatus) {
			case true:
				return (
					<div
						style={{
							backgroundColor: hexToRGBA('#0081C6', 0.2),
							borderRadius: '4px',
							padding: '10px',
							color: '#0081C6',
							fontWeight: 'bold',
						}}
					>
						{status[0]}
					</div>
				);
			case false:
				return (
					<div
						style={{
							backgroundColor: hexToRGBA('#009B48', 0.2),
							borderRadius: '4px',
							padding: '10px',
							color: '#009B48',
							fontWeight: 'bold',
						}}
					>
						{status[1]}
					</div>
				);
			default:
				return (
					<div
						style={{
							backgroundColor: hexToRGBA('#FF0000', 0.2),
							borderRadius: '4px',
							padding: '10px',
							color: '#FF0000',
							fontWeight: 'bold',
						}}
					>
						Error
					</div>
				);
		}
	};

	const newData: any[] = data.map((item: IGunsStats) => {
		return {
			...item,
			accumulated_charge: <>${item.accumulated_charge}</>,
			accumulated_charge_cost: <>${item.accumulated_charge_cost}</>,
			datetime_charging: (
				<div style={{ width: '125px' }}>
					{dayjs(item.update_time).format('DD-MM-YYYY HH:ss')}
				</div>
			),
			status: getChargingStatus(item.status),
		};
	});
	return newData;
};
