// React dependencies
import { useEffect } from 'react';

// Third-party libraries
import {
	Avatar,
	Grid,
	Paper,
	Typography,
	Card,
	CardContent,
	CardHeader,
	CardActions,
} from '@mui/material';
import EvStationIcon from '@mui/icons-material/EvStation';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import AdjustIcon from '@mui/icons-material/Adjust';
// Style dependencies
import styles from './styles/guns.module.css';
// Resources
import CustomButton from '../../components/button.component';
import Graph from '../../assets/imgs/img-grap.png';
import { useTableCustom } from '../../hooks/useTableCustom';
import { mockDataGun } from './interfaces/guns.interface';
import { gunsAdapter } from './adapter/guns.adapter';
import { gunsColumns } from './utils/guns.column';
import TableCustom from '../../components/table-custom.component';

export const Guns = () => {
	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();

	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: gunsAdapter(mockDataGun),
		});
	}, []);

	return (
		<Grid container gap={2}>
			<Grid container item xs={12} md={3} gap={1}>
				<Grid item xs={12}>
					<Paper className={styles.paperGrid}>
						<Grid
							item
							xs={12}
							display="flex"
							alignItems="center"
							justifyContent="center"
						>
							<Avatar className={styles.avatarBackground} variant="square">
								<EvStationIcon />
							</Avatar>
						</Grid>

						<Typography
							variant="h4"
							color="var(--color-blue-main)"
							fontWeight="bold"
							textAlign="center"
						>
							Gun 01
						</Typography>
					</Paper>
				</Grid>
				<Grid item xs={12}>
					<Card className={styles.infoCard}>
						<CardHeader
							avatar={<AdjustIcon />}
							className={styles.headerCard}
							title="Plugtype"
						/>
						<CardContent className={styles.plugType}>
							<Avatar className={styles.avatarBackground2}>
								<AdjustIcon />
							</Avatar>
							<Typography
								fontSize={18}
								variant="subtitle2"
								color="var(--color-blue-main)"
							>
								Type 1
							</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card className={styles.infoCard}>
						<CardHeader
							avatar={<ElectricBoltIcon />}
							className={styles.headerCard}
						/>
						<CardContent>
							<Typography
								variant="subtitle1"
								fontWeight={'bold'}
								color={'#B6B6C3'}
								fontSize={18}
							>
								assigned to
							</Typography>
							<Typography
								fontSize={18}
								variant="subtitle2"
								color="var(--color-blue-main)"
								fontWeight={'bold'}
							>
								Station 02
							</Typography>
						</CardContent>
						<CardActions disableSpacing sx={{ marginTop: '25px' }}>
							<Grid item xs={3}>
								<CustomButton
									label="View"
									variant="solid"
									onClick={() => {}}
									styles={{ backgroundColor: '#2AA8E0' }}
								/>
							</Grid>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
			<Grid container item xs={12} md={4}>
				<Grid item xs={12} className={styles.imgContainer}>
					<img src={Graph} width={'99%'} />
				</Grid>
				<Grid item xs={12} className={styles.imgContainer}>
					<img src={Graph} width={'99%'} />
				</Grid>
			</Grid>
			<Grid container item xs={12} md={4}>
				<Paper sx={{ padding: '10px' }}>
					<TableCustom
						columns={gunsColumns}
						data={data}
						setData={setData}
						titleHeader={'Price'}
						showTotalCount={false}
						hideChips
						showSearchBar={false}
						bodyBold
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
