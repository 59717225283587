import { useDispatch, useSelector } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useNavigate, useParams } from 'react-router-dom';
import { ICharger } from '../../../interfaces/charger.interface';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { useApiRequest } from '../../../hooks/useApiRequest';

interface IUseEditCharger {
	setChargerData: React.Dispatch<React.SetStateAction<ICharger>>;
}

export function useEditCharger({ setChargerData }: IUseEditCharger) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id: chargerId } = useParams();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { role } = useSelector((state: RootState) => state.user);

	const {
		execute: getCharger,
		value: getChargerVal,
		status: getChargerStatus,
	} = useBackendRequest<any, ICharger>({
		path: 'chargers/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getChargerStatus === 'success') {
			if (getChargerVal?.data) {
				setChargerData(getChargerVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('chargers.error-single-info'),
						severity: 'error',
					})
				);
			}
		} else if (getChargerStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-single-info'),
					severity: 'error',
				})
			);
		}
	}, [getChargerStatus]);

	const { execute: editCharger, status: editChargerStatus } = useBackendRequest<
		ICharger,
		any
	>({
		path: 'chargers/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (editChargerStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.edited'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.CHARGERS },
				});
			} else {
				navigate('/partners/all-chargers');
			}
		} else if (editChargerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-edited'),
					severity: 'error',
				})
			);
		}
	}, [editChargerStatus]);

	const { execute: deleteCharger, status: deleteChargerStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'chargers/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteChargerStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.deleted'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.CHARGERS },
				});
			} else {
				navigate('/partners/all-chargers');
			}
		} else if (deleteChargerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deleteChargerStatus]);

	const handleGetCharger = (id: string): void => {
		dispatch(setLoading({ loading: true }));
		getCharger({}, id);
	};

	const handleEditCharger = (charger: ICharger): void => {
		dispatch(setLoading({ loading: true }));
		const id = charger.id;
		delete charger.id;
		delete charger.create_time;
		delete charger.update_time;
		editCharger(charger, id);
	};

	const handleDeleteCharger = (id: string): void => {
		dispatch(setLoading({ loading: true }));
		deleteCharger({}, id);
	};

	const handleDeleteQionCharger = (
		stationUniqueCode: string,
		chargerUniqueCode: string
	): void => {
		deleteQionCharger({ chargerUniqueCode: chargerUniqueCode }, stationUniqueCode);
	};

	//To DELETE a Charger on Qion DB
	const { execute: deleteQionCharger, status: deleteQionChargerStatus } = useApiRequest<
		{ chargerUniqueCode: string },
		any
	>({
		path: 'cms-station/remove-charger/',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteQionChargerStatus === 'success') {
			deleteCharger({}, chargerId);
		} else if (deleteQionChargerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deleteQionChargerStatus]);

	return {
		handleGetCharger,
		handleEditCharger,
		handleDeleteCharger,
		handleDeleteQionCharger,
	};
}
