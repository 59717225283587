import Dashboard from '../../pages/dasboard';
import RevenueDashboard from '../../pages/dasboard/pages/revenueDashboard';
import LocationDashboard from '../../pages/dasboard/pages/locationDashboard';
import ChargingDashboard from '../../pages/dasboard/pages/chargingDashboard';
//Icons
import { ReactComponent as Apps } from '../../assets/svgs/apps.svg';
import { ReactComponent as Partner } from '../../assets/svgs/partners.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svgs/settings.svg';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';

import PartnerDetail from '../../pages/partners-group-details/index';
import Partners from '../../pages/partners';
import Schedules from '../../pages/schedules';
import Financial from '../../pages/financial';

import { Users } from '../../pages/users';
import { AddUser } from '../../pages/add-user';
import { Associates } from '../../pages/associates';
import { Chargers } from '../../pages/chargers';
import { Guns } from '../../pages/guns';
import AddAssociateGroup from '../../pages/add-associate-group';
import AssociateGroupDetails from '../../pages/associate-group-details';
import DetailCharger from '../../pages/partners-group-details/components/admin/CargerTab/detail-charger';
import StationDetails from '../../pages/station-details';
import AdminDetailGun from '../../pages/partners-group-details/components/admin/GunsTab/detail-gun';
import AddStation from '../../pages/add-station';
import EditAssociate from '../../pages/partners-group-details/components/admin/Associates/edit-associate';
import DetailAssociate from '../../pages/partners-group-details/components/admin/Associates/detail-associate';
import AddSchedules from '../../pages/add-schedules';
import SettingsLanguage from '../../pages/settings-page/components/settings-language.component';
import SettingsTheme from '../../pages/settings-page/components/settings-theme.component';
import UsersPermissions from '../../pages/users-permissions';
import EditUserPermissions from '../../pages/users-edit-permissions';
import UserDetails from '../../pages/users-details';
import MobileUsers from '../../pages/users-mobile';
import { filterAvailableMenuItems, IMenuList } from '../utils/route-list';
import AddMobileUser from '../../pages/add-user-mobile';

import {
	Bolt,
	Person,
	Public,
	Paid,
	EvStation,
	Today,
	Translate,
	Palette,
	Rule,
	CalendarMonth,
	History,
	Receipt,
	PinDropOutlined,
	LockPerson,
	PhonelinkSetup,
	HelpOutline,
	Assessment,
	Password,
} from '@mui/icons-material';
import { ReactComponent as PlugImg } from '../../assets/svgs/plug.svg';

import { useTranslation } from 'react-i18next';
import GenerateReport from '../../pages/generate-report';
import CreateStation from '../../pages/create-station';
import CreateCharger from '../../pages/create-charger';
import CreateGun from '../../pages/create-gun';
import AddAssociate from '../../pages/add-associate';
import FAQ from '../../pages/faq';
import SharedOrderReport from '../../pages/shared-order-report';
import ChangePassword from '../../pages/change-password';
import SettingsVals from '../../pages/settings-page/components/settings-vals.component';
import { useUserPermisions } from '../../hooks/useUserPermissions';

const styleIconContainer: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	width: '25px',
	height: '25px',
};

export function useAdminRoutes() {
	const [t] = useTranslation('global');

	const {
		getAssociatesGroupPermission,
		getAssociatesPermission,
		getReportsPermission,
		getStationsPermission,
		getUsersPermission,
		getChargersPermission,
		getGunsPermission,
		getDefaultValuesPermission,
		getSchedulesPermission,
	} = useUserPermisions();

	const AdminRouteList: IMenuList[] = [
		{
			title: 'Station details',
			link: '/stations/details/:id',
			Component: StationDetails,
			hideMenu: true,
			available: getStationsPermission(),
		},
		{
			title: 'Station details',
			link: '/stations/add-station',
			Component: AddStation,
			hideMenu: true,
			available: getStationsPermission(),
		},
		{
			title: 'Station details',
			link: '/stations/create-station',
			Component: CreateStation,
			hideMenu: true,
			available: getStationsPermission(),
		},
		{
			title: 'Station details',
			link: '/stations/edit-station/:id',
			Component: CreateStation,
			hideMenu: true,
			available: getStationsPermission(),
		},
		{
			available: getAssociatesGroupPermission() || getAssociatesPermission(),
			icon: (
				<div style={styleIconContainer}>
					<Apps />
				</div>
			),
			title: t('menu.dashboards.title'),
			link: '/dashboard',
			Component: Dashboard,
			submenu: [
				{
					image: <Public />,
					title: t('menu.dashboards.title-dashboard-1'),
					description: t('menu.dashboards.description-dashboard-1'),
					link: '/',
					Component: Dashboard,
					available:
						getAssociatesGroupPermission() || getAssociatesPermission(),
				},
				{
					image: <Paid />,
					title: t('menu.dashboards.title-dashboard-2'),
					description: t('menu.dashboards.description-dashboard-2'),
					link: '/revenue-dashboard',
					Component: RevenueDashboard,
					available:
						getAssociatesGroupPermission() || getAssociatesPermission(),
				},
				{
					image: <PinDropOutlined />,
					title: t('menu.dashboards.title-dashboard-3'),
					description: t('menu.dashboards.description-dashboard-3'),
					link: '/location-dashboard',
					Component: LocationDashboard,
					available:
						getAssociatesGroupPermission() || getAssociatesPermission(),
				},
				{
					image: <PlugImg width={20} />,
					title: t('menu.dashboards.title-dashboard-4'),
					description: t('menu.dashboards.description-dashboard-4'),
					link: '/charging-dashboard',
					Component: ChargingDashboard,
					available:
						getAssociatesGroupPermission() || getAssociatesPermission(),
				},
			],
		},
		{
			available:
				getAssociatesPermission() ||
				getAssociatesGroupPermission() ||
				getStationsPermission() ||
				getChargersPermission() ||
				getGunsPermission() ||
				getSchedulesPermission() ||
				getUsersPermission(),
			icon: (
				<div style={styleIconContainer}>
					<Partner />
				</div>
			),
			title: t('menu.partners.title'),
			link: '/partners',
			Component: Partners,
			submenu: [
				{
					title: t('menu.partners.stations'),
					description: t('menu.partners.stations-desc'),
					link: '/details/stations',
					Component: PartnerDetail,
					image: <EvStation />,
					available: getStationsPermission(),
				},
				{
					title: t('menu.partners.chargers'),
					description: t('menu.partners.chargers-desc'),
					link: '/details/chargers',
					Component: PartnerDetail,
					image: <Bolt />,
					available: getChargersPermission(),
				},
				{
					title: t('menu.partners.guns'),
					description: t('menu.partners.guns-desc'),
					link: '/details/guns',
					Component: PartnerDetail,
					image: <PlugImg width={20} />,
					available: getGunsPermission(),
				},
				{
					title: 'Details',
					link: '/details',
					Component: PartnerDetail, //Principal
					hideMenu: true,
					available: true,
				},
				{
					title: 'Associates',
					link: '/associates',
					Component: Associates,
					hideMenu: true,
					available: getAssociatesPermission(),
				},
				{
					title: 'New associate',
					link: '/add-associate',
					Component: AddAssociate,
					hideMenu: true,
					available: getAssociatesPermission(),
				},
				{
					title: 'Edit associate',
					link: '/edit-associate/:id',
					Component: EditAssociate,
					hideMenu: true,
					available: getAssociatesPermission(),
				},
				{
					title: 'New associate',
					link: '/detail-associate/:id',
					Component: DetailAssociate,
					hideMenu: true,
					available: getAssociatesPermission(),
				},
				{
					title: 'Chargers',
					link: '/chargers',
					Component: Chargers,
					hideMenu: true,
					available: getChargersPermission(),
				},
				{
					title: 'Chargers',
					link: '/add-charger',
					Component: CreateCharger,
					hideMenu: true,
					available: getChargersPermission(),
				},
				{
					title: 'Edit charger',
					link: '/edit-charger/:id',
					Component: CreateCharger,
					hideMenu: true,
					available: getChargersPermission(),
				},
				{
					title: 'Chargers',
					link: '/detail-charger/:id',
					Component: DetailCharger,
					hideMenu: true,
					available: getChargersPermission(),
				},
				{
					title: 'Guns',
					link: '/guns',
					Component: Guns,
					hideMenu: true,
					available: getGunsPermission(),
				},
				{
					title: 'New Gun',
					link: '/add-gun',
					Component: CreateGun,
					hideMenu: true,
					available: getGunsPermission(),
				},
				{
					title: 'Edit Gun',
					link: '/edit-gun/:id',
					Component: CreateGun,
					hideMenu: true,
					available: getGunsPermission(),
				},
				{
					title: 'New Gun',
					link: '/detail-gun/:id',
					Component: AdminDetailGun,
					hideMenu: true,
					available: getGunsPermission(),
				},
				{
					title: 'Add Associate Group',
					link: '/add-associate-group',
					Component: AddAssociateGroup,
					hideMenu: true,
					available: getAssociatesGroupPermission(),
				},
				{
					title: 'Associate Group Details',
					link: '/associate-group-details/:id',
					Component: AssociateGroupDetails,
					hideMenu: true,
					available: getAssociatesGroupPermission(),
				},
				{
					title: 'Add Schedules',
					link: '/add-schedules',
					Component: AddSchedules,
					hideMenu: true,
					available: getSchedulesPermission(),
				},
				{
					title: 'Associate Group Edit',
					link: '/associate-group-edit/:id',
					Component: AddAssociateGroup,
					hideMenu: true,
					available: getAssociatesGroupPermission(),
				},
			],
		},
		{
			available: getSchedulesPermission(),
			icon: (
				<div style={styleIconContainer}>
					<CalendarMonth />
				</div>
			),
			title: t('menu.schedules.title'),
			link: '/schedules',
			Component: Schedules,
			submenu: [
				{
					title: t('menu.schedules.title'),
					description: t('menu.schedules.desc'),
					link: '',
					Component: PartnerDetail,
					image: <Today />,
					available: getSchedulesPermission(),
				},
				{
					title: 'Schedules',
					link: '/edit-schedule/:id',
					Component: AddSchedules,
					hideMenu: true,
					available: getSchedulesPermission(),
				},
			],
		},
		{
			available:
				getReportsPermission() ||
				getStationsPermission() ||
				getGunsPermission() ||
				getAssociatesGroupPermission(),
			icon: (
				<div style={styleIconContainer}>
					<Paid />
				</div>
			),
			title: t('menu.financial.title'),
			link: getReportsPermission()
				? '/financial'
				: getGunsPermission()
				? '/gun-transactions-center/gun'
				: getStationsPermission()
				? '/station-transactions-center/station'
				: '',
			Component: Financial,
			submenu: [
				{
					title: t('menu.financial.title-1'),
					description: t('menu.financial.desc-1'),
					link: '/',
					Component: Financial,
					image: <History />,
					available: getReportsPermission(),
				},
				{
					title: t('menu.financial.title-2'),
					description: t('menu.financial.desc-2'),
					link: '/being-charging-order/being',
					Component: Financial,
					image: <Receipt />,
					available: getReportsPermission(),
				},
				{
					title: t('menu.financial.title-3'),
					description: t('menu.financial.desc-3'),
					link: '/gun-transactions-center/gun',
					Component: Financial,
					image: <PlugImg width={20} />,
					available: getGunsPermission(),
				},
				{
					title: t('menu.financial.title-4'),
					description: t('menu.financial.desc-4'),
					link: '/station-transactions-center/station',
					Component: Financial,
					image: <LocalGasStationOutlinedIcon />,
					available: getStationsPermission(),
				},
				{
					title: 'Generate report',
					description: 'Generate report',
					link: '/generate-report',
					Component: GenerateReport,
					hideMenu: true,
					available: getReportsPermission(),
				},
				{
					title: 'Shared order report',
					description: 'Revenue per associate',
					link: '/shared-order-report',
					Component: SharedOrderReport,
					image: <Assessment />,
					available: getAssociatesGroupPermission(),
				},
			],
		},
		{
			available: getUsersPermission(),
			icon: (
				<div style={styleIconContainer}>
					<Person />
				</div>
			),
			title: t('menu.users.title'),
			link: '/users',
			Component: Users,
			submenu: [
				{
					title: t('menu.users.title-1'),
					description: t('menu.users.desc-1'),
					image: <Person />,
					link: '',
					Component: Users,
					hideMenu: false,
					available: getUsersPermission(),
				},
				{
					title: t('menu.users.title-2'),
					description: t('menu.users.desc-2'),
					image: <LockPerson />,
					link: '/permissions',
					Component: UsersPermissions,
					hideMenu: false,
					available: getUsersPermission(),
				},
				{
					title: t('menu.users.title-3'),
					description: t('menu.users.desc-3'),
					image: <PhonelinkSetup />,
					link: '/mobile-users',
					Component: MobileUsers,
					hideMenu: false,
					available: getUsersPermission(),
				},
				{
					title: 'Add user',
					description: 'Users',
					image: <LockPerson />,
					link: '/add-user',
					Component: AddUser,
					hideMenu: true,
					available: getUsersPermission(),
				},
				{
					title: 'Add mobile user',
					description: 'Add mobile user',
					image: <LockPerson />,
					link: '/add-mobile-user',
					Component: AddMobileUser,
					hideMenu: true,
					available: getUsersPermission(),
				},
				{
					title: 'Edit permissions',
					description: 'Edit permissions',
					image: <LockPerson />,
					link: '/edit-permissions/:id',
					Component: EditUserPermissions,
					hideMenu: true,
					available: getUsersPermission(),
				},
				{
					title: 'User details',
					description: 'User details',
					image: <LockPerson />,
					link: '/details/:id',
					Component: UserDetails,
					hideMenu: true,
					available: getUsersPermission(),
				},
			],
		},
		{
			available: true,
			icon: (
				<div style={styleIconContainer}>
					<SettingsIcon />
				</div>
			),
			title: t('menu.settings.title'),
			link: '/settings',
			Component: SettingsVals,
			submenu: [
				{
					title: t('menu.settings.title-1'),
					description: t('menu.settings.desc-1'),
					link: '/default-values',
					image: <Rule />,
					Component: SettingsVals,
					hideMenu: false,
					available: getDefaultValuesPermission(),
				},
				{
					title: t('menu.settings.title-2'),
					description: t('menu.settings.desc-2'),
					image: <Translate />,
					link: '/languages',
					Component: SettingsLanguage,
					hideMenu: false,
					available: true,
				},
				{
					title: t('menu.settings.title-3'),
					description: t('menu.settings.desc-3'),
					image: <Palette />,
					link: '/theme',
					Component: SettingsTheme,
					hideMenu: false,
					available: true,
				},
				{
					title: t('menu.settings.title-5'),
					description: t('menu.settings.desc-5'),
					image: <Password />,
					link: '/change-password',
					Component: ChangePassword,
					hideMenu: false,
					available: true,
				},
			],
		},
		{
			available: true,
			icon: (
				<div style={styleIconContainer}>
					<HelpOutline />
				</div>
			),
			title: 'FAQ',
			link: '/faq',
			Component: FAQ,
		},
	];

	const FilteredAdminRouteList = filterAvailableMenuItems(AdminRouteList);

	return { FilteredAdminRouteList };
}
