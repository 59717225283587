/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import CipherUtils from '../utils/CipherUtils';
import { useGetQionToken } from './useGetQionToken';

export interface IMainResponse<I> {
	code: string;
	msg: string;
	objData: I | null;
}

interface IRequestPayload {
	path: string;
	headers?: {
		[key: string]: string;
	};
	baseURL?: string;
	port?: string;
	method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
	encripted?: boolean;
}

export const useApiRequest = <ParamsType, ResponseType>(
	{
		port,
		path,
		headers,
		baseURL = process.env.REACT_APP_API_URL,
		method,
		encripted,
	}: IRequestPayload,
	immediate = false
) => {
	const { getToken } = useGetQionToken();
	const [status, setStatus] = useState<'pending' | 'success' | 'error'>('pending');
	const [value, setValue] = useState<IMainResponse<ResponseType> | null>(null);
	const [token, setToken] = useState<string>('');

	const execute = useCallback(async (params?: ParamsType, pathPag?: string) => {
		/* if (encripted) {
			const token = await getToken();
			if (token === null) {
				setStatus('error');
			} else {
				setToken(token);
			}
		} */

		setStatus('pending');
		setValue(null);

		try {
			const pathFlag = pathPag ? path + pathPag : path;
			const url =
				process.env.NODE_ENV === 'development'
					? port
						? `http://localhost:${port}/api/v1/${path}`
						: `${baseURL}/${pathFlag}`
					: `${baseURL}/${pathFlag}`;
			if (encripted) {
				const token = await getToken();
				if (token === null) {
					setStatus('error');
					return;
				} else {
					setToken(token);
				}
				const encriptedParams = CipherUtils.encrypt(
					JSON.stringify(params),
					process.env.REACT_APP_ENC_KEY
				);
				const response = await axios({
					url: url,
					data: encriptedParams,
					method: method ? method : 'POST',
					headers: {
						'Content-Type': 'text/plain',
						Authorization: `Bearer ${token}`,
						...headers,
					},
				});
				const decryptedResponse = CipherUtils.decrypt(
					response.data,
					process.env.REACT_APP_ENC_KEY
				);
				if (decryptedResponse.code === 200) {
					setValue(decryptedResponse.objData);
					setStatus('success');
				} else {
					setStatus('error');
				}
			} else {
				const response = await axios({
					url: url,
					data: JSON.stringify(params),
					method: method ? method : 'POST',
					headers: {
						'Content-Type': 'text/plain',
						factor: 101,
						...headers,
					},
				});
				setValue(response.data);
				setStatus('success');
			}
		} catch (error: any) {
			setStatus('error');
		}
	}, []);

	useEffect(() => {
		if (immediate) {
			execute();
		}
	}, [execute, immediate]);

	return { execute, status, value };
};
