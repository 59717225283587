import { useSelector } from 'react-redux';
import styles from './styles/section-chargers.component.module.css';

import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { ICharger } from '../../../interfaces/charger.interface';

interface ISectionChargers {
	data: ICharger[];
}

const SectionChargers = ({ data }: ISectionChargers) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);

	return (
		<div className={styles.stationsChargersContainer}>
			<div className={styles.nameContainer}>
				<h3>{t('stations.station-chargers')}</h3>
			</div>
			<hr className={styles.customHr} />
			{data.length > 0 ? (
				<div
					className={styles.chargersContainer}
					style={{ borderColor: colors.secondary }}
				>
					{data.map((charger) => (
						<div className={styles.chargerCard} key={charger.id!}>
							<BoltOutlinedIcon
								style={{ color: colors.secondary, fontSize: '40px' }}
							/>
							<span>
								<b>{charger.name}</b>
							</span>
						</div>
					))}
				</div>
			) : (
				<div
					style={{
						width: '100%',
						display: 'flex',
						justifyContent: 'center',
						padding: '20px 0',
						fontSize: '20px',
						fontWeight: 'bold',
					}}
				>
					<span>Station has not chargers</span>
				</div>
			)}
		</div>
	);
};

export default SectionChargers;
