import { Grid, Paper } from '@mui/material';
import TableCustom from '../../components/table-custom.component';
import { useMobileUsers } from './hooks/useMobileUsers';
import CustomModal from '../../components/modal.component';
import CustomButton from '../../components/button.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

import styles from './styles/users-edit-kw.style.module.css';
import Input from '../../components/input.component';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useMobileUserColumns } from './utils/mobile-users-table.colums';
import { useUserPermisions } from '../../hooks/useUserPermissions';
import { Refresh } from '@mui/icons-material';
import { CustomTooltip } from '../../components/button-icon-small.component';
import { ROLES } from '../../constants/user-roles.constants';
import { useGetPartners } from './hooks/useGetPartners';
import SelectInput from '../../components/select-input.component';

const MobileUsers = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const navigate = useNavigate();

	const { getUsersPermission } = useUserPermisions();
	const { mobileUserColumns } = useMobileUserColumns();
	const { partnerOptions } = useGetPartners();
	const {
		data,
		setData,
		openModal,
		selectedUser,
		handleSetData,
		dataToUpdate,
		handleUpdateData,
		disabledButton,
		handleCancel,
		refresh,
		selectedPartner,
		setSelectedPartner,
	} = useMobileUsers();

	return (
		<>
			{getUsersPermission() && role.roleName !== ROLES.SUPER_ADMIN && (
				<CustomButton
					label={t('mobile-users.add')}
					variant={'solid'}
					onClick={() => navigate('/users/add-mobile-user')}
					styles={{
						width: 'fit-content',
						paddingLeft: 25,
						paddingRight: 25,
						backgroundColor: colors.accent,
						color: 'black',
						alignSelf: 'end',
					}}
				/>
			)}
			{role.roleName === ROLES.SUPER_ADMIN && !simulating && (
				<Grid container>
					<Grid item xl={4} md={12}>
						<SelectInput
							name="partnerId"
							id="partnerId"
							label={t('common.partner').toString()}
							placeholder={t('common.required').toString()}
							style={{ width: '100%' }}
							required
							options={partnerOptions}
							onChange={(e) => setSelectedPartner(e.target.value)}
							value={selectedPartner}
						/>
					</Grid>
				</Grid>
			)}
			<Paper elevation={2} sx={{ padding: 1 }}>
				<div className={styles.header}>
					<h3 style={{ marginLeft: '1%' }}>{t('mobile-users.title')}</h3>
					<CustomTooltip
						placement="top"
						title={t('common.refresh')}
						style={{
							marginRight: '1%',
						}}
						onClick={refresh}
					>
						<Refresh style={{ fontSize: '40px', cursor: 'pointer' }} />
					</CustomTooltip>
				</div>
				<TableCustom
					columns={mobileUserColumns}
					data={data}
					setData={setData}
					showTotalCount={false}
					showLimitPerPage
					showPagination
					hideChips
					hideHeader
				/>
			</Paper>
			<CustomModal
				open={openModal}
				maxWidth="sm"
				fullWidth
				title={t('mobile-users.change-vals')}
			>
				<div className={styles.container}>
					<span className={styles.subtitle}>
						<b>{t('users.user')}:</b> {selectedUser.name}
					</span>
					<span className={styles.subtitle}>
						<b>{t('mobile-users.current-kw')}:</b>{' '}
						{selectedUser.freeKw || 0.0} Kw
					</span>
					<Input
						id={'kw'}
						name={'gifted_kw'}
						label={t('mobile-users.new-kw').toString()}
						placeholder={t('common.required').toString()}
						style={{
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						value={dataToUpdate.gifted_kw}
						type="text"
						required
					/>
					<span className={styles.subtitle} style={{ marginTop: '15px' }}>
						<b>{t('mobile-users.current-money')}:</b> $
						{selectedUser.freeMoney || 0.0}
					</span>
					<Input
						id={'money'}
						name={'gifted_money'}
						label={t('mobile-users.new-money').toString()}
						placeholder={t('common.required').toString()}
						style={{
							height: '43px',
							paddingLeft: '14px',
						}}
						onChange={handleSetData}
						value={dataToUpdate.gifted_money}
						type="text"
						required
					/>
					<hr />
					<div className={styles.buttonsContainer}>
						<CustomButton
							label={t('common.cancel')}
							variant={'solid'}
							onClick={handleCancel}
							styles={{
								alignSelf: 'end',
								width: '140px',
								paddingLeft: 25,
								paddingRight: 25,
								backgroundColor: 'var(--color-black-main)',
							}}
						/>
						<CustomButton
							label={t('common.save')}
							variant={'solid'}
							onClick={handleUpdateData}
							styles={{
								width: '140px',
								paddingLeft: 25,
								paddingRight: 25,
								backgroundColor: colors.accent,
								color: 'black',
							}}
							disabled={disabledButton}
						/>
					</div>
				</div>
			</CustomModal>
		</>
	);
};

export default MobileUsers;
