import { createSlice } from '@reduxjs/toolkit';

interface ISimulation {
	simulating: boolean;
	simulatedPartnerGuns: string[];
}

const defaultSimulation: ISimulation = { simulating: false, simulatedPartnerGuns: [] };

export const partnerSimulationSlice = createSlice({
	name: 'partnerSimulation',
	initialState: defaultSimulation,
	reducers: {
		editSimulation: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { editSimulation } = partnerSimulationSlice.actions;

export default partnerSimulationSlice.reducer;
