export interface IAssociate {
	group: string | JSX.Element;
	associates: string;
	stations: string | number;
	charger: string | number;
	actions?: JSX.Element;
}
export interface IStations {
	name: string | JSX.Element;
	location: string;
	chargers: string | number;
	guns: string | number;
	actions?: JSX.Element;
}
export interface ICharger {
	charger: string | number;
	station: string;
	location: string;
	guns: string | number;
	actions?: JSX.Element;
}
export interface IFinances {
	month: string | number;
	year: string;
	cost: string;
	revenue: string | number;
	actions?: JSX.Element;
}
export const mockDataAssociate: IAssociate[] = [
	{
		group: 'Group 01',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 02',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 03',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 04',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 05',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 06',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 07',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 08',
		associates: '5',
		stations: '10',
		charger: '10',
	},
	{
		group: 'Group 09',
		associates: '5',
		stations: '10',
		charger: '10',
	},
];
export const mockDataStations: IStations[] = [
	{
		name: 'Station 01',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 03',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 04',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 05',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 06',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 07',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 08',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
	{
		name: 'Station 09',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		chargers: '10',
		guns: '10',
	},
];
export const mockDataCharger: ICharger[] = [
	{
		charger: 'Charger 01',
		station: 'Station 01',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 02',
		station: 'Station 02',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 03',
		station: 'Station 03',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 04',
		station: 'Station 04',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 05',
		station: 'Station 05',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 06',
		station: 'Station 06',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 07',
		station: 'Station 07',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
	{
		charger: 'Charger 08',
		station: 'Station 08',
		location: 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert',
		guns: '10',
	},
];
export const mockDataFinance: IFinances[] = [
	{
		month: 'January',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
	{
		month: 'February',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
	{
		month: 'March',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
	{
		month: 'April',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
	{
		month: 'June',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
	{
		month: 'July',
		year: '2023',
		cost: '$10000.00',
		revenue: '$10000.00',
	},
];
