import { useEffect } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IAddUserParams } from '../../../interfaces/user';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../store';

export function useCreateAssociateAccount() {
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const dispatch = useDispatch();

	const { execute: addUser, status: addUserStatus } = useBackendRequest<
		IAddUserParams,
		any
	>({
		path: 'users/sign-up',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (addUserStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-added'),
					severity: 'success',
				})
			);
		} else if (addUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-added-error'),
					severity: 'error',
				})
			);
		}
	}, [addUserStatus]);

	const handleAddUser = (
		mobile: string,
		password: string,
		email: string,
		name: string,
		lastName: string
	) => {
		addUser({
			partnerId: id,
			user_name: 'X',
			mobile: mobile,
			login_pwd: password,
			real_name: 'X',
			sex: 'unknown',
			email: email,
			user_system_type: 2,
			name: name,
			first_last_name: lastName,
			second_last_name: 'X',
		});
	};

	return { handleAddUser };
}
