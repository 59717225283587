import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useUserColumns() {
	const [t] = useTranslation('global');

	const userColumns: IColumns[] = [
		{
			text: t('users.user-associate'),
			nameID: 'name',
		},
		{
			text: t('common.email'),
			nameID: 'email',
		},
		{
			text: t('common.phone'),
			nameID: 'mobile',
		},
		/* {
			text: t('users.profile'),
			nameID: 'profile',
		},*/
		{
			text: t('common.status'),
			nameID: 'status',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];
	return { userColumns };
}
