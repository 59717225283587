import {
	Checkbox,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from '@mui/material';
import { useState } from 'react';
import { IOptions } from './select-input.component';

const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: 300,
			overflowy: 'scroll',
		},
	},
};

interface ICustomSelect {
	name: string;
	val: string[];
	selectData: IOptions[];
	onChange: (gun: string[]) => void;
}

const CustomMultiSelect = ({ name, selectData, onChange, val }: ICustomSelect) => {
	const [data] = useState<string[]>([]);

	const handleChange = (event: SelectChangeEvent<typeof data>) => {
		const {
			target: { value },
		} = event;
		if (typeof value === 'object') onChange(value);
		/* setData(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		); */
	};

	const getName = (id: string): string => {
		return selectData.filter((data) => data.value === id)[0].label;
	};

	return (
		<>
			<FormControl
				sx={{ width: '100%', height: 'fit-content', margin: 0, padding: 0 }}
			>
				<InputLabel id="multiple-chip-label">{name}</InputLabel>
				<Select
					labelId="demo-multiple-chip-label"
					id="demo-multiple-chip"
					multiple
					value={val}
					onChange={handleChange}
					input={<OutlinedInput id="select-multiple-chip" label={name} />}
					renderValue={(selected) => {
						const names = selected.map((option) => {
							return getName(option);
						});
						return names.join(', ');
					}}
					MenuProps={MenuProps}
				>
					{selectData.map((item: IOptions) => (
						<MenuItem key={item.value} value={item.value}>
							<Checkbox checked={val.indexOf(item.value.toString()) > -1} />
							<ListItemText primary={item.label} />
						</MenuItem>
					))}
				</Select>
			</FormControl>
		</>
	);
};

export default CustomMultiSelect;
