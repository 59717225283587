import {
	Toolbar,
	IconButton,
	Badge,
	Box,
	AppBar,
	Avatar,
	useMediaQuery,
	Tooltip,
	Menu,
	MenuItem,
	Typography,
} from '@mui/material';
import { Menu as MenuIcon } from '@mui/icons-material';
//styles
import { BadgeProps } from '@mui/material/Badge';
import { styled, useTheme } from '@mui/material/styles';
//import BreadcrumbsComponent from './breadcrumbs.component';
import { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { resetUser } from '../store/modules/user';
// import { RootState } from '../store';

interface IProps {
	toggleDrawer: () => void;
	drawerWidth: number;
	arrowToggle: boolean;
}

const AppBarLayout = ({ toggleDrawer, drawerWidth, arrowToggle }: IProps) => {
	const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
	//const dispatch = useDispatch();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	//const user = useSelector((state: RootState) => state.users);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	return (
		<AppBar elevation={0} sx={{ boxShadow: '0px 1px 6px #00000029' }}>
			<Toolbar
				sx={{
					ml: { minWidth: `${drawerWidth}px` },
				}}
				style={{
					background: '#FFF',
					color: '#1C1C1E',
					height: 60,
				}}
			>
				<IconButton
					color="inherit"
					aria-label="btnOpenDrawer"
					data-testid="btnOpenDrawer"
					edge="start"
					onClick={toggleDrawer}
					sx={{ mr: 2, display: { md: 'none' } }}
				>
					<MenuIcon />
				</IconButton>

				<Box
					display={'inline-flex'}
					paddingLeft={matches ? '0px' : arrowToggle ? '100px' : '320px'}
					flexGrow={1}
				>
					{/* <BreadcrumbsComponent /> */}
				</Box>

				<Tooltip title="Opciones">
					<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
						{/* <Avatar alt={user.firstName} /> */}
					</IconButton>
				</Tooltip>
				<Menu
					sx={{ mt: '45px' }}
					id="menu-appbar"
					anchorEl={anchorElUser}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					keepMounted
					transformOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={Boolean(anchorElUser)}
					onClose={handleCloseUserMenu}
				>
					<MenuItem onClick={() => {}}>
						<Typography textAlign="center">Cerrar Sesión</Typography>
					</MenuItem>
				</Menu>
			</Toolbar>
		</AppBar>
	);
};

export default AppBarLayout;
