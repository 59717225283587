import { useTranslation } from 'react-i18next';
import { IGunsStats } from '../../../../../interfaces/guns-stats.interface';
import { IRevenue } from '../../../../associate-group-details/components/interfaces/revenue-tab.interface';

export function useTransactionsTabAdapter() {
	const [t] = useTranslation('global');

	const getAssociateValues = (associate: IRevenue): JSX.Element => {
		if (!associate) {
			return <>-</>;
		} else {
			return associate.name !== 'iva' ? (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					{associate.isRemainingOwner && (
						<span style={{ textDecoration: 'underline' }}>
							<b>{t('shared-report.owner')}</b>
						</span>
					)}
					<span>
						<b>{t('shared-report.name')}: </b>
						{associate.name ? associate.name : '-'}
					</span>
					<span>
						<b>{t('shared-report.revenue')}: </b>
						{parseFloat(associate.revenue).toFixed(2)}
					</span>
					{!associate.isFirstInvoice && (
						<span>
							<b>
								{associate.unit
									? `${t('shared-report.unit')}: `
									: `${t('shared-report.fraction')}: `}
							</b>
							{`${associate.fraction ? associate.fraction : '-'} ${
								associate.unit ? '' : '%'
							}`}
						</span>
					)}
				</div>
			) : (
				<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<span>
						<b>{t('shared-report.vat-reserve')}: </b>
						{associate.fraction}%
					</span>
					<span>
						<b>{t('shared-report.vat-total')}: </b>
						{associate.revenue}
					</span>
				</div>
			);
		}
	};

	const transactionsTabAdapter = (data: IGunsStats[]): any[] => {
		const newData: any[] = data.map((item: IGunsStats) => {
			return {
				...item,
				total: <>{`$${item.total}`}</>,
				accumulated_charge: <>{`$${item.accumulated_charge}`}</>,
				load_time: <>{item.load_time}</>,
				firstInvoice: (
					<>{item.associates ? getAssociateValues(item.associates[0]) : '-'}</>
				),
				vat: (
					<>{item.associates ? getAssociateValues(item.associates[1]) : '-'}</>
				),
				associate1name: (
					<>{item.associates ? getAssociateValues(item.associates[2]) : '-'}</>
				),
				associate2name: (
					<>{item.associates ? getAssociateValues(item.associates[3]) : '-'}</>
				),
				associate3name: (
					<>{item.associates ? getAssociateValues(item.associates[4]) : '-'}</>
				),
				associate4name: (
					<>{item.associates ? getAssociateValues(item.associates[5]) : '-'}</>
				),
				associate5name: (
					<>{item.associates ? getAssociateValues(item.associates[6]) : '-'}</>
				),
				costs: (
					<div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
						<span>
							<b>{t('common.charge-cost')}: </b> $
							{item.costs ? item.costs.chargeCost : '-'}
						</span>
						<span>
							<b>{t('common.charge-factor')}: </b>{' '}
							{item.costs ? item.costs.chargeFactor : '-'}
						</span>
						<span>
							<b>{t('common.parking-cost')}: </b> $
							{item.costs ? item.costs.parkingCost : '-'}
						</span>
						<span>
							<b>{t('common.parking-factor')}: </b>{' '}
							{item.costs ? item.costs.parkingFactor : '-'}
						</span>
					</div>
				),
				credit_money: <>{item.credit_money ? `${item.credit_money}` : '-'}</>,
				real_money: <>{item.real_money ? `${item.real_money}` : '-'}</>,
			};
		});
		return newData;
	};

	return { transactionsTabAdapter };
}
