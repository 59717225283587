import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { useTranslation } from 'react-i18next';
import { IPartnerInfo } from '../../../interfaces/partner';
import { IGun } from '../../../interfaces/gun.interface';

export function useSuperAdminGunsAdapter() {
	const [t] = useTranslation('global');

	const gunsAdapter = (
		data: IGun[],
		partners: IPartnerInfo[],
		seeDetails: (gun: IGun) => void
	): any[] => {
		const getPartnerName = (id: string): string => {
			const partner = partners.filter((p) => p.id === id)[0];
			if (partner) {
				return partner.name;
			} else {
				return '-';
			}
		};

		const newData: any[] = data.map((item: IGun) => {
			return {
				...item,
				address: (
					<>{`${item.address}, ${item.state}, ${item.country}. ${item.zip}`}</>
				),
				partnerInfoId: <>{getPartnerName(item.partnerInfoId)}</>,
				actions: (
					<ButtonIconSmall
						onPress={() => seeDetails(item)}
						type="primary"
						icon={<VisibilityOutlinedIcon />}
						hoverLabel={t('common.view')}
					/>
				),
			};
		});
		return newData;
	};

	return { gunsAdapter };
}
