// Dependencies
import React, { ReactElement } from 'react';
import {
	ButtonOutlined,
	ButtonSolid,
	ButtonSolidBlue,
} from '../styled-components/buttons.styled-components';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { DefaultTFuncReturn } from 'i18next';
interface IButton {
	label?: string | React.ReactElement | DefaultTFuncReturn;
	onClick?: () => void;
	variant: 'solid' | 'outlined' | 'solid-blue' | 'solid-red';
	styles?: React.CSSProperties;
	disabled?: boolean;
	type?: 'button' | 'submit' | 'reset';
	children?: React.ReactNode;
	icon?: (OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }) | null;
	iconStyles?: React.CSSProperties;
}

const CustomButton = (props: IButton) => {
	const colors = useSelector((state: RootState) => state.colors);
	const {
		onClick,
		children,
		label,
		variant,
		styles,
		disabled,
		type,
		icon: Icon,
		iconStyles,
	} = props;

	const getButtonType = (): ReactElement => {
		if (variant === 'solid') {
			return (
				<ButtonSolid
					onClick={onClick}
					style={{
						...styles,
						fontSize: '14px',
						minHeight: '40px',
						borderRadius: '8px',
						backgroundColor: disabled ? 'lightgray' : styles?.backgroundColor,
						color: disabled ? 'white' : styles?.color,
					}} //Use the branch color
					disabled={disabled}
					type={type}
				>
					{Icon && <Icon style={{ ...iconStyles }} />}
					{label}
					{children}
				</ButtonSolid>
			);
		} else if (variant === 'solid-blue') {
			return (
				<ButtonSolidBlue
					onClick={onClick}
					style={{ backgroundColor: disabled ? '' : colors.accent, ...styles }} //Use the branch color
					disabled={disabled}
					type={type}
				>
					{Icon && <Icon />}
					{label}
					{children}
				</ButtonSolidBlue>
			);
		}
		return (
			<ButtonOutlined
				onClick={onClick}
				style={{
					...styles,
					backgroundColor: disabled ? '#CDCDCD' : 'transparent',
					borderColor: colors.secondary,
					color: colors.secondary,
				}}
				disabled={disabled}
				type={type}
				id={label as string}
			>
				{Icon && <Icon style={{ ...iconStyles }} />}
				{label}
				{children}
			</ButtonOutlined>
		);
	};
	return getButtonType();
};

export default CustomButton;
