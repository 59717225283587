// React dependencies
import { useLocation, useNavigate } from 'react-router-dom';

// Third-party libraries
import { Paper } from '@mui/material';
// Style dependencies
import styles from './styles/stations.module.css';

// Resources
import TableCustom from '../../components/table-custom.component';
import { useSuperAdminChargers } from './hooks/useSuperAdminChargers';
import { useSuperAdminChargersColumns } from './utils/station.columns';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/button.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Add, ArrowBack } from '@mui/icons-material';

export const SuperAdminChargers = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);
	const location = useLocation();

	const { superAdminChargersColumns } = useSuperAdminChargersColumns();

	const { data, setData } = useSuperAdminChargers();

	return (
		<>
			<div className={styles.buttonsContainer}>
				<CustomButton
					label={t('common.back')}
					variant={'solid'}
					onClick={() => navigate('/partners')}
					styles={{
						width: 'fit-content',
						padding: '0 15px',
						display: 'flex',
						gap: '10px',
						backgroundColor: 'black',
						color: 'white',
					}}
					icon={ArrowBack}
				/>
				<CustomButton
					label={t('chargers.new')}
					variant={'solid'}
					onClick={() =>
						navigate('/partners/add-charger', {
							state: { prevUrl: location.pathname },
						})
					}
					styles={{
						width: 'fit-content',
						padding: '0 15px',
						display: 'flex',
						gap: '10px',
						backgroundColor: colors.accent,
						color: 'black',
					}}
					icon={Add}
				/>
			</div>
			<Paper elevation={2} className={styles.boxPaper}>
				<TableCustom
					columns={superAdminChargersColumns}
					data={data}
					setData={setData}
					titleHeader={t('common.chargers')}
					showTotalCount={false}
					hideChips
				/>
			</Paper>
		</>
	);
};
