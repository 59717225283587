import { useSelector } from 'react-redux';
import style from './styles/label-styles.component.module.css';
import { RootState } from '../store';

interface LabelProps {
	label?: string;
	required?: boolean;
	name: string;
	disabled?: boolean;
}

export function Label({ name, label, required, disabled }: LabelProps) {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<>
			{label && (
				<label
					className={`${disabled ? style.labelDisabled : style.label}`}
					htmlFor={name}
				>
					{label}{' '}
					{required && (
						<span
							style={{
								color: colors.secondary,
								fontWeight: 'bold',
								fontSize: '17px',
							}}
						>
							*
						</span>
					)}
				</label>
			)}
		</>
	);
}
