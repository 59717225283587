import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { useGetAssociates } from './useGetAssociates';
import { useEffect, useMemo, useState } from 'react';
import { IAssociate } from '../../../interfaces/associate.interface';
import {
	IAddAssociateGroup,
	IAssociateForGroup,
} from '../interfaces/associate-group.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { transformateDataToDecimal } from '../../../utils/transformToDecimal';
import { useGetGroupData } from './useGetGroupData';

export function useAddGroup() {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { partnerId } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { id: groupId } = useParams();
	const reg = /^(?!.*\..*\.)[.\d]+$/;

	const { associatesData } = useGetAssociates();

	const {
		default_iva,
		default_charge_cost,
		default_charge_factor,
		default_parking_cost,
		default_parking_factor,
		paymentProcessorPercentaje,
		paymentProcessorQty,
	} = useSelector((state: RootState) => state.defaultValues);

	const [openModal, setOpenModal] = useState(false);

	//Asociados que se muestran cuando se acepta el modal
	const [selectedAssociates, setSelectedAssociates] = useState<IAssociate[]>([]);
	const [modalAssociates, setModalAssociates] = useState<IAssociate[]>([]);

	//Asociados modal
	const [tempSelectedAssociates, setTempSelectedAssociates] = useState<IAssociate[]>(
		[]
	);

	const [associateGroupData, setAssociateGroupData] = useState<IAddAssociateGroup>({
		partnerId: simulating ? id : partnerId.toString(),
		group: '',
		firstInvoiceAssociateId: '',
		remainingOwnerId: '',
		iva: default_iva,
		chargeCost: default_charge_cost,
		chargeFactor: default_charge_factor,
		parkingCost: default_parking_cost,
		parkingFactor: default_parking_factor,
		paymentProcessorPercentaje: paymentProcessorPercentaje,
		paymentProcessorQty: paymentProcessorQty,
		associates: [],
	});

	useGetGroupData({ associatesData, setAssociateGroupData, setSelectedAssociates });

	const { execute: editGroup, status: statusEditGroup } = useBackendRequest<any, any>({
		path: 'partners-group/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (statusEditGroup === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('groups.updated'),
					severity: 'success',
				})
			);
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
			});
		} else if (statusEditGroup === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-updated'),
					severity: 'error',
				})
			);
		}
	}, [statusEditGroup]);

	const {
		execute: createAssociatesGroups,
		value: associatesGVal,
		status: associatesGStatus,
	} = useBackendRequest<IAddAssociateGroup, any>({
		path: 'partners-group',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (associatesGStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			if (associatesGVal?.data) {
				dispatch(
					setAlert({
						show: true,
						message: t('groups.created'),
						severity: 'success',
					})
				);
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.ASSOCIATES_GROUP },
				});
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('groups.error-created'),
						severity: 'error',
					})
				);
			}
		} else if (associatesGStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-created'),
					severity: 'error',
				})
			);
		}
	}, [associatesGStatus]);

	const disabledButton = useMemo((): boolean => {
		return !(
			associateGroupData.group !== '' &&
			associateGroupData.chargeCost !== '' &&
			associateGroupData.chargeFactor !== '' &&
			associateGroupData.parkingCost !== '' &&
			associateGroupData.parkingFactor !== '' &&
			associateGroupData.firstInvoiceAssociateId !== '' &&
			associateGroupData.remainingOwnerId !== '' &&
			associateGroupData.associates.length > 0 &&
			associateGroupData.paymentProcessorPercentaje !== '' &&
			associateGroupData.paymentProcessorQty !== ''
		);
	}, [associateGroupData]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = event.target;
		if (
			name === 'iva' ||
			name === 'chargeCost' ||
			name === 'chargeFactor' ||
			name === 'parkingCost' ||
			name === 'parkingFactor' ||
			name === 'paymentProcessorPercentaje' ||
			name === 'paymentProcessorQty'
		) {
			if (value !== '') if (!value.match(reg)) return;
		}
		setAssociateGroupData({ ...associateGroupData, [name]: value });
	};

	const handleSelectAssociate = (id: string, selected: boolean) => {
		let newArr;
		selected
			? (newArr = tempSelectedAssociates.concat(
					associatesData.find((element) => element.id === id)!
			  ))
			: (newArr = tempSelectedAssociates.filter((element) => element.id !== id));
		setTempSelectedAssociates(newArr);
	};

	const handleAcceptModal = () => {
		const newSelectedAsociates = selectedAssociates.concat(tempSelectedAssociates);
		setSelectedAssociates(newSelectedAsociates);
		setTempSelectedAssociates([]);
		const selectedAssociatesInfo: IAssociateForGroup[] = newSelectedAsociates.map(
			(associate) => {
				return getSelectedAssociatePrevInfo(associate.id!);
			}
		);
		setAssociateGroupData({
			...associateGroupData,
			firstInvoiceAssociateId: newSelectedAsociates[0].id!,
			remainingOwnerId: newSelectedAsociates[0].id!,
			associates: selectedAssociatesInfo,
		});
		setOpenModal(false);
	};

	const getSelectedAssociatePrevInfo = (associateId: string): IAssociateForGroup => {
		const associateData = associateGroupData.associates.filter(
			(a) => a.associateId === associateId
		)[0];
		if (associateData) {
			return associateData;
		} else {
			return { associateId: associateId, fraction: 0.1, unit: false };
		}
	};

	const handleRemoveCard = (index: number) => {
		let newArr;
		newArr = selectedAssociates;
		newArr.splice(index, 1);
		setSelectedAssociates(newArr);

		newArr = associateGroupData.associates;
		newArr.splice(index, 1);
		setAssociateGroupData({
			...associateGroupData,
			associates: newArr,
		});
	};

	const handleFirstInvoiceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setAssociateGroupData({
			...associateGroupData,
			firstInvoiceAssociateId: value,
		});
	};

	const handleRemnantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = event.target;
		setAssociateGroupData({
			...associateGroupData,
			remainingOwnerId: value,
		});
	};

	const handleFactorUnitChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		const booleanVal: boolean = value === 'false' ? false : true;
		const newValues = associateGroupData.associates.map((associate, i) => {
			if (i === index) {
				return {
					...associate,
					unit: booleanVal,
				};
			}
			return associate;
		});
		setAssociateGroupData({ ...associateGroupData, associates: newValues });
	};

	const handleQuantityChange = (
		index: number,
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const { value } = event.target;
		if (value !== '') if (!value.match(reg)) return;

		const newValues = associateGroupData.associates.map((associate, i) => {
			if (i === index) {
				return {
					...associate,
					fraction: value,
				};
			}
			return associate;
		});

		setAssociateGroupData({ ...associateGroupData, associates: newValues });
	};

	const saveAssociateGroup = (): void => {
		dispatch(setLoading({ loading: true }));
		const newAssociateGroupData = transformateDataToDecimal(associateGroupData);
		createAssociatesGroups(newAssociateGroupData);
	};

	const handleOpenModal = (): void => {
		const activeAssociates = associatesData.filter((a) => a.status === 1);
		setModalAssociates(activeAssociates);
		setOpenModal(true);
	};

	const handleEditGroup = (): void => {
		dispatch(setLoading({ loading: true }));
		const newAssociateGroupData = transformateDataToDecimal(associateGroupData);
		editGroup(newAssociateGroupData, groupId);
	};

	return {
		groupId,
		associateGroupData,
		selectedAssociates,
		openModal,
		modalAssociates,
		disabledButton,
		tempSelectedAssociates,
		handleEditGroup,
		setOpenModal,
		handleSetData,
		handleSelectAssociate,
		handleAcceptModal,
		handleRemoveCard,
		handleFirstInvoiceChange,
		handleRemnantChange,
		handleFactorUnitChange,
		handleQuantityChange,
		saveAssociateGroup,
		handleOpenModal,
	};
}
