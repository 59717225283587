// React dependencies
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
// Third-party libraries
import { Card, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import { hexToRGBA } from '../../../../../utils/hex-to-rgba';
import ModalStop from '../../../../../components/modal-stop.component';
import { useTranslation } from 'react-i18next';
import { useChargerTab } from './hooks/useChargerTab';
import { useStopCharger } from './hooks/useStopCharger';
import ChargerCard from './components/charger-card.component';
import CustomButton from '../../../../../components/button.component';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';
// Resources

const AdminChargerTab = () => {
	const [t] = useTranslation('global');
	const nav = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);

	const {
		chargersData,
		openModal,
		selectedCharger,
		setOpenModal,
		handleOpenModal,
		getStationName,
	} = useChargerTab();

	const { handleGetChargerGuns } = useStopCharger({ setOpenModal });

	const { getChargersPermission } = useUserPermisions();

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'space-between' }}>
				<div></div>
				{getChargersPermission() && (
					<CustomButton
						icon={Add}
						variant="solid"
						label={t('chargers.new')}
						styles={{
							backgroundColor: colors.accent,
							color: 'black',
							width: 'fit-content',
							padding: '0 15px',
							gap: '10px',
						}}
						onClick={() => nav('/partners/add-charger')}
					/>
				)}
			</div>
			<Grid
				container
				gap={'20px'}
				sx={{ alignContent: 'stretch', alignItems: 'stretch' }}
			>
				{chargersData.length > 0 ? (
					chargersData.map((charger) => (
						<Grid
							key={charger.id}
							item
							xs={12}
							sm={6}
							md={3}
							lg={2}
							sx={{ alignContent: 'stretch', alignItems: 'stretch' }}
						>
							<ChargerCard
								id={charger.id!}
								name={charger.name}
								stationId={getStationName(charger.stationId)}
								handleOpenModal={handleOpenModal}
							/>
						</Grid>
					))
				) : (
					<Card
						sx={{
							padding: 5,
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: hexToRGBA(colors.secondary, 0.2),
							border: `1px solid ${colors.secondary}`,
						}}
					>
						<h1 style={{ justifySelf: 'center', color: colors.secondary }}>
							{t('chargers.no-data')}
						</h1>
					</Card>
				)}
			</Grid>
			<ModalStop
				open={openModal}
				setOpen={setOpenModal}
				acceptModal={() =>
					handleGetChargerGuns(selectedCharger.id, selectedCharger.qionId)
				}
				name={selectedCharger.name}
				type="charger"
			/>
		</>
	);
};

export default AdminChargerTab;
