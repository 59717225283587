import React from 'react';
import { Card, CardActions, CardContent, CardHeader, Divider } from '@mui/material';

interface ISimpleCard {
	children: JSX.Element;
	avatar?: JSX.Element;
	style?: React.CSSProperties;
	className?: string;
	title?: string | false;
	titleStyles?: React.CSSProperties;
	footer?: JSX.Element | false;
	footerStyles?: React.CSSProperties;
	footerClasses?: string;
	containerStyles?: React.CSSProperties;
	dividers?: boolean;
	headerActions?: JSX.Element | false;
}

const SimpleCard = ({
	children,
	style = {},
	className,
	title,
	titleStyles = {},
	footer,
	footerStyles = {},
	avatar,
	footerClasses,
	containerStyles = {},
	dividers = false,
	headerActions,
}: ISimpleCard) => {
	return (
		<Card style={containerStyles}>
			{title && (
				<CardHeader
					avatar={avatar}
					title={title}
					titleTypographyProps={{ style: titleStyles }}
					action={headerActions}
				/>
			)}
			{dividers && <Divider />}
			<CardContent style={style} className={className}>
				{children}
			</CardContent>
			{dividers && <Divider />}
			{footer && (
				<CardActions className={footerClasses} style={footerStyles}>
					{footer}
				</CardActions>
			)}
		</Card>
	);
};

export default SimpleCard;
