import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';

export const gunsAdapter = (data: any[]): any[] => {
	const newData: any[] = data.map((item: any) => {
		return {
			...item,
		};
	});
	return newData;
};
