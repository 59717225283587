import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { useParams } from 'react-router-dom';
import { IRevenue, IRevenuesResponse } from '../interfaces/revenue-tab.interface';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading } from '../../../../store/modules/loading';
import { useTranslation } from 'react-i18next';
import { useMonthSelector } from '../../../../hooks/useMonthSelector';

export function useRevenueTab() {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const dispatch = useDispatch();
	const [associatesNames, setAssociatesNames] = useState<string[]>([]);
	const [associatesValues, setAssociatesValues] = useState<any[]>([]);

	const {
		actualMonth,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
		getDateStartFormated,
		getDateEndFormated,
	} = useMonthSelector();

	const {
		execute: getRevenues,
		value: revenuesVal,
		status: revenuesStatus,
	} = useBackendRequest<{ startDate: string; endDate: string }, IRevenuesResponse>({
		path: 'partners-group/revenue/',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (revenuesStatus === 'success') {
			getNamesAndRevenues(revenuesVal?.data!.revenues!);
		} else if (revenuesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-revenues'),
					severity: 'error',
				})
			);
		}
	}, [revenuesStatus]);

	const getNamesAndRevenues = (revenues: IRevenue[]): void => {
		const revenuesArr = [];
		const namesArr: any[] = [];
		let name = '';
		for (let i = 0; i < revenues.length; i++) {
			if (revenues[i].isFirstInvoice) {
				name = `${t('groups.invoice')} - ${revenues[i].name}`;
			} else if (revenues[i].name === 'iva') {
				name = t('common.vat');
			} else {
				name = revenues[i].name;
			}
			revenuesArr.push(revenues[i].revenue !== null ? revenues[i].revenue : '0.0');
			namesArr.push(name);
		}
		setAssociatesValues(revenuesArr);
		setAssociatesNames(namesArr);
		dispatch(setLoading({ loading: false }));
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		setAssociatesNames([]);
		setAssociatesValues([]);
		getRevenues(
			{
				startDate: getDateStartFormated(),
				endDate: getDateEndFormated(),
			},
			id
		);
	}, [actualMonth]);

	return {
		associatesNames,
		associatesValues,
		handleGetFutureMonth,
		handleGetPastMonth,
		getMonthName,
	};
}
