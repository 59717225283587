import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { IAssociate } from '../../../../../../../interfaces/associate.interface';
import { useBackendRequest } from '../../../../../../../hooks/useBackendRequest';
import { setAlert } from '../../../../../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../../../../../constants/tab-values.constants';
import { setLoading } from '../../../../../../../store/modules/loading';
import { IAssociateGroupResponse } from '../../../../../interfaces/associates-group.interface';
import { RootState } from '../../../../../../../store';
import { useTableCustom } from '../../../../../../../hooks/useTableCustom';
import { useAssociatesGroupAdater } from '../adapters/useAssociatesGroupAdapter.adapter';

export function useTabDetails() {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const nav = useNavigate();
	const dispatch = useDispatch();
	const { id: partnerId } = useSelector((state: RootState) => state.partner);
	const [associateData, setAssociateData] = useState<IAssociate>();
	/* const [associatesGroups, setAssociatesGroups] = useState<IAssociateGroupResponse[]>(
		[]
	); */

	const { groupsAdapter } = useAssociatesGroupAdater();

	const { data, setData } = useTableCustom<any>();

	const [tab, setTab] = useState('Details');

	const {
		execute: getAssociate,
		value: associateVal,
		status: associateStatus,
	} = useBackendRequest<unknown, IAssociate>({
		path: 'associates/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associateStatus === 'success') {
			if (associateVal?.data) {
				setAssociateData(associateVal.data);
				getAssociatesGroups({}, partnerId);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('associates.single-associate-error'),
						severity: 'error',
					})
				);
				nav('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
				});
			}
		} else if (associateStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('associates.single-associate-error'),
					severity: 'error',
				})
			);
			nav('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
			});
		}
	}, [associateStatus]);

	const {
		execute: getAssociatesGroups,
		value: associatesGVal,
		status: associatesGStatus,
	} = useBackendRequest<unknown, IAssociateGroupResponse[]>({
		path: 'partners-group/partner/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (associatesGStatus === 'success') {
			if (associatesGVal?.data) {
				filterGroups(associatesGVal.data);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('groups.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (associatesGStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('groups.error-info'),
					severity: 'error',
				})
			);
		}
	}, [associatesGStatus]);

	const filterGroups = (groupsData: IAssociateGroupResponse[]): void => {
		const associatesGroups: IAssociateGroupResponse[] = [];
		for (let i = 0; i < groupsData.length; i++) {
			const group = groupsData[i];
			for (let j = 0; j < group.associates.length; j++) {
				const associate = group.associates[j].associateId;
				if (associate.toString() === id?.toString()) {
					associatesGroups.push(group);
				}
			}
		}
		setData({
			...data,
			countRows: 0,
			rows: groupsAdapter(associatesGroups, handleSeeDetails),
		});
		dispatch(setLoading({ loading: false }));
	};

	const handleSeeDetails = (id: string) => {
		nav(`/partners/associate-group-details/${id}`);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getAssociate({}, id);
	}, []);

	return {
		id,
		associateData,
		tab,
		data,
		setTab,
		setData,
	};
}
