import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IGuns } from '../interfaces/guns.interface';

export const gunsAdapter = (
	data: IGuns[],
	handleSeeDetails: (data: IGuns) => void
): IGuns[] => {
	const newData: IGuns[] = data.map((item: IGuns) => {
		return {
			...item,
			actions: (
				<ButtonIconSmall
					onPress={() => handleSeeDetails(item)}
					type="primary"
					icon={<VisibilityOutlinedIcon />}
				/>
			),
		};
	});
	return newData;
};
