import { useTranslation } from 'react-i18next';

export function useFaq() {
	const [t] = useTranslation('global');

	const faqQuestions = [
		{
			title: t('faq.t1'),
			ans: t('faq.r1'),
		},

		{
			title: t('faq.t4'),
			ans: t('faq.r4'),
		},
		{
			title: t('faq.t2'),
			ans: t('faq.r2'),
		},

		{
			title: t('faq.t5'),
			ans: t('faq.r5'),
		},
		{
			title: t('faq.t3'),
			ans: t('faq.r3'),
		},

		{
			title: t('faq.t6'),
			ans: t('faq.r6'),
		},
		{
			title: t('faq.t7'),
			ans: t('faq.r7'),
		},
		{
			title: t('faq.t8'),
			ans: t('faq.r8'),
		},
		{
			title: t('faq.t9'),
			ans: t('faq.r9'),
		},
	];

	return { faqQuestions };
}
