import React, { useEffect, useState } from 'react';

import styles from './styles/image-input-styles.component.module.css';
import { Badge, Paper } from '@mui/material';
import { Cancel, Edit, ImageOutlined } from '@mui/icons-material';
import { CustomTooltip } from './button-icon-small.component';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { setAlert } from '../store/modules/snackbar';

interface IImageInput {
	image: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onDelete: () => void;
	id?: string;
	name?: string;
	type?: 'image' | 'gif';
	padding?: boolean;
}

const ImageInput = ({
	onChange,
	onDelete,
	type = 'image',
	id,
	name,
	image,
	padding = true,
}: IImageInput) => {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const mbSize = file.size / (1024 * 1024);

		if (mbSize >= 64.0) {
			dispatch(
				setAlert({
					show: true,
					message: t('common.image-error'),
					severity: 'error',
				})
			);
			return;
		}

		onChange(event);
	};

	const handleDeleteImage = () => {
		onDelete();
	};

	return (
		<div
			className={styles.container}
			style={{ paddingLeft: padding ? '15px' : '0px' }}
		>
			{image !== '' ? (
				<div className={styles.boxImageContainer}>
					<Badge
						badgeContent={
							<div
								onClick={handleDeleteImage}
								style={{ cursor: 'pointer' }}
							>
								<CustomTooltip
									title={t('common.delete-img')}
									placement="top"
								>
									<Cancel color="error" style={{ fontSize: 'large' }} />
								</CustomTooltip>
							</div>
						}
					>
						<img
							src={`data:image/${
								type === 'image' ? 'png' : 'gif'
							};base64,${image}`}
							className={styles.image}
						/>
					</Badge>
				</div>
			) : (
				<label>
					<input
						id={id}
						type="file"
						name={name}
						accept={type === 'image' ? 'image/png, image/jpeg' : 'image/gif'}
						onChange={handleImageChange}
					/>
					<Badge
						badgeContent={
							<CustomTooltip title={t('common.add-img')} placement="top">
								<Edit color="primary" style={{ fontSize: 'large' }} />
							</CustomTooltip>
						}
					>
						<Paper>
							<ImageOutlined style={{ width: '100px', height: '100px' }} />
						</Paper>
					</Badge>
				</label>
			)}
		</div>
	);
};

export default ImageInput;
