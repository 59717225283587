import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../hooks/useTableCustom';

export function useGroupsTabColums() {
	const [t] = useTranslation('global');

	const groupsTabColumns: IColumns[] = [
		{
			text: t('groups.group-name'),
			nameID: 'nameGroup',
		},
		{
			text: t('common.total'),
			nameID: 'total',
		},
		{
			text: t('groups.invoice'),
			nameID: 'firstInvoice',
		},
		{
			text: t('common.vat'),
			nameID: 'vat',
		},
		{
			text: t('shared-report.associate1'),
			nameID: 'associate1',
		},
		{
			text: t('shared-report.associate2'),
			nameID: 'associate2',
		},
		{
			text: t('shared-report.associate3'),
			nameID: 'associate3',
		},
		{
			text: t('shared-report.associate4'),
			nameID: 'associate4',
		},
		{
			text: t('shared-report.associate5'),
			nameID: 'associate5',
		},
	];

	return { groupsTabColumns };
}
