import { useDispatch, useSelector } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IStation } from '../../../interfaces/stations.interface';
import { setLoading } from '../../../store/modules/loading';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { useApiRequest } from '../../../hooks/useApiRequest';

interface IUseEditStation {
	setStationData: React.Dispatch<React.SetStateAction<IStation>>;
	setImages: React.Dispatch<
		React.SetStateAction<{ image1: string; image2: string; image3: string }>
	>;
}

export function useEditStation({ setStationData, setImages }: IUseEditStation) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { role } = useSelector((state: RootState) => state.user);

	const { id } = useParams();

	const {
		execute: getStation,
		value: getStationVal,
		status: getStationStatus,
	} = useBackendRequest<any, IStation>({
		path: 'stations/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getStationStatus === 'success') {
			if (getStationVal?.data) {
				setStationData(getStationVal.data);
				setImages({
					image1: getStationVal.data.image1,
					image2: getStationVal.data.image2,
					image3: getStationVal.data.image3,
				});
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-stations'),
						severity: 'error',
					})
				);
			}
		} else if (getStationStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-stations'),
					severity: 'error',
				})
			);
		}
	}, [getStationStatus]);

	const { execute: editStation, status: editStationStatus } = useBackendRequest<
		IStation,
		any
	>({
		path: 'stations/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (editStationStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.edited'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.STATIONS },
				});
			} else {
				navigate('/partners/all-stations');
			}
		} else if (editStationStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-edited'),
					severity: 'error',
				})
			);
		}
	}, [editStationStatus]);

	const { execute: deleteStation, status: deleteStationStatus } = useBackendRequest<
		any,
		any
	>({
		path: 'stations/',
		baseURL: process.env.REACT_APP_API,
		method: 'DELETE',
	});

	useEffect(() => {
		if (deleteStationStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.deleted'),
					severity: 'success',
				})
			);
			if (role.roleName === ROLES.ADMIN || simulating) {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.STATIONS },
				});
			} else {
				navigate('/partners/all-stations');
			}
		} else if (deleteStationStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-deleted'),
					severity: 'error',
				})
			);
		}
	}, [deleteStationStatus]);

	const handleGetStation = (id: string): void => {
		dispatch(setLoading({ loading: true }));
		getStation({}, id);
	};

	const handleEditStation = (station: IStation): void => {
		dispatch(setLoading({ loading: true }));
		const id = station.id;
		delete station.id;
		delete station.create_time;
		delete station.update_time;
		delete station.stationUniqueCode;
		editStation(station, id);
	};

	const handleDeleteStation = (id: string): void => {
		dispatch(setLoading({ loading: true }));
		deleteStation({}, id);
	};

	//To delete Station fron Qion DB
	const { execute: deleteQionStation, status: deleteQionStationStatus } = useApiRequest<
		{ stationUniqueCode: number },
		any
	>({
		path: 'cms-station/remove-relation',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'DELETE',
	});

	useEffect(() => {
		if (
			deleteQionStationStatus === 'success' ||
			deleteQionStationStatus === 'error'
		) {
			deleteStation({}, id);
		}
	}, [deleteQionStationStatus]);

	const handleDeleteQionStation = (stationUniqueCode: string): void => {
		dispatch(setLoading({ loading: true }));
		deleteQionStation({ stationUniqueCode: parseInt(stationUniqueCode) });
	};

	return {
		handleGetStation,
		handleEditStation,
		handleDeleteStation,
		handleDeleteQionStation,
	};
}
