import { useDispatch, useSelector } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setDefaultValues } from '../../../store/modules/default-values';
import { useEffect } from 'react';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { setLoading } from '../../../store/modules/loading';
import { useTranslation } from 'react-i18next';

export function useGetDefaultValues() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { role, partnerId } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const {
		execute: getDefaultValues,
		value: valueDefaultValues,
		status: statusDefaultValues,
	} = useBackendRequest<any, any>({
		path: 'partners/partner/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (statusDefaultValues === 'success') {
			dispatch(setDefaultValues(valueDefaultValues?.data[0]));
			dispatch(setLoading({ loading: false }));
		} else if (statusDefaultValues === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('default-values.error-default-val'),
					severity: 'error',
				})
			);
		}
	}, [statusDefaultValues]);

	useEffect(() => {
		if (role.roleName === ROLES.SUPER_ADMIN) {
			dispatch(setLoading({ loading: true }));
			getDefaultValues({}, simulating ? id : partnerId.toString());
		}
	}, []);
}
