import { VisibilityOutlined } from '@mui/icons-material';
import ButtonIconSmall, {
	CustomTooltip,
} from '../../../components/button-icon-small.component';
import { IMobileUsers, IUser } from '../interfaces/mobile-users.interface';
import { useTranslation } from 'react-i18next';
import styles from '../styles/users-edit-kw.style.module.css';
import { useUserPermisions } from '../../../hooks/useUserPermissions';

export function useMobileUsersAdapter() {
	const [t] = useTranslation('global');
	const { getUsersPermission } = useUserPermisions();

	const mobileUsersAdapter = (
		data: IUser[],
		handleSeeDetails: (user: IUser) => void
	): IMobileUsers[] => {
		const newData: any[] = data.map((item: IUser) => {
			return {
				...item,
				name: (
					<>
						{item.name || item.real_name}{' '}
						{item.first_last_name || item.sur_name}
					</>
				),
				kwExpended: (
					<>{parseFloat(item.total_kw_consumed || '0.0').toFixed(2)} Kw</>
				),
				moneyExpended: <>$ {parseFloat(item.total_money || '0.0').toFixed(2)} </>,
				online: (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CustomTooltip
							title={
								item.online
									? t('charging-status.charging')
									: t('charging-status.not-charging')
							}
							placement="top"
						>
							<div
								className={styles.onlineIndicatorContainer}
								style={{
									borderColor: item.online ? 'green' : 'red',
								}}
							>
								<div
									className={styles.onlineIndicatorContent}
									style={{
										backgroundColor: item.online ? 'green' : 'red',
									}}
								/>
							</div>
						</CustomTooltip>
					</div>
				),
				gifted_kw: <>{item.userDetail?.gifted_kw || 0} Kw</>,
				gifted_money: <>$ {item.userDetail?.gifted_money || 0}</>,
				actions: getUsersPermission() ? (
					<div style={{ display: 'flex', gap: '5px' }}>
						<ButtonIconSmall
							onPress={() => handleSeeDetails(item)}
							type="primary"
							icon={<VisibilityOutlined />}
						/>
					</div>
				) : (
					<span>{t('common.no-actions')}</span>
				),
			};
		});
		return newData;
	};

	return { mobileUsersAdapter };
}
