import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { ICharger } from '../../../../interfaces/charger.interface';
import { IGun } from '../../../../interfaces/gun.interface';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../../store/modules/snackbar';
import { setLoading } from '../../../../store/modules/loading';

interface IUseGetStationGuns {
	setGuns: React.Dispatch<React.SetStateAction<IGun[]>>;
}

export function useGetStationGuns({ setGuns }: IUseGetStationGuns) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const [stationId, setStationId] = useState<string>('');
	const [chargers, setChargers] = useState<ICharger[]>([]);

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<{}, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				const data = filterStationChargers(getChargersVal?.data);
				setChargers(data);
				getGuns();
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-chargers'),
						severity: 'error',
					})
				);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-chargers'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const filterStationChargers = (data: ICharger[]): ICharger[] => {
		const stationChargers = data.filter((charger) => charger.stationId === stationId);
		return stationChargers;
	};

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				filterStationGuns(getGunsVal.data);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-guns'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-guns'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const filterStationGuns = (data: IGun[]): void => {
		const chargersIds: string[] = chargers.map((c) => c.id!);
		const stationGuns = data.filter((gun) => chargersIds.includes(gun.chargerId));
		setGuns(stationGuns);
	};

	const handleGetChargers = (idStation: string): void => {
		setStationId(idStation);
		getChargers();
	};

	return { handleGetChargers, chargers };
}
