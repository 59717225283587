import { useTranslation } from 'react-i18next';
import { VisibilityOutlined } from '@mui/icons-material';
import ButtonIconSmall from '../../../../../../../components/button-icon-small.component';
import { IAssociateGroupResponse } from '../../../../../interfaces/associates-group.interface';

export function useAssociatesGroupAdater() {
	const [t] = useTranslation('global');

	const groupsAdapter = (
		data: IAssociateGroupResponse[],
		handleSeeDetails: (id: string) => void
	): IAssociateGroupResponse[] => {
		const newData: any[] = data.map((item: IAssociateGroupResponse) => {
			return {
				...item,
				group: <>{item.group}</>,
				actions: (
					<ButtonIconSmall
						onPress={() => handleSeeDetails(item.id)}
						type="primary"
						icon={<VisibilityOutlined />}
						hoverLabel={t('common.see-details')}
					/>
				),
			};
		});
		return newData;
	};

	return { groupsAdapter };
}
