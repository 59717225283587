import { useEffect, useMemo, useState } from 'react';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { IMobileUsers, IGiftedData, IUser } from '../interfaces/mobile-users.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { useMobileUsersAdapter } from '../adapters/mobile-users.adapter';
import { ROLES } from '../../../constants/user-roles.constants';

export function useMobileUsers() {
	const [t] = useTranslation('global');
	const { partnerId, role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id: simulatedPartnerId } = useSelector((state: RootState) => state.partner);
	const reg = /^(?!.*\..*\.)[.\d]+$/;
	const dispatch = useDispatch();

	const { mobileUsersAdapter } = useMobileUsersAdapter();

	const { data, setData, pagination, rowsPerPage } = useTableCustom<any>();
	const [openModal, setOpenModal] = useState<boolean>(false);
	const [selectedPartner, setSelectedPartner] = useState<string>('');

	const [selectedUser, setSelectedUser] = useState({
		id: '0',
		name: 'Name 1',
		freeKw: '0.0',
		freeMoney: '0.0',
	});

	const [dataToUpdate, setdataToUpdate] = useState<IGiftedData>({
		partnerId: simulatedPartnerId,
		gifted_kw: '',
		gifted_money: '',
		user_system_type: 1,
	});

	const disabledButton = useMemo((): boolean => {
		return dataToUpdate.gifted_kw === '' || dataToUpdate.gifted_money === '';
	}, [dataToUpdate]);

	const {
		execute: getMobileUsers,
		value: mobileUsersVal,
		status: mobileUsersStatus,
	} = useBackendRequest<any, IMobileUsers>({
		path: 'users/find',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: updateGiftedData,
		value: updateVal,
		status: updateStatus,
	} = useBackendRequest<IGiftedData, any>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (mobileUsersStatus === 'success') {
			if (mobileUsersVal?.data) {
				setData({
					...data,
					countRows: mobileUsersVal.data.totalElements,
					rows: mobileUsersAdapter(mobileUsersVal.data.users, handleSeeDetails),
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('mobile-users.user-info-error'),
						severity: 'error',
					})
				);
			}
		} else if (mobileUsersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('mobile-users.user-info-error'),
					severity: 'error',
				})
			);
		}
	}, [mobileUsersStatus]);

	useEffect(() => {
		if (updateStatus === 'success') {
			if (updateVal?.data) {
				setOpenModal(false);
				setdataToUpdate({
					gifted_kw: '',
					gifted_money: '',
					partnerId: simulatedPartnerId,
					user_system_type: 1,
				});
				dispatch(
					setAlert({
						show: true,
						message: t('mobile-users.update-msg'),
						severity: 'success',
					})
				);
				getUsersByRole();
			}
		} else if (updateStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('mobile-users.update-error-msg'),
					severity: 'error',
				})
			);
		}
	}, [updateStatus]);

	const handleSeeDetails = (user: IUser) => {
		console.log(user);
		setSelectedUser({
			id: user.id,
			name: user.name,
			freeKw: user.userDetail?.gifted_kw || '0.0',
			freeMoney: user.userDetail?.gifted_money || '0.0',
		});
		setOpenModal(true);
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (value !== '') if (!value.match(reg)) return;
		setdataToUpdate({ ...dataToUpdate, [name]: value });
	};

	const handleUpdateData = () => {
		dispatch(setLoading({ loading: true }));
		updateGiftedData(dataToUpdate, selectedUser.id);
	};

	const handleCancel = () => {
		setOpenModal(false);
		setdataToUpdate({
			gifted_kw: '',
			gifted_money: '',
			partnerId: simulatedPartnerId,
			user_system_type: 1,
		});
	};

	const getUsersByRole = (): void => {
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			if (selectedPartner !== '') {
				getMobileUsers({
					user_system_type: 1,
					page: parseInt(pagination),
					pageSize: parseInt(rowsPerPage!),
					partnerId: selectedPartner,
				});
			}
		} else {
			getMobileUsers({
				user_system_type: 1,
				page: parseInt(pagination),
				pageSize: parseInt(rowsPerPage!),
				partnerId: simulating ? simulatedPartnerId : partnerId,
			});
		}
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getUsersByRole();
	}, [rowsPerPage, pagination, selectedPartner]);

	const refresh = (): void => {
		dispatch(setLoading({ loading: true }));
		getMobileUsers({
			user_system_type: 1,
			page: parseInt(pagination),
			pageSize: parseInt(rowsPerPage!),
			partnerId: simulating
				? simulatedPartnerId
				: role.roleName === ROLES.SUPER_ADMIN
				? ''
				: partnerId,
		});
	};

	return {
		data,
		setData,
		openModal,
		setOpenModal,
		selectedUser,
		handleSetData,
		dataToUpdate,
		handleUpdateData,
		disabledButton,
		handleCancel,
		refresh,
		selectedPartner,
		setSelectedPartner,
	};
}
