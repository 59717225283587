import { CustomTooltip } from './button-icon-small.component';
import { DefaultTFuncReturn } from 'i18next';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';

interface IPeriodSelector {
	title: string | DefaultTFuncReturn;
	prevText: string | DefaultTFuncReturn;
	nextText: string | DefaultTFuncReturn;
	onPrev: () => void;
	onNext: () => void;
}

const PeriodSelector = ({
	title,
	prevText,
	nextText,
	onPrev,
	onNext,
}: IPeriodSelector) => {
	return (
		<div
			style={{
				display: 'flex',
				gap: '15px',
				alignItems: 'center',
			}}
		>
			<div
				style={{
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
				}}
				onClick={onPrev}
			>
				<CustomTooltip title={prevText} placement="top">
					<ChevronLeft />
				</CustomTooltip>
			</div>
			<span
				style={{
					fontSize: '20px',
					width: '180px',
					textAlign: 'center',
				}}
			>
				{title}
			</span>
			<div
				style={{
					cursor: 'pointer',
					display: 'flex',
					alignItems: 'center',
				}}
				onClick={onNext}
			>
				<CustomTooltip title={nextText} placement="top">
					<ChevronRight />
				</CustomTooltip>
			</div>
		</div>
	);
};

export default PeriodSelector;
