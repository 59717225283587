import React, { useEffect, useMemo, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useLocation, useNavigate } from 'react-router-dom';
import { RootState } from '../../../store';
import { IAddUserParams } from '../../../interfaces/user';
import { useTranslation } from 'react-i18next';
import { isPasswordValid } from '../../../utils/validators';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { QION_ID } from '../../../constants/qion-id.constants';
import { getJWTInfo } from '../../../utils/getJWTInfo';
import { ROLES } from '../../../constants/user-roles.constants';

export function useAddUser() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { partnerId, role } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);

	const { state } = useLocation();

	const [addUserData, setAddUserData] = useState<IAddUserParams>({
		partnerId: simulating ? id : partnerId.toString(),
		user_name: 'X',
		mobile: '',
		login_pwd: '',
		real_name: 'X',
		sex: 'unknown',
		email: '',
		user_system_type: 2,
		name: '',
		first_last_name: '',
		second_last_name: '',
	});

	const disabledButton = useMemo((): boolean => {
		if (
			addUserData.mobile.length < 10 ||
			!isPasswordValid(addUserData.login_pwd) ||
			addUserData.email === '' ||
			addUserData.name === '' ||
			addUserData.first_last_name === '' ||
			addUserData.second_last_name === ''
		)
			return true;
		return false;
	}, [addUserData]);

	const {
		execute: addUser,
		value: addUserVal,
		status: addUserStatus,
	} = useBackendRequest<IAddUserParams, { accessToken: string; refreshToken: string }>({
		path: 'users/sign-up',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const { execute: editUserPermissions, status: editUserPermissionsStatus } =
		useBackendRequest<any, any>({
			path: 'users/saveOrUpdatePermissions',
			baseURL: process.env.REACT_APP_API,
			method: 'PUT',
		});

	useEffect(() => {
		if (addUserStatus === 'success') {
			if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
				const user = getJWTInfo(addUserVal?.data?.accessToken);
				handleAddPermissions(user.id);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('users.user-added'),
						severity: 'success',
					})
				);
				handleNavigateBack();
			}
		} else if (addUserStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-added-error'),
					severity: 'error',
				})
			);
		}
	}, [addUserStatus]);

	const handleAddPermissions = (userId: string): void => {
		editUserPermissions({
			userId: userId,
			permissions: [
				'367cec7a-c1ac-4814-8218-ec396535f112',
				'40038c3c-6adc-4659-a6e0-4edc3a5405ec',
				'4c70ac77-d39c-42d2-950e-3473448fc86e',
				'8aa84d8e-788f-4056-b937-a8f5def07452',
				'91b0a09a-f878-4060-a853-5e18d1b79235',
				'a36d3646-1445-43c9-bf5b-f249f819cfc0',
				'45452459-fa74-47ce-9149-df02a0cccc16',
				'45f46a8b-9beb-454e-b902-1e0ba617b958',
				'3d87b0ed-4aff-46ad-9666-3db5cebfeba0',
				'58b35f0b-521b-4920-9310-96d51045c4ac',
				'78d7c431-3222-4a3e-b8e1-1ffc3143a9ee',
			],
		});
	};

	useEffect(() => {
		if (editUserPermissionsStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-added'),
					severity: 'success',
				})
			);
			handleNavigateBack();
		} else if (editUserPermissionsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error when setting permissions',
					severity: 'success',
				})
			);
			handleNavigateBack();
		}
	}, [editUserPermissionsStatus]);

	const handleNavigateBack = (): void => {
		state.prevUrl === '/users'
			? navigate(-1)
			: navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.USERS },
			  });
	};

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, name } = event.target;
		setAddUserData({ ...addUserData, [name]: value });
	};

	const handleAddUser = () => {
		dispatch(setLoading({ loading: true }));
		addUser(addUserData);
	};

	const getInputLabel = (inputName: string): string => {
		switch (inputName) {
			case 'name':
				return t('common.name');
			case 'first_last_name':
				return t('common.f-last-name');
			case 'second_last_name':
				return t('common.s-last-name');
			case 'mobile':
				return t('common.phone');
			case 'email':
				return t('common.email');
			case 'login_pwd':
				return t('common.password');
			default:
				return 'Label';
		}
	};

	return { addUserData, disabledButton, handleSetData, handleAddUser, getInputLabel };
}
