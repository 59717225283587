import { Grid, Tab, Tabs, ThemeProvider, useMediaQuery, useTheme } from '@mui/material';
import { useSharedReport } from './hooks/useSharedReport';
import styles from './styles/styles.module.css';
import { useTranslation } from 'react-i18next';
import { Group, Person, Receipt } from '@mui/icons-material';
import { useState } from 'react';
import AssociatesTab from './components/associates-tab';
import GroupsTab from './components/groups-tab';
import TransactionsTab from './components/transaction-tab';

const SharedOrderReport = () => {
	const { tabsTheme } = useSharedReport();

	const [t] = useTranslation('global');
	const theme = useTheme();

	const [tabValue, setValueTab] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValueTab(newValue);
	};

	const onHandleTab = (tab: number) => {
		switch (tab) {
			case 0:
				return <AssociatesTab />;
			case 1:
				return <GroupsTab />;
			case 2:
				return <TransactionsTab />;
			default:
				return <AssociatesTab />;
		}
	};

	return (
		<div className={styles.mainContainer}>
			<h3 style={{ margin: 0, marginBottom: '25px' }}>
				{t('shared-report.title')}
			</h3>
			<Grid container xs={12}>
				<ThemeProvider theme={tabsTheme}>
					<Tabs
						value={tabValue}
						onChange={handleChange}
						style={{ width: '100%' }}
						variant={
							useMediaQuery(theme.breakpoints.up('lg'))
								? 'fullWidth'
								: 'scrollable'
						}
					>
						<Tab
							icon={<Person />}
							iconPosition="start"
							label={<span>{t('shared-report.associates')}</span>}
						/>
						<Tab
							icon={<Group />}
							iconPosition="start"
							label={<span>{t('shared-report.groups')}</span>}
						/>
						<Tab
							icon={<Receipt />}
							iconPosition="start"
							label={<span>{t('shared-report.transactions')}</span>}
						/>
					</Tabs>
				</ThemeProvider>
			</Grid>
			<Grid container padding={3} xs={12}>
				{onHandleTab(tabValue)}
			</Grid>
		</div>
	);
};

export default SharedOrderReport;
