import { DefaultTFuncReturn } from 'i18next';
import { hexToRGBA } from '../../utils/hex-to-rgba';
import styles from './styles.component.module.css';

interface IDashboardOption {
	name: string | DefaultTFuncReturn;
	img: JSX.Element | JSX.Element[];
	desc: string;
	onClick: () => void;
	hexColor: string;
}

const DashboardOption = ({ name, img, desc, onClick, hexColor }: IDashboardOption) => {
	return (
		<div className={styles.mainContainer} onClick={onClick}>
			<div
				style={{
					color: hexColor,
					backgroundColor: hexToRGBA(hexColor, 0.2),
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					height: '40px',
					minWidth: '40px',
					borderRadius: 4,
				}}
			>
				{img}
			</div>
			<div className={styles.subContainer}>
				<span className={styles.title}>{name}</span>
				<span className={styles.desc}>{desc}</span>
			</div>
		</div>
	);
};

export default DashboardOption;
