import { useLocation, useNavigate } from 'react-router-dom';
import TableCustom from '../../components/table-custom.component';
import CustomButton from '../../components/button.component';

import styles from './styles/user-page.styles.module.css';
import { Add } from '@mui/icons-material';
import { Grid, Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useUserPermisions } from '../../hooks/useUserPermissions';
import { useTranslation } from 'react-i18next';
import { useUsers } from './hooks/useUsers';
import DeleteModal from './components/delete-modal.component';
import DisableModal from './components/disable-modal.component';

export const Users = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const location = useLocation();
	const colors = useSelector((state: RootState) => state.colors);
	const { getUsersPermission } = useUserPermisions();

	const {
		userColumns,
		data,
		openModal,
		selectedUser,
		openDisableModal,
		disabledModalContent,
		setData,
		handleCloseModal,
		handleDeleteUser,
		handleAcceptModal,
	} = useUsers();

	return (
		<Grid container gap={'25px'} flexDirection={'column'}>
			<Grid item display={'flex'} flexDirection={'row'} justifyContent={'end'}>
				{getUsersPermission() && (
					<CustomButton
						label={t('users.new-user')}
						variant={'solid'}
						onClick={() =>
							navigate('/users/add-user', {
								state: { prevUrl: location.pathname },
							})
						}
						icon={Add}
						styles={{
							backgroundColor: colors.accent,
							color: 'black',
							width: 'fit-content',
							padding: '0 15px',
							gap: '8px',
							justifySelf: 'flex-end',
							alignSelf: 'flex-end',
						}}
					/>
				)}
			</Grid>
			<Paper elevation={2} className={styles.boxPaper}>
				<TableCustom
					columns={userColumns}
					data={data}
					setData={setData}
					showTotalCount={false}
					hideChips
					hideHeader
				/>
			</Paper>
			<DeleteModal
				openModal={openModal}
				selectedUser={selectedUser}
				handleDeleteUser={handleDeleteUser}
				handleCloseModal={handleCloseModal}
			/>
			<DisableModal
				openDisableModal={openDisableModal}
				selectedUser={selectedUser}
				content={disabledModalContent}
				handleDisableUser={handleAcceptModal}
				handleCloseModal={handleCloseModal}
			/>
		</Grid>
	);
};
