// React dependencies
import { useNavigate } from 'react-router-dom';
import { RootState } from '../../../../../store';
import { useSelector } from 'react-redux';
// Third-party libraries
import { Button, Card, CardActions, CardContent, CardHeader, Grid } from '@mui/material';
// Style dependencies
import styles from '../../../styles/admin-guns-tab.module.css';
import { ReactComponent as PlugIcon } from '../../../../../assets/svgs/plug.svg';
import { hexToRGBA } from '../../../../../utils/hex-to-rgba';
import StopButton from '../../../../../components/stop-button.component';
import ModalStop from '../../../../../components/modal-stop.component';
import { useTranslation } from 'react-i18next';
import { useGunsTab } from './hooks/useGunsTab';
import CustomButton from '../../../../../components/button.component';
import { Add } from '@mui/icons-material';
import { useUserPermisions } from '../../../../../hooks/useUserPermissions';

const AdminGunsTab = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const nav = useNavigate();

	const {
		gunsData,
		openModal,
		selectedGun,
		setOpenModal,
		handleOpenModal,
		handleStopGun,
		getChargerName,
	} = useGunsTab();

	const { getGunsPermission } = useUserPermisions();

	return (
		<>
			{
				<div className={styles['filter-container']}>
					<div></div>
					{getGunsPermission() && (
						<CustomButton
							icon={Add}
							variant="solid"
							label={t('guns.new')}
							onClick={() => nav('/partners/add-gun')}
							styles={{
								backgroundColor: colors.accent,
								color: 'black',
								width: 'fit-content',
								padding: '0 15px',
								gap: '10px',
							}}
						/>
					)}
				</div>
			}
			<Grid container spacing={2}>
				{gunsData.length > 0 ? (
					gunsData.map((gun) => (
						<Grid
							key={gun.id!}
							item
							xs={12}
							sm={6}
							md={4}
							lg={3}
							className={styles.card}
						>
							<Card
								sx={{
									backgroundColor: hexToRGBA(colors.secondary, 0.2),
									border: `1px solid ${colors.secondary}`,
									height: '100%',
								}}
							>
								<CardHeader
									titleTypographyProps={{
										fontWeight: 600,
										fontSize: '20px',
										color: colors.primary,
									}}
									title={gun.name}
									avatar={
										<PlugIcon
											style={{
												color: colors.primary,
												width: '25px',
												height: '25px',
											}}
										/>
									}
								/>
								<CardContent>
									<div className={styles.cardContent}>
										<span style={{ color: colors.primary }}>
											<b>{t('common.plugtype')}:</b>
										</span>
										{t('common.plugtype')} {gun.plugType}
										<span style={{ color: colors.primary }}>
											<b>{t('dashboard-4.charger-asigned')}:</b>
										</span>
										{getChargerName(gun.chargerId)}
									</div>
								</CardContent>
								<CardActions
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
									}}
								>
									<Button
										onClick={() =>
											nav(
												`/partners/detail-gun/${gun.qionGunUniqueId}`,
												{
													state: {
														charger: getChargerName(
															gun.chargerId
														),
														plugtype: gun.plugType,
														gunId: gun.id,
														gunName: gun.name,
													},
												}
											)
										}
										style={{
											color: colors.secondary,
											fontWeight: 'bold',
											fontSize: 14,
										}}
									>
										{t('common.view').toUpperCase()}
									</Button>
									{getGunsPermission() && (
										<StopButton
											onButtonClick={() => handleOpenModal(gun)}
											text={`${t('guns.stop')} ${gun.name}`}
										/>
									)}
								</CardActions>
							</Card>
						</Grid>
					))
				) : (
					<Card
						sx={{
							padding: 5,
							width: '100%',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							backgroundColor: hexToRGBA(colors.secondary, 0.2),
							border: `1px solid ${colors.secondary}`,
						}}
					>
						<h1 style={{ justifySelf: 'center', color: colors.secondary }}>
							{t('guns.unasotied')}
						</h1>
					</Card>
				)}
			</Grid>
			<ModalStop
				open={openModal}
				setOpen={setOpenModal}
				acceptModal={handleStopGun}
				name={selectedGun.name}
				type="gun"
			/>
		</>
	);
};

export default AdminGunsTab;
