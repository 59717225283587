import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import styles from './styles.module.css';
import { useFaq } from './utils/useFaq';
import { useTranslation } from 'react-i18next';

const FAQ = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);

	const { faqQuestions } = useFaq();

	return (
		<div className={styles.container}>
			<h1 style={{ color: colors.secondary }}>{t('faq.title')}</h1>
			{faqQuestions.map((q, index) => (
				<div key={`question-${index}`}>
					<h3 className={styles.question} style={{ color: colors.secondary }}>
						{q.title}
					</h3>
					<span className={styles.answer}>{q.ans}</span>
				</div>
			))}
		</div>
	);
};

export default FAQ;
