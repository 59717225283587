import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IStation } from '../../../interfaces/stations.interface';
import { IOptions } from '../../../components/select-input.component';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { setLoading } from '../../../store/modules/loading';
import { IGun } from '../../../interfaces/gun.interface';

export function useGetStations() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { role } = useSelector((state: RootState) => state.user);
	const { id } = useSelector((state: RootState) => state.partner);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const [stationsOptions, setStationsOptions] = useState<IOptions[]>([]);
	const [gunsOptions, setGunsOptions] = useState<IOptions[]>([]);

	const [disabledReportButton, setDisabledReportButton] = useState<boolean>(true);

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				const data = filterData(getStationsVal.data);
				getStationsSelect(data);
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const filterData = (stations: IStation[]): IStation[] => {
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			return stations;
		} else {
			return stations.filter((s) => s.partnerInfoId === id);
		}
	};

	const getStationsSelect = (stations: IStation[]): void => {
		const optionsSelect: IOptions[] = stations.map((s, index) => {
			return {
				label: s.name,
				value: `${s.name}-${index}`,
			};
		});
		setStationsOptions(optionsSelect);
		getGuns();
	};

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const gunsFiltered = filterGuns(getGunsVal?.data);
				generateGunsOptions(gunsFiltered);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const filterGuns = (guns: IGun[]): IGun[] => {
		let filteredGuns: IGun[] = [];
		if (role.roleName === ROLES.ADMIN || simulating) {
			filteredGuns = guns.filter((g) => g.partnerInfoId === id);
		} else {
			filteredGuns = guns;
		}
		return filteredGuns;
	};

	const generateGunsOptions = (guns: IGun[]): void => {
		const gunsOptionsVals: IOptions[] = guns.map((g) => {
			return { label: g.name, value: g.qionGunUniqueId };
		});
		setGunsOptions(gunsOptionsVals);
	};

	useEffect(() => {
		if (gunsOptions.length > 0 && stationsOptions.length > 0) {
			setDisabledReportButton(false);
		}
	}, [gunsOptions, stationsOptions]);

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return { stationsOptions, gunsOptions, disabledReportButton };
}
