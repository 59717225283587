import { useState } from 'react';
import { GenerateReportContext } from './GenerateReportContext';
import {
	IColumnItem,
	IDownloadReportParams,
} from '../interfaces/generate-report.interface';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { useLocation } from 'react-router-dom';
import { IOptions } from '../../../components/select-input.component';

interface GenerateReportProvider {
	children: JSX.Element | JSX.Element[];
}

const GenerateReportProvider = ({ children }: GenerateReportProvider) => {
	const { state } = useLocation();
	const gunsSelect = state.guns as IOptions[];
	const guns = gunsSelect.map((g) => {
		return g.value.toString();
	});
	const { id } = useSelector((state: RootState) => state.partner);
	const { role, partnerId } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const [columns, setColumns] = useState<IColumnItem[]>([]);
	const [selectedColumns, setSelectedColumns] = useState<IColumnItem[]>([]);
	const [filterData, setFilterData] = useState<IDownloadReportParams>({
		partnerInfoId:
			role.roleName === ROLES.SUPER_ADMIN && !simulating
				? partnerId.toString()
				: id,
		document: 'csv',
		gun_ids: guns,
		station: 'all',
		datetime_charging_start: dayjs().startOf('month').format('YYYY-MM-DD'),
		datetime_charging_end: dayjs().format('YYYY-MM-DD'),
		dataReports: [],
	});

	return (
		<GenerateReportContext.Provider
			value={{
				selectedColumns,
				setSelectedColumns,
				columns,
				setColumns,
				filterData,
				setFilterData,
			}}
		>
			{children}
		</GenerateReportContext.Provider>
	);
};

export default GenerateReportProvider;
