import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setPermissions } from '../../../store/modules/permissions';
import { defaultUser, editUser } from '../../../store/modules/user';
import { RootState } from '../../../store';
import { getJWTInfo } from '../../../utils/getJWTInfo';
import { IDefaultValues, setDefaultValues } from '../../../store/modules/default-values';
import { IPartnerInfo } from '../../../interfaces/partner';
import { setAlert } from '../../../store/modules/snackbar';
import { editPartner } from '../../../store/modules/partner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ITheme } from '../../settings-page/components/settings-theme.component';
import { defaultColors, editColors } from '../../../store/modules/colors';
import { QION_ID } from '../../../constants/qion-id.constants';
import { IGun } from '../../../interfaces/gun.interface';
import { setLoading } from '../../../store/modules/loading';
import {
	IUsersDetailsPartner,
	IUsersFindInfoResponse,
} from '../interfaces/login.interface';
import { isEmpty } from '../../../utils/validators';

interface IUseGetPermissions {
	getSelectedPartner: () => string;
}

export function useGetPermissions({ getSelectedPartner }: IUseGetPermissions) {
	const [t, i18n] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const user = useSelector((state: RootState) => state.user);
	const partner = useSelector((state: RootState) => state.partner);

	const [partnerId, setPartnerId] = useState<string>('');

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getPermissions,
		value: valuePermissions,
		status: statusPermissions,
	} = useBackendRequest<any, any>({
		path: 'permissions',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getUserPermissions,
		value: valueUserPermissions,
		status: statusUserPermissions,
	} = useBackendRequest<any, IUsersFindInfoResponse>({
		path: 'users/findInfo/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getDefaultValues,
		value: valueDefaultValues,
		status: statusDefaultValues,
	} = useBackendRequest<any, IDefaultValues[]>({
		path: 'partners/partner/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getPartnerInfo,
		value: getPartnerInfoVal,
		status: getPartnerInfoStatus,
	} = useBackendRequest<any, IPartnerInfo>({
		path: 'partners-information/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (statusPermissions === 'success') {
			dispatch(setPermissions(valuePermissions?.data));
			const user = getJWTInfo();
			getUserPermissions({}, user.id);
		} else if (statusPermissions === 'error') {
			removeLocalStorageData();
		}
	}, [statusPermissions]);

	const removeLocalStorageData = (): void => {
		localStorage.removeItem('validToken');
		localStorage.removeItem('accessToken');
		localStorage.removeItem('persist:root');
		dispatch(editUser(defaultUser));
		dispatch(setLoading({ loading: false }));
		dispatch(
			setAlert({
				show: true,
				message: t('login.error-services'),
				severity: 'error',
			})
		);
	};

	const filterPartner = (
		partnerId: string,
		partnerDetails: IUsersDetailsPartner[]
	): IUsersDetailsPartner | null => {
		const cmsPartners = partnerDetails.filter((p) => p.user_system_type === 2);
		if (isEmpty(cmsPartners)) {
			return null;
		}
		const partners = cmsPartners.filter((p) => p.partnerId === partnerId)[0];
		if (isEmpty(partners)) {
			return null;
		} else {
			return partners;
		}
	};

	useEffect(() => {
		if (statusUserPermissions === 'success') {
			const partner = filterPartner(
				getSelectedPartner(),
				valueUserPermissions?.data?.userDetailsPartner!
			);
			if (
				partner === null ||
				partner.is_deleted === 1 ||
				!valueUserPermissions?.data
			) {
				removeLocalStorageData();
				return;
			}
			let userRole: { roleId: number; roleName: string } = {
				roleId: 0,
				roleName: '',
			};
			if (partner.partnerId === QION_ID) {
				userRole = { roleId: 2, roleName: 'SUPER_ADMIN' };
			} else {
				userRole = { roleId: 1, roleName: 'ADMIN' };
			}
			dispatch(
				editUser({
					...user,
					id: valueUserPermissions?.data.id,
					name: valueUserPermissions?.data.name,
					lastName: valueUserPermissions?.data.first_last_name,
					partnerId: partner.partnerId,
					permissions: valueUserPermissions?.data.userPermissions,
					role: userRole,
				})
			);
			const userTheme: ITheme = valueUserPermissions?.data.theme;
			if (userTheme === null) {
				dispatch(editColors(defaultColors));
			} else {
				dispatch(
					editColors({
						...userTheme,
						primary: userTheme.colors.primary,
						secondary: userTheme.colors.secondary,
						accent: userTheme.colors.accent,
					})
				);
			}
			//HERE
			const userPartnerId = partner.partnerId;
			setPartnerId(userPartnerId);
			getDefaultValues({}, userPartnerId);
		} else if (statusUserPermissions === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('login.error-services'),
					severity: 'error',
				})
			);
		}
	}, [statusUserPermissions]);

	useEffect(() => {
		if (statusDefaultValues === 'success') {
			const values = valueDefaultValues?.data![0];
			dispatch(setDefaultValues(values));
			getPartnerInfo({}, partnerId);
		} else if (statusDefaultValues === 'error') {
			removeLocalStorageData();
		}
	}, [statusDefaultValues]);

	const setInitialPartnerLanguage = (languajes: string[]): void => {
		if (languajes) {
			if (languajes.length === 1) {
				i18n.changeLanguage(languajes[0]);
				localStorage.setItem('language', languajes[0]);
			}
		}
	};

	useEffect(() => {
		if (getPartnerInfoStatus === 'success') {
			if (getPartnerInfoVal?.data) {
				dispatch(editPartner(getPartnerInfoVal.data));
				setInitialPartnerLanguage(getPartnerInfoVal.data.languages);
				getGuns();
			} else {
				removeLocalStorageData();
			}
		} else if (getPartnerInfoStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('login.error-services'),
					severity: 'error',
				})
			);
		}
	}, [getPartnerInfoStatus]);

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const filteredGuns = getGunsVal.data.filter(
					(g) => g.partnerInfoId === partner.id
				);
				const qionIds = filteredGuns.map((g) => {
					return g.qionGunUniqueId;
				});
				dispatch(editUser({ ...user, partnerGuns: qionIds }));
				localStorage.setItem('validToken', 'true');
				navigate('/dashboard');
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const handleGetPermissions = () => {
		getPermissions();
	};

	return {
		handleGetPermissions,
	};
}
