export interface IModalContent {
	title: string;
	deleteMsg1: string;
	disableMsg: string;
	deleteMsg2: string;
	buttonTxt: string;
}

export const defaultModalContent: IModalContent = {
	title: '',
	deleteMsg1: '',
	disableMsg: '',
	deleteMsg2: '',
	buttonTxt: '',
};
