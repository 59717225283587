import { useSelector } from 'react-redux';
import styles from './styles/item.component.module.css';
import { RootState } from '../../../store';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { Chip } from '@mui/material';
import { HighlightOff } from '@mui/icons-material';

interface IItemProps {
	id: string;
	name: string;
	onItemClick: (id: string) => void;
	selected: boolean;
}

const Item = ({ id, name, onItemClick, selected }: IItemProps) => {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<div
			className={selected ? styles.selectedItem : styles.item}
			style={{
				borderColor: colors.secondary,
				backgroundColor: hexToRGBA(colors.secondary, 0.2),
			}}
			onClick={selected ? () => {} : () => onItemClick(id)}
		>
			{name}
			{selected && (
				<div
					onClick={() => onItemClick(id)}
					style={{ color: colors.secondary, fontSize: 4, cursor: 'pointer' }}
				>
					<HighlightOff />
				</div>
			)}
		</div>
	);
};

export default Item;
