export const COLUMS = Object.freeze({
	ID: 'id',
	DATETIME_CHARGING: 'datetime_charging',
	STATUS: 'status',
	ORDER_NUMBER: 'order_number',
	STATION: 'station',
	LOAD_PERCENTAGE: 'load_percentage',
	LOAD_TIME: 'load_time',
	CONSUMED_KW: 'consumed_kw',
	VOLTAGE: 'voltage',
	CURRENT: 'current',
	ACUMULATED_CHARGE: 'accumulated_charge',
	ACUMULATED_CHARGE_TIME: 'accumulated_charge_time',
	ACUMULATED_CHARGE_COST: 'accumulated_charge_cost',
	STATUS_NUMBER: 'status_number',
	TOTAL: 'total',
	PAYING_CARD: 'paying_card',
	VEHICLE_ID: 'vehicle_id',
	GUN_ID: 'gun_id',
	ASSOCIATES: 'associates',
	VIN: 'vin',
	STATION_ID: 'station_id',
	USER: 'user',
});

export const preSelectedIds: string[] = [
	'update_time',
	'order_number',
	'gun_id',
	'station',
	'status',
	'vehicle_id',
	'voltage',
	'current',
	'accumulated_charge',
	'load_time',
	'accumulated_charge_cost',
];
