import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useUserPermissionsColumns() {
	const [t] = useTranslation('global');

	const userPermissionsColumns: IColumns[] = [
		{
			text: t('users.user'),
			nameID: 'name',
		},
		{
			text: t('users.permissions'),
			nameID: 'permissions',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { userPermissionsColumns };
}
