import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IStations } from '../interfaces/stations.interface';

export const stationsAdapter = (
	data: IStations[],
	handleSeeDetails: (data: IStations) => void
): IStations[] => {
	const newData: IStations[] = data.map((item: IStations) => {
		return {
			...item,
			actions: (
				<ButtonIconSmall
					onPress={() => handleSeeDetails(item)}
					type="primary"
					icon={<VisibilityOutlinedIcon />}
				/>
			),
		};
	});
	return newData;
};
