import { IColumns } from '../../../hooks/useTableCustom';

export const associateGroupsColumns: IColumns[] = [
	{
		text: 'Group',
		nameID: 'group',
	},
	{
		text: 'Associates',
		nameID: 'associates',
	},
	{
		text: 'Stations',
		nameID: 'stations',
	},
	{
		text: 'Charger',
		nameID: 'charger',
	},
	{
		text: 'Actions',
		nameID: 'actions',
	},
];
