import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { editColors } from '../../../../../store/modules/colors';
import { setLoading } from '../../../../../store/modules/loading';
import { setAlert } from '../../../../../store/modules/snackbar';
import { useEffect } from 'react';
import { RootState } from '../../../../../store';

export function useUpdateUserTheme() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const { id: userId } = useSelector((state: RootState) => state.user);

	const { execute: updateUserTheme, status: statusUserTheme } = useBackendRequest<
		{ themeId: string },
		any
	>({
		path: 'users/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (statusUserTheme === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated'),
					severity: 'success',
				})
			);
			dispatch(
				editColors({
					id: '1',
					name: 'Color 1',
					primary: '#000000',
					secondary: '#474747',
					accent: '#2ba8e0',
				})
			);
		} else if (statusUserTheme === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('themes.updated-error'),
					severity: 'error',
				})
			);
		}
	}, [statusUserTheme]);

	const updateDefaultTheme = (): void => {
		updateUserTheme({ themeId: '27aaa49a-75ff-48cc-8ab5-bdcbb50429b6' }, userId);
	};

	return {
		updateDefaultTheme,
	};
}
