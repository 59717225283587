import { Divider, Grid, Paper, Typography } from '@mui/material';
import CustomButton from '../../components/button.component';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Input from '../../components/input.component';
import { useEditPartner } from './hooks/useEditPartner';

import styles from './styles/add-partner.module.css';
import ImageInput from '../../components/image-input.component';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CustomMultiSelect from '../../components/custom-multiselect.component';
import { useLanguagesCatalog } from '../../utils/languages-catalog';

const EditPartner = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();

	const colors = useSelector((state: RootState) => state.colors);

	const {
		partnerInfo,
		disabledButton,
		handleSetData,
		handleImageChange,
		handleDeleteImage,
		handleEditPartner,
		handleLanguages,
	} = useEditPartner();

	const { languages } = useLanguagesCatalog();

	return (
		<Grid container display="flex" justifyContent="center">
			<Paper className={styles.boxPaper} elevation={1}>
				<Grid container item xs={12} display="flex" alignItems="center">
					<Grid item xs={5}>
						<Typography
							variant="h6"
							color="var(--color-blue-main)"
							fontWeight="bold"
						>
							{t('partners.edit-title')}
						</Typography>
					</Grid>
					<Grid item xs={2} style={{ marginLeft: 'auto' }}>
						<CustomButton
							label={t('common.back').toString()}
							onClick={() => navigate(-1)}
							variant="solid"
							styles={{
								backgroundColor: 'var(--color-black-main)',
								width: 'fit-content',
								padding: '0 15px',
								gap: 10,
							}}
							icon={ArrowBackIcon}
						/>
					</Grid>
				</Grid>
				<Grid item>
					<Divider style={{ margin: '20px 0' }} />
				</Grid>
				<Grid
					container
					item
					xs={12}
					spacing={3}
					paddingRight={2}
					paddingLeft={2}
					mb={4}
				>
					<Grid item xs={12} md={6}>
						<Input
							label={t('partners.partner-name').toString()}
							id="name"
							name="name"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.name}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('partners.stripe-code').toString()}
							id="stripeCode"
							name="stripeCode"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.stripeCode || ''}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('partners.responsible-name').toString()}
							id="responsibleName"
							name="responsibleName"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.responsibleName}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('partners.responsible-last-name').toString()}
							id="responsibleLastName"
							name="responsibleLastName"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.responsibleLastName}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.address').toString()}
							id="address"
							name="address"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.address}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.state').toString()}
							id="state"
							name="state"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.state}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.country').toString()}
							id="country"
							name="country"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.country}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.zip').toString()}
							id="zip"
							name="zip"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.zip}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.tax-id').toString()}
							id="rfc"
							name="rfc"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.rfc}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={t('common.phone').toString()}
							id="phone"
							name="phone"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.phone}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<Input
							label={'Hold'}
							id="hold"
							name="hold"
							placeholder={t('common.required').toString()}
							type="text"
							required
							value={partnerInfo.hold || ''}
							onChange={handleSetData}
						/>
					</Grid>
					<Grid item xs={12} md={6}>
						<CustomMultiSelect
							name={t('partners.languages')}
							selectData={languages}
							onChange={handleLanguages}
							val={partnerInfo.languages ? partnerInfo.languages : []}
						/>
					</Grid>
				</Grid>
				<Grid item xs={5} paddingLeft={2} mb={4}>
					<Typography
						variant="h6"
						color="var(--color-blue-main)"
						fontWeight="bold"
					>
						{t('partners.branding')}
					</Typography>
				</Grid>
				<ImageInput
					onChange={handleImageChange}
					onDelete={handleDeleteImage}
					image={partnerInfo.img}
				/>

				<Grid item>
					<Divider style={{ margin: '10px 0' }} />
				</Grid>
				<Grid
					container
					item
					xs={12}
					gap={1}
					display="flex"
					justifyContent="flex-end"
				>
					<Grid item xs={2}>
						<CustomButton
							label={t('common.cancel')}
							onClick={() => navigate(-1)}
							variant="solid"
							styles={{ backgroundColor: 'var(--color-black-main)' }}
						/>
					</Grid>
					<Grid item xs={2}>
						<CustomButton
							label={t('common.save')}
							onClick={handleEditPartner}
							variant="solid"
							styles={{
								backgroundColor: colors.accent,
								color: 'black',
							}}
							disabled={disabledButton}
						/>
					</Grid>
				</Grid>
			</Paper>
		</Grid>
	);
};

export default EditPartner;
