/* eslint-disable prettier/prettier */
import styles from './styles.component.module.css';
import { Bolt } from '@mui/icons-material';
import { IDashboardLocationGun } from '../../pages/interfaces/location-dashboard.interface';

import { ReactComponent as PlugImg } from '../../../../assets/svgs/plug.svg';
import { useTranslation } from 'react-i18next';

interface ICardCharger {
	data: IDashboardLocationGun;
}

const CardCharger = ({ data }: ICardCharger) => {
	const [t] = useTranslation('global');
	return (
		<div className={styles.card}>
			<p className={styles.title}>
				<PlugImg width={'20px'} height={'20px'} />
				{data.name}
			</p>
			<div
				style={{
					marginTop: 'auto',
				}}
			>
				<p className={styles.text}>{t('dashboard-3.daily-kw')}</p>
				<p className={styles.textGreen}>{data.kw} Kw</p>
				<p className={styles.text}>{t('dashboard-3.status')}</p>
				<p className={styles.text}>
					<span
						className={styles.circle}
						style={{
							background:
								data.status === true
									? '#18C19F'
									: data.status === false
									? '#FAB803'
									: '#E6E6E6',
						}}
					></span>
					{data.status === true
						? t('dashboard-3.charging')
						: t('dashboard-3.not-charging')}
				</p>
			</div>
		</div>
	);
};

export default CardCharger;
