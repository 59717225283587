import { createSlice } from '@reduxjs/toolkit';
const defaultGuns: any = {};

export const userSlice = createSlice({
	name: 'guns',
	initialState: defaultGuns,
	reducers: {
		createGuns: (state, action) => action.payload,
		editGuns: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { createGuns, editGuns } = userSlice.actions;

export default userSlice.reducer;
