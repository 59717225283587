import { useEffect } from 'react';
import TableCustom from '../../../components/table-custom.component';
import { useTableCustom } from '../../../hooks/useTableCustom';

import styles from './styles/section-connectors-component.module.css';
import { IGunsResponse } from '../interfaces/station-details.interface';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useGunsColums } from './utils/connectors-table.columns';
import { useConnectorsAdapter } from './adapters/connectors.adapter';
import { IGun } from '../../../interfaces/gun.interface';

interface ISectionConnectors {
	gunsData: IGun[];
}

const SectionConnectors = ({ gunsData }: ISectionConnectors) => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const { data, setData } = useTableCustom<any>();

	const { gunsColumns } = useGunsColums();
	const { connectorsAdapter } = useConnectorsAdapter();

	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: connectorsAdapter(gunsData, handleSeeDetails),
		});
	}, []);

	const handleSeeDetails = (gun: IGun): void => {
		navigate(`/partners/detail-gun/${gun.qionGunUniqueId}`, {
			state: {
				charger: gun.chargerId,
				plugtype: gun.plugType,
			},
		});
	};

	return (
		<div className={styles.mainContainer}>
			<div className={styles.titleContainer}>
				<h3>{t('stations.station-guns')}</h3>
			</div>
			<TableCustom
				columns={gunsColumns}
				data={data}
				setData={setData}
				showTotalCount={false}
				hideChips
				showSearchBar={false}
				hideHeader
			/>
			{/* <CustomModal title="New Assignment" open={openModal} maxWidth="xl" fullWidth>
				<>
					<hr />
					<Grid container spacing={5} className={styles.modalContent}>
						<Grid
							item
							xl={7}
							lg={7}
							md={12}
							sm={12}
							display={'flex'}
							flexDirection={'column'}
							gap={5}
						>
							<Input name="Associates" label="Associates" type="text" />
							<Input
								name="Type of assignment"
								label="Type of assignment"
								type="text"
							/>
							<Input name="Assignment" label="Assignment" type="text" />
						</Grid>
						<Grid
							item
							xl={5}
							lg={5}
							md={12}
							sm={12}
							display={'flex'}
							flexDirection={'column'}
							gap={5}
						>
							<img src={Graph} alt="Graph" width={'50%'} />
							<div className={styles.infoContainer}>
								<span className={styles.infoName}>Available</span>
								<span className={styles.infoPer}>100%</span>
							</div>
							<div className={styles.infoContainer}>
								<span className={styles.infoName}>Assigned</span>
								<span className={styles.infoPer}>0%</span>
							</div>
							<div className={styles.infoContainer}>
								<span className={styles.infoName}>New Assignment</span>
								<span className={styles.infoPer}>35%</span>
							</div>
						</Grid>
					</Grid>
					<hr />
					<div className={styles.buttonsContainer}>
						<CustomButton
							label={'Cancel'}
							variant={'solid'}
							onClick={() => setOpenModal(false)}
							styles={{
								width: '158px',
								gap: '11px',
								backgroundColor: 'var(--color-black-main)',
							}}
						/>
						<CustomButton
							label={'Assign'}
							variant={'solid'}
							onClick={() => setOpenModal(false)}
							styles={{
								width: '158px',
								gap: '11px',
								backgroundColor: 'var(--color-primary-main)',
							}}
						/>
					</div>
				</>
			</CustomModal> */}
		</div>
	);
};

export default SectionConnectors;
