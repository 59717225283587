import { useEffect, useState } from 'react';
import { Box, CssBaseline, Container, useMediaQuery, useTheme } from '@mui/material';
// Components
import AppBarLayout from '../AppBarLayout';
import DrawerLayout from '../Drawer';

interface IProps {
	children?: JSX.Element[] | JSX.Element;
}

const drawerWidth: number = 93;

const Layout = ({ children }: IProps) => {
	const [openMenu, setOpenMenu] = useState(false);
	const [arrowToggle] = useState<boolean>(false);
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));

	const [isSubMenuOpen, setIsSubMenuOpen] = useState<boolean>(false);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const toggleDrawer = () => {
		setOpenMenu(!openMenu);
	};

	const handleResize = () => {
		setOpenMenu(false);
	};

	return (
		<Box sx={{ display: 'flex', height: '100%' }}>
			<CssBaseline />

			{matches && (
				<AppBarLayout
					toggleDrawer={toggleDrawer}
					drawerWidth={drawerWidth}
					arrowToggle={arrowToggle}
				/>
			)}

			<Box
				component={'nav'}
				sx={{
					position: 'fixed',
					width: 'fit-content',
					height: '100%',
					top: 0,
					left: 0,
					zIndex: 100,
				}}
			>
				<DrawerLayout
					isSubMenuOpen={isSubMenuOpen}
					setIsSubMenuOpen={setIsSubMenuOpen}
					arrowToggle={arrowToggle}
				/>
			</Box>

			<Box
				component="main"
				sx={{
					flexGrow: 1,
					background: '#F3F6F9',
					width: '100%',
					minHeight: '100vh',
					display: 'flex',
					justifyContent: 'center',
					padding: 5,
					marginTop: matches ? '60px' : 0,
					marginLeft: isSubMenuOpen ? '417px' : '93px',
				}}
			>
				<Container
					style={{
						display: 'flex',
						flexDirection: 'column',
						gap: 40,
						maxWidth: isSubMenuOpen ? '' : '100%',
						margin: 0,
						padding: 0,
					}}
				>
					{children}
				</Container>
			</Box>
		</Box>
	);
};
export default Layout;
