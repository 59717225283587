import CCS1 from '../assets/ccs1.svg';
import CCS2 from '../assets/ccs2.svg';
import GBT from '../assets/gbt.svg';
import GBTDC from '../assets/gbt-dc.svg';
import TESLA from '../assets/tesla.svg';
import TYPE1 from '../assets/type1.svg';
import TYPE2 from '../assets/type2.svg';

export const PLUGTYPES_NAMES: { [key: number]: string } = {
	1: 'GB/T - DC',
	2: 'CCS2',
	3: 'GB/T',
	4: 'Tesla',
	5: 'CSS1',
	6: 'TYPE2',
	7: 'TYPE1',
};

export const PLUGTYPES_IMAGES: { [key: number]: any } = {
	1: GBTDC,
	2: CCS2,
	3: GBT,
	4: TESLA,
	5: CCS1,
	6: TYPE1,
	7: TYPE2,
};
