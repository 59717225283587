import CustomModal from '../../../components/modal.component';
import CustomButton from '../../../components/button.component';
import { useTranslation } from 'react-i18next';

import styles from '../styles/index.module.css';
import { IAssociatesGroup } from '../../../interfaces/partner';
import AssociateCard from './associate-card.component';

interface IGroupModal {
	data: IAssociatesGroup[];
	openModal: boolean;
	tempGroupId: string | number;
	disabledButton: boolean;
	onCardClick: (id: number) => void;
	onCancel: () => void;
	onAccept: () => void;
}

const GroupModal = ({
	data,
	openModal,
	tempGroupId,
	disabledButton,
	onCardClick,
	onAccept,
	onCancel,
}: IGroupModal) => {
	const [t] = useTranslation('global');

	return (
		<CustomModal
			open={openModal}
			maxWidth="xl"
			fullWidth
			title={t('add-schedule.add-group')}
		>
			<>
				<hr className={styles.customHr} />
				<div className={styles.associatesCardsModalContainer}>
					{data.map((group) => (
						<AssociateCard
							id={group.id}
							key={`selected-associate-${group.id}`}
							name={group.group}
							address="Robert Robertson, 1234 NW Bobcat Lane, St. Robert"
							onClick={onCardClick}
							showCostFactor={true}
							costFactor={{
								chargeCost: group.chargeCost,
								chargeFactor: group.chargeFactor,
								parkingCost: group.parkingCost,
								parkingFactor: group.parkingFactor,
							}}
							isSelected={tempGroupId === group.id}
						/>
					))}
				</div>
				<hr className={styles.customHr} />
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={onCancel}
						styles={{
							width: '158px',
							gap: '11px',
							backgroundColor: 'var(--color-black-main)',
						}}
					/>
					<CustomButton
						label={t('common.assign')}
						variant={'solid'}
						onClick={onAccept}
						styles={{
							width: '158px',
							gap: '11px',
						}}
						disabled={disabledButton}
					/>
				</div>
			</>
		</CustomModal>
	);
};

export default GroupModal;
