import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './router/routes';
import { ThemeProvider } from '@mui/material/styles';
import theme from './config/theme';
import './config/colors.css';
import { CssBaseline } from '@mui/material';
import { Provider } from 'react-redux';
import store from './store';

function App() {
	return (
		<Provider store={store}>
			<BrowserRouter>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					<Router />
				</ThemeProvider>
			</BrowserRouter>
		</Provider>
	);
}

export default App;
