/* eslint-disable react-hooks/exhaustive-deps */
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { IOptions } from '../../../components/select-input.component';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IStation } from '../../../interfaces/stations.interface';
import { setAlert } from '../../../store/modules/snackbar';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { IQionCharger } from '../../../interfaces/qion-data.interface';
import { setLoading } from '../../../store/modules/loading';
import { ROLES } from '../../../constants/user-roles.constants';

export function useGetData() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const [stationsSelect, setStationsSelect] = useState<IOptions[]>([]);
	const [qionChargersSelect, setQionChargersSelect] = useState<IOptions[]>([]);
	const [stationsData, setStationsData] = useState<IStation[]>([]);

	const {
		execute: getUnassotiedChargers,
		value: getUnassotiedChargersVal,
		status: getUnassotiedChargersStatus,
	} = useApiRequest<{}, IQionCharger[]>({
		path: 'cms-charger/get-unassotied-chargers',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'POST',
	});

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				setStationsData(getStationsVal.data);
				getSelectValues(getStationsVal.data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-info'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-info'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	useEffect(() => {
		if (getUnassotiedChargersStatus === 'success') {
			if (getUnassotiedChargersVal?.objData) {
				getQionSelectValues(getUnassotiedChargersVal.objData);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('chargers.error-data'),
						severity: 'error',
					})
				);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getUnassotiedChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
			dispatch(setLoading({ loading: false }));
		}
	}, [getUnassotiedChargersStatus]);

	const getSelectValues = (data: IStation[]): void => {
		let filteredData;
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			filteredData = data;
		} else {
			filteredData = data.filter((s) => s.partnerInfoId === id);
		}
		const selectData: IOptions[] = filteredData.map((s) => {
			return {
				label: s.name,
				value: s.id!,
			};
		});
		setStationsSelect(selectData);
	};

	const getQionSelectValues = (chargers: IQionCharger[]): void => {
		const data: IOptions[] = chargers.map((charger) => {
			return {
				label: `${charger.chargerUniqueCode} - ${charger.name}`,
				value: charger.chargerUniqueCode,
			};
		});
		setQionChargersSelect(data);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
		getUnassotiedChargers();
	}, []);

	return { stationsSelect, qionChargersSelect, stationsData, setStationsSelect };
}
