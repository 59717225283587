export const newAssociateForm = [
	{
		name: 'name',
		label: 'Associate name',
	},
	{
		name: 'alias',
		label: 'Alias',
	},
	{
		name: 'rfc',
		label: 'Tax ID',
	},
	{
		name: 'address',
		label: 'Address',
	},
	{
		name: 'state',
		label: 'State',
	},
	{
		name: 'country',
		label: 'Country',
	},
	{
		name: 'zip',
		label: 'ZIP',
	},

	{
		name: 'phone',
		label: 'Phone',
	},
];
