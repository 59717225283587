import { useContext, useEffect } from 'react';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../store/modules/loading';
import { setAlert } from '../../../../store/modules/snackbar';
import { GenerateReportContext } from '../../context/GenerateReportContext';
import { IColumnItem } from '../../interfaces/generate-report.interface';
import { COLUMS, preSelectedIds } from '../utils/colums-id';
import { useTranslation } from 'react-i18next';

export function useGetColumns() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { filterData, setColumns, setSelectedColumns, setFilterData } =
		useContext(GenerateReportContext);

	const {
		execute: getColumns,
		value: getColumnsVal,
		status: getColumnsStatus,
	} = useBackendRequest<{}, string[]>({
		path: 'charge-fills/getColumnNames',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getColumnsStatus === 'success') {
			generateColumsNames(getColumnsVal?.data!);
		} else if (getColumnsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('report.error-columns'),
					severity: 'error',
				})
			);
		}
	}, [getColumnsStatus]);

	const generateColumsNames = (columns: string[]): void => {
		const arr: IColumnItem[] = [];
		const preSelectedIdsArr: IColumnItem[] = [];
		for (let i = 0; i < columns.length; i++) {
			const element = {
				id: columns[i] === 'datetime_charging' ? 'update_time' : columns[i],
				name: getColumnNames(columns[i]),
			};
			if (preSelectedIds.includes(element.id)) {
				preSelectedIdsArr.push(element);
			} else {
				arr.push(element);
			}
		}
		setFilterData({ ...filterData, dataReports: preSelectedIdsArr });
		setSelectedColumns(preSelectedIdsArr);
		setColumns(arr);
		dispatch(setLoading({ loading: false }));
	};

	const getColumnNames = (columId: string): string => {
		switch (columId) {
			case COLUMS.ID:
				return t('report.id');
			case COLUMS.DATETIME_CHARGING:
				return t('report.datetime');
			case COLUMS.STATUS:
				return t('report.status');
			case COLUMS.ORDER_NUMBER:
				return t('report.order');
			case COLUMS.STATION:
				return t('report.station');
			case COLUMS.LOAD_PERCENTAGE:
				return t('report.load-per');
			case COLUMS.LOAD_TIME:
				return t('report.load-time');
			case COLUMS.CONSUMED_KW:
				return t('report.consumed');
			case COLUMS.VOLTAGE:
				return t('report.voltage');
			case COLUMS.CURRENT:
				return t('report.current');
			case COLUMS.ACUMULATED_CHARGE:
				return t('report.acumulated-charge');
			case COLUMS.ACUMULATED_CHARGE_TIME:
				return t('report.acumulated-charge-time');
			case COLUMS.ACUMULATED_CHARGE_COST:
				return t('report.acumulated-charge-cost');
			case COLUMS.STATUS_NUMBER:
				return t('report.status-num');
			case COLUMS.TOTAL:
				return t('report.total');
			case COLUMS.PAYING_CARD:
				return t('report.card');
			case COLUMS.VEHICLE_ID:
				return t('report.vehicle');
			case COLUMS.GUN_ID:
				return t('report.gun');
			case COLUMS.ASSOCIATES:
				return t('report.associates');
			case COLUMS.VIN:
				return t('report.vin');
			case COLUMS.STATION_ID:
				return t('report.station-id');
			case COLUMS.USER:
				return t('report.user');
			default:
				return columId;
		}
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getColumns();
	}, []);
}
