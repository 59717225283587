import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../store/modules/loading';
import { RootState } from '../../../../store';
import { setAlert } from '../../../../store/modules/snackbar';
import { IGunsByStation, IStationGuns } from '../interfaces/charging-dashboard.interface';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { useTranslation } from 'react-i18next';
import { ROLES } from '../../../../constants/user-roles.constants';
import { IOptions } from '../../../../components/select-input.component';
import { IStation } from '../../../../interfaces/stations.interface';
import { isEmpty } from '../../../../utils/validators';

export function useChargingDashboard() {
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const dispatch = useDispatch();

	const [stationsOptions, setStationsOptions] = useState<IOptions[]>([]);
	const [selectedStation, setSelectedStation] = useState<string>('');

	const [gunsInfo, setGunsInfo] = useState<IStationGuns[]>([]);
	const [gunsInfoFiltered, setGunsInfoFiltered] = useState<IStationGuns[]>([]);

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<any, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getStationGuns,
		value: getStationGunsVal,
		status: getStationGunsStatus,
	} = useBackendRequest<{ stationId: string }, IGunsByStation[]>({
		path: 'guns/stations/get-guns',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				const filteredStationsOptions = filterStations(getStationsVal.data);
				setStationsOptions([
					{ label: t('common.all'), value: '' },
					...filteredStationsOptions,
				]);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-gun-information'),
						severity: 'error',
					})
				);
			}
		} else if (getStationsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const filterStations = (data: IStation[]): IOptions[] => {
		let filteredData: IStation[] = [];
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			filteredData = data;
		} else {
			filteredData = data.filter((s) => s.partnerInfoId === id);
		}
		const options: IOptions[] = filteredData.map((s) => {
			return {
				label: s.name,
				value: s.id!,
			};
		});
		return options;
	};

	useEffect(() => {
		if (getStationGunsStatus === 'success') {
			if (getStationGunsVal?.data) {
				const allGuns = getGunsFromStations(getStationGunsVal.data);
				setGunsInfo(allGuns);
				setGunsInfoFiltered(allGuns);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-gun-information'),
						severity: 'error',
					})
				);
			}
		} else if (getStationGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
		}
	}, [getStationGunsStatus]);

	const getGunsFromStations = (data: IGunsByStation[]): IStationGuns[] => {
		let guns: IStationGuns[] = [];
		const stationsData = filterAdminStations(data);
		if (stationsData.length > 0) {
			for (const station of stationsData) {
				guns = guns.concat(station.guns);
			}
		}
		guns = guns.filter((g) => {
			if (!isEmpty(g)) return g;
		});
		return guns;
	};

	const filterAdminStations = (data: IGunsByStation[]): IGunsByStation[] => {
		if ((role.roleName === ROLES.ADMIN || simulating) && selectedStation === '') {
			const stationIds: string[] = stationsOptions.map((s) => s.value.toString());
			const filteredStations: IGunsByStation[] = data.filter((station) =>
				stationIds.includes(station.stationId)
			);
			return filteredStations;
		} else {
			return data;
		}
	};

	useEffect(() => {
		if (stationsOptions.length > 0) {
			dispatch(setLoading({ loading: true }));
			getStationGuns({ stationId: selectedStation });
		}
	}, [selectedStation, stationsOptions]);

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		setSelectedStation(value);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStations();
	}, []);

	return {
		gunsInfo,
		gunsInfoFiltered,
		stationsOptions,
		selectedStation,
		handleSelectChange,
		setGunsInfo,
		setGunsInfoFiltered,
	};
}
