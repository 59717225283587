import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../../../store';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { ADMIN_TAB_VALUES } from '../../../../../../constants/tab-values.constants';
import { useNavigate } from 'react-router-dom';
import { IAllUsersResponse } from '../../../../../login/interfaces/login.interface';

export function useDeleteUser() {
	const navigate = useNavigate();
	const { id } = useSelector((state: RootState) => state.partner);
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const [userEmail, setUserEmail] = useState<string>('');

	const {
		execute: getUsers,
		value: getUsersVal,
		status: getUsersStatus,
	} = useBackendRequest<any, IAllUsersResponse>({
		path: 'users/find-by-partner-id/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getUsersStatus === 'success') {
			const user = getUsersVal?.data?.userCms.filter(
				(u: any) => u.email === userEmail
			)[0];
			if (user) {
				deleteUser({
					partnerId: id,
					user_system_type: 2,
					id_user: user.id,
				});
			} else {
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
				});
			}
		} else if (getUsersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-list-error'),
					severity: 'error',
				})
			);
		}
	}, [getUsersStatus]);

	const { execute: deleteUser, status: deleteUserStatus } = useBackendRequest<
		{
			partnerId: string;
			user_system_type: number;
			id_user: string;
		},
		any
	>({
		path: 'users/delete',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (deleteUserStatus === 'success') {
			navigate('/partners/details', {
				state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
			});
		} else if (deleteUserStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('users.user-deleted-error'),
					severity: 'error',
				})
			);
		}
	}, [deleteUserStatus]);

	const handleGetPartnerUsers = (email: string): void => {
		setUserEmail(email);
		getUsers({}, id);
	};

	return { handleGetPartnerUsers };
}
