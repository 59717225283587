import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../hooks/useTableCustom';

export function useTransactionsTabColums() {
	const [t] = useTranslation('global');

	const transactionsTabColumns: IColumns[] = [
		{
			text: t('financial.order-number'),
			nameID: 'order_number',
		},
		{
			text: t('common.total'),
			nameID: 'total',
		},
		{
			text: t('financial.acumulated'),
			nameID: 'accumulated_charge',
		},
		{
			text: t('financial.time'),
			nameID: 'load_time',
		},
		{
			text: t('groups.invoice'),
			nameID: 'firstInvoice',
		},
		{
			text: t('common.vat'),
			nameID: 'vat',
		},
		{
			text: t('shared-report.associate1'),
			nameID: 'associate1name',
		},
		{
			text: t('shared-report.associate2'),
			nameID: 'associate2name',
		},
		{
			text: t('shared-report.associate3'),
			nameID: 'associate3name',
		},
		{
			text: t('shared-report.associate4'),
			nameID: 'associate4name',
		},
		{
			text: t('shared-report.associate5'),
			nameID: 'associate5name',
		},
		{
			text: t('shared-report.costs'),
			nameID: 'costs',
		},
		{
			text: t('shared-report.credit'),
			nameID: 'credit_money',
		},
		{
			text: t('shared-report.real'),
			nameID: 'real_money',
		},
		{
			text: t('common.payment-processor'),
			nameID: 'paymentProcessor',
		},
	];

	return { transactionsTabColumns };
}
