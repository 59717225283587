import { useEffect, useState } from 'react';
import { LatLngTuple } from 'leaflet';
import { IMapStation } from '../../../components/map';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../../store/modules/snackbar';
import { setLoading } from '../../../store/modules/loading';
import dayjs from 'dayjs';
import { RootState } from '../../../store';
import { ROLES } from '../../../constants/user-roles.constants';
import { useTranslation } from 'react-i18next';
import { IGunsStats, IGunsStatsPag } from '../../../interfaces/guns-stats.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { IStation } from '../../../interfaces/stations.interface';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { DAY_STRINGS } from '../utils/utils';

export function useDashboard() {
	const dispatch = useDispatch();
	const [t] = useTranslation('global');
	const { id } = useSelector((state: RootState) => state.partner);
	const { partnerGuns, role } = useSelector((state: RootState) => state.user);
	const { simulating, simulatedPartnerGuns } = useSelector(
		(state: RootState) => state.simulation
	);
	const [stationsUbications, setStationsUbications] = useState<IMapStation[]>([]);
	const [mapCenter, setMapCenter] = useState<LatLngTuple>([0.0, 0.0]);
	const [renderMap, setRenderMap] = useState<boolean>(false);

	const [dateFilter, setDateFilter] = useState<any>({
		datetime_charging_start: '',
		datetime_charging_end: '',
	});

	const [totalCharges, setTotalCharges] = useState<string>();
	const [totalKW, setTotalKW] = useState<string>();

	const currentDate: any = new Date();
	const yearStart: any = new Date(currentDate.getFullYear(), 0, 1);
	const totalDays = Math.ceil((currentDate - yearStart) / (1000 * 60 * 60 * 24));
	const [dateRange, setDateRange] = useState([0, totalDays]);

	const [qionServiceWorking, setQionServiceWorking] = useState<boolean>(false);
	const [externalServiceWorking, setExternalerviceWorking] = useState<boolean>(false);

	const handleDateChange = (event: any, newValue: any) => {
		setDateRange(newValue);
	};

	const formatDate = (value: any) => {
		const date = new Date(yearStart.getTime() + (value - 1) * (24 * 60 * 60 * 1000));
		const day = String(date.getDate()).padStart(2, '0');
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const year = date.getFullYear();

		return `${day}-${month}-${year}`;
	};

	const {
		execute: getStations,
		value: getStationsVal,
		status: getStationsStatus,
	} = useBackendRequest<{}, IStation[]>({
		path: 'stations',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getChargeFills,
		value: chargeFillVal,
		status: chargeFillStatus,
	} = useBackendRequest<{}, IGunsStatsPag>({
		path: 'charge-fills/find',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const { execute: getAllStations, status: getAllStationsStatus } = useApiRequest<
		{},
		{}
	>({
		path: '/cms-station/get-all-stations',
		baseURL: process.env.REACT_APP_BASE_URL,
		method: 'GET',
	});

	useEffect(() => {
		if (getAllStationsStatus === 'success') {
			setQionServiceWorking(true);
		}
	}, [getAllStationsStatus]);

	useEffect(() => {
		if (chargeFillStatus === 'success') {
			let stats: IGunsStats[] = [];
			setExternalerviceWorking(true);
			if (simulating) {
				const filteredData = chargeFillVal?.data?.chargeFills!.filter((d) => {
					return simulatedPartnerGuns.includes(d.gun_id as string);
				});
				stats = filteredData ?? [];
			} else {
				if (partnerGuns.length === 0) {
					stats = [];
				} else {
					stats = chargeFillVal?.data?.chargeFills!;
				}
			}
			setTotalCharges(getTotalOfCharges(stats));
			setTotalKW(getTotalConsumedKW(stats));
			dispatch(setLoading({ loading: false }));
		} else if (chargeFillStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-1.error-data'),
					severity: 'error',
				})
			);
		}
	}, [chargeFillStatus]);

	useEffect(() => {
		if (getStationsStatus === 'success') {
			if (getStationsVal?.data) {
				const data = filterStationsData(getStationsVal?.data);
				getStationsUbications(data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('dashboard-1.no-stations'),
						severity: 'error',
					})
				);
				dispatch(setLoading({ loading: false }));
			}
		} else if (getStationsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-1.error-locations'),
					severity: 'error',
				})
			);
		}
	}, [getStationsStatus]);

	const filterStationsData = (data: IStation[]): IStation[] => {
		if (role.roleName === ROLES.SUPER_ADMIN && !simulating) {
			return data;
		} else {
			const filteredData = data.filter((d) => d.partnerInfoId === id);
			return filteredData;
		}
	};

	const getStationsUbications = (data: IStation[]): void => {
		const arr = [];
		for (let i = 0; i < data.length; i++) {
			const ubArr: LatLngTuple = [
				parseFloat(data[i].latitude ?? 0),
				parseFloat(data[i].longitude ?? 0),
			];
			const station: IMapStation = {
				id: data[i].id!,
				name: data[i].name,
				location: ubArr,
			};
			arr.push(station);
		}
		const center = getMapCenter(data);
		setMapCenter(center);
		setStationsUbications(arr);
		setRenderMap(true);
		dispatch(setLoading({ loading: false }));
	};

	const getMapCenter = (data: IStation[]): LatLngTuple => {
		if (data.length > 0) {
			let latProm = 0,
				longProm = 0;
			for (let i = 0; i < data.length; i++) {
				latProm += parseFloat(data[i].latitude);
				longProm += parseFloat(data[i].longitude);
			}
			latProm = latProm / data.length;
			longProm = longProm / data.length;
			return [latProm, longProm];
		} else {
			return [0.01, 0.01];
		}
	};

	const getStats = async () => {
		const date = new Date();

		const isoActualDate = date.toISOString().split('T')[0];
		const firstDayMonth = new Date(`${date.getFullYear()}-${date.getMonth() + 1}-01`)
			.toISOString()
			.split('T')[0];

		//Obtener numero del dia en el año del primer dia del mes actual
		const firstDayOfYear: any = new Date(date.getFullYear(), 0, 1);
		const firstDayOfMonth: any = new Date(date.getFullYear(), date.getMonth(), 1);
		const diff = firstDayOfMonth - firstDayOfYear;
		const dayOfYear = Math.ceil(diff / (1000 * 60 * 60 * 24)) + 1;

		//Valores inciales
		setDateRange([dayOfYear, totalDays]);
		setDateFilter({
			datetime_charging_start: dayjs(firstDayMonth),
			datetime_charging_end: dayjs(isoActualDate),
		});
		getChargeFills({
			datetime_charging_start: firstDayMonth,
			datetime_charging_end: isoActualDate,
			gun_ids: partnerGuns,
		});
	};

	const getTotalOfCharges = (data: IGunsStats[]) => {
		let total = 0;
		for (let i = 0; i < data.length; i++) {
			if (parseFloat(data[i].total) > 0) {
				total += parseFloat(data[i].total);
			}
		}
		return total.toFixed(2);
	};

	const getTotalConsumedKW = (data: IGunsStats[]) => {
		let total = 0.0;
		for (let i = 0; i < data.length; i++) {
			if (parseFloat(data[i].consumed_kw) > 0) {
				total += parseFloat(data[i].consumed_kw);
			}
		}
		return total.toFixed(2);
	};

	const getDayOfYear = (dateString: string) => {
		const date: any = new Date(dateString);
		const startOfYear: any = new Date(date.getFullYear(), 0, 0);
		const diff = date - startOfYear;
		const dayOfYear = Math.floor(diff / (1000 * 60 * 60 * 24));

		return dayOfYear + 1;
	};

	const handleSetData = (event: any) => {
		const { name, value } = event.target;
		const date = dayjs(value.$d).toISOString().split('T')[0];
		if (name === 'startDate') {
			if (dateFilter.datetime_charging_end === date) {
				setDateFilter({
					datetime_charging_start: `${date}${DAY_STRINGS.START}`,
					datetime_charging_end: `${dateFilter.datetime_charging_end}${DAY_STRINGS.END}`,
				});
				return;
			}
			setDateFilter({ ...dateFilter, datetime_charging_start: date });
			setDateRange([getDayOfYear(date), dateRange[1]]);
		} else {
			if (dateFilter.datetime_charging_start === date) {
				setDateFilter({
					datetime_charging_start: `${dateFilter.datetime_charging_start}${DAY_STRINGS.START}`,
					datetime_charging_end: `${date}${DAY_STRINGS.END}`,
				});
				return;
			}
			setDateFilter({ ...dateFilter, datetime_charging_end: date });
			setDateRange([dateRange[0], getDayOfYear(date)]);
		}
	};

	useEffect(() => {
		if (
			dateFilter.datetime_charging_start !== '' &&
			dateFilter.datetime_charging_end !== ''
		)
			getChargeFills({
				datetime_charging_start: getISODateFromDayOfYear(dateRange[0]),
				datetime_charging_end: getISODateFromDayOfYear(dateRange[1]),
				gun_ids: partnerGuns,
			});
	}, [dateFilter]);

	const getISODateFromDayOfYear = (dayOfYear: number) => {
		const currentDate = new Date();
		const year = currentDate.getFullYear();
		const date = new Date(year, 0);

		date.setDate(dayOfYear);

		return date.toISOString().split('T')[0];
	};

	const filterDataInRange = () => {
		dispatch(setLoading({ loading: true }));
		setDateFilter({
			datetime_charging_start: dayjs(getISODateFromDayOfYear(dateRange[0])),
			datetime_charging_end: dayjs(getISODateFromDayOfYear(dateRange[1])),
		});
		getChargeFills({
			datetime_charging_start: getISODateFromDayOfYear(dateRange[0]),
			datetime_charging_end: getISODateFromDayOfYear(dateRange[1]),
			gun_ids: partnerGuns,
		});
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStats();
		getAllStations();
		getStations();
	}, []);

	return {
		stationsUbications,
		dateFilter,
		totalCharges,
		totalKW,
		dateRange,
		totalDays,
		qionServiceWorking,
		externalServiceWorking,
		mapCenter,
		renderMap,
		handleDateChange,
		formatDate,
		handleSetData,
		filterDataInRange,
	};
}
