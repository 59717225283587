import { Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
} from 'chart.js';
import { useSelector } from 'react-redux';
import { RootState } from '../store';

import { DefaultTFuncReturn } from 'i18next';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler
);

interface ILineChart {
	mainData: any[];
	labels: string[];
	options?: any;
	labelDataSet?: string | DefaultTFuncReturn;
	fillDataSet?: boolean;
	colorDataSet?: string;
	backgroundDataSet?: string;
	pointRadius?: number;
}

export default function LinesChart({
	mainData,
	labels,
	options,
	labelDataSet,
	fillDataSet = false,
	colorDataSet,
	backgroundDataSet,
	pointRadius = 5,
}: ILineChart) {
	const colors = useSelector((state: RootState) => state.colors);
	const chartData = {
		labels: labels,
		datasets: [
			// Cada una de las líneas del gráfico
			{
				label: labelDataSet || 'Total revenue',
				data: mainData,
				tension: 0.5,
				fill: fillDataSet,
				borderColor: colorDataSet || colors.primary,
				backgroundColor: backgroundDataSet || colors.primary,
				pointRadius: pointRadius,
				pointBorderColor: colorDataSet || colors.primary,
				pointBackgroundColor: colorDataSet || colors.primary,
			},
		],
	};

	return (
		<Line data={chartData} options={options}>
			<p>Hola</p>
		</Line>
	);
}
