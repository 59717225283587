// Dependencies

// Resources
import styles from './styles/button-icon-small.component.module.css';
import theme from '../config/theme';
import { LabelType } from '../interfaces/label-component.interface';
import { styled } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { DefaultTFuncReturn } from 'i18next';

interface IButtonIconSmall {
	type: LabelType;
	text?: string | JSX.Element;
	style?: React.CSSProperties;
	icon?: JSX.Element;
	disabled?: boolean;
	onPress?: () => void;
	hoverLabel?: string | DefaultTFuncReturn;
}

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
	<Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
	[`& .${tooltipClasses.arrow}`]: {
		color: 'white',
	},
	[`& .${tooltipClasses.tooltip}`]: {
		backgroundColor: 'white',
		color: 'black',
		width: 'auto',
		height: 'fit-content',
		boxShadow: '0px 0px 20px #00000014',
		fontSize: '14px',
		fontWeight: '600',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		padding: '15px',
	},
}));

const ButtonIconSmall = (props: IButtonIconSmall) => {
	const { type, text, style, icon, disabled, onPress, hoverLabel } = props;

	const getColorBackground = (): string => {
		let color = '';
		switch (type) {
			case 'success':
				color = theme.palette.success.contrastText;
				break;
			case 'warning':
				color = theme.palette.warning.main;
				break;
			case 'error':
				color = theme.palette.error.light;
				break;
			case 'info':
				color = theme.palette.info.light;
				break;
			case 'primary':
				color = theme.palette.primary.light;
				break;
			case 'brown':
				color = theme.palette.brown.light;
				break;
			case 'grey':
				color = theme.palette.grey[300];
				break;
			default:
				break;
		}
		return color;
	};

	const getColorLabel = (): string => {
		let color = '';
		switch (type) {
			case 'success':
				color = theme.palette.success.main;
				break;
			case 'warning':
				color = theme.palette.warning.dark;
				break;
			case 'error':
				color = theme.palette.error.dark;
				break;
			case 'info':
				color = theme.palette.info.main;
				break;
			case 'primary':
				color = theme.palette.primary.main;
				break;
			case 'brown':
				color = theme.palette.brown.main;
				break;
			case 'grey':
				color = '#FFFFFF';
				break;
			default:
				break;
		}
		return color;
	};

	const gertBorderColor = (): string => {
		let color = '';
		switch (type) {
			case 'success':
				color = theme.palette.success.main;
				break;
			case 'warning':
				color = theme.palette.warning.dark;
				break;
			case 'error':
				color = theme.palette.error.dark;
				break;
			case 'info':
				color = theme.palette.info.main;
				break;
			case 'primary':
				color = theme.palette.primary.main;
				break;
			case 'brown':
				color = theme.palette.brown.main;
				break;
			case 'grey':
				color = '#000000';
				break;
			default:
				break;
		}
		return color;
	};

	return (
		<CustomTooltip title={hoverLabel} placement="top">
			<label
				className={styles.buttonIconSmall}
				style={
					disabled
						? {
								...style,
								color: '#fff',
								backgroundColor: '#e6e6e9',
								borderColor: '#d8d8dc',
								cursor: 'default',
						  }
						: {
								...style,
								color: getColorLabel(),
								backgroundColor: getColorBackground(),
								borderColor: gertBorderColor(),
						  }
				}
				onClick={disabled ? () => {} : onPress}
			>
				{icon}
				{text && text}
			</label>
		</CustomTooltip>
	);
};

export default ButtonIconSmall;
