import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useState } from 'react';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IStation, defaultStation } from '../../../interfaces/stations.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { ICharger } from '../../../interfaces/charger.interface';
import { IGun } from '../../../interfaces/gun.interface';

export function useStationDetails() {
	const [t] = useTranslation('global');
	const { id } = useParams();
	const dispatch = useDispatch();
	//const { externalPartnerId } = useSelector((state: RootState) => state.partner);

	const [station, setStation] = useState<IStation>(defaultStation);

	const [chargers, setChargers] = useState<ICharger[]>([]);
	const [guns, setGuns] = useState<IGun[]>([]);

	const {
		execute: getStation,
		value: getStationVal,
		status: getStationStatus,
	} = useBackendRequest<{}, IStation>({
		path: 'stations/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<{}, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<unknown, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		dispatch(setLoading({ loading: false }));
		if (getStationStatus === 'success') {
			if (getStationVal?.data) {
				setStation(getStationVal.data);
				getGuns();
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-stations'),
						severity: 'error',
					})
				);
			}
		} else if (getStationStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-stations'),
					severity: 'error',
				})
			);
		}
	}, [getStationStatus]);

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				const data = filterStationChargers(getChargersVal?.data);
				setChargers(data);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-chargers'),
						severity: 'error',
					})
				);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-chargers'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const filterStationChargers = (data: ICharger[]): ICharger[] => {
		const stationChargers = data.filter((charger) => charger.stationId === id);
		return stationChargers;
	};

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const guns = filterStationGuns(getGunsVal.data);
				setGuns(guns);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-guns'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-guns'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const filterStationGuns = (data: IGun[]): IGun[] => {
		const chargersIds: string[] = chargers.map((c) => c.id!);
		return data.filter((gun) => chargersIds.includes(gun.chargerId));
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getStation({}, id);
		getChargers();
	}, []);

	return {
		station,
		chargers,
		guns,
	};
}
