/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useSelector } from 'react-redux';

import { Paper, Grid } from '@mui/material';

import TableCustom from '../../components/table-custom.component';
import SelectInput, { IOptions } from '../../components/select-input.component';
import Input from '../../components/input.component';
import DateInput from '../../components/DateInput';
import CustomButton from '../../components/button.component';

import styles from './styles/financial.module.css';
import { RootState } from '../../store';
import ButtonIconSmall from '../../components/button-icon-small.component';
import { Download } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useFinancialColums } from './utils/financial.colums';
import { useNavigate } from 'react-router-dom';
import { useFinancial } from './hooks/useFinancial';
import CustomMultiSelect from '../../components/custom-multiselect.component';
import { useUserPermisions } from '../../hooks/useUserPermissions';

const dayjs = require('dayjs');

const Financial = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const { financialTabColumns } = useFinancialColums();
	const navigate = useNavigate();

	const { getReportsPermission } = useUserPermisions();

	const {
		view,
		dataQuery,
		stationsOptions,
		data,
		gunsOptions,
		disabledReportButton,
		setData,
		handleSetData,
		handleSearch,
		hanldeClearFilters,
		handleGunsChange,
	} = useFinancial();

	return (
		<>
			<Grid container flexDirection={'column'}>
				{view === 'financial' && (
					<h1 className={styles.title}>{t('financial.title-1')}</h1>
				)}
				{view === 'being' && (
					<h1 className={styles.title}>{t('financial.title-2')}</h1>
				)}
				{view === 'gun' && (
					<h1 className={styles.title}>{t('financial.title-3')}</h1>
				)}
				{view === 'station' && (
					<h1 className={styles.title}>{t('financial.title-4')}</h1>
				)}

				<Paper elevation={1} className={styles.mb}>
					<div className={styles.header}>
						<p>
							<b>{t('financial.search-condition')}</b>
						</p>
						{getReportsPermission() && (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '15px',
								}}
							>
								<span style={{ fontWeight: 600 }}>
									{t('financial.download')}
								</span>
								<ButtonIconSmall
									onPress={() =>
										navigate('/financial/generate-report', {
											state: {
												guns: gunsOptions,
												stations: stationsOptions,
											},
										})
									}
									type="primary"
									icon={<Download />}
									disabled={disabledReportButton}
								/>
							</div>
						)}
					</div>
					<div className={styles.container}>
						<Grid container spacing={2}>
							{view !== 'gun' && view !== 'station' && (
								<Grid
									item
									xs={3}
									display={'flex'}
									alignItems={'flex-end'}
								>
									<Input
										label={t('financial.order-number').toString()}
										name={'order_number'}
										value={dataQuery.order_number}
										onChange={handleSetData}
										required
									/>
								</Grid>
							)}
							{view !== 'being' && view !== 'gun' && view !== 'station' && (
								<Grid
									item
									xs={3}
									display={'flex'}
									alignItems={'flex-end'}
								>
									<CustomMultiSelect
										name={t('common.guns')}
										selectData={gunsOptions}
										onChange={handleGunsChange}
										val={dataQuery.gun_ids}
									/>
								</Grid>
							)}
							{view !== 'being' && view !== 'gun' && view !== 'station' && (
								<Grid item xs={3} display={'flex'}>
									<DateInput
										label={t('financial.start-date')}
										name="datetime_charging_start"
										value={dayjs(dataQuery.datetime_charging_start)}
										onChange={handleSetData}
										required
									/>
								</Grid>
							)}
							{view !== 'being' && view !== 'gun' && view !== 'station' && (
								<Grid item xs={3} display={'flex'}>
									<DateInput
										label={t('financial.end-date')}
										name="datetime_charging_end"
										value={dayjs(dataQuery.datetime_charging_end)}
										onChange={handleSetData}
										required
									/>
								</Grid>
							)}
							<Grid item xs={3} display={'flex'} alignItems={'flex-end'}>
								<SelectInput
									name="station"
									id="station"
									label={t('financial.select-station').toString()}
									placeholder={t('common.required').toString()}
									style={{ width: '100%' }}
									required
									options={stationsOptions}
									onChange={handleSetData}
									value={dataQuery.station}
								/>
							</Grid>
							{view !== 'being' && (
								<Grid
									item
									xs={3}
									display={'flex'}
									alignItems={'flex-end'}
								>
									<SelectInput
										name="status"
										id="status"
										label={t('financial.select-status').toString()}
										placeholder={t('common.required').toString()}
										style={{ width: '100%' }}
										required
										options={[
											{
												label: t('financial.active'),
												value: 'true',
											},
											{
												label: t('financial.inactive'),
												value: 'false',
											},
										]}
										value={
											dataQuery.status === undefined
												? ''
												: dataQuery.status
												? 'true'
												: 'false'
										}
										onChange={handleSetData}
									/>
								</Grid>
							)}
							{view === 'gun' && (
								<Grid
									item
									xs={3}
									display={'flex'}
									alignItems={'flex-end'}
								>
									<CustomMultiSelect
										name={t('common.guns')}
										selectData={gunsOptions}
										onChange={handleGunsChange}
										val={dataQuery.gun_ids}
									/>
								</Grid>
							)}
							<Grid
								item
								xs={3}
								display={'flex'}
								alignItems={'flex-end'}
								gap={5}
							>
								<CustomButton
									label={t('financial.search')}
									onClick={() => handleSearch()}
									variant="solid"
									styles={{
										backgroundColor: 'var(--color-black-main)',
										height: '43px',
										width: '100px',
									}}
									disabled={
										!(
											dataQuery.gun_ids.length > 0 ||
											dataQuery.order_number !== '' ||
											dataQuery.station !== '' ||
											dataQuery.datetime_charging_start !== '' ||
											dataQuery.datetime_charging_end !== ''
										)
									}
								/>
								<CustomButton
									label={t('financial.clear')}
									onClick={hanldeClearFilters}
									variant="solid"
									styles={{
										color: 'black',
										backgroundColor: colors.accent,
										height: '43px',
										width: '100px',
									}}
									disabled={false}
								/>
							</Grid>
						</Grid>
					</div>
				</Paper>

				<Paper elevation={1} sx={{ padding: 1 }}>
					<TableCustom
						columns={financialTabColumns}
						data={data}
						setData={setData}
						showTotalCount={false}
						hideChips
						showLimitPerPage
						hideHeader
						showPagination
					/>
				</Paper>
			</Grid>
		</>
	);
};
export default Financial;
