import dayjs from 'dayjs';
import { useContext } from 'react';
import { GenerateReportContext } from '../../context/GenerateReportContext';
import { useLocation } from 'react-router-dom';
import { IOptions } from '../../../../components/select-input.component';
import { useTranslation } from 'react-i18next';

export function useFiltersSelector() {
	const [t] = useTranslation('global');
	const { state } = useLocation();
	const { filterData, setFilterData } = useContext(GenerateReportContext);

	const stations: IOptions[] = state.stations as IOptions[];
	const stationsOptions: IOptions[] = [
		{ label: t('common.all'), value: 'all' },
		...stations,
	];

	const handleSetData = (event: any) => {
		const { name, value } = event.target;
		if (name === 'datetime_charging_start' || name === 'datetime_charging_end') {
			const date = dayjs(value.$d);
			setFilterData({ ...filterData, [name]: date.format('YYYY-MM-DD') });
		} else if (name === 'status') {
			if (value === 'true') {
				setFilterData({ ...filterData, status: true });
			} else {
				setFilterData({ ...filterData, status: false });
			}
		} else {
			setFilterData({ ...filterData, [name]: value });
		}
	};

	const handleGunsChange = (guns: string[]): void => {
		setFilterData({ ...filterData, gun_ids: guns });
	};

	const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const { value } = event.target;
		setFilterData({ ...filterData, station: value });
	};

	return {
		filterData,
		stationsOptions,
		setFilterData,
		handleSetData,
		handleGunsChange,
		handleSelectChange,
	};
}
