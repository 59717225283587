import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../../../components/button-icon-small.component';
import styles from './styles/colors-container.module.css';

interface IColorsContainer {
	name: string;
	primary: string;
	secondary: string;
	accent: string;
}

const ColorsContainer = ({ name, primary, secondary, accent }: IColorsContainer) => {
	const [t] = useTranslation('global');
	return (
		<div className={styles.mainContainer}>
			<div>
				<span className={styles.title}>
					<b>{t('common.name')}:</b> {name}
				</span>
			</div>
			<div className={styles.colorsContainer}>
				<span className={styles.title}>
					<b>{t('themes.colors')}: </b>
				</span>
				<CustomTooltip title={t('themes.primary-color')} placement="top">
					<div
						className={styles.color}
						style={{ backgroundColor: primary }}
					></div>
				</CustomTooltip>
				<CustomTooltip title={t('themes.secondary-color')} placement="top">
					<div
						className={styles.color}
						style={{ backgroundColor: secondary }}
					></div>
				</CustomTooltip>
				<CustomTooltip title={t('themes.accent-color')} placement="top">
					<div
						className={styles.color}
						style={{ backgroundColor: accent }}
					></div>
				</CustomTooltip>
			</div>
		</div>
	);
};

export default ColorsContainer;
