import { SetStateAction, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../../../../store/modules/loading';
import { setAlert } from '../../../../../../store/modules/snackbar';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { ICharger } from '../../../../../../interfaces/charger.interface';
import { IGun } from '../../../../../../interfaces/gun.interface';
import { ILogs } from '../../../../../../interfaces/logs.interface';

interface IStopStation {
	setOpenModal: React.Dispatch<SetStateAction<boolean>>;
}

export function useStopStation({ setOpenModal }: IStopStation) {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const [stationId, setStationId] = useState<string>('');
	const [stationName, setStationName] = useState<string>('');
	const [chargersData, setChargersData] = useState<ICharger[]>([]);

	const { execute: saveLog } = useBackendRequest<ILogs, any>({
		path: 'apps-logs/save',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				const gunsIds = getStationGuns(getGunsVal?.data);
				handleStopGuns(gunsIds);
				setOpenModal(false);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('errors.error-gun-information'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('errors.error-gun-information'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const getStationGuns = (guns: IGun[]): string[] => {
		const chargersIds: string[] = chargersData.map((c) => c.id!);
		const filteredGuns = guns.filter((gun) => chargersIds.includes(gun.chargerId));
		const gunsId: string[] = filteredGuns.map((g) => {
			return g.qionGunUniqueId;
		});
		return gunsId;
	};

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<unknown, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				filterStationChargers(getChargersVal.data);
				getGuns();
			} else {
				dispatch(setLoading({ loading: false }));
				setChargersData([]);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const filterStationChargers = (chargers: ICharger[]): void => {
		const data = chargers.filter((c) => c.stationId === stationId);
		setChargersData(data);
	};

	const handleGetStationGuns = (stationId: string, name: string): void => {
		dispatch(setLoading({ loading: true }));
		setStationId(stationId);
		setStationName(name);
		getChargers();
	};

	const handleStopGuns = (guns: string[]): void => {
		console.log(stationName);
		const request = guns.map((gun) => {
			return new Promise((resolve, reject) => {
				axios
					.get(`${process.env.REACT_APP_BASE_URL}/cms-gun/stopCharging/${gun}`)
					.then(() => {
						resolve(gun);
					})
					.catch((error) => {
						reject(error);
						dispatch(
							setAlert({
								show: true,
								message: t('guns.stoped'),
								severity: 'error',
							})
						);
					});
			});
		});

		Promise.all(request)
			.then(() => {
				saveLog({
					message: `Station ${stationName} stopped.`,
					className: 'CMS',
				});
				dispatch(
					setAlert({
						show: true,
						message: t('stations.stoped'),
						severity: 'success',
					})
				);
			})
			.catch(() => {
				saveLog({
					message: `Station ${stationName} can not be stopped.`,
					exception: 'The process to stop stations guns could not be executed.',
					url: 'cms-gun/stopCharging',
					className: 'CMS',
				});
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-stoped'),
						severity: 'error',
					})
				);
			});
	};

	return { handleGetStationGuns };
}
