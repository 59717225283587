// React dependencies
import React, { useEffect, useState } from 'react';

// Third-party libraries
import GridViewIcon from '@mui/icons-material/GridView';
import {
	Grid,
	Paper,
	Tab,
	Tabs,
	ThemeProvider,
	Typography,
	createTheme,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import Groups2OutlinedIcon from '@mui/icons-material/Groups2Outlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';

import { Person, Today } from '@mui/icons-material';

// Style dependencies
import styles from './styles/partners-details.module.css';

// Resources
import {
	AssociatesTab,
	Home,
	AssociatesGroupTab,
	StationsTab,
	ChargerTab,
	GunsTab,
	UsersTab,
	IHomeTabCount,
} from './components';

import AdminChargerTab from './components/admin/CargerTab/index.component';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import AdminAssociateGroupTab from './components/admin/AssociatesGropusTab';
import { ROLES } from '../../constants/user-roles.constants';
import AdminStationsTab from './components/admin/StationsTab';
import AdminGunsTab from './components/admin/GunsTab';
import SchedulesTab from './components/schedules-tab.component';
import AdminAssociates from './components/admin/Associates';
import { useLocation } from 'react-router-dom';

import { hexToRGBA } from '../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../hooks/useBackendRequest';
import { setAlert } from '../../store/modules/snackbar';
import { setLoading } from '../../store/modules/loading';
import { useUserPermisions } from '../../hooks/useUserPermissions';

const PartnerDetail = () => {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const colors = useSelector((state: RootState) => state.colors);
	const tabsTheme = createTheme({
		components: {
			MuiTabs: {
				styleOverrides: {
					root: {
						height: '78px',
						'& .MuiTabs-indicator': {
							backgroundColor: colors.primary,
						},
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						opacity: 0.5,
						span: {
							color: colors.primary,
							fontWeight: '600',
						},
						'& .MuiSvgIcon-root': {
							color: colors.primary,
							fontSize: '35px',
						},
						'&:hover': {
							transition: 'all 0.25s ease',
							transform: 'scale(1.1)',
							opacity: 1,
							span: { fontWeight: '600' },
						},
						'&.Mui-selected': {
							opacity: 1,
							'& .MuiSvgIcon-root': {
								fontSize: '40px',
							},
							span: {
								fontWeight: '600',
							},
							'&:hover': {
								transform: 'none',
								opacity: 1,
							},
						},
					},
				},
			},
		},
	});

	const { role } = useSelector((state: RootState) => state.user);
	//Partner info from store
	const partner = useSelector((state: RootState) => state.partner);

	const location = window.location.pathname;

	const [tabsCount, setTabsCount] = useState<IHomeTabCount>({
		Associates: 0,
		Chargers: 0,
		Guns: 0,
		Stations: 0,
		PartnersGroup: 0,
	});

	const { state } = useLocation();

	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down('md'));
	const [tabValue, setValueTab] = useState<number>(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValueTab(newValue);
	};

	const {
		getAssociatesPermission,
		getAssociatesGroupPermission,
		getStationsPermission,
		getChargersPermission,
		getGunsPermission,
		getSchedulesPermission,
		getUsersPermission,
	} = useUserPermisions();

	const {
		execute: getValues,
		value: valuesVal,
		status: valuesStatus,
	} = useBackendRequest<{}, IHomeTabCount>({
		path: 'partners-information/dashboardCount/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (valuesStatus === 'success') {
			if (valuesVal?.data) {
				setTabsCount(valuesVal.data);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('partners.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (valuesStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [valuesStatus]);

	useEffect(() => {
		if (state) {
			setValueTab(state?.tab);
		}
		dispatch(setLoading({ loading: true }));
		getValues({}, partner.id);
	}, []);

	useEffect(() => {
		const locationArr = location.split('/');
		const module = locationArr[locationArr.length - 1];
		switch (module) {
			case 'stations':
				setValueTab(4);
				break;
			case 'chargers':
				setValueTab(5);
				break;
			case 'guns':
				setValueTab(6);
				break;
		}
	}, [location]);

	const onHandleTab = (tab: number) => {
		console.log(tab);
		switch (tab) {
			case 1:
				return <Home setTabValue={setValueTab} count={tabsCount} />;
			case 2:
				if (role.roleName === ROLES.ADMIN || simulating)
					return <AdminAssociates />;
				else return <AssociatesTab />;
			case 3:
				if (role.roleName === ROLES.ADMIN || simulating)
					return <AdminAssociateGroupTab />;
				else return <AssociatesGroupTab />;
			case 4:
				if (role.roleName === ROLES.ADMIN || simulating)
					return <AdminStationsTab />;
				else return <StationsTab />;
			case 5:
				if (role.roleName === ROLES.ADMIN || simulating)
					return <AdminChargerTab />;
				else return <ChargerTab />;
			case 6:
				if (role.roleName === ROLES.ADMIN || simulating) return <AdminGunsTab />;
				else return <GunsTab />;
			case 7:
				if (role.roleName === ROLES.ADMIN || simulating) return <SchedulesTab />;
				else return <UsersTab />;
			case 8:
				return <UsersTab />;

			default:
				return <Home setTabValue={setValueTab} count={tabsCount} />;
		}
	};

	return (
		<div className={styles.flexContainer}>
			<Paper className={styles.boxPaper} elevation={2}>
				<Grid container className={styles.header}>
					<Grid
						item
						xs={12}
						md={2}
						xl={1}
						display={'flex'}
						alignContent={'center'}
						justifyContent={'center'}
						sx={{
							backgroundColor: hexToRGBA(colors.primary, 0.4),
							borderRadius: 4,
						}}
						padding={matches ? 3 : ''}
					>
						{partner.img ? (
							<img
								src={`data:image/png;base64,${partner.img}`}
								alt="logo-img"
								className={styles.logoImg}
							/>
						) : (
							<Person
								style={{
									width: '50px',
									height: '50px',
									justifySelf: 'center',
									alignSelf: 'center',
									color: 'white',
								}}
							/>
						)}
					</Grid>
					<Grid
						item
						xs={7}
						md={8}
						xl={10}
						display="flex"
						flexDirection="column"
						justifyContent="center"
						paddingLeft={'15px'}
						marginTop={matches ? 3 : ''}
					>
						<Typography variant="h6" fontWeight="bold">
							{partner.name}
						</Typography>
						<div
							className={styles.flexText}
							style={{ marginBottom: matches ? '10px' : '' }}
						>
							<Typography variant="subtitle1" fontWeight="bold">
								{t('common.email')}:
							</Typography>
							<Typography variant="subtitle1">{partner.email}</Typography>
						</div>
						<div className={styles.flexText}>
							<Typography variant="subtitle1" fontWeight="bold">
								{t('common.phone')}:
							</Typography>
							<Typography variant="subtitle1">{partner.phone}</Typography>
						</div>
					</Grid>
				</Grid>
				<Grid container item xs={12} className={styles.subHeader}>
					<ThemeProvider theme={tabsTheme}>
						<Tabs
							value={tabValue}
							onChange={handleChange}
							style={{ width: '100%' }}
							variant={
								useMediaQuery(theme.breakpoints.up('lg'))
									? 'fullWidth'
									: 'scrollable'
							}
						>
							<Tab
								icon={<GridViewIcon />}
								iconPosition="start"
								label={<span>{t('common.home')}</span>}
								value={1}
							/>
							{getAssociatesPermission() && (
								<Tab
									icon={<Person2OutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.associates')}</span>}
									value={2}
								/>
							)}
							{getAssociatesGroupPermission() && (
								<Tab
									icon={<Groups2OutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.groups')}</span>}
									value={3}
								/>
							)}
							{getStationsPermission() && (
								<Tab
									icon={<LocalGasStationOutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.stations')}</span>}
									value={4}
								/>
							)}
							{getChargersPermission() && (
								<Tab
									icon={<BoltOutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.chargers')}</span>}
									value={5}
								/>
							)}
							{getGunsPermission() && (
								<Tab
									icon={<PowerOutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.guns')}</span>}
									value={6}
								/>
							)}
							{getSchedulesPermission() && (
								<Tab
									icon={<Today />}
									iconPosition="start"
									label={<span>{t('common.schedules')}</span>}
									value={7}
								/>
							)}
							{getUsersPermission() && (
								<Tab
									icon={<Person2OutlinedIcon />}
									iconPosition="start"
									label={<span>{t('common.users')}</span>}
									value={8}
								/>
							)}
						</Tabs>
					</ThemeProvider>
				</Grid>
			</Paper>
			{onHandleTab(tabValue)}
		</div>
	);
};

export default PartnerDetail;
