import { ArrowBack, BarChart, Edit } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { CardActionArea, Grid } from '@mui/material';
import CustomButton from '../../../../../components/button.component';
import SimpleCard from '../../../../../components/simple-card.component';
import styles from '../../../styles/admin-associates-tab.module.css';
import DetailsTab from './tabs/details-tab';
import { ADMIN_TAB_VALUES } from '../../../../../constants/tab-values.constants';
import { useTranslation } from 'react-i18next';
import { useTabDetails } from './tabs/hooks/useTabDetails';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import { hexToRGBA } from '../../../../../utils/hex-to-rgba';

const DetailAssociate = () => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const nav = useNavigate();
	const { id, associateData, tab, setTab } = useTabDetails();

	return (
		<>
			<CustomButton
				label={t('common.back')}
				variant="solid"
				icon={ArrowBack}
				styles={{
					width: 'fit-content',
					padding: '0 15px',
					gap: '10px',
					backgroundColor: 'black',
				}}
				onClick={() =>
					nav('/partners/details', {
						state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
					})
				}
			/>
			<Grid container spacing={2}>
				<Grid item container spacing={2} xs={12} sm={4} alignContent="flex-start">
					<Grid item xs={12}>
						<SimpleCard
							containerStyles={{ width: '100%' }}
							className={styles['associate-detail-card']}
						>
							<div
								style={{
									display: 'flex',
									flexDirection: 'column',
									justifyContent: 'center',
									alignItems: 'center',
									gap: '10px',
								}}
							>
								<div
									className={styles.imageContainer}
									style={{
										backgroundColor: hexToRGBA(colors.secondary, 0.1),
									}}
								>
									<img
										src={`data:image/png;base64,${associateData?.img}`}
										style={{ width: '65%', height: '65%' }}
										alt="associate-icon"
									/>
								</div>
								<b>{associateData?.name}</b>
								<b>{associateData?.rfc}</b>
								<small>{associateData?.address}</small>
								<CustomButton
									variant="solid"
									label={t('common.edit')}
									icon={Edit}
									styles={{
										width: 'fit-content',
										padding: '0 15px',
										gap: '10px',
										backgroundColor: 'black',
									}}
									onClick={() => nav('/partners/edit-associate/' + id)}
								/>
							</div>
						</SimpleCard>
					</Grid>
					<Grid item xs={12} md={6}>
						<CardActionArea onClick={() => setTab('Details')}>
							<SimpleCard
								containerStyles={{
									background:
										tab === 'Details' ? colors.secondary : 'white',
								}}
							>
								<>
									<BarChart
										style={{
											color:
												tab === 'Details'
													? 'white'
													: colors.secondary,
										}}
									/>
									<br />
									<b
										style={{
											color: tab === 'Details' ? 'white' : 'black',
										}}
									>
										{t('common.details')}
									</b>
								</>
							</SimpleCard>
						</CardActionArea>
					</Grid>
				</Grid>
				<Grid item container spacing={2} xs={12} sm={8}>
					{tab === 'Details' && <DetailsTab />}
				</Grid>
			</Grid>
		</>
	);
};

export default DetailAssociate;
