interface IColors {
	primary: string;
	secondary: string;
	accent: string;
	links: string;
}

interface ILibraries {
	sentryKeyAndroid: string;
	sentryKeyiOS: string;
	stripeKeyProd: string;
	stripeKeyQa: string;
}

interface IMenuConfig {
	showPrivacyItem: boolean;
	privacyURL?: string | null;
	showSupportItem: boolean;
	supportURL?: string | null;
	showAboutURLItem: boolean;
	aboutURL?: string | null;
	showReviewsURLItem: boolean;
	reviewsURL?: string | null;
	showXcWorldURLItem: boolean;
	xcWorldURL?: string | null;
}

interface IServicesConfig {
	nextiaServices: string;
	nextiaServicesQa: string;
	clientServices: string;
}

export interface IMobileSettings {
	name: string;
	partnerId: string;
	homeId: string | number;
	publishable_key_prod: string;
	publishable_key_qa: string;
	stripeAccountId: string;
	librariesConfig: ILibraries;
	menuConfig: IMenuConfig;
	colors: IColors;
	servicesConfig: IServicesConfig;
}

export const defaultMobileSettings: IMobileSettings = {
	name: '',
	partnerId: '',
	homeId: '',
	publishable_key_prod: '',
	publishable_key_qa: '',
	stripeAccountId: '',
	librariesConfig: {
		sentryKeyAndroid: '',
		sentryKeyiOS: '',
		stripeKeyProd: '',
		stripeKeyQa: '',
	},
	menuConfig: {
		showPrivacyItem: false,
		showSupportItem: false,
		showAboutURLItem: false,
		showReviewsURLItem: false,
		showXcWorldURLItem: false,
	},
	colors: {
		primary: '#ffffff',
		secondary: '#ffffff',
		accent: '#ffffff',
		links: '#ffffff',
	},
	servicesConfig: {
		nextiaServices: ' https://qion-api-dev-7af91b065ba3.herokuapp.com',
		nextiaServicesQa: 'https://qion-api.herokuapp.com',
		clientServices: 'http://www.qiontech.cloud',
	},
};
