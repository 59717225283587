import { ImagesKeys } from '../interfaces/images-keys.interface';
import { iconNames } from './icon-names.utils';

export const iconsForm: {
	name: ImagesKeys;
	label: string;
	width: number;
	height: number;
	requiredIn: number[];
}[] = [
	{
		name: iconNames.ICON_APP,
		label: 'App icon',
		width: 139,
		height: 136,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_MENU,
		label: 'Menu icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_PROFILE,
		label: 'Profile icon 1',
		width: 40,
		height: 40,
		requiredIn: [1, 2],
	},
	{
		name: iconNames.ICON_STATIONS,
		label: 'Stations icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2],
	},
	{
		name: iconNames.ICON_SCAN,
		label: 'Scan Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2],
	},
	{
		name: iconNames.ICON_LOGIN,
		label: 'Login icon',
		width: 139,
		height: 136,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_POINT,
		label: 'Point icon',
		width: 30,
		height: 30,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_APP_2,
		label: 'App icon 2',
		width: 60,
		height: 60,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_PROFILE_2,
		label: 'Profile icon 2',
		width: 40,
		height: 40,
		requiredIn: [1, 2],
	},
	{
		name: iconNames.ICON_SUPP,
		label: 'Support icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_ABOUT,
		label: 'About icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.ICON_XC,
		label: 'XC World Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.LOGOUT,
		label: 'Logout Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.GHOST_SCAN,
		label: 'Ghost Scan Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.TYPE_1,
		label: 'Type 1 Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.TYPE_2,
		label: 'Type 2 Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.GBT,
		label: 'GB/T Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.TESLA,
		label: 'Tesla Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.CCS1,
		label: 'CSS1 Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.CCS2,
		label: 'CSS2 Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.GBT_DC,
		label: 'GB/T - DC Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.RATIO_SEARCH_ZI,
		label: 'Ratio Search Zoom In Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
	{
		name: iconNames.RATIO_SEARCH_ZO,
		label: 'Ratio Search Zoom Out Icon',
		width: 40,
		height: 40,
		requiredIn: [1, 2, 3],
	},
];
