import { VisibilityOutlined, Delete } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IUsers } from '../interfaces/users.interface';
import SwitchCustom from '../../../components/switch.component';
import { ROLES } from '../../../constants/user-roles.constants';
import { useTranslation } from 'react-i18next';

export function useUsersAdapter() {
	const [t] = useTranslation('global');

	const usersAdapter = (
		data: IUsers[],
		userRole: string,
		handleSeeDetails: (id: string) => void,
		comparePermissions: () => boolean,
		handleOpenModal: (user: IUsers) => void,
		handleDisableUser: (user: IUsers) => void
	): IUsers[] => {
		const newData: any[] = data.map((item: IUsers) => {
			return {
				...item,
				name: <>{`${item.name} ${item.first_last_name}`}</>,
				status: (
					<SwitchCustom
						value={item.is_disabled === 0}
						tooltipLabel="Disable account"
						onChange={() => handleDisableUser(item)}
						disabled={!comparePermissions()}
					/>
				),
				email: item.email,
				profile: item.profile || t('users.no-profile'),
				actions:
					comparePermissions() || userRole === ROLES.SUPER_ADMIN ? (
						<div style={{ display: 'flex', gap: '5px' }}>
							<ButtonIconSmall
								onPress={() => handleSeeDetails(item.id)}
								type="primary"
								icon={<VisibilityOutlined />}
								hoverLabel={t('common.see-details')}
							/>

							<ButtonIconSmall
								onPress={() => handleOpenModal(item)}
								type="error"
								icon={<Delete />}
								hoverLabel={t('users.delete-user')}
							/>
						</div>
					) : (
						<span>{t('common.no-actions')}</span>
					),
			};
		});
		return newData;
	};

	return { usersAdapter };
}
