/* eslint-disable prettier/prettier */
import { Delete, BorderColor } from '@mui/icons-material';
import ButtonIconSmall from '../../../components/button-icon-small.component';
import { IAssociatesGroup, ISchedule } from '../../../interfaces/partner';
import { useTranslation } from 'react-i18next';
import { useUserPermisions } from '../../../hooks/useUserPermissions';
const dayjs = require('dayjs');

const labelStyles: React.CSSProperties = {
	padding: '10px',
	fontSize: '14px',
	fontWeight: 'bold',
	width: 'fit-content',
	borderRadius: '8px',
	textAlign: 'center',
};

const dayStyles: React.CSSProperties = {
	//backgroundColor: blue,
	borderRadius: '4px',
	backgroundColor: '#ced1fc',
	padding: '5px',
	color: '#1b2cf2',
	width: '40px',
	height: '40px',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	fontWeight: 'bold',
};

type Days = 'mon' | 'tue' | 'wed' | 'thu' | 'fri' | 'sat' | 'sun';

export function useSchedulesTabAdapter() {
	const [t] = useTranslation('global');
	const { getSchedulesPermission } = useUserPermisions();

	const getSplitedDays = (day: Days): string => {
		let splitedDay = '';
		switch (day) {
			case 'mon':
				splitedDay = t('days.mon');
				break;
			case 'tue':
				splitedDay = t('days.tue');
				break;
			case 'wed':
				splitedDay = t('days.wed');
				break;
			case 'thu':
				splitedDay = t('days.thu');
				break;
			case 'fri':
				splitedDay = t('days.fri');
				break;
			case 'sat':
				splitedDay = t('days.sat');
				break;
			case 'sun':
				splitedDay = t('days.sun');
				break;
		}

		return splitedDay.slice(0, 3);
	};

	//TODO: inglés
	function restarHoras(hora: any, horasARestar: any) {
		const horaActual = new Date();
		const partesHora = hora.split(':');
		horaActual.setHours(parseInt(partesHora[0], 10) - horasARestar);
		horaActual.setMinutes(partesHora[1]);
		horaActual.setSeconds(partesHora[2]);
		return horaActual.toLocaleTimeString('es-ES', {
			hour: '2-digit',
			minute: '2-digit',
			second: '2-digit',
		});
	}

	const schedulesTabAdapter = (
		data: ISchedule[],
		handleOpenModal: (schedule: ISchedule) => void,
		handleEditSchedule: (id: string) => void,
		groups: IAssociatesGroup[]
	): ISchedule[] => {
		const getGroupName = (id: string | number): string => {
			return groups.filter((g) => g.id === id)[0]?.group || '';
		};

		const newData: any[] = data
			? data.map((item: ISchedule) => {
					return {
						...item,
						associateGroup: <>{getGroupName(item.associateGroupId)}</>,
						days: (
							<div
								style={{
									display: 'flex',
									gap: '5px',
									maxWidth: 'max-content',
								}}
							>
								{item.days.toString().includes('mon') ? (
									<div style={dayStyles}>{getSplitedDays('mon')}</div>
								) : null}
								{item.days.toString().includes('tue') ? (
									<div style={dayStyles}>{getSplitedDays('tue')}</div>
								) : null}
								{item.days.toString().includes('wed') ? (
									<div style={dayStyles}>{getSplitedDays('wed')}</div>
								) : null}
								{item.days.toString().includes('thu') ? (
									<div style={dayStyles}>{getSplitedDays('thu')}</div>
								) : null}
								{item.days.toString().includes('fri') ? (
									<div style={dayStyles}>{getSplitedDays('fri')}</div>
								) : null}
								{item.days.toString().includes('sat') ? (
									<div style={dayStyles}>{getSplitedDays('sat')}</div>
								) : null}
								{item.days.toString().includes('sun') ? (
									<div style={dayStyles}>{getSplitedDays('sun')}</div>
								) : null}
							</div>
						),
						startDate: item.startDate
							? dayjs(item.startDate).format('DD-MMM-YYYY')
							: t('schedules.no-start'),
						endDate: item.endDate
							? dayjs(item.endDate).format('DD-MMM-YYYY')
							: t('schedules.no-end'),
						time: !item.allDay
							? (restarHoras(item.startTime, 6) || '00:00') +
							  ' - ' +
							  (restarHoras(item.endTime, 6) || '00:00')
							: t('schedules.all-day'),
						status: item.status ? (
							<div
								style={{
									...labelStyles,
									color: '#5ed092',
									backgroundColor: '#c1edd5',
								}}
							>
								{t('schedules.enabled')}
							</div>
						) : (
							<div
								style={{
									...labelStyles,
									color: 'white',
									backgroundColor: 'red',
								}}
							>
								{t('schedules.disabled')}
							</div>
						),
						actions: getSchedulesPermission() ? (
							<div style={{ display: 'flex', gap: '10px' }}>
								<ButtonIconSmall
									onPress={() => handleEditSchedule(item.id!)}
									type="success"
									icon={<BorderColor />}
									hoverLabel={t('common.edit')}
								/>
								<ButtonIconSmall
									onPress={() => handleOpenModal(item)}
									type="error"
									icon={<Delete />}
									hoverLabel={t('common.delete')}
								/>
							</div>
						) : (
							<span>{t('common.no-actions')}</span>
						),
					};
			  })
			: [];
		return newData;
	};

	return { schedulesTabAdapter };
}
