/* eslint-disable prettier/prettier */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
// Styles
import styles from './drawer-layout.component.module.css';
// Assets
import logo from '../../assets/logos/logo-white.png';
// Components
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../store';
import { ROLES } from '../../constants/user-roles.constants';

import { ExitToApp, Logout, Person } from '@mui/icons-material';
import { editSimulation } from '../../store/modules/partner-simulation';
import { editPartner } from '../../store/modules/partner';
import { useTranslation } from 'react-i18next';
import { CustomTooltip } from '../button-icon-small.component';
import { editColors } from '../../store/modules/colors';
import { defaultPartnerInfo } from '../../interfaces/partner';
import DrawerSubmenu from './components/drawer-submenu.component';
import DrawerLi from './components/drawer-li.component';
import LogoutModal from './components/logout-modal.component';
import { useDrawer } from './hooks/useDrawer';

export interface IDrawerProps {
	isSubMenuOpen: boolean;
	setIsSubMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
	arrowToggle?: boolean;
}

const DrawerLayout = ({ isSubMenuOpen, setIsSubMenuOpen, arrowToggle }: IDrawerProps) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {
		roleName,
		simulating,
		selectMenu,
		partner,
		submenuNumber,
		classNameDrawer,
		openLogoutModal,
		handleSubmenuChange,
		handleLogout,
		toogleMenu,
		getRouteList,
		setOpenLogoutModal,
	} = useDrawer({ isSubMenuOpen, setIsSubMenuOpen });

	const getPartnerImage = (): JSX.Element => {
		if (roleName === ROLES.SUPER_ADMIN) {
			return (
				<img
					src={simulating ? `data:image/png;base64,${partner.img}` : logo}
					alt="logo"
					style={{ width: 45, cursor: 'pointer' }}
					onClick={() => toogleMenu(0, '/dashboard')}
				/>
			);
		} else {
			if (partner.img) {
				return (
					<img
						src={
							roleName === ROLES.ADMIN || simulating
								? `data:image/png;base64,${partner.img}`
								: logo
						}
						alt="logo"
						style={{ width: 45, cursor: 'pointer' }}
						onClick={() => toogleMenu(0, '/dashboard')}
					/>
				);
			} else {
				return <Person style={{ width: '50px', height: '50px' }} />;
			}
		}
	};

	return (
		<>
			<div style={{ display: 'flex', height: '100%' }}>
				{/* Menu lateral permanente */}
				<Box
					sx={{
						minWidth: '93px',
						maxWidth: '93px',
						background: colors.primary,
						height: '100%',
						color: '#FFFFFF',
						display: 'flex',
						flexDirection: 'column',
						zIndex: 10000,
					}}
					padding={1}
				>
					<Box
						className={styles.containerTitleDrawer}
						style={{ width: '100%', height: 80 }}
						display="flex"
						alignItems="center"
					>
						<Box
							display="flex"
							flexGrow={1}
							alignItems="center"
							justifyContent="center"
						>
							{getPartnerImage()}
						</Box>
					</Box>
					<ul
						className={styles.menuDrawer}
						//data-small={arrowToggle && !matches ? 'true' : 'false'}
					>
						{getRouteList(roleName).map(
							(menu, index) =>
								!menu.hideMenu && (
									<DrawerLi
										menu={menu}
										i={index}
										selectMenu={selectMenu}
										submenuNumber={submenuNumber}
										arrowToggle={arrowToggle!}
										handleSubmenuChange={handleSubmenuChange}
										toogleMenu={toogleMenu}
									/>
								)
						)}
					</ul>
					{simulating && (
						<CustomTooltip
							title={t('partners.exit-simulation')}
							placement="top"
						>
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									width: '100%',
									height: '25px',
									cursor: 'pointer',
								}}
								onClick={() => {
									dispatch(
										editSimulation({
											simulating: false,
											simulatedPartnerGuns: [],
										})
									);
									dispatch(editPartner(defaultPartnerInfo));
									dispatch(
										editColors({
											id: '1',
											name: 'Color 1',
											primary: '#000000',
											secondary: '#474747',
											accent: '#2ba8e0',
										})
									);
									navigate('/partners');
								}}
							>
								<ExitToApp />
							</div>
						</CustomTooltip>
					)}
					<div
						className={styles.logout}
						onClick={() => setOpenLogoutModal(true)}
					>
						<Logout />
					</div>
				</Box>
				{/* Sumbenu*/}
				<DrawerSubmenu
					classNameDrawer={classNameDrawer}
					submenuNumber={submenuNumber}
					isSubMenuOpen={isSubMenuOpen}
				/>
			</div>
			<LogoutModal
				openLogoutModal={openLogoutModal}
				setOpenLogoutModal={setOpenLogoutModal}
				handleLogout={handleLogout}
			/>
		</>
	);
};

export default DrawerLayout;
