export const newUserForm = [
	{
		name: 'name',
		label: 'Name',
		required: true,
	},
	{
		name: 'first_last_name',
		label: 'First last name',
		required: true,
	},
	{
		name: 'second_last_name',
		label: 'Second last name',
		required: false,
	},
	{
		name: 'mobile',
		label: 'Phone',
		required: true,
	},
	{
		name: 'email',
		label: 'Email',
		required: true,
	},
];
