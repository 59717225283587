import { useColumnSelector } from './hooks/useColumnSelector';
import Item from './item.component';
import { useGetColumns } from './hooks/useGetColumns';

import styles from './styles/column-selector.component.module.css';
import { Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const ColumnSelector = () => {
	const [t] = useTranslation('global');
	useGetColumns();
	const {
		columns,
		selectedColumns,
		handleItemClick,
		handleDeleteAllColumns,
		handleSelectAllColumns,
	} = useColumnSelector();

	return (
		<div className={styles.columnsContainer}>
			<div className={styles.column}>
				<div className={styles.columnHeader}>
					<span className={styles.columnTitle}>{t('report.available')}</span>
					{columns.length > 0 && (
						<Chip
							label={t('report.select-all')}
							color="success"
							onClick={handleSelectAllColumns}
						/>
					)}
				</div>
				<div className={styles.availableColumns}>
					{columns.map((c, index) => (
						<Item
							key={index}
							id={c.id.toString()}
							name={c.name}
							onItemClick={handleItemClick}
							selected={false}
						/>
					))}
				</div>
			</div>
			<div className={styles.column}>
				<div className={styles.columnHeader}>
					<span className={styles.columnTitle}>{t('report.selected')}</span>
					{selectedColumns.length > 0 && (
						<Chip
							label={t('report.delete-all')}
							color="error"
							onClick={handleDeleteAllColumns}
						/>
					)}
				</div>
				<div className={styles.selectedColumns}>
					{selectedColumns.map((c, index) => (
						<Item
							key={index}
							id={c.id.toString()}
							name={c.name}
							onItemClick={handleItemClick}
							selected={true}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default ColumnSelector;
