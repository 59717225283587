import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../../../../../../store/modules/snackbar';
import { setLoading } from '../../../../../../store/modules/loading';
import { useTranslation } from 'react-i18next';
import { useBackendRequest } from '../../../../../../hooks/useBackendRequest';
import { ICharger, defaultCharger } from '../../../../../../interfaces/charger.interface';
import { IGun } from '../../../../../../interfaces/gun.interface';

export function useChargerDetail() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { id } = useParams();

	const [chargerInfo, setChargerInfo] = useState<ICharger>(defaultCharger);
	const [chargerGuns, setChargerGuns] = useState<IGun[]>([]);

	const {
		execute: getChargerInfo,
		value: getChargerInfoVal,
		status: getChargerInfoStatus,
	} = useBackendRequest<any, ICharger>({
		path: 'chargers/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<any, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getChargerInfoStatus === 'success') {
			if (getChargerInfoVal?.data) {
				setChargerInfo(getChargerInfoVal.data);
				getGuns();
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('chargers.error-single-info'),
						severity: 'error',
					})
				);
			}
		} else if (getChargerInfoStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-single-info'),
					severity: 'error',
				})
			);
		}
	}, [getChargerInfoStatus]);

	useEffect(() => {
		if (getGunsStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			if (getGunsVal?.data) {
				const guns = filterStationGuns(getGunsVal.data);
				setChargerGuns(guns);
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('stations.error-guns'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('stations.error-guns'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	const filterStationGuns = (data: IGun[]): IGun[] => {
		return data.filter((gun) => gun.chargerId === chargerInfo.id!);
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getChargerInfo({}, id);
	}, []);

	return { chargerInfo, chargerGuns };
}
