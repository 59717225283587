import { Grid, Paper } from '@mui/material';

import styles from './styles/edit-user.styles.module.css';
import CustomButton from '../../components/button.component';
import { useLocation, useNavigate } from 'react-router-dom';
import Input from '../../components/input.component';
import { useEditUser } from './hooks/useEditUser';
import { IUserDetailsResponse } from './interfaces/user-details.interface';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useEditUserForm } from './utils/utils';
import { useTranslation } from 'react-i18next';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';

const UserDetails = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const { state } = useLocation();
	const colors = useSelector((state: RootState) => state.colors);
	const { userData, disabledButton, handleSetData, handleAddUser } = useEditUser();

	const { editUserForm } = useEditUserForm();

	return (
		<Paper elevation={1} className={styles.boxPaper}>
			<Grid container display={'flex'} flexDirection={'column'}>
				<Grid item className={styles.titleContainer}>
					<span>{t('users.edit-title')}</span>
				</Grid>
				<div
					className={styles.separator}
					style={{ marginTop: '24px', marginBottom: '33px' }}
				/>
				<Grid container className={styles.inputsContainer} spacing={3}>
					{editUserForm.map((input) => (
						<Grid item xl={6} sm={12} md={12} key={input.name}>
							<Input
								label={input.label}
								id={input.name}
								name={input.name}
								placeholder="Required"
								type={'text'}
								required={input.required}
								onChange={handleSetData}
								value={userData[input.name as keyof IUserDetailsResponse]}
							/>
						</Grid>
					))}
				</Grid>
				<div
					className={styles.separator}
					style={{ marginTop: '28px', marginBottom: '22px' }}
				/>
				<Grid item className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={
							state.prevUrl === '/users'
								? () => navigate(-1)
								: () =>
										navigate('/partners/details', {
											state: { tab: ADMIN_TAB_VALUES.USERS },
										})
						}
						styles={{
							backgroundColor: 'var(--color-black-main)',
							width: '124px',
							gap: '8px',
						}}
					/>
					<CustomButton
						label={t('common.save')}
						variant={'solid'}
						onClick={handleAddUser}
						disabled={disabledButton}
						styles={{
							width: '124px',
							gap: '8px',
							backgroundColor: colors.accent,
							color: 'black',
						}}
					/>
				</Grid>
			</Grid>
		</Paper>
	);
};

export default UserDetails;
