import { configureStore, compose, combineReducers } from '@reduxjs/toolkit';

import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';

import storage from 'redux-persist/lib/storage';

import userReducer from './modules/user';
import partnerReducer from './modules/partner';
import loadingReducer from './modules/loading';
import gunsReducer from './modules/guns';
import colorsReducer from './modules/colors';
import snackbarReducer from './modules/snackbar';
import configReducer from './modules/config';
import permissionsReducer from './modules/permissions';
import defaultValuesReducer from './modules/default-values';
import partnerSimulationReducer from './modules/partner-simulation';

declare global {
	interface Window {
		__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
	}
}
export const mainReducer = combineReducers({
	user: userReducer,
	partner: partnerReducer,
	loading: loadingReducer,
	guns: gunsReducer,
	colors: colorsReducer,
	snackbar: snackbarReducer,
	config: configReducer,
	permissions: permissionsReducer,
	defaultValues: defaultValuesReducer,
	simulation: partnerSimulationReducer,
});

const persistedReducer = persistReducer(
	{
		key: 'root',
		storage,
		whitelist: [
			'user',
			'partner',
			'guns',
			'colors',
			'permissions',
			'defaultValues',
			'simulation',
		],
		version: 1,
	},
	mainReducer
);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);
export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
