/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import { IDrawerProps } from '..';
import { RootState } from '../../../store';
import { useRouteList } from '../../../router/utils/route-list';
import { useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

import styles from '../drawer-layout.component.module.css';
import { editConfig } from '../../../store/modules/config';
import { editSimulation } from '../../../store/modules/partner-simulation';
import { defaultPartner, editPartner } from '../../../store/modules/partner';
import { defaultUser, editUser } from '../../../store/modules/user';

export function useDrawer({ isSubMenuOpen, setIsSubMenuOpen }: IDrawerProps) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { roleName } = useSelector((state: RootState) => state.user.role);
	const { simulating } = useSelector((state: RootState) => state.simulation);

	const partner = useSelector((state: RootState) => state.partner);
	const { getRouteList } = useRouteList();
	const matches = useMediaQuery(useTheme().breakpoints.down('md'));
	const [selectMenu, setSelectMenu] = useState<number>(0);
	const [submenuNumber, setSubmenuNumber] = useState<number>(0);
	const [classNameDrawer, setClassNameDrawer] = useState(styles.none);
	const [openLogoutModal, setOpenLogoutModal] = useState<boolean>(false);

	const toogleMenu = (menu: number, redirect?: string) => {
		if (redirect) {
			setSelectMenu(menu);
			navigate(redirect);
		} else {
			setSelectMenu(selectMenu === menu ? -1 : menu);
			dispatch(editConfig({ menu: selectMenu === menu ? false : true }));
		}
	};

	const handleSubmenuChange = (index: number, navigateUrl: string) => {
		if (index === submenuNumber) {
			setSubmenuNumber(0);
			return;
		}
		setSubmenuNumber(index);
		navigate(navigateUrl);
	};

	useEffect(() => {
		if (matches) {
			setClassNameDrawer(styles.hidden);
		} else {
			setClassNameDrawer(styles.none);
		}

		if (isSubMenuOpen && matches) {
			setClassNameDrawer(styles.showMobile);
		}
	}, [isSubMenuOpen, matches, submenuNumber]);

	useEffect(() => {
		setSubmenuNumber(0);
	}, [simulating]);

	useEffect(() => {
		if (submenuNumber === 0) {
			setIsSubMenuOpen(false);
		} else {
			setIsSubMenuOpen(true);
		}
	}, [submenuNumber]);

	const handleLogout = () => {
		localStorage.removeItem('accessToken');
		localStorage.removeItem('validToken');
		localStorage.removeItem('persist:root');
		dispatch(editSimulation({ simulating: false, simulatedPartnerGuns: [] }));
		dispatch(editPartner(defaultPartner));
		dispatch(editUser(defaultUser));
		navigate('/');
	};

	return {
		roleName,
		simulating,
		selectMenu,
		partner,
		submenuNumber,
		classNameDrawer,
		openLogoutModal,
		handleSubmenuChange,
		handleLogout,
		toogleMenu,
		getRouteList,
		setOpenLogoutModal,
	};
}
