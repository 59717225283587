import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../store';
import { useEffect, useMemo, useState } from 'react';
import {
	emailValid,
	isNumberValidate,
	isPasswordValid,
	zipRegex,
} from '../../../utils/validators';
import { setLoading } from '../../../store/modules/loading';
import { IAssociate, defaultAssociate } from '../../../interfaces/associate.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { useNavigate } from 'react-router-dom';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { useCreateAssociateAccount } from './useCreateAssociateAccount';

export function useAddAssociate() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useSelector((state: RootState) => state.partner);
	const [newAssociateData, setNewAssociateData] = useState<IAssociate>({
		...defaultAssociate,
		partnerInfoId: id,
	});

	const { handleAddUser } = useCreateAssociateAccount();

	const {
		execute: addAssociate,
		value: addAssociateVal,
		status: addAssociateStatus,
	} = useBackendRequest<IAssociate, any>({
		path: 'associates',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (addAssociateStatus === 'success') {
			dispatch(setLoading({ loading: false }));
			if (addAssociateVal?.data) {
				handleAddUser(
					newAssociateData.phone,
					newAssociateData.password,
					newAssociateData.email,
					newAssociateData.name,
					newAssociateData.alias
				);
				dispatch(
					setAlert({
						show: true,
						message: t('associates.registered'),
						severity: 'success',
					})
				);
				navigate('/partners/details', {
					state: { tab: ADMIN_TAB_VALUES.ASSOCIATES },
				});
			} else {
				dispatch(
					setAlert({
						show: true,
						message: t('associates.registered-error'),
						severity: 'error',
					})
				);
			}
		} else if (addAssociateStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			console.log('Error');
			dispatch(
				setAlert({
					show: true,
					message: t('associates.registered-error'),
					severity: 'error',
				})
			);
		}
	}, [addAssociateStatus]);

	const disabledButton = useMemo((): boolean => {
		return !(
			newAssociateData.name !== '' &&
			newAssociateData.alias !== '' &&
			newAssociateData.rfc !== '' &&
			newAssociateData.address !== '' &&
			newAssociateData.state !== '' &&
			newAssociateData.country !== '' &&
			newAssociateData.zip.length > 0 &&
			emailValid(newAssociateData.email).valid &&
			isPasswordValid(newAssociateData.password) &&
			newAssociateData.phone.length >= 10 &&
			newAssociateData.img !== ''
		);
	}, [newAssociateData]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;

		if (name === 'phone') {
			if (!isNumberValidate(value)) return;
		} else if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		}

		setNewAssociateData({ ...newAssociateData, [name]: value });
	};

	const saveAssociate = (): void => {
		dispatch(setLoading({ loading: true }));
		addAssociate(newAssociateData);
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setNewAssociateData({ ...newAssociateData, img: base64String });
			//setNewAssociateData({ ...newAssociateData, img: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteImage = () => {
		setNewAssociateData({ ...newAssociateData, img: '' });
	};

	const getInputLabel = (input: string): string => {
		switch (input) {
			case 'name':
				return t('common.name');
			case 'alias':
				return t('common.alias');
			case 'rfc':
				return t('common.tax-id');
			case 'address':
				return t('common.address');
			case 'state':
				return t('common.state');
			case 'country':
				return t('common.country');
			case 'zip':
				return t('common.zip');
			case 'email':
				return t('common.email');
			case 'password':
				return t('common.password');
			case 'phone':
				return t('common.phone');
			default:
				return 'Label';
		}
	};

	return {
		newAssociateData,
		disabledButton,
		handleSetData,
		getInputLabel,
		saveAssociate,
		handleImageChange,
		handleDeleteImage,
	};
}
