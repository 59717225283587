import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import styles from './styles/section-ds.component.module.css';
import { useTranslation } from 'react-i18next';

interface ISectionDs {
	image1: string;
	image2: string;
	image3: string;
}

const SectionDS = ({ image1, image2, image3 }: ISectionDs) => {
	const [t] = useTranslation('global');
	const slideImages = [
		{
			url: image1,
		},
		{
			url: image2,
		},
		{
			url: image3,
		},
	];

	return (
		<div className={styles.mainContainer}>
			<h3>{t('stations.images')}</h3>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<div style={{ width: '500px' }}>
					<Fade>
						{slideImages.map((fadeImage, index) => (
							<div
								key={index}
								style={{ display: 'flex', justifyContent: 'center' }}
							>
								<img
									style={{ width: '80%' }}
									src={`data:image/png;base64,${fadeImage.url}`}
								/>
							</div>
						))}
					</Fade>
				</div>
			</div>
		</div>
	);
};

export default SectionDS;
