// Dependencies
import { Routes, Route, Navigate } from 'react-router-dom';

/* Pages */
import { useRouteList } from './utils/route-list';
import LoginPage from '../pages/login';
import ProtectedRoute from './protected';
import ModalLoading from '../components/loading.component';
import CustomSnackbar from '../components/custom-snack-bar.component';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { useEffect, useState } from 'react';
import { ROLES } from '../constants/user-roles.constants';
import { useUserPermisions } from '../hooks/useUserPermissions';

export const Router = () => {
	const data = localStorage.getItem('validToken');
	const { role } = useSelector((state: RootState) => state.user);

	const [routesRole, setRoutesRole] = useState<string>('');

	const { getRouteList } = useRouteList();

	const {
		getAssociatesGroupPermission,
		getAssociatesPermission,
		getReportsPermission,
		getStationsPermission,
		getUsersPermission,
		getChargersPermission,
		getGunsPermission,
		getSchedulesPermission,
	} = useUserPermisions();

	useEffect(() => {
		setRoutesRole(role.roleName);
	}, [role]);

	const getInitialRoute = (): string => {
		if (role.roleName === ROLES.SUPER_ADMIN) {
			return '/dashboard';
		} else {
			if (getAssociatesGroupPermission() || getAssociatesPermission()) {
				return '/dashboard';
			} else if (
				getAssociatesPermission() ||
				getAssociatesGroupPermission() ||
				getStationsPermission() ||
				getChargersPermission() ||
				getGunsPermission() ||
				getSchedulesPermission() ||
				getUsersPermission()
			) {
				return '/partners/details';
			} else if (getReportsPermission()) {
				return '/financial';
			} else {
				return '/settings';
			}
		}
	};

	return (
		<>
			<Routes>
				<Route path="404" element={<Navigate to={getInitialRoute()} />} />
				<Route path="*" element={<Navigate to={getInitialRoute()} />} />
				<Route
					path="/"
					element={data ? <Navigate to={getInitialRoute()} /> : <LoginPage />}
				/>
				{getRouteList(routesRole).map(
					(routes, i) =>
						routes.Component && (
							<Route
								key={i}
								path={routes.link}
								element={<ProtectedRoute component={routes.Component} />}
							/>
						)
				)}
				{getRouteList(routesRole).map((routes) =>
					routes.submenu?.map(
						(route, i) =>
							route.Component && (
								<Route
									key={i}
									path={routes.link + route.link}
									element={
										<ProtectedRoute component={route.Component} />
									}
								/>
							)
					)
				)}
			</Routes>
			<ModalLoading />
			<CustomSnackbar />
		</>
	);
};
