import React from 'react';
import CustomModal from './modal.component';
import CustomButton from './button.component';
import { useTranslation } from 'react-i18next';

interface IStopModal {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
	acceptModal: () => void;
	name: string;
	type: 'gun' | 'charger' | 'station';
}

const ModalStop = ({ open, setOpen, acceptModal, name, type }: IStopModal) => {
	const [t] = useTranslation('global');

	const getLabel = (type: string): string => {
		switch (type) {
			case 'gun':
				return t('stop-modal.gun');
			case 'charger':
				return t('stop-modal.charger');
			case 'station':
				return t('stop-modal.station');
			default:
				return 'gun';
		}
	};

	return (
		<CustomModal open={open} maxWidth="md" fullWidth title={t('stop-modal.title')}>
			<div>
				<span>
					{`${t('stop-modal.desc')} ${getLabel(type)}`} <strong>{name}</strong>?
				</span>
				<div
					style={{
						display: 'flex',
						gap: 20,
						justifyContent: 'center',
						marginTop: 25,
					}}
				>
					<CustomButton
						variant="solid"
						label="Cancel"
						styles={{
							backgroundColor: 'black',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={() => setOpen(false)}
					/>
					<CustomButton
						variant="solid"
						label="Stop"
						styles={{
							backgroundColor: '#DD0528',
							fontSize: '18px',
							minHeight: '40px',
							width: '25%',
							borderRadius: '4px',
						}}
						onClick={acceptModal}
					/>
				</div>
			</div>
		</CustomModal>
	);
};

export default ModalStop;
