import styles from './station-card-styles.component.module.css';
import Icon from '../../../../assets/svgs/station-icon.svg';

import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTranslation } from 'react-i18next';

interface IStationCardDetails {
	name?: string;
	location?: string;
	guns?: number;
	chargers: number;
}

const StationDatailCard = ({ name, location, guns, chargers }: IStationCardDetails) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);

	return (
		<div className={styles.mainContainer}>
			<div className={styles.imgContainer}>
				<img src={Icon} alt="icon" height={'20px'} width={'20px'} />
			</div>
			<span className={styles.name}>{name || 'Station 01'}</span>
			<span className={styles.address}>
				{location || 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert'}
			</span>
			<div className={styles.infoRow}>
				<div
					className={styles.infoElement}
					style={{ borderColor: colors.secondary }}
				>
					<span className={styles.infoNumber}>{chargers}</span>
					<span className={styles.infoName}>{t('dashboard-3.chargers')}</span>
				</div>
				<div
					className={styles.infoElement}
					style={{ borderColor: colors.secondary }}
				>
					<span className={styles.infoNumber}>{guns || 0}</span>
					<span className={styles.infoName}>{t('dashboard-3.guns')}</span>
				</div>
			</div>
		</div>
	);
};

export default StationDatailCard;
