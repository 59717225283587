// Third-party libraries
import { Grid } from '@mui/material';
import SchedulesTab from '../partners-group-details/components/schedules-tab.component';

const Schedules = () => {
	return (
		<Grid container flexDirection={'column'} style={{ padding: '40px 25px 60px' }}>
			<SchedulesTab />
		</Grid>
	);
};
export default Schedules;
