export const USER_PERMISSIONS = Object.freeze({
	MANAGE_USERS: '367cec7a-c1ac-4814-8218-ec396535f112',
	MANAGE_ASSOCIATES_GROUPS: '40038c3c-6adc-4659-a6e0-4edc3a5405ec',
	MANAGE_STATIONS: '4c70ac77-d39c-42d2-950e-3473448fc86e',
	ADD_THEMES: '8aa84d8e-788f-4056-b937-a8f5def07452',
	MANAGE_REPORTS: '91b0a09a-f878-4060-a853-5e18d1b79235',
	MANAGE_ASSOCIATES: 'a36d3646-1445-43c9-bf5b-f249f819cfc0',
	MANAGE_CHARGERS: '45f46a8b-9beb-454e-b902-1e0ba617b958',
	MANAGE_GUNS: '45452459-fa74-47ce-9149-df02a0cccc16',
	MANAGE_SCHEDULES: '58b35f0b-521b-4920-9310-96d51045c4ac',
	MANAGE_DEFAULT_VALUES: '3d87b0ed-4aff-46ad-9666-3db5cebfeba0',
	MANAGE_STRIPE: '78d7c431-3222-4a3e-b8e1-1ffc3143a9ee',
});
