import { createSlice } from '@reduxjs/toolkit';
import { IConfig } from '../../interfaces/config';

const defaultConfig: IConfig = {
	menu: true,
};

export const configSlice = createSlice({
	name: 'config',
	initialState: defaultConfig,
	reducers: {
		editConfig: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { editConfig } = configSlice.actions;

export default configSlice.reducer;
