interface IPermissions {
	permissionId: string;
}

export interface IUser {
	id: string;
	name: string;
	lastName: string;
	partnerId: number | string;
	partnerGuns: string[];
	role: {
		roleId: number;
		roleName: string;
	};
	permissions: IPermissions[];
}

export const defaultPermissions: IPermissions[] = [
	{
		permissionId: '5fac4f5f-7d70-424c-8e45-164cec9d0daa',
	},
	{
		permissionId: '794e10e8-85ca-4d16-9e11-cb06a7caa4e1',
	},
	{
		permissionId: '895a760c-e98f-4e35-ae0e-a63ed8c1637d',
	},
];

export interface IAddUserParams {
	partnerId: string;
	user_name: string;
	mobile: string;
	login_pwd: string;
	real_name: string;
	sex: string;
	email: string;
	user_system_type: number;
	name: string;
	first_last_name: string;
	second_last_name: string;
}
