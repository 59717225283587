import { IUsersDetailsPartner } from '../pages/login/interfaces/login.interface';

export const getUserPartner = (
	userPartnerDetails: IUsersDetailsPartner[],
	partnerId: string
): IUsersDetailsPartner | null => {
	const partner = userPartnerDetails.filter(
		(detail) => detail.partnerId === partnerId && detail.user_system_type === 2
	)[0];
	return partner;
};
