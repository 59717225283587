export interface IAddQionPartnerParams {
	img: string;
	name: string;
	responsibleName: string;
	responsibleLastName: string;
	address: string;
	rfc: string;
	email: string;
	password: string;
	phone: string;
}

export interface IAddQionPartnerResponse {
	id: number | string;
	img: string;
	name: string;
	responsibleName: string;
	responsibleLastName: string;
	address: string;
	rfc: string;
	email: string;
	password: string;
	phone: 5256488457;
}

export interface IAddPartnerParams {
	name: string;
	responsibleName: string;
	responsibleLastName: string;
	address: string;
	state: string;
	country: string;
	zip: string;
	rfc: string;
	phone: string;
	email: string;
	password: string;
	img: string;
	externalPartnerId: string;
	stripeCode: string;
	languages: string[];
	hold: string;
}

export const defaultPartner: IAddPartnerParams = {
	name: '',
	responsibleName: '',
	responsibleLastName: '',
	address: '',
	state: '',
	country: '',
	zip: '',
	rfc: '',
	phone: '',
	email: '',
	password: '',
	img: '',
	externalPartnerId: '',
	stripeCode: '',
	languages: [],
	hold: '',
};
