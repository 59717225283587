import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../hooks/useTableCustom';

export function useSuperAdminStationsColumns() {
	const [t] = useTranslation('global');

	const superAdminStationColumns: IColumns[] = [
		{
			text: t('common.name'),
			nameID: 'name',
		},
		{
			text: t('common.address'),
			nameID: 'address',
		},
		{
			text: t('common.partner'),
			nameID: 'partnerInfoId',
		},
		{
			text: t('common.actions'),
			nameID: 'actions',
		},
	];

	return { superAdminStationColumns };
}
