/* eslint-disable prettier/prettier */

import styles from './styles.component.module.css';
import { IStationGuns } from '../../pages/interfaces/charging-dashboard.interface';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlugImg } from '../../../../assets/svgs/plug.svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import { useTranslation } from 'react-i18next';

interface ICardGun {
	data: IStationGuns;
}

const CardStation = ({ data }: ICardGun) => {
	const [t] = useTranslation('global');
	const { secondary } = useSelector((state: RootState) => state.colors);
	const navigate = useNavigate();
	return (
		<div className={styles.card}>
			<p className={styles.title}>
				<PlugImg style={{ color: '#18C19F', width: '30px', height: '30px' }} />
				{data.name}
			</p>
			<div style={{ marginTop: 'auto' }}>
				<p className={styles.textAmount}>
					{t('dashboard-4.gun-id')}: <strong>{data.qionGunUniqueId}</strong>{' '}
				</p>
				<p className={styles.textAmount}>
					{t('dashboard-4.charger-asigned')}: <b>{data.chargerName}</b>
				</p>
				<p className={styles.text}>
					{t('dashboard-4.plugtype')}: {data.plugType}
				</p>
				<div
					className={styles.circle}
					style={{
						background:
							data.status === true
								? '#18C19F'
								: data.status === false
								? '#FAB803'
								: '#E6E6E6',
					}}
				></div>

				<div
					className={styles.links}
					onClick={() =>
						navigate(`/partners/detail-gun/${data.qionGunUniqueId}`, {
							state: {
								charger: data.chargerName,
								plugtype: data.plugType,
								gunName: data.name,
							},
						})
					}
					style={{ color: secondary }}
				>
					{t('dashboard-4.view')}
				</div>
			</div>
		</div>
	);
};

export default CardStation;
