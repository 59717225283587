import { useEffect, useState } from 'react';
import { useBackendRequest } from '../../../../hooks/useBackendRequest';
import { useDispatch, useSelector } from 'react-redux';
import { setLoading } from '../../../../store/modules/loading';
import { IRevenueDashboardResponse } from '../interfaces/revenue-dashboard.interface';
import { useTableCustom } from '../../../../hooks/useTableCustom';
import { revenueAdapter } from '../adapters/revenue.adapter';
import dayjs from 'dayjs';
import { setAlert } from '../../../../store/modules/snackbar';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../../store';
import { IGun } from '../../../../interfaces/gun.interface';

export function useRevenueDashboard() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const { partnerGuns } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { id: partnerId } = useSelector((state: RootState) => state.partner);
	const { data, setData } = useTableCustom<any>();
	const months = [
		t('months.january'),
		t('months.february'),
		t('months.march'),
		t('months.april'),
		t('months.may'),
		t('months.june'),
		t('months.july'),
		t('months.august'),
		t('months.september'),
		t('months.october'),
		t('months.november'),
		t('months.december'),
	];

	const slicedMonths = months.map((m: string) => {
		return m.slice(0, 3);
	});

	const [revenueData, setRevenueData] = useState<string[]>([]);
	const [usageData, setUsageData] = useState<string[]>([]);
	const [year, setYear] = useState<number>(dayjs().year());

	const [simulatedPartnerGuns, setSimulatedPartnerGuns] = useState<string[]>([]);

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				getQionPartnerGuns(getGunsVal.data);
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	useEffect(() => {
		if (simulating) {
			getGuns();
		}
	}, []);

	const getQionPartnerGuns = (data: IGun[]): void => {
		const filteredGuns = data.filter((g) => g.partnerInfoId === partnerId);
		const ids: string[] = filteredGuns.map((g) => g.qionGunUniqueId);
		setSimulatedPartnerGuns(ids);
	};

	const {
		execute: getRevenue,
		value: getRevenueVal,
		status: getRevenueStatus,
	} = useBackendRequest<any, IRevenueDashboardResponse[]>({
		path: 'charge-fills/calculateTotalsByMonthAndYear',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (getRevenueStatus === 'success') {
			if (getRevenueVal?.data) {
				getRevenueUsageVals(getRevenueVal.data);
				setData({
					...data,
					countRows: 0,
					rows: revenueAdapter(getRevenueVal.data, months),
				});
				dispatch(setLoading({ loading: false }));
			}
		} else if (getRevenueStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('dashboard-1.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getRevenueStatus]);

	const getRevenueUsageVals = (data: IRevenueDashboardResponse[]): void => {
		const arrKw = [];
		const arrTotal = [];
		for (let i = 0; i < data.length; i++) {
			arrKw.push(data[i].totalKw.toFixed(2));
			arrTotal.push(data[i].totalRevenue.toFixed(2));
		}
		setRevenueData(arrTotal);
		setUsageData(arrKw);
	};

	useEffect(() => {
		if (simulating && simulatedPartnerGuns.length > 0) {
			dispatch(setLoading({ loading: true }));
			getRevenue({
				year: year.toString(),
				gun_ids: simulatedPartnerGuns,
			});
		} else if (!simulating) {
			if (partnerGuns.length === 0) return;
			dispatch(setLoading({ loading: true }));
			getRevenue({
				year: year.toString(),
				gun_ids: partnerGuns,
			});
		}
	}, [year, simulatedPartnerGuns]);

	const getPreviousYear = (): void => {
		setYear(year - 1);
	};

	const getNextYear = (): void => {
		setYear(year + 1);
	};

	return {
		data,
		year,
		months,
		slicedMonths,
		revenueData,
		usageData,
		setData,
		getPreviousYear,
		getNextYear,
	};
}
