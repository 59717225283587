import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import 'leaflet/dist/leaflet.css';

import styles from './styles/map.component.module.css';

import location from '../assets/svgs/location-dot-solid.svg';

const iconUb = L.icon({
	iconUrl: location,
	iconSize: [10, 10],
});

export interface IMapStation {
	id: number | string;
	name: string;
	location: LatLngTuple;
}

export interface IMap {
	stations: IMapStation[];
	center: LatLngTuple;
	zoom?: number;
}

const Map = ({ stations, center, zoom = 3 }: IMap) => {
	return (
		<MapContainer
			center={center}
			zoom={zoom}
			minZoom={2}
			scrollWheelZoom={true}
			className={styles.map}
			worldCopyJump={true}
			style={{ height: '500px', maxWidth: '100%' }}
		>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{stations
				? stations.map((station, index) => (
						<Marker key={index} position={station.location} icon={iconUb}>
							<Popup>{station.name}</Popup>
						</Marker>
				  ))
				: null}
		</MapContainer>
	);
};

export default Map;
