// React dependencies
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// Third-party libraries
import { Paper } from '@mui/material';
// Style dependencies
import styles from '../styles/partners-details.module.css';
import stylesAdmin from '../styles/admin-partners-details.module.css';
// Resources
import TableCustom from '../../../components/table-custom.component';
import { useTableCustom } from '../../../hooks/useTableCustom';

import { associateGroupsColumns } from '../utils/associate-groups-table.columns';
import {
	IAssociateGroup,
	mockDataAssociateGroup,
} from '../interfaces/associates-group.interface';
import { associateGroupAdapter } from '../adapters/associate-group.adapter';

export const AssociatesGroupTab = () => {
	const { data, setData, pagination, rowsPerPage, filters, handleFilter } =
		useTableCustom<any>();
	const navigate = useNavigate();
	const handleSeeDetails = (data: IAssociateGroup) => {
		navigate('/partners/associates');
	};
	useEffect(() => {
		setData({
			...data,
			countRows: 0,
			rows: associateGroupAdapter(mockDataAssociateGroup, handleSeeDetails),
		});
	}, []);
	return (
		<Paper className={styles.boxPaper} elevation={1}>
			<TableCustom
				columns={associateGroupsColumns}
				data={data}
				setData={setData}
				showPagination
				showLimitPerPage
				titleHeader="Associates Group"
				showTotalCount={false}
				hideChips
			/>
		</Paper>
	);
};
