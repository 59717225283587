import { createSlice } from '@reduxjs/toolkit';
import { IPartnerInfo } from '../../interfaces/partner';

export const defaultPartner: IPartnerInfo = {
	id: '',
	img: '',
	name: '',
	responsibleName: '',
	responsibleLastName: '',
	address: '',
	rfc: '',
	email: '',
	password: '',
	phone: '',
	state: '',
	country: '',
	zip: '',
	externalPartnerId: '',
	stripeCode: '',
	languages: [],
	hold: '',
};

export const partnerSlice = createSlice({
	name: 'partner',
	initialState: defaultPartner,
	reducers: {
		createPartner: (state, action) => action.payload,
		editPartner: (state, action) => ({ ...state, ...action.payload }),
	},
});

export const { createPartner, editPartner } = partnerSlice.actions;

export default partnerSlice.reducer;
