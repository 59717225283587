import CustomModal from '../../../components/modal.component';
import GunCard from './gun-card.component';
import { IGun } from '../../../interfaces/gun.interface';
import CustomButton from '../../../components/button.component';
import { useTranslation } from 'react-i18next';

import styles from '../styles/index.module.css';

interface IGunsModal {
	data: IGun[];
	openModal: boolean;
	buttonDisabled: boolean;
	onCardClick: (id: string, selected: boolean) => void;
	getChargerName: (id: string) => string;
	onAccept: () => void;
	onCancel: () => void;
}

const GunsModal = ({
	data,
	openModal,
	buttonDisabled,
	onCardClick,
	getChargerName,
	onAccept,
	onCancel,
}: IGunsModal) => {
	const [t] = useTranslation('global');
	return (
		<CustomModal
			open={openModal}
			maxWidth="xl"
			fullWidth
			title={t('add-schedule.assign-gun')}
		>
			<>
				<hr className={styles.customHr} />
				<div className={styles.associatesCardsModalContainer}>
					{data.map((gun) => (
						<GunCard
							key={gun.qionGunUniqueId}
							id={gun.qionGunUniqueId}
							name={gun.name}
							onClick={onCardClick}
							chargerName={getChargerName(gun.chargerId)}
							location={`${gun.address}, ${gun.state}, ${gun.country}. ${gun.zip}.`}
						/>
					))}
				</div>
				<hr className={styles.customHr} />
				<div className={styles.buttonsContainer}>
					<CustomButton
						label={t('common.cancel')}
						variant={'solid'}
						onClick={onCancel}
						styles={{
							width: '158px',
							gap: '11px',
							backgroundColor: 'var(--color-black-main)',
						}}
					/>
					<CustomButton
						label={t('common.assign')}
						variant={'solid'}
						onClick={onAccept}
						styles={{
							width: '158px',
							gap: '11px',
						}}
						disabled={buttonDisabled}
					/>
				</div>
			</>
		</CustomModal>
	);
};

export default GunsModal;
