import axios from 'axios';
import CipherUtils from '../utils/CipherUtils';

export const useGetQionToken = () => {
	const getToken = async () => {
		const encryptedAccount = CipherUtils.encrypt(
			JSON.stringify({
				account: process.env.REACT_APP_ENC_ACC,
				password: process.env.REACT_APP_ENC_PSW,
			}),
			process.env.REACT_APP_ENC_KEY
		);
		try {
			const response = await axios({
				url: `${process.env.REACT_APP_BASE_URL}/certification/token`,
				data: encryptedAccount,
				method: 'POST',
				headers: {
					'Content-Type': 'text/plain',
				},
			});
			const responsePlain = CipherUtils.decrypt(
				response.data,
				process.env.REACT_APP_ENC_KEY
			);
			const responseParsed = JSON.parse(responsePlain);
			return responseParsed.objData;
		} catch {
			return null;
		}
	};

	return { getToken };
};
