// React dependencies
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

// Third-party libraries
import {
	Card,
	CardHeader,
	Grid,
	CardContent,
	Typography,
	CardActions,
} from '@mui/material';
import CustomButton from '../../../components/button.component';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import LocalGasStationOutlinedIcon from '@mui/icons-material/LocalGasStationOutlined';
import PowerOutlinedIcon from '@mui/icons-material/PowerOutlined';

// Style dependencies
import styles from '../styles/home.module.css';
import { SetStateAction } from 'react';
import { ADMIN_TAB_VALUES } from '../../../constants/tab-values.constants';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';
import { useUserPermisions } from '../../../hooks/useUserPermissions';

// Resources

export interface IHomeTabCount {
	Associates: number;
	Chargers: number;
	Guns: number;
	Stations: number;
	PartnersGroup: number;
}

interface IHome {
	setTabValue: React.Dispatch<SetStateAction<number>>;
	count: IHomeTabCount;
}

export const Home = ({ setTabValue, count }: IHome) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);

	const {
		getAssociatesPermission,
		getAssociatesGroupPermission,
		getStationsPermission,
		getChargersPermission,
		getGunsPermission,
	} = useUserPermisions();

	const cardData = [
		{
			count: count.Associates,
			title: t('common.associates'),
			icon: (
				<PermIdentityOutlinedIcon
					style={{ fontSize: '50px', color: colors.secondary }}
				/>
			),
			tabVal: ADMIN_TAB_VALUES.ASSOCIATES,
			available: getAssociatesPermission(),
		},
		{
			count: count.PartnersGroup,
			title: t('common.groups'),
			icon: (
				<GroupsOutlinedIcon
					style={{ fontSize: '50px', color: colors.secondary }}
				/>
			),
			tabVal: ADMIN_TAB_VALUES.ASSOCIATES_GROUP,
			available: getAssociatesGroupPermission(),
		},
		{
			count: count.Stations,
			title: t('common.stations'),
			icon: (
				<PinDropOutlinedIcon
					style={{ fontSize: '50px', color: colors.secondary }}
				/>
			),
			tabVal: ADMIN_TAB_VALUES.STATIONS,
			available: getStationsPermission(),
		},
		{
			count: count.Chargers,
			title: t('common.chargers'),
			icon: (
				<LocalGasStationOutlinedIcon
					style={{ fontSize: '50px', color: colors.secondary }}
				/>
			),
			tabVal: ADMIN_TAB_VALUES.CHARGERS,
			available: getChargersPermission(),
		},
		{
			count: count.Guns,
			title: t('common.guns'),
			icon: (
				<PowerOutlinedIcon
					style={{ fontSize: '50px', color: colors.secondary }}
				/>
			),
			tabVal: ADMIN_TAB_VALUES.GUNS,
			available: getGunsPermission(),
		},
	];

	return (
		<Grid container display={'flex'} justifyContent={'space-around'} gap={5}>
			{cardData.map((item, index) => {
				if (item.available)
					return (
						<Grid
							item
							xs={12}
							sm={5}
							md={4}
							lg={3}
							xl={2}
							key={`homeCard-${index}`}
						>
							<Card
								sx={{
									backgroundColor: hexToRGBA(colors.secondary, 0.22),
									height: '100%',
								}}
							>
								<CardHeader
									avatar={item.icon}
									className={styles.headerCard}
								/>
								<CardContent>
									<Typography
										variant="h2"
										fontWeight={'bold'}
										letterSpacing={2}
									>
										{item.count}
									</Typography>
									<Typography variant="h6">{item.title}</Typography>
								</CardContent>
								<CardActions disableSpacing>
									<CustomButton
										label={t('common.view')}
										variant="outlined"
										onClick={() => setTabValue(item.tabVal)}
										styles={{
											backgroundColor: colors.primary,
											width: '50%',
										}}
									/>
								</CardActions>
							</Card>
						</Grid>
					);
			})}
		</Grid>
	);
};
