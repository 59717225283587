export interface IStation {
	id?: string;
	name: string;
	address: string;
	state: string;
	country: string;
	zip: string;
	longitude: string;
	latitude: string;
	image1: string;
	image2: string;
	image3: string;
	create_time?: string;
	update_time?: string;
	partnerInfoId: string;
	stationUniqueCode?: string;
}

export const defaultStation: IStation = {
	id: '',
	name: '',
	address: '',
	state: '',
	country: '',
	zip: '',
	longitude: '',
	latitude: '',
	image1: '',
	image2: '',
	image3: '',
	partnerInfoId: '',
};
