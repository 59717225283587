export const gunDetailAdapter = (data: any[]): any[] => {
	const newData: any[] = data.map((item: any) => {
		return {
			...item,
			day: <>{item.day}</>,
			price: <>${item.price}</>,
		};
	});
	return newData;
};
