import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { defaultAlertValue } from '../interfaces/alert.interface';
import { RootState } from '../store';
import { setAlert } from '../store/modules/snackbar';
import { getColorBackground, getColorLabel } from '../utils/color';

const CustomSnackbar = () => {
	const { show, message, severity } = useSelector((state: RootState) => state.snackbar);
	const colors = useSelector((state: RootState) => state.colors);
	const dispatch = useDispatch();
	return (
		<Snackbar
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			open={show}
			autoHideDuration={5000}
			onClose={() => dispatch(setAlert(defaultAlertValue))}
		>
			<Alert
				severity={severity}
				sx={{
					width: '100%',
					borderRadius: 5,
					backgroundColor: getColorBackground(severity),
					color: colors.primary,
					fontWeight: 'bold',
				}}
				icon={null}
				variant="filled"
				onClose={() => dispatch(setAlert(defaultAlertValue))}
			>
				{message}
			</Alert>
		</Snackbar>
	);
};
export default CustomSnackbar;
