import styles from './styles/station-card-styles.component.module.css';
import Icon from '../../../assets/svgs/station-icon.svg';
import CustomButton from '../../../components/button.component';

import { Edit } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useUserPermisions } from '../../../hooks/useUserPermissions';

interface IStationCardDetails {
	id: string;
	name?: string;
	location?: string;
}

const StationCardDetails = ({ id, name, location }: IStationCardDetails) => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();

	const { getStationsPermission } = useUserPermisions();

	return (
		<div className={styles.mainContainer}>
			<div className={styles.imgContainer}>
				<img src={Icon} alt="icon" height={'15px'} width={'15px'} />
			</div>
			<span className={styles.name}>{name || 'Station 01'}</span>
			<span className={styles.address}>
				{location || 'Robert Robertson, 1234 NW Bobcat Lane, St. Robert'}
			</span>
			{getStationsPermission() && (
				<CustomButton
					label={t('common.edit')}
					variant={'solid'}
					onClick={() => navigate(`/stations/edit-station/${id}`)}
					styles={{
						width: '118px',
						gap: '11px',
						backgroundColor: 'var(--color-black-main)',
					}}
					icon={Edit}
				/>
			)}
		</div>
	);
};

export default StationCardDetails;
