import React, { useEffect, useState } from 'react';

import styles from './styles/gun-card.component.module.css';

import plugImg from '../../../assets/svgs/plug.svg';
import { HighlightOff } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useApiRequest } from '../../../hooks/useApiRequest';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { useTranslation } from 'react-i18next';

interface IGunCard {
	id: string;
	name: string;
	location: string;
	chargerName: string;
	onClick?: (id: string, selected: boolean) => void;
	clickable?: boolean;
	onRemove?: (id: string) => void;
}

const GunCard = ({
	id,
	name,
	onClick,
	clickable = true,
	onRemove,
	chargerName,
	location,
}: IGunCard) => {
	const [t] = useTranslation('global');
	const colors = useSelector((state: RootState) => state.colors);
	const [selected, setSelected] = useState<boolean>(false);

	const handleClick = () => {
		if (onClick !== undefined) onClick(id, !selected);
		setSelected(!selected);
	};

	const handleRemove = () => {
		if (onRemove !== undefined) onRemove(id);
	};

	return (
		<div
			className={styles.cardContainer}
			style={{
				borderColor: selected
					? colors.secondary
					: hexToRGBA(colors.secondary, 0.5),
				borderWidth: selected ? '2px' : '1px',
				cursor: clickable ? 'pointer' : 'default',
				backgroundColor: selected
					? hexToRGBA(colors.secondary, 0.3)
					: hexToRGBA(colors.secondary, 0.1),
			}}
			onClick={clickable ? handleClick : () => {}}
		>
			{!clickable ? (
				<div
					style={{ marginLeft: 'auto', cursor: 'pointer' }}
					onClick={handleRemove}
				>
					<HighlightOff style={{ width: '16px', height: '16px' }} />
				</div>
			) : null}
			<img src={plugImg} alt="PlugImg" width={30} height={30} />
			<span className={styles.name}>{name}</span>
			<div className={styles.infoContainer}>
				<span>{t('dashboard-4.charger-asigned')}</span>
				<span>{chargerName}</span>
			</div>
			<div className={styles.infoContainer}>
				<span>{t('common.location')}</span>
				<span className={styles.infoDesc}>{location}</span>
			</div>
		</div>
	);
};

export default GunCard;
