export const getJWTInfo = (data?: string) => {
	const dataUrl = data
		? data.split('.')[1]
		: localStorage.getItem('accessToken')?.split('.')[1];
	const dataB64 = dataUrl!.replace(/-/g, '+').replace(/_/g, '/');
	const jsonPayload = decodeURIComponent(
		window
			.atob(dataB64!)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);
	const userInfo = JSON.parse(jsonPayload);
	return userInfo;
};
