import { createTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

export function useSharedReport() {
	const colors = useSelector((state: RootState) => state.colors);

	const tabsTheme = createTheme({
		components: {
			MuiTabs: {
				styleOverrides: {
					root: {
						height: '78px',
						'& .MuiTabs-indicator': {
							backgroundColor: colors.primary,
						},
					},
				},
			},
			MuiTab: {
				styleOverrides: {
					root: {
						opacity: 0.5,
						span: {
							color: colors.primary,
							fontWeight: '600',
						},
						'& .MuiSvgIcon-root': {
							color: colors.primary,
							fontSize: '35px',
						},
						'&:hover': {
							transition: 'all 0.25s ease',
							transform: 'scale(1.1)',
							opacity: 1,
							span: { fontWeight: '600' },
						},
						'&.Mui-selected': {
							opacity: 1,
							'& .MuiSvgIcon-root': {
								fontSize: '40px',
							},
							span: {
								fontWeight: '600',
							},
							'&:hover': {
								transform: 'none',
								opacity: 1,
							},
						},
					},
				},
			},
		},
	});

	return { tabsTheme };
}
