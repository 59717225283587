import { ChangeEvent, useEffect, useState } from 'react';
import { ImagesKeys } from '../interfaces/images-keys.interface';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../store/modules/loading';
import { Badge, Paper } from '@mui/material';
import { CustomTooltip } from '../../../components/button-icon-small.component';
import { Cancel, Edit, ImageOutlined } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';

export interface ISvgUploader {
	name: ImagesKeys;
	label: string;
	width: number;
	height: number;
	value: string | null;
	disabled: (name: ImagesKeys) => boolean;
	onChange: (name: ImagesKeys, value: string) => void;
}

const SvgUploader = ({
	name,
	label,
	width,
	height,
	value,
	disabled,
	onChange,
}: ISvgUploader) => {
	//const [svgValue, setSvgValue] = useState<string | null>(null);
	const [t] = useTranslation('global');
	const dispatch = useDispatch();

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];

		if (file && file.type === 'image/svg+xml') {
			const reader = new FileReader();

			reader.onload = function (e) {
				const svgContent = e.target?.result as string;
				const parser = new DOMParser();
				const doc = parser.parseFromString(svgContent, 'image/svg+xml');
				const svgElement = doc.documentElement;

				// Verificar dimensiones específicas (ancho y alto)
				const requiredWidth = width; // ajusta según tus necesidades
				const requiredHeight = height; // ajusta según tus necesidades

				const svgWidth = svgElement.getAttribute('width');
				const svgHeight = svgElement.getAttribute('height');

				// Verificar fondo transparente
				const backgroundColor = svgElement.getAttribute('style');
				const isTransparent =
					!backgroundColor ||
					backgroundColor.includes('background: transparent');

				if (
					svgWidth &&
					svgHeight &&
					parseInt(svgWidth) === requiredWidth &&
					parseInt(svgHeight) === requiredHeight &&
					isTransparent
				) {
					const base64Svg = btoa(unescape(encodeURIComponent(svgContent)));
					onChange(name, base64Svg);
				} else {
					dispatch(
						setAlert({
							show: true,
							message: 'File does not fit dimensions.',
							severity: 'error',
						})
					);
				}
			};

			reader.readAsText(file);
		} else {
			dispatch(
				setAlert({
					show: true,
					message: 'Select a valid SVG file.',
					severity: 'error',
				})
			);
		}
	};

	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				gap: '10px',
				justifyContent: 'center',
				alignItems: 'center',
			}}
		>
			<span style={{ fontWeight: '600' }}>{`${label} (${width} x ${height})`}</span>
			{value !== '' ? (
				<div>
					<Badge
						badgeContent={
							<div
								onClick={() => onChange(name, '')}
								style={{ cursor: 'pointer' }}
							>
								<CustomTooltip
									title={t('common.delete-img')}
									placement="top"
								>
									<Cancel color="error" style={{ fontSize: 'large' }} />
								</CustomTooltip>
							</div>
						}
					>
						<img src={`data:image/svg+xml;base64,${value}`} />
					</Badge>
				</div>
			) : (
				<label>
					<input
						type="file"
						name={name}
						accept=".svg"
						onChange={handleFileChange}
					/>
					<Badge
						badgeContent={
							disabled(name) ? null : (
								<CustomTooltip
									title={t('common.add-img') + ' (SVG)'}
									placement="top"
								>
									<Edit color="primary" style={{ fontSize: 'large' }} />
								</CustomTooltip>
							)
						}
					>
						<Paper>
							<ImageOutlined
								style={{
									width: '100px',
									height: '100px',
									color: disabled(name) ? 'gray' : 'black',
									cursor: disabled(name) ? 'default' : 'pointer',
								}}
							/>
						</Paper>
					</Badge>
				</label>
			)}
		</div>
	);
};

export default SvgUploader;
