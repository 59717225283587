import SettingsValues from './settings-values.component';

const SettingsVals = () => {
	return (
		<div
			style={{
				backgroundColor: 'white',
				borderRadius: '8px',
				padding: '20px 10px',
			}}
		>
			<SettingsValues />
		</div>
	);
};

export default SettingsVals;
