/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useTableCustom } from '../../../hooks/useTableCustom';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setAlert } from '../../../store/modules/snackbar';
import { useSuperAdminGunsAdapter } from '../adapters/guns.adapter';
import { setLoading } from '../../../store/modules/loading';
import { IPartnerInfo } from '../../../interfaces/partner';
import { useNavigate } from 'react-router-dom';
import { ICharger } from '../../../interfaces/charger.interface';
import { IGun } from '../../../interfaces/gun.interface';

export function useSuperAdminGuns() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { data, setData } = useTableCustom<any>();
	const { gunsAdapter } = useSuperAdminGunsAdapter();

	const [partnerInfo, setPartnerInfo] = useState<IPartnerInfo[]>([]);
	const [chargers, setChargers] = useState<ICharger[]>([]);

	const {
		execute: getPartners,
		value: getPartnersVal,
		status: getPartnersStatus,
	} = useBackendRequest<any, IPartnerInfo[]>({
		path: 'partners-information',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getPartnersStatus === 'success') {
			if (getPartnersVal?.data) {
				getChargers();
				setPartnerInfo(getPartnersVal?.data);
			}
		} else if (getPartnersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnersStatus]);

	const {
		execute: getChargers,
		value: getChargersVal,
		status: getChargersStatus,
	} = useBackendRequest<{}, ICharger[]>({
		path: 'chargers',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const {
		execute: getGuns,
		value: getGunsVal,
		status: getGunsStatus,
	} = useBackendRequest<{}, IGun[]>({
		path: 'guns',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	useEffect(() => {
		if (getGunsStatus === 'success') {
			if (getGunsVal?.data) {
				setData({
					...data,
					countRows: 0,
					rows: gunsAdapter(getGunsVal.data, partnerInfo, seeGunDetails),
				});
				dispatch(setLoading({ loading: false }));
			} else {
				dispatch(setLoading({ loading: false }));
				dispatch(
					setAlert({
						show: true,
						message: t('guns.error-data'),
						severity: 'error',
					})
				);
			}
		} else if (getGunsStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('guns.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getGunsStatus]);

	useEffect(() => {
		if (getChargersStatus === 'success') {
			if (getChargersVal?.data) {
				setChargers(getChargersVal?.data);
				getGuns();
			} else {
				setChargers([]);
			}
		} else if (getChargersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('chargers.error-data'),
					severity: 'error',
				})
			);
		}
	}, [getChargersStatus]);

	const getChargerName = (id: string): string => {
		const charger = chargers.filter((c) => c.id === id)[0];
		let name = '';
		if (charger !== undefined) name = charger.name;
		return name;
	};

	const seeGunDetails = (gun: IGun): void => {
		navigate(`/partners/detail-gun/${gun.qionGunUniqueId}`, {
			state: {
				charger: getChargerName(gun.chargerId),
				plugtype: gun.plugType,
				gunId: gun.id,
				gunName: gun.name,
			},
		});
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getPartners();
	}, []);

	return { data, setData };
}
