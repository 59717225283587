import { useTranslation } from 'react-i18next';
import { IColumns } from '../../../../../../hooks/useTableCustom';

export function useGunDetailColums() {
	const [t] = useTranslation('global');
	const gunDetailColumns: IColumns[] = [
		{
			text: t('guns.day'),
			nameID: 'day',
		},
		{
			text: `${t('guns.price')} Kw/h`,
			nameID: 'price',
			width: '300px',
		},
	];

	return { gunDetailColumns };
}
