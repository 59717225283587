// React dependencies
import { useNavigate } from 'react-router-dom';

// Third-party libraries
import { Avatar, Grid, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';

// Style dependencies
import styles from '../styles/confirm-modal.module.css';

// Resources
import CustomButton from '../../../components/button.component';
import CustomModal from '../../../components/modal.component';

interface ICustomModal {
	openModal: boolean;
	setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
	onResetForm?: () => void;
}

export const ConfirmModalComponent = ({
	openModal,
	setOpenModal,
	onResetForm,
}: ICustomModal) => {
	const navigate = useNavigate();
	return (
		<CustomModal open={openModal} maxWidth="lg">
			<Grid container className={styles.customModal}>
				<Grid
					item
					display="flex"
					alignItems="center"
					flexDirection="column"
					justifyContent="center"
					gap={3}
					xs={12}
					className={styles.boxIcon}
				>
					<Avatar className={styles.avatarCheck}>
						<CheckIcon />
					</Avatar>
					<Typography
						variant="h5"
						fontWeight="bold"
						color="var(--color-gray-main)"
						fontSize={31}
						textAlign="center"
					>
						Registration successful!
					</Typography>
				</Grid>
				<Grid
					container
					item
					xs={12}
					display="flex"
					justifyContent="flex-end"
					gap={2}
				>
					<Grid item xs={4} md={2}>
						<CustomButton
							label="New register"
							onClick={() => {
								//onResetForm();
								setOpenModal(false);
							}}
							variant="solid"
							styles={{ backgroundColor: 'var(--color-black-main)' }}
						/>
					</Grid>
					<Grid item xs={4} md={2}>
						<CustomButton
							label="Ok"
							onClick={() => {
								navigate(-1);
							}}
							variant="solid"
							styles={{
								backgroundColor: 'var(--color-secondary-main)',
							}}
						/>
					</Grid>
				</Grid>
			</Grid>
		</CustomModal>
	);
};
