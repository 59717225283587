import { Grid } from '@mui/material';

import styles from './styles/create-gun.module.css';
import Input from '../../components/input.component';
import { useCreateGunForm } from './utils/create-gun-form';
import { useCreateGun } from './hooks/useCreateGun';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../components/button.component';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ADMIN_TAB_VALUES } from '../../constants/tab-values.constants';
import SelectInput from '../../components/select-input.component';
import DeleteModal from './components/delete-modal.component';
import { IGun } from '../../interfaces/gun.interface';
import { ROLES } from '../../constants/user-roles.constants';
import { useGetPartners } from '../create-station/hooks/useGetPartners';

const CreateGun = () => {
	const [t] = useTranslation('global');
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);
	const { role } = useSelector((state: RootState) => state.user);
	const { simulating } = useSelector((state: RootState) => state.simulation);
	const { createGunForm } = useCreateGunForm();

	const { partnerOptions } = useGetPartners();

	const {
		view,
		gunData,
		disabledButton,
		openModal,
		chargersSelect,
		qionChargersSelect,
		gunsSelect,
		handleSetData,
		handleChargerSelectChange,
		handleGunSelectChange,
		setOpenModal,
		handleAddGun,
		editGun,
		deleteGun,
		handlePartnerSelectChange,
	} = useCreateGun();

	return (
		<>
			<Grid container spacing={3} className={styles.mainContainer}>
				<Grid item xs={12}>
					<h3 className={styles.customH3}>
						{view === 'add-gun' ? t('guns.create') : t('guns.edit')}
					</h3>
				</Grid>
				{role.roleName === ROLES.SUPER_ADMIN && !simulating && (
					<>
						<Grid item xl={4} lg={4} md={4} sm={12}>
							<SelectInput
								name="partnerId"
								id="partnerId"
								label={t('common.partner').toString()}
								placeholder={t('common.required').toString()}
								style={{ width: '100%' }}
								required
								options={partnerOptions}
								onChange={handlePartnerSelectChange}
								value={gunData.partnerInfoId}
							/>
						</Grid>
						<Grid item xs={12}></Grid>
					</>
				)}
				<Grid item md={4} sm={12} xs={12}>
					<SelectInput
						name="chargerId"
						id="chargerId"
						label={t('common.charger').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={chargersSelect}
						onChange={handleChargerSelectChange}
						value={gunData.chargerId}
					/>
				</Grid>
				<Grid item xs={12}></Grid>
				{createGunForm.map((element) => (
					<Grid key={element.id} item md={4} sm={12} xs={12}>
						<Input
							id={element.id}
							name={element.id}
							label={element.label}
							value={gunData[element.id as keyof IGun]}
							placeholder={t('common.required').toString()}
							style={{ height: '43px', paddingLeft: '14px' }}
							onChange={handleSetData}
							type="text"
							required
							tooltip={element.tooltip}
						/>
					</Grid>
				))}
				<Grid item xs={12}>
					<h3 className={styles.customH3}>{t('guns.associate-to')}</h3>
				</Grid>
				{view !== 'add-gun' && (
					<Grid item xs={12}>
						<span className={styles.customH3}>
							<span>
								<span
									style={{
										color: colors.secondary,
										fontWeight: 'bold',
										fontSize: '18px',
									}}
								>
									*
								</span>{' '}
								{t('guns.actually-msg')} <b>{gunData.qionGunUniqueId}</b>
							</span>
						</span>
					</Grid>
				)}
				{/* <Grid item md={4} sm={12} xs={12}>
					<SelectInput
						name="qionCharger"
						id="qionCharger"
						label={t('guns.select-charger').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={qionChargersSelect}
						onChange={handleChargerSelectChange}
					/>
				</Grid> */}
				<Grid item md={4} sm={12} xs={12}>
					<SelectInput
						name="qionGun"
						id="qionGun"
						label={t('guns.associate-gun').toString()}
						placeholder={t('common.required').toString()}
						style={{ width: '100%' }}
						required
						options={gunsSelect}
						onChange={handleGunSelectChange}
						disabled={gunsSelect.length < 1}
						value={gunData.qionGunUniqueId}
					/>
				</Grid>
				<Grid item md={4} sm={12} xs={12}>
					<Input
						id={'plugType'}
						name={'plugType'}
						label={t('common.plugtype').toString()}
						value={gunData.plugType}
						placeholder={t('common.required').toString()}
						style={{ height: '43px', paddingLeft: '14px' }}
						onChange={handleSetData}
						type="text"
						disabled
					/>
				</Grid>
				<Grid
					item
					container
					xs={12}
					display={'flex'}
					justifyContent={view !== 'add-gun' ? 'space-between' : 'end'}
					gap={5}
					marginTop={3}
				>
					{view !== 'add-gun' && (
						<CustomButton
							label={t('common.delete')}
							variant={'solid'}
							onClick={() => setOpenModal(true)}
							styles={{
								width: '120px',
								backgroundColor: 'var(--color-error-main)',
								padding: '0 15px',
								gap: '10px',
							}}
						/>
					)}
					<div style={{ display: 'flex', gap: '20px' }}>
						<CustomButton
							label={t('common.back')}
							variant={'solid'}
							onClick={
								role.roleName === ROLES.SUPER_ADMIN && !simulating
									? () => navigate('/partners/all-guns')
									: view !== 'add-gun'
									? () => navigate(-1)
									: () =>
											navigate('/partners/details', {
												state: { tab: ADMIN_TAB_VALUES.GUNS },
											})
							}
							styles={{
								width: '120px',
								backgroundColor: 'black',
								padding: '0 15px',
								gap: '10px',
							}}
						/>
						<CustomButton
							label={t('common.save')}
							variant={'solid'}
							onClick={view !== 'add-gun' ? editGun : handleAddGun}
							styles={{
								width: '120px',
								backgroundColor: colors.accent,
								color: 'black',
								padding: '0 15px',
								gap: '10px',
							}}
							disabled={disabledButton}
						/>
					</div>
				</Grid>
			</Grid>
			<DeleteModal
				open={openModal}
				setOpen={setOpenModal}
				name={gunData.name}
				acceptModal={deleteGun}
			/>
		</>
	);
};

export default CreateGun;
