import { IColumns } from '../../../hooks/useTableCustom';

export const gunsColumns: IColumns[] = [
	{
		text: 'Day',
		nameID: 'day',
	},
	{
		text: 'Price Kw/h',
		nameID: 'price',
	},
];
