import { IAssociatesGroup, ISchedule } from '../interfaces/partner';

export const schedulesDefaultValue: ISchedule = {
	partnerId: 0, //Cambiar > dinámico
	name: '',
	weight: '',
	status: true,
	limitedDays: true,
	startDate: null,
	endDate: null,
	days: [],
	allDay: false,
	startTime: '',
	endTime: '',
	associateGroupId: 0,
	guns: [], //Cambiar > dinámico
};

export const associateGroupDefaultVaule: IAssociatesGroup = {
	id: 0,
	firstInvoiceAssociate: 0,
	group: '',
	chargeCost: '',
	chargeFactor: '',
	parkingCost: '',
	parkingFactor: '',
	associates: [],
};
