import React from 'react';
import { Navigate } from 'react-router-dom';
// import { RootState } from '../store';
// import { useSelector } from 'react-redux';
// import { isEmpty } from '../utils/validators';
import Layout from '../components/LayoutComp';

interface IProtectedRoute {
	component: React.ComponentType;
}

const ProtectedRoute = ({ component: RouteComponent }: IProtectedRoute) => {
	//const user = useSelector((state: RootState) => state.users);

	const token = localStorage.getItem('validToken');

	if (token) {
		return (
			<Layout>
				<RouteComponent />
			</Layout>
		);
	}

	return <Navigate to="/" />;
};

export default ProtectedRoute;
