import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	IAddPartnerParams,
	defaultPartner,
} from '../../add-partner/interfaces/add-partner.interface';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setLoading } from '../../../store/modules/loading';
import { setAlert } from '../../../store/modules/snackbar';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isEmpty, isNumberValidate, zipRegex } from '../../../utils/validators';

export function useEditPartner() {
	const [t] = useTranslation('global');
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { id } = useParams();
	const [partnerInfo, setPartnerInfo] = useState<IAddPartnerParams>(defaultPartner);

	const disabledButton = useMemo((): boolean => {
		if (
			isEmpty(partnerInfo.name) ||
			isEmpty(partnerInfo.stripeCode) ||
			isEmpty(partnerInfo.responsibleName) ||
			isEmpty(partnerInfo.responsibleLastName) ||
			isEmpty(partnerInfo.address) ||
			isEmpty(partnerInfo.state) ||
			isEmpty(partnerInfo.country) ||
			partnerInfo.zip.length < 1 ||
			isEmpty(partnerInfo.rfc) ||
			partnerInfo.phone.length < 10 ||
			isEmpty(partnerInfo.email) ||
			isEmpty(partnerInfo.img) ||
			partnerInfo.languages?.length < 1 ||
			isEmpty(partnerInfo.hold)
		) {
			return true;
		}

		return false;
	}, [partnerInfo]);

	const {
		execute: getPartner,
		value: getPartnerVal,
		status: getPartnerStatus,
	} = useBackendRequest<any, IAddPartnerParams>({
		path: 'partners-information/',
		baseURL: process.env.REACT_APP_API,
		method: 'GET',
	});

	const { execute: editPartner, status: editPartnerStatus } = useBackendRequest<
		IAddPartnerParams,
		IAddPartnerParams
	>({
		path: 'partners-information/',
		baseURL: process.env.REACT_APP_API,
		method: 'PUT',
	});

	useEffect(() => {
		if (getPartnerStatus === 'success') {
			setPartnerInfo(getPartnerVal?.data!);
			dispatch(setLoading({ loading: false }));
		} else if (getPartnerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-single-data'),
					severity: 'error',
				})
			);
		}
	}, [getPartnerStatus]);

	useEffect(() => {
		if (editPartnerStatus === 'success') {
			dispatch(
				setAlert({
					show: true,
					message: t('partners.edited'),
					severity: 'success',
				})
			);
			navigate(-1);
		} else if (editPartnerStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: t('partners.error-edited'),
					severity: 'error',
				})
			);
		}
	}, [editPartnerStatus]);

	const handleSetData = (event: React.ChangeEvent<HTMLInputElement>): void => {
		const { name, value } = event.target;
		if (name === 'zip') {
			if (value.length > 0) if (!zipRegex(value)) return;
		} else if (name === 'phone') {
			if (!isNumberValidate(value)) return;
		} else if (name === 'hold') {
			if (!isNumberValidate(value)) return;
		}
		setPartnerInfo({ ...partnerInfo, [name]: value });
	};

	const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (!event.target.files) return;
		const file = event.target.files[0];

		const reader = new FileReader();
		reader.onloadend = function () {
			const base64String = reader.result!.toString().split(',')[1];
			setPartnerInfo({ ...partnerInfo, img: base64String });
		};

		if (file) {
			reader.readAsDataURL(file);
		}
	};

	const handleDeleteImage = (): void => {
		setPartnerInfo({ ...partnerInfo, img: '' });
	};

	const handleEditPartner = () => {
		dispatch(setLoading({ loading: true }));
		editPartner(
			{
				name: partnerInfo.name,
				responsibleName: partnerInfo.responsibleName,
				responsibleLastName: partnerInfo.responsibleLastName,
				address: partnerInfo.address,
				state: partnerInfo.state,
				country: partnerInfo.country,
				zip: partnerInfo.zip,
				rfc: partnerInfo.rfc,
				phone: partnerInfo.phone,
				email: partnerInfo.email,
				password: partnerInfo.password,
				img: partnerInfo.img,
				externalPartnerId: partnerInfo.externalPartnerId,
				stripeCode: partnerInfo.stripeCode,
				languages: partnerInfo.languages,
				hold: partnerInfo.hold,
			},
			id
		);
	};

	const handleLanguages = (langs: string[]): void => {
		setPartnerInfo({ ...partnerInfo, languages: langs });
	};

	useEffect(() => {
		dispatch(setLoading({ loading: true }));
		getPartner({}, id);
	}, []);

	return {
		partnerInfo,
		disabledButton,
		handleSetData,
		handleImageChange,
		handleDeleteImage,
		handleEditPartner,
		handleLanguages,
	};
}
