import React from 'react';

import styles from '../styles/associate-group-details.component.module.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { selectedSection } from '..';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material';

interface ITabCard {
	selected: boolean;
	name: string;
	icon: any;
	setSelectedTo: selectedSection;
	setSelectedSection: React.Dispatch<React.SetStateAction<selectedSection>>;
}

const TabCard = ({
	selected,
	name,
	icon: Icon,
	setSelectedTo,
	setSelectedSection,
}: ITabCard) => {
	const colors = useSelector((state: RootState) => state.colors);
	return (
		<div
			className={selected ? `${styles.section} ${styles.selected}` : styles.section}
			style={{
				backgroundColor: selected ? colors.secondary : 'white',
			}}
			onClick={() => setSelectedSection(setSelectedTo)}
		>
			<Icon
				style={{
					width: '30px',
					height: '30px ',
					color: selected ? 'white' : colors.secondary,
				}}
			/>
			<span>{name}</span>
		</div>
	);
};

export default TabCard;
