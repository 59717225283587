import { useEffect, useState } from 'react';

import { Grid } from '@mui/material';
import { ArrowBack, PinDropOutlined } from '@mui/icons-material';

import SelectInput from '../../../components/select-input.component';
import CustomButton from '../../../components/button.component';
import StationDatailCard from '../components/stationDetailCard';
import Map from '../../../components/map';
import CardCharger from '../components/cardCharger';

import styles from '../styles.module.css';
import { useLocationDashboard } from './hooks/useLocationDashboard';
import { IDashboardLocationGun } from './interfaces/location-dashboard.interface';
import { useTranslation } from 'react-i18next';

const LocationDashboard = () => {
	const [t] = useTranslation('global');
	const dataStatus = [
		{
			label: t('charging-status.all'),
			value: 'all',
		},
		{
			label: t('charging-status.charging'),
			value: 'Charging',
		},
		{
			label: t('charging-status.not-charging'),
			value: 'Not charging',
		},
	];

	const {
		selectData,
		selectedStation,
		viewStationdata,
		gunsInfo,
		chargers: stationChargers,
		handleSetData,
		setViewStationdata,
		handleBack,
	} = useLocationDashboard();

	const [gunsFiltered, setGunsFiltered] = useState<IDashboardLocationGun[]>([]);

	const handleSetStatus = (event: any) => {
		const { value } = event.target;
		if (value === 'all') {
			setGunsFiltered(gunsInfo);
		} else if (value === 'Not charging') {
			const filtered = gunsInfo.filter((g) => g.status === false);
			setGunsFiltered(filtered);
		} else if (value === 'Charging') {
			const filtered = gunsInfo.filter((g) => g.status === true);
			setGunsFiltered(filtered);
		}
	};

	useEffect(() => {
		setGunsFiltered(gunsInfo);
	}, [gunsInfo]);

	return (
		<Grid container display={'flex'}>
			<Grid item sm={12}>
				<h1 className={styles.title}>{t('dashboard-3.location')}</h1>
			</Grid>
			{!viewStationdata && (
				<Grid item sm={12}>
					<div className={styles.containerSelectloaction}>
						<div className={styles.containerSelect}>
							<div className={styles.iconLocation}>
								<PinDropOutlined fontSize="inherit" htmlColor="#005C53" />
							</div>
							<div>
								<SelectInput
									name="station"
									id="station"
									label=""
									placeholder={t(
										'dashboard-3.select-station'
									).toString()}
									style={{ width: '100%' }}
									required
									options={selectData}
									onChange={handleSetData}
									value={selectedStation.id}
								/>
								<CustomButton
									label={t('dashboard-3.see-location')}
									onClick={() => {
										setViewStationdata(true);
									}}
									variant="solid"
									styles={{
										backgroundColor: 'var(--color-black-main)',
										height: '45px',
										width: '165px',
										margin: '50px auto 0',
									}}
									disabled={selectedStation.name === ''}
								/>
							</div>
						</div>
					</div>
				</Grid>
			)}
			{viewStationdata && (
				<Grid item sm={12}>
					<Grid container display={'flex'}>
						<Grid item md={4} sm={12} xs={12}>
							<StationDatailCard
								name={selectedStation.name}
								location={`${selectedStation.address}, ${selectedStation.state}, ${selectedStation.country}. ${selectedStation.zip}`}
								guns={gunsInfo.length}
								chargers={stationChargers.length}
							/>
							<div
								className={styles.cardContainer}
								style={{ height: '310px' }}
							>
								<Map
									stations={[
										{
											id: selectedStation.id!,
											name: selectedStation.name,
											location: [
												parseFloat(selectedStation.latitude),
												parseFloat(selectedStation.longitude),
											],
										},
									]}
									center={[
										parseFloat(selectedStation.latitude),
										parseFloat(selectedStation.longitude),
									]}
									zoom={4}
								/>
							</div>
						</Grid>
						<Grid item md={8} sm={12}>
							<div style={{ paddingLeft: '20px' }}>
								<div
									className={styles.cardContainer}
									style={{ marginBottom: '20px' }}
								>
									<SelectInput
										name="status"
										id="status"
										label=""
										placeholder={t('dashboard-3.status').toString()}
										style={{ width: '50%' }}
										required
										options={dataStatus}
										onChange={handleSetStatus}
									/>
									<CustomButton
										label={t('common.back')}
										onClick={handleBack}
										variant="solid"
										styles={{
											backgroundColor: 'var(--color-black-main)',
											width: 'fit-content',
											padding: '0 15px',
											gap: '10px',
										}}
										icon={ArrowBack}
									/>
								</div>
								<div className={styles.cardContainer}>
									{gunsFiltered.length > 0 ? (
										<div className={styles.containerCards}>
											{gunsFiltered.map(
												(item: IDashboardLocationGun) => (
													<CardCharger
														data={item}
														key={item.id}
													/>
												)
											)}
										</div>
									) : (
										<span>{t('dashboard-3.no-guns-charging')}</span>
									)}
								</div>
							</div>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};

export default LocationDashboard;
