import { IAssociatesTab } from '../interfaces/associates.interfaces';

export function useAssociatesTabAdapter() {
	//const [t] = useTranslation('global');

	const associatesTabAdapter = (data: IAssociatesTab[]): any[] => {
		const newData: any[] = data.map((item: IAssociatesTab) => {
			return {
				...item,
				total: <>${item.total}</>,
			};
		});
		return newData;
	};

	return { associatesTabAdapter };
}
