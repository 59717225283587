import { useDispatch } from 'react-redux';
import { useBackendRequest } from '../../../hooks/useBackendRequest';
import { setAlert } from '../../../store/modules/snackbar';
import { useEffect, useState } from 'react';
import { IOptions } from '../../../components/select-input.component';
import { setLoading } from '../../../store/modules/loading';
import { IPartnersLoginResponse } from '../interfaces/login.interface';

export function useValidateMultipartner() {
	const dispatch = useDispatch();
	const [hasMultipartners, setHasMultipartners] = useState(false);
	const [partnersOptions, setPartnersOptions] = useState<IOptions[]>([]);
	const [selectedPartner, setSelectedPartner] = useState<string>('');

	//Get partners from user
	const {
		execute: getUsersPartners,
		value: getUsersPartnersVal,
		status: getUsersPartnersStatus,
	} = useBackendRequest<
		{ user_system_type: number; email: string },
		IPartnersLoginResponse
	>({
		path: 'users/getPartnersLogin',
		baseURL: process.env.REACT_APP_API,
		method: 'POST',
	});

	useEffect(() => {
		if (getUsersPartnersStatus === 'success') {
			if (getUsersPartnersVal?.data) {
				if (getUsersPartnersVal?.data.partnersLoginList.length > 0) {
					if (getUsersPartnersVal.data.partnersLoginList.length === 1) {
						setSelectedPartner(
							getUsersPartnersVal.data.partnersLoginList[0].id
						);
						dispatch(setLoading({ loading: false }));
						return;
					}
					setHasMultipartners(true);
					const options: IOptions[] =
						getUsersPartnersVal.data.partnersLoginList.map((p) => {
							return {
								label: p.name,
								value: p.id,
							};
						});
					setPartnersOptions(options);
				} else {
					dispatch(
						setAlert({
							show: true,
							message: 'No account registered with this email.',
							severity: 'error',
						})
					);
					setHasMultipartners(false);
					setPartnersOptions([]);
				}
			} else {
				dispatch(
					setAlert({
						show: true,
						message: 'Error',
						severity: 'error',
					})
				);
			}
			dispatch(setLoading({ loading: false }));
		} else if (getUsersPartnersStatus === 'error') {
			dispatch(setLoading({ loading: false }));
			dispatch(
				setAlert({
					show: true,
					message: 'Error',
					severity: 'error',
				})
			);
		}
	}, [getUsersPartnersStatus]);

	const handleEmailBlur = (email: string): void => {
		dispatch(setLoading({ loading: true }));
		getUsersPartners({ email: email, user_system_type: 2 });
	};

	const getSelectedPartner = (): string => {
		return selectedPartner;
	};

	return {
		hasMultipartners,
		partnersOptions,
		selectedPartner,
		handleEmailBlur,
		setSelectedPartner,
		getSelectedPartner,
	};
}
