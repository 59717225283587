// Third-party libraries
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import ButtonIconSmall from '../../../components/button-icon-small.component';
// Resources

import { Delete, Edit, Person } from '@mui/icons-material';
import { hexToRGBA } from '../../../utils/hex-to-rgba';
import { IPartnerInfo } from '../../../interfaces/partner';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function usePartnersApter() {
	const navigate = useNavigate();
	const colors = useSelector((state: RootState) => state.colors);
	const [t] = useTranslation('global');
	const partnersAdapter = (
		data: IPartnerInfo[],
		handleSeeDetails: (data: IPartnerInfo) => void,
		handleOpenModal: (partner: IPartnerInfo) => void
	): any[] => {
		const newData: any[] = data.map((item: IPartnerInfo) => {
			return {
				...item,
				img: (
					<>
						{item.img.length > 20 ? (
							<div
								style={{
									display: 'grid',
									placeItems: 'center',
									backgroundColor: hexToRGBA(colors.secondary, 0.8),
									height: '70px',
									borderRadius: '8px',
								}}
							>
								<img
									src={`data:image/png;base64,${item.img}`}
									style={{ width: '50px', height: '50px' }}
								/>
							</div>
						) : (
							<div
								style={{
									display: 'grid',
									placeItems: 'center',
									height: '70px',
									borderRadius: '8px',
								}}
							>
								<Person style={{ width: '50px', height: '50px' }} />
							</div>
						)}
					</>
				),
				responsibleName: (
					<>{`${item.responsibleName} ${item.responsibleLastName}`}</>
				),
				address: (
					<>{`${item.address}, ${item.state}, ${item.country}. ${item.zip}.`}</>
				),
				actions: (
					<div style={{ display: 'flex', gap: '10px' }}>
						<ButtonIconSmall
							onPress={() => handleSeeDetails(item)}
							type="primary"
							icon={<VisibilityOutlinedIcon />}
							hoverLabel={`${t('partners.simulate')} ${item.name}`}
						/>
						<ButtonIconSmall
							onPress={() => navigate(`/partners/edit-partner/${item.id}`)}
							type="success"
							icon={<Edit />}
							hoverLabel={`Edit partner ${item.name}`}
						/>
						<ButtonIconSmall
							onPress={() => handleOpenModal(item)}
							type="error"
							icon={<Delete />}
							hoverLabel={t('partners.delete')}
						/>
					</div>
				),
			};
		});
		return newData;
	};

	return { partnersAdapter };
}
