import { Visibility, VisibilityOff } from '@mui/icons-material';
import style from './styles/show-and-hide-password-styles.component.module.css';

interface ShowPasswordProps {
	show: boolean;
	onClick: () => void;
	hasLabel: boolean;
}

export function ShowAndHidePassword({ show, onClick, hasLabel }: ShowPasswordProps) {
	return (
		<button
			className={style.button}
			onClick={onClick}
			tabIndex={-1}
			type="button"
			style={{
				bottom: hasLabel ? 0 : '',
				top: hasLabel ? '' : 0,
			}}
		>
			{show ? <Visibility /> : <VisibilityOff />}
		</button>
	);
}
