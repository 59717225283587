import { InputHTMLAttributes, useState } from 'react';
import { Label } from './label.component';
import { AlertInput } from './alert-input.component';
import { ShowAndHidePassword } from './show-and-hide-password.component';

import style from './styles/input-styles.component.module.css';
import { CustomTooltip } from './button-icon-small.component';

interface ITextareaProps extends InputHTMLAttributes<HTMLInputElement> {
	error?: { valid: boolean; msg: string };
	label?: string;
	name: string;
	notCopy?: boolean;
	notPaste?: boolean;
	containerStyles?: React.CSSProperties;
	tooltip?: string;
}

export default function Input({
	error,
	label,
	name,
	notCopy,
	notPaste,
	containerStyles,
	tooltip,
	...rest
}: ITextareaProps) {
	const [showPassword, setShowPassword] = useState(false);
	const clickShowPassword = () => setShowPassword(!showPassword);
	const type =
		rest.type === 'password' ? (showPassword ? 'text' : 'password') : rest.type;

	const onCopy = (e: any) => {
		if (notCopy) {
			e.preventDefault();
			return false;
		}
	};
	const onPaste = (e: any) => {
		if (notPaste) {
			e.preventDefault();
			return false;
		}
	};
	return (
		<CustomTooltip title={tooltip} placement="top-start">
			<div
				className={`${style.content} ${
					rest.disabled ? style.contentDisabled : ''
				}`}
				style={containerStyles}
			>
				<Label
					label={label}
					name={name}
					required={rest.required}
					disabled={rest.disabled}
				/>
				<div className={style.inputContent}>
					<input
						{...rest}
						className={`${style.input} `}
						type={type}
						name={name}
						onCopy={onCopy}
						onPaste={onPaste}
					/>
					{rest.type === 'password' && (
						<ShowAndHidePassword
							show={showPassword}
							onClick={clickShowPassword}
							hasLabel={label !== undefined}
						/>
					)}
				</div>
				{error && (
					<AlertInput name={name} valid={error.valid} message={error.msg} />
				)}
			</div>
		</CustomTooltip>
	);
}
