import { VisibilityOutlined, Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { IAssociate } from '../../../../interfaces/associate.interface';
import { IAGAssociate } from '../../../partners-group-details/interfaces/associates-group.interface';

export function useAssociatesTabAdapter() {
	const [t] = useTranslation('global');

	const associatesTabAdapter = (
		associates: IAssociate[],
		shares: IAGAssociate[]
	): IAssociate[] => {
		const getShareType = (id: string): JSX.Element => {
			const associate: IAGAssociate = shares.filter((a) => a.associateId === id)[0];
			if (associate.unit) {
				return <>{t('groups.unit')}</>;
			} else {
				return <>{t('groups.fraction')}</>;
			}
		};

		const getValue = (id: string): JSX.Element => {
			const associate: IAGAssociate = shares.filter((a) => a.associateId === id)[0];
			if (associate.unit) {
				return <>{associate.fraction}</>;
			} else {
				return <>{associate.fraction * 100}%</>;
			}
		};

		const newData: any[] = associates.map((item: IAssociate) => {
			return {
				...item,
				name: <>{item.name}</>,
				taxId: <>{item.rfc}</>,
				address: (
					<>{`${item.address}, ${item.state}, ${item.country}. ${item.zip}`}</>
				),
				share: <>{getShareType(item.id!)}</>,
				value: <>{getValue(item.id!)}</>,
			};
		});
		return newData;
	};

	return { associatesTabAdapter };
}
