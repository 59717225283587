import CryptoJS from 'crypto-js';

//const ALGORITHM = 'AES';
//const TRANSFORMATION = 'AES/CBC/PKCS5Padding';

class CipherUtils {
	static encrypt(input, key) {
		try {
			const keySpec = CryptoJS.enc.Utf8.parse(key);
			const iv = CryptoJS.enc.Utf8.parse(''.padStart(16, '\0'));
			const encrypted = CryptoJS.AES.encrypt(input, keySpec, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});
			return encrypted.toString();
		} catch (e) {
			return null;
		}
	}

	static decrypt(input, key) {
		try {
			const keySpec = CryptoJS.enc.Utf8.parse(key);
			const iv = CryptoJS.enc.Utf8.parse(''.padStart(16, '\0'));
			const decrypted = CryptoJS.AES.decrypt(input, keySpec, {
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			});
			return decrypted.toString(CryptoJS.enc.Utf8);
		} catch (e) {
			return null;
		}
	}
}

export default CipherUtils;
